import React from 'react'
import { useRegisterActions } from 'kbar'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useClusters } from 'src/api'
import { ReactComponent as KubernetesIcon } from 'src/images/logo--kubernetes.svg'
import { ClusterIcon } from 'src/next/components/CSP/CSP'

const StyledKubernetesIcon = styled(KubernetesIcon)`
  fill: var(--cds-icon-primary);
`
const StyledClusterIcon = styled(ClusterIcon)`
  height: 20px;
  width: 20px;
`

export const useCLUIRegisterClusters = (enabled = true) => {
  const navigate = useNavigate()

  const query = useClusters({
    select: ({ clusters }) => clusters,
    staleTime: 10000,
    enabled,
  })

  useRegisterActions(
    query.data?.map(cluster => ({
      id: `cluster-${cluster.uuid}` || '',
      name: cluster.name || '',
      icon: cluster.csp !== 'baremetal' && (
        <StyledClusterIcon type={cluster?.csp} />
      ),
      parent: 'clusterFinder',
      perform: () => navigate(`/app/clusters/${cluster.uuid}`),
    })) || [],
    [query?.data],
  )

  useRegisterActions(
    query.data?.map(cluster => ({
      id: `kubernetes-cluster-${cluster.uuid}` || '',
      name: cluster.name || '',
      icon: cluster.csp !== 'baremetal' && <StyledKubernetesIcon />,
      parent: 'kubernetesClusterFinder',
      perform: () => navigate(`/app/kubernetes-resources/${cluster.uuid}/pods`),
    })) || [],
    [query?.data],
  )

  return query
}
