import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Workload } from '@cloudnatix-types/dashboard'
import { Tooltip, TooltipButton } from 'src/next/components/Tooltip'
import { secondsToDhms } from 'src/next/utils'

const NoWrap = styled.span`
  white-space: nowrap;
`

const StyledTooltipButton = styled(TooltipButton)`
  color: var(--data-table-cell-text-color, var(--cds-text-secondary));
  &&:hover {
    color: var(--data-table-cell-text-color, var(--cds-text-secondary));
  }
`

interface WorkloadAgeProps {
  workload: Workload
  hideTooltips?: boolean
}

export const WorkloadAge = ({ workload, hideTooltips }: WorkloadAgeProps) => {
  const { t } = useTranslation()

  if (!workload) {
    return null
  }

  const createdAt = dayjs(Number(workload.createdAt) / 1_000_000)

  const deletedAt =
    workload.deletedAt === '0'
      ? null
      : dayjs(Number(workload.deletedAt) / 1_000_000)

  const duration = deletedAt
    ? deletedAt.diff(createdAt, 'seconds')
    : dayjs().diff(createdAt, 'seconds')
  const durationFriendly = secondsToDhms(duration)

  const ageLabel = <NoWrap>{durationFriendly.split(',')[0]}</NoWrap>

  if (hideTooltips) {
    return ageLabel
  }

  return (
    <Tooltip
      label={
        <div>
          {t('Workload.CreatedAt')}: {createdAt.format('llll')}
          <br />
          {deletedAt ? (
            <>
              {t('Workload.DeletedAt')}: {deletedAt.format('llll')}
              <br />
            </>
          ) : null}
          {t('Workload.Duration')}: {durationFriendly}
        </div>
      }
      align="bottom"
    >
      <StyledTooltipButton>{ageLabel}</StyledTooltipButton>
    </Tooltip>
  )
}
