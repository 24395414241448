import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ComputeInstanceSummary } from '@cloudnatix-types/dashboard'

import { useCurrentOrgInstanceSummaries } from 'src/api'
import {
  useRollupPeriod,
  useTimeRangeOverflowMenu,
} from 'src/next/components/dashboard/timerange'
import { LineGraphProps } from 'src/next/components/Graphs'
import { Graph } from 'src/next/components/Graphs/Graph'
import GraphTooltip from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import Loading from 'src/next/components/Loading'
import { bytesToUserFriendlySize } from 'src/next/utils'
import { buildDayTicks } from 'src/next/utils/graph.utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

import { MetricContainer } from './MetricContainer'
import { useTooltipHeading } from './useTooltipHeading'

export const GpuMemoryMetrics = () => {
  const { t } = useTranslation()

  const id = 'dashboard-metrics-gpu-memory'

  const {
    selectedItem: selectedTimeRange,
    startTimeNs,
    endTimeNs,
  } = useTimeRangeOverflowMenu({
    id: `${id}-time-range`,
  })

  const { selectedItem: duration } = useRollupPeriod({
    id: `${id}-duration`,
    timeRange: selectedTimeRange.id,
  })

  const { data, isLoading } = useCurrentOrgInstanceSummaries({
    duration: duration.id,
    startTimeNs,
    endTimeNs,
  })

  const capacityDataPoints = useMemo(
    () =>
      data?.summaries?.map((summary: ComputeInstanceSummary) => ({
        x: new Date(nanoToMilliSeconds(summary.timestampNs!)),
        y: summary.avgGpuMemoryCapacity!,
      })) || [],
    [data],
  )

  // The range x may take is the same for the used and unused series. Use either of them to build tick values.
  const xTickValues = useMemo(
    () => buildDayTicks(capacityDataPoints),
    [capacityDataPoints],
  )

  const graphConfig = [
    {
      id: 'avg_gpu_memory_capacity',
      label: t('ComputeInstanceSummary.avg_gpu_memory_capacity'),
      tooltipLabel: t('ComputeInstanceSummary.avg_gpu_memory_capacity'),
      tooltipValueTransformFn: (value: unknown) =>
        bytesToUserFriendlySize(Number(value)),
      enabled: true,

      type: 'line' as const,
      data: capacityDataPoints,
      props: {
        style: { data: { stroke: 'var(--carbonPalette4)' } },
      },
    } as LineGraphProps,
  ]

  const getTooltipHeading = useTooltipHeading(duration.id)

  return (
    <MetricContainer id={id} title={t('Common.GPUMemory')}>
      {isLoading ? (
        <Loading centered size="small" withOverlay={false} />
      ) : (
        <>
          <Graph
            data={graphConfig}
            unit="memory"
            height={200}
            xAxis={[
              {
                tickFormat: (x: any) => dayjs(x).format('MM/DD'),
                tickValues: xTickValues,
              },
            ]}
            yAxis={[{}]}
            containerComponent={GraphTooltipContainer(
              <GraphTooltip
                graphConfig={graphConfig}
                heading={getTooltipHeading}
              />,
            )}
          />
        </>
      )}
    </MetricContainer>
  )
}
