import styled, { css } from 'styled-components'

export const LegendList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-items: start;
  font-family: var(--cn-font-family--condensed);
  position: relative;
`

export const LegendListItem = styled.li<{ $hasSelection?: boolean }>`
  display: flex;
  flex: 0 0 auto;
  margin-bottom: var(--cds-spacing-01);
  margin-right: var(--cds-spacing-05);

  ${({ $hasSelection }) =>
    $hasSelection &&
    css`
      label span:first-of-type {
        background: currentColor !important;
        box-shadow: none !important;
        opacity: 0.3;
      }
    `}
`

export const LegendItemLabel = styled.label`
  align-items: center;
  color: var(--cds-text-secondary);
  display: flex;
  font-size: var(--cds-label-01-font-size);
  line-height: var(--cds-label-01-line-height);
  position: relative;
  user-select: none;
`

export const LegendItemColor = styled.span<{ $color: string }>`
  display: block;
  margin-right: var(--cds-spacing-03);
  height: 0.75rem;
  width: 0.75rem;
  color: ${({ $color }) => $color};
`

export const LegendItemCheckmark = styled.svg`
  fill: var(--cds-icon-inverse);
  display: none;
  pointer-events: none;
  position: absolute;
  left: 0.5px;
  margin-top: -1px;
`

export const LegendItemInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:hover:not([disabled]) + .label .color,
  &:focus-visible:not([disabled]) + .label .color {
    outline-color: var(--cds-button-primary);
  }

  &:not([disabled]) + ${LegendItemLabel} {
    cursor: pointer;
  }

  + ${LegendItemLabel} ${LegendItemColor} {
    background-color: transparent;
    box-shadow: 0 0 0 1px var(--cds-icon-secondary);
    border-radius: 1px;
    border: 2px solid transparent;
    outline: 2px solid transparent;
    outline-offset: 1px;
    transition: outline 0.1s ease-out;
  }

  &:checked + ${LegendItemLabel} ${LegendItemColor} {
    border-width: 1px;
    background-color: currentColor;
    border-radius: 1px;
    box-shadow: none;
    opacity: 1;
  }

  &:checked + ${LegendItemLabel} ${LegendItemCheckmark} {
    display: block;
  }
`
