import React, { useMemo } from 'react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { VMConfig } from '@cloudnatix-types/multiclustercontroller'

import { useVMConfigs } from 'src/api/queries/useVMConfigs'
import DataTable, {
  DataTablePagination,
  usePagination,
} from 'src/next/components/DataTable'
import {
  TableFilter,
  TableFilterAccordion,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterCheckbox,
  TableFilterContainer,
  TableFilterContextProvider,
  TableFilterToolbarActions,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { DataTableHeaders } from 'src/next/types/dataTable'

export const Configuration = () => {
  const { t } = useTranslation()

  return (
    <TableFilterContextProvider localStorageId="administration-configuration-filters">
      <TableFilterContainer>
        <TableFilter>
          <TableFilterActiveItems />
          <TableFilterAccordion>
            <TableFilterAccordionItem
              title={t(
                'AdministrativeConfiguration.Datatable.Filters.Status',
                'Status',
              )}
            >
              <TableFilterCheckbox
                id="errorOnly"
                labelText={t(
                  'AdministrativeConfiguration.Datatable.Filters.ErrorOnly',
                  'Only with errors',
                )}
              />
            </TableFilterAccordionItem>
          </TableFilterAccordion>
        </TableFilter>
        <ConfigurationTable />
      </TableFilterContainer>
    </TableFilterContextProvider>
  )
}

const ConfigurationTable = () => {
  const { t } = useTranslation()

  const pagination = usePagination('administration-configuration-page-size')

  const { resetPage, setPage, ...dataTablePaginationProps } = pagination

  const { filters, methods } = useTableFilter()

  // reset pagination when filters change
  methods.watch(() => resetPage())

  const headers = useHeaders()

  const query = useVMConfigs({
    filter: filters,
    pageSize: pagination.pageSize,
  })
  const { data, isFetching, isError } = query

  const currentPageData = data?.pages?.[pagination.page - 1] || {}

  const configs = useMemo(
    () => currentPageData.configs || [],
    [currentPageData.configs],
  )

  const rows = useMemo(() => formatData(configs, t), [configs, t])

  return (
    <div>
      <DataTable
        size="xl"
        storageKey="datatable-administration-configuration"
        isLoading={isFetching}
        headers={headers}
        rows={rows}
        pageSize={pagination.pageSize}
        toolbar={<TableFilterToolbarActions />}
      />
      {!query.isFetching && !isError && configs.length === 0 ? (
        <InlineNotification
          title={t(
            'AdministrativeConfiguration.NoData',
            'No configuration found',
          )}
        />
      ) : null}

      {isError ? (
        <InlineNotification
          title={t(
            'AdministrativeConfiguration.LoadingError',
            'Failed to load configurations',
          )}
          kind="error"
        />
      ) : null}

      <DataTablePagination {...dataTablePaginationProps} query={query} />
    </div>
  )
}

const formatData = (configs: VMConfig[], t: TFunction) => {
  return configs.map(config => {
    let csp = '---'
    let cspSpecificDetails: React.ReactNode = null
    if (config.aws) {
      const c = config.aws
      csp = 'AWS'
      cspSpecificDetails = (
        <>
          <div>Account ID: {c.accountId}</div>
          <div>Regions: {c.regions}</div>
          <div>Monitor EKS: {c.monitorEks ? 'Yes' : 'No'}</div>
        </>
      )
    }
    if (config.gcp) {
      const c = config.gcp
      csp = 'GCP'
      cspSpecificDetails = (
        <>
          <div>Project ID: {c.projectId}</div>
          <div>Regions: {c.regions}</div>
          <div>Monitor GKE: {c.monitorGke ? 'Yes' : 'No'}</div>
        </>
      )
    }
    if (config.azure) {
      const c = config.azure
      csp = 'Azure'
      cspSpecificDetails = (
        <>
          <div>Subscription ID: {c.subscriptionId}</div>
          <div>Client ID: {c.clientId}</div>
          <div>Tenant ID: {c.tenantId}</div>
          <div>Resource groups: {c.resourceGroups}</div>
          <div>Regions: {c.regions}</div>
          <div>Monitor AKS: {c.monitorAks ? 'Yes' : 'No'}</div>
        </>
      )
    }
    if (config.oci) {
      const c = config.oci
      csp = 'OCI'
      cspSpecificDetails = (
        <>
          <div>Compartment OCID: {c.compartmentOcid}</div>
          <div>Regions: {c.regions}</div>
          <div>Monitor OKS: {c.monitorOks ? 'Yes' : 'No'}</div>
          {c.profile ? (
            <>
              <div>Tenant OCID: {c.profile.tenantOcid}</div>
              <div>User OCID: {c.profile.tenantOcid}</div>
              <div>Compartment OCID: {c.profile.tenantOcid}</div>
              <div>Regions: {c.profile.regions}</div>
              <div>Monitor OKS: {c.profile.monitorOks ? 'Yes' : 'No'}</div>
            </>
          ) : null}
        </>
      )
    }
    if (config.datadog) {
      const c = config.datadog
      csp = 'Datadog'
      cspSpecificDetails = (
        <>
          <div>API key: {c.apiKey}</div>
          <div>App key: {c.appKey}</div>
          <div>Annotation: {c.annotation}</div>
        </>
      )
    }

    return {
      id: config.id,
      clusterUuid: config.clusterUuid,
      disabled: config.disabled,
      csp,
      cspSpecificDetails,
      errorReason: config.errorReason,
      errorMessage: config.errorMessage,
    }
  })
}

const useHeaders = () => {
  const { t } = useTranslation()

  return [
    {
      key: 'id',
      header: t('AdministrativeConfiguration.Datatable.Username', 'ID'),
    },
    {
      key: 'clusterUuid',
      header: t(
        'AdministrativeConfiguration.Datatable.ClusterUuid',
        'Cluster UUID',
      ),
    },
    {
      key: 'disabled',
      header: t('AdministrativeConfiguration.Datatable.Disabled', 'Disabled'),
    },
    {
      key: 'csp',
      header: t('Common.CSP', 'CSP'),
    },
    {
      key: 'cspSpecificDetails',
      header: t(
        'AdministrativeConfiguration.Datatable.CSPSpecificDetails',
        'CSP-specific Details',
      ),
    },
    {
      key: 'errorReason',
      header: t(
        'AdministrativeConfiguration.Datatable.ErrorReason',
        'Error Reason',
      ),
    },
    {
      key: 'errorMessage',
      header: t(
        'AdministrativeConfiguration.Datatable.ErrorMessage',
        'Error Message',
      ),
    },
  ] as DataTableHeaders
}
