import React from 'react'
import styled from 'styled-components'
import { Box, Flex, Link } from 'src/next/components'
import Typography from '../Typography'

const Headings = styled.div`
  width: 75%;
`

const Children = styled.div`
  margin-top: 0.5rem;
`

interface Props {
  title: string | React.ReactNode
  children?: React.ReactNode
  link?: {
    href: string
    title: string
  }
}

export const PageTitle = ({ title, children, link }: Props) => (
  <Box mb={6}>
    <Flex justifyContent="space-between">
      <Headings>
        <Typography variant="heading-03" as="h1">
          {title}
        </Typography>

        {children && <Children>{children}</Children>}
      </Headings>
      {link?.href && <Link to={link.href}>{link.title}</Link>}
    </Flex>
  </Box>
)
