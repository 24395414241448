import React from 'react'
import { useTranslation } from 'react-i18next'
import { InlineNotification } from 'src/next/components/InlineNotification'

export interface LimitWarningProps {
  used: number | string
  total: number | string
  unit: string
}

export const LimitWarning = ({ used, total, unit }: LimitWarningProps) => {
  const { t } = useTranslation()

  return (
    <InlineNotification
      kind="warning"
      role="alert"
      title={t('LimitWarningBanner.Title')}
      subtitle={t('LimitWarningBanner.SubTitle', {
        used,
        total,
        unit,
      })}
      // hideCloseButton={false} // If you can close it, when do we show it again?
    >
      <a href={t('LimitWarningBanner.Link')}>
        {t('LimitWarningBanner.LinkText')}
      </a>
    </InlineNotification>
  )
}
