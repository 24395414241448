import React from 'react'

import {
  TableFilterContainer,
  TableFilterContextProvider,
} from 'src/next/components/TableFilter'

import { RulesTable } from './RulesTable'
import RulesTableFilters from './RulesTableFilters'

export const Rules = () => {
  return (
    <TableFilterContextProvider localStorageId="admin-rules-filters">
      <TableFilterContainer>
        <RulesTableFilters />
        <RulesTable />
      </TableFilterContainer>
    </TableFilterContextProvider>
  )
}
