import React, { Dispatch, SetStateAction } from 'react'
import { SortAscending, SortDescending } from '@carbon/react/icons'
import { Button, Dropdown } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { Box } from 'src/next/components'
import { OrderByItem } from './Insights'
import { css } from 'styled-components'

interface Props {
  setOrderBy: Dispatch<SetStateAction<OrderByItem>>
  setIsSortDesc: Dispatch<SetStateAction<boolean>>
  isSortDesc: boolean
  orderBy: OrderByItem
  orderByItems: OrderByItem[]
}

export const InsightsToolbar = ({
  setIsSortDesc,
  setOrderBy,
  isSortDesc,
  orderBy,
  orderByItems,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <Box minWidth="170px">
        <Dropdown
          id="insights-order-options"
          label="insights-order-options"
          hideLabel
          items={orderByItems}
          selectedItem={orderBy}
          onChange={({ selectedItem }) => setOrderBy(selectedItem!)}
        />
      </Box>
      <Button
        size="md"
        kind="ghost"
        onClick={() => setIsSortDesc(!isSortDesc)}
        renderIcon={isSortDesc ? SortDescending : SortAscending}
        css={css`
          color: var(--cds-text-primary);
          &:hover {
            color: inherit;
          }
        `}
      >
        {isSortDesc ? t('Insights.sortDesc') : t('Insights.sortAsc')}
      </Button>
    </>
  )
}
