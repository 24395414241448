import { useMemo } from 'react'
import { get } from 'lodash'
import { useClusters } from 'src/api'
import { GraphConfig } from 'src/next/components/Graphs'
import {
  createFormatFn,
  getCarbonPaletteCssVariable,
  getFormatType,
} from 'src/next/utils/graph.utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'
import { useClusterMetricsQuery } from './useClusterMetricsQuery'

export interface UseClusterMetricsGraphProps {
  startTimeNs: string
  endTimeNs: string
  metricToPlot: string
  clusterIds?: string[]
}

export const useCompareMetrics = ({
  startTimeNs,
  endTimeNs,
  metricToPlot,
}: UseClusterMetricsGraphProps) => {
  const { data: clusters } = useClusters({
    select: data => data.clusters,
  })

  const { isLoading, data, error } = useClusterMetricsQuery({
    filter: {
      startTimeNs,
      endTimeNs,
    },
  })

  const graphConfig = useMemo(() => {
    if (!data) return []

    const clusterMetrics = Object.entries(data?.clusterMetrics || {})

    return clusterMetrics.map(([clusterId, metrics], i) => {
      const cluster = clusters?.find(c => c.uuid === clusterId)

      return {
        id: clusterId,
        type: 'area',
        stack: true,
        label: cluster?.name,
        tooltipLabel: cluster?.name,
        tooltipValueTransformFn: (data: any) =>
          createFormatFn(getFormatType(metricToPlot))(data),
        props: {
          style: {
            data: {
              fill: getCarbonPaletteCssVariable(i),
            },
          },
        },
        data: metrics.metrics?.map(group => ({
          x: nanoToMilliSeconds(group.summaryTimeNs!),
          y: get(group, metricToPlot),
        })),
      }
    }) as GraphConfig[]
  }, [data, clusters, metricToPlot])

  return { isLoading, graphConfig, error }
}
