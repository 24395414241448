import React from 'react'
import { Dropdown } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import {
  DropdownHeading,
  DropdownSubheading,
} from 'src/next/components/Dropdown/DropdownHeading'
import { usePersistentMetricDropdown } from 'src/next/hooks/usePersistentMetricDropdown'

interface Props {
  id: string
  dropdownProps: ReturnType<typeof usePersistentMetricDropdown>['dropdownProps']
  label?: string
}
export const KubernetesWorkloadResourceTypeHeading = ({
  id,
  label,
  dropdownProps,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <DropdownHeading>
        <Dropdown
          {...dropdownProps}
          data-testid={id}
          type="inline"
          label={`${t('Workload.TopCharts.ChooseResourceType')}`}
        />
      </DropdownHeading>
      <DropdownSubheading>{label}</DropdownSubheading>
    </>
  )
}
