import { useQuery, UseQueryOptions } from 'react-query'
import {
  GetSubscriptionStatusRequest as Request,
  GetSubscriptionStatusResponse as Response,
} from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'

export const useSubscriptionStatus = <T = Response>(
  request?: Request,
  options?: UseQueryOptions<Response, Error, T>,
) => {
  const url = '/v1/subscriptions/status'

  const params = useRequestParams({ request })

  return useQuery<Response, Error, T>(
    [url, params],
    () => fetchData<Response>(url, params),
    options,
  )
}
