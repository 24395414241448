import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { ErrorBoundaryProps } from '@sentry/react/types/errorboundary'
import { GlobalError } from './GlobalError'
import { ChunkLoadErrorModal } from './ChunkLoadErrorModal'

interface FallbackComponentProps {
  error: Error
}

export const FallbackComponent = ({ error }: FallbackComponentProps) => {
  if (error?.name === 'ChunkLoadError') {
    return <ChunkLoadErrorModal />
  }

  return <GlobalError error={error} />
}

export interface GlobalErrorBoundaryProps extends ErrorBoundaryProps {
  children: React.ReactNode
  onReset?(): void
}

export const GlobalErrorBoundary = ({
  children,
  onReset,
}: GlobalErrorBoundaryProps) => {
  return (
    <ErrorBoundary fallback={FallbackComponent} onReset={onReset}>
      {children}
    </ErrorBoundary>
  )
}
