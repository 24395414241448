import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import {
  ListActivitiesRequest,
  ListActivitiesResponse,
} from '@cloudnatix-types/activity-tracker'
import { fetchData } from 'src/api/axios'
import { useRequestParams } from 'src/api/useRequestParams'

export const useAuditLogs = (
  request: ListActivitiesRequest,
  options?: UseInfiniteQueryOptions<ListActivitiesResponse, Error>,
) => {
  const url = '/v1/activities'

  const params = useRequestParams({ request, addOrgFilter: true })

  return useInfiniteQuery<ListActivitiesResponse, Error>(
    [url, params],
    ({ pageParam }) => {
      return fetchData<ListActivitiesResponse>(url, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
      staleTime: 3600000,
      ...options,
    },
  )
}
