import React, { useContext, useEffect } from 'react'
import { Checkbox, CheckboxProps } from '@carbon/react'
import {
  ControllerRenderProps,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form'
import { useTableFilter } from '../TableFilterContext'
import { TableFilterCheckboxGroupContext } from '.'

export interface TableFilterCheckboxProps
  extends Omit<CheckboxProps, 'defaultChecked' | 'defaultValue' | 'onChange'> {
  id: string
  activeFilterText?: string
  onValueChange?: (
    checked: boolean,
    id: string,
    field: ControllerRenderProps<FieldValues, string>,
  ) => void
}

export const TableFilterCheckbox = ({
  id,
  labelText,
  activeFilterText,
  onValueChange,
  ...props
}: TableFilterCheckboxProps) => {
  // use 'group' from TableFilterCheckboxGroup-context if any, else treat as
  // single checkbox. Difference between an item in a checkbox group is that a
  // checkbox group will return an array of values and a single one does not.
  const { control, watch } = useFormContext()
  const { contextId, registerLabel, registerDefaultValue } = useTableFilter()
  const { group, singleSelection } =
    useContext(TableFilterCheckboxGroupContext) || {}
  const isInCheckboxGroup = !!group
  const { field } = useController({
    control,
    name: group ?? id,
  })
  const formValue = watch(group ?? id)

  // for checkbox groups this is done in the group-component
  if (!isInCheckboxGroup) registerDefaultValue(id, false)

  useEffect(() => {
    const label = activeFilterText || labelText

    if (typeof label === 'string') {
      registerLabel({ [id]: label })
    } else {
      console.warn(
        'No string for label provided. Please provide a string via activeFilterText',
      )
    }
  }, [id, activeFilterText, labelText, registerLabel])

  return (
    <Checkbox
      {...field}
      id={`${contextId}-${id}`}
      value={id}
      labelText={labelText}
      checked={isInCheckboxGroup ? !!formValue?.includes(id) : formValue}
      onChange={(event, { checked }) => {
        const checkboxValue = event.target.value

        if (isInCheckboxGroup) {
          if (singleSelection) {
            if (checked) {
              field.onChange([checkboxValue])
            } else {
              field.onChange([])
            }
          } else {
            // update checkbox value
            const newValue = [
              ...(formValue || []),
              // add id when checked
              ...(checked ? [checkboxValue] : []),
              // remove id when unchecked
            ].filter(value => !(!checked && value === checkboxValue))

            field.onChange(newValue)
          }
        } else {
          field.onChange(checked)
        }
      }}
      {...props}
    />
  )
}
