import dayjs from 'dayjs'
import { milliToNanoSeconds } from 'src/next/utils/unitConversion'
import { Timeframe, TimeIntervals } from '../types'
import { durationMap } from 'src/next/components/TimeInterval'

export interface GetTimeFrameProps {
  timeInterval?: TimeIntervals
  start?: Timeframe
  end?: Timeframe
}

export const useGetTimeframe = ({
  timeInterval = 'days',
  start,
  end = new Date(),
}: GetTimeFrameProps) => {
  const DATA_POINTS_PER_HOUR = 12

  const getTimeInterval = (timeInterval: TimeIntervals) => {
    if (timeInterval === 'custom' || timeInterval === null) return 'minutes'
    return timeInterval
  }
  const interval = getTimeInterval(timeInterval)

  const endTime = dayjs(end).startOf('minutes')

  const startTime = start
    ? dayjs(start).startOf('minutes')
    : endTime.subtract(durationMap[interval], 'minutes')

  const diffInHours = endTime.diff(startTime, 'hours')

  const pageSize = Math.round(diffInHours * DATA_POINTS_PER_HOUR)

  return {
    startTimeNs: milliToNanoSeconds(startTime.valueOf()),
    endTimeNs: milliToNanoSeconds(endTime.valueOf()),
    pageSize,
  }
}
