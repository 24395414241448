import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from 'src/next/themes/GlobalStyle'
import { cnxTheme } from 'src/next/themes/theme'
import { cookie } from 'src/next/utils/cookies'

interface Props {
  children: React.ReactNode
  theme: string
}

interface ThemeContextProps {
  theme: string
  switchTheme: () => void
}

export const ThemeContext = createContext<ThemeContextProps>({
  theme: 'dark',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  switchTheme: () => {},
})

export const GlobalThemeProvider = ({ children, theme: themeProp }: Props) => {
  const themePropRef = useRef(themeProp)

  // Check if passed theme prop is 'dark' or 'light'.
  // Falls back to 'dark' in other cases.
  const validThemeProp =
    themeProp === 'dark' || themeProp === 'light' ? themeProp : 'dark'
  const [theme, setThemeName] = useState(validThemeProp)

  const switchTheme = useCallback(() => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    setThemeName(newTheme)
  }, [theme])

  // theme is changed via the themeProp (controlled)
  useEffect(() => {
    if (themePropRef.current !== themeProp) {
      themePropRef.current = themeProp
      switchTheme()
    }
  }, [switchTheme, themeProp])

  // when the theme is switched apply side effects (change the
  // `data-theme`-attribute and save to cookie)
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme)

    // store value in cookie, since it is used for the login page also (which
    // doesn't have access to the subdomain)
    cookie.set('theme', theme)
  }, [theme])

  const contextValue = useMemo(
    () => ({
      switchTheme,
      theme,
    }),
    [switchTheme, theme],
  )

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={cnxTheme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export function useThemeContext() {
  const context = React.useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeContext')
  }
  return context
}
