import { useInfiniteQuery } from 'react-query'
import {
  ListWorkloadsRequest,
  ListWorkloadsResponse,
} from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'
import { useOrgSelection } from 'src/next/hooks'
import { Workload } from 'src/next/types/workloads'

const url = '/v1/dashboard/workloads'
export const key = url

interface CustomListWorkloadsResponse extends ListWorkloadsResponse {
  workloads?: Workload[]
}

export const useWorkloads = (request: ListWorkloadsRequest) => {
  const [orgUuid] = useOrgSelection()

  const params = useRequestParams({
    request: {
      ...request,
      filter: {
        ...request.filter,
        orgUuids: orgUuid,
        // reverse the filter values for Workload Status, since in the UI the labels are reversed: checked means enabled, while for the API this means hidden.
        hideCnatixManagedWorkload: !request.filter?.hideCnatixManagedWorkload,
        hideSystemWorkload: !request.filter?.hideSystemWorkload,
        hideDeletedWorkload: !request.filter?.hideDeletedWorkload,
      },
    },
  })

  return useInfiniteQuery<CustomListWorkloadsResponse, any>(
    [key, params],
    ({ pageParam }) => {
      return fetchData<CustomListWorkloadsResponse>(url, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
    },
  )
}
