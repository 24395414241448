import { useState } from 'react'
import { UserTableRowData } from 'src/next/components/administration/Users/UsersTable'
import { UserActionsModal } from '../UserActionsModal'

export const useUserActions = () => {
  const [open, setOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserTableRowData | null>(
    null,
  )

  const onCloseModal = () => {
    setSelectedUser(null)
    setIsDelete(false)
    setOpen(false)
  }

  const addUser = () => {
    setOpen(true)
  }

  const editUser = (user: UserTableRowData) => {
    setSelectedUser(user)
    setOpen(true)
  }

  const deleteUser = async (user: UserTableRowData) => {
    setSelectedUser(user)
    setOpen(true)
    setIsDelete(true)
  }

  return {
    userModalProps: {
      open,
      isDelete,
      user: selectedUser,
      onClose: onCloseModal,
    },
    addUser,
    editUser,
    deleteUser,
    UserActionsModal,
  }
}
