import { InlineNotification } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  .cds--inline-notification {
    justify-content: flex-end;
  }

  .cds--inline-notification__details {
    flex: unset;
  }
`

export const NoClustersBanner = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <InlineNotification
        kind="info"
        title={t('NoClustersBanner.Title')}
        subtitle={
          <>
            <span>{t('NoClustersBanner.SubTitle')} </span>
            <a href={t('NoClustersBanner.Link')}>
              {t('NoClustersBanner.LinkText')}
            </a>
          </>
        }
        hideCloseButton
      />
    </Wrapper>
  )
}
