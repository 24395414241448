import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Navigate } from 'react-router-dom'
import Loading from 'src/next/components/Loading'

const tokenParams = {
  response_type: 'id_token',
  scopes: ['openid', 'email', 'profile', 'offline_access'],
  state: import.meta.env.VITE_LOGIN_STATE,
  nonce: import.meta.env.VITE_LOGIN_NONCE,
  originalUri: `${window.location.origin}`,
}

export const SignIn = () => {
  const { oktaAuth, authState } = useOktaAuth()

  if (!authState) {
    return <Loading withOverlay={false} centered />
  }

  if (!authState.isAuthenticated) {
    oktaAuth.signInWithRedirect(tokenParams)
    return null
  }

  return <Navigate to={{ pathname: '/app/dashboard' }} />
}
