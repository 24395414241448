import * as DialogPrimitive from '@radix-ui/react-dialog'
import styled from 'styled-components'

export const AnimatedOverlay = styled(DialogPrimitive.Overlay)`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes slideIn {
    from {
      transform: translate3d(0, -24px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }

  &[data-state='open'] {
    animation: fadeIn 500ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  &[data-state='closed'] {
    animation: fadeOut 500ms cubic-bezier(0.3, 1, 0.16, 1) forwards;
  }
`

export const AnimatedContent = styled(DialogPrimitive.Content)<
  DialogPrimitive.DialogContentProps & { $size?: 'xs' | 'sm' | 'md' | 'lg' }
>`
  @keyframes slideOut {
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      transform: translate3d(0, -48px, 0);
    }
  }

  &[data-state='open'] {
    animation: slideIn 500ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  &[data-state='closed'] {
    animation: slideOut 500ms cubic-bezier(0.3, 1, 0.16, 1) forwards;
  }
`
