import React, { useContext } from 'react'
import {
  InlineNotification as CarbonInlineNotification,
  InlineNotificationProps as CarbonInlineNotificationProps,
} from '@carbon/react'
import styled from 'styled-components'
import { ThemeContext } from 'src/next/contexts/GlobalThemeProvider'

export interface InlineNotificationProps extends CarbonInlineNotificationProps {
  /**
   * By default, the contrast of the notifications in Carbon is very high (white on
   * black and vice versa). Use `invert` to decrease the contrast of the Carbon notification
   */
  invert?: boolean
  /**
   * fullWidth removes the maximum width of the notifications, use this only for short sentences.
   */
  fullWidth?: boolean
  hideBorder?: boolean
}

const StyledInlineNotification = styled(CarbonInlineNotification).withConfig({
  shouldForwardProp: prop => !['fullWidth', 'hideBorder'].includes(prop),
})<InlineNotificationProps>`
  ${({ fullWidth }) => (fullWidth ? 'max-width: none;' : '')}
  ${({ hideBorder }) => (hideBorder ? 'border-left: none;' : '')}

  .cds--inline-notification__text-wrapper {
    // Use same line height for the children as subtitle
    line-height: var(--cds-body-short-01-line-height);
  }

  .cds--inline-notification__subtitle {
    // Prevent children and subtitle from sticking together
    margin-right: 0.5em;
  }
`

export const InlineNotification = ({
  invert = true,
  ...props
}: InlineNotificationProps) => {
  const { theme } = useContext<any>(ThemeContext)
  const themeAttr =
    invert && theme === 'dark'
      ? 'light'
      : invert && theme === 'light'
      ? 'dark'
      : null

  return (
    <StyledInlineNotification
      kind="info"
      hideCloseButton
      fullWidth
      hideBorder
      {...props}
      data-theme={themeAttr}
    />
  )
}
