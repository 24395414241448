import React from 'react'
import { OpenPanelFilledLeft } from '@carbon/react/icons'
import { Button, ButtonProps, FilterTagProps, Tag } from '@carbon/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Flex } from '../Flex'
import { useTableFilter } from './TableFilterContext'

const StyledButton = styled(Button)`
  &.active {
    color: var(--cds-button-interactive);
  }
`

const StyledTag = styled<React.ComponentType<FilterTagProps>>(Tag)`
  align-self: center;
  margin-right: var(--cds-spacing-03);
`

interface TableFilterToolbarActionsProps {
  buttonProps?: ButtonProps
}

export const TableFilterToolbarActions = ({
  buttonProps,
}: TableFilterToolbarActionsProps) => {
  const { t } = useTranslation()

  const { isOpen, toggleFilters, activeFiltersCount, reset } = useTableFilter()

  return (
    <Flex>
      {activeFiltersCount ? (
        <StyledTag
          filter
          title={t('DataTable.ResetFilters')}
          onClick={reset}
          type="high-contrast"
          data-testid="active-filters-count"
        >
          {activeFiltersCount}
        </StyledTag>
      ) : null}

      <StyledButton
        className={clsx('cds--toolbar-action', 'cds--overflow-menu', {
          active: isOpen,
        })}
        hasIconOnly
        iconDescription={t(
          'DataTable.ToggleTableFilters',
          'Toggle table filters',
        )}
        kind="secondary"
        onClick={toggleFilters}
        renderIcon={OpenPanelFilledLeft}
        size="md"
        tooltipPosition="bottom"
        {...buttonProps}
      />
    </Flex>
  )
}
