import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  line-height: 1.2;
`

const Icon = styled.div`
  margin-right: 0.5em;
  height: 1.2em;
  width: 1.2em;
  vertical-align: middle;

  svg {
    height: 1.2em;
    width: 1.2em;
  }
`

const StyledText = styled.div`
  display: flex;
`

interface TextWithIconProps {
  icon?: ReactNode
  children: ReactNode
}

export const Text = ({ icon, children }: TextWithIconProps) => (
  <Wrapper>
    {icon ? <Icon>{icon}</Icon> : null}
    <StyledText>{children}</StyledText>
  </Wrapper>
)
