import React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import styled from 'styled-components'

const Popover = styled(PopoverPrimitive.Root)``

const PopoverTrigger = styled(PopoverPrimitive.Trigger)`
  background-color: inherit;
  border: none;
  outline: none;
  padding-left: 0;
  padding-right: var(--cds-spacing-03);

  & > * {
    border: 1px solid inherit;
  }

  &:focus > *,
  &:hover > * {
    border: 1px solid var(--cds-border-inverse);
  }
`

const PopoverContent = styled(PopoverPrimitive.Content)`
  border: none;
  max-width: 60vw;
`

const PopoverArrow = styled(PopoverPrimitive.Arrow)`
  fill: var(--cds-border-inverse);
`

interface PopoverProps extends PopoverPrimitive.PopoverTriggerProps {
  buttonContents: React.ReactNode
  panelContents: React.ReactNode
  'data-testid'?: string
}

const UIPopover = ({
  buttonContents,
  panelContents,
  ...rest
}: PopoverProps) => {
  return (
    <Popover>
      <PopoverTrigger {...rest}>{buttonContents}</PopoverTrigger>

      <PopoverContent>
        {panelContents}

        <PopoverArrow />
      </PopoverContent>
    </Popover>
  )
}

export default UIPopover
