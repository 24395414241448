/* eslint-disable react/no-unknown-property */
import React from 'react'
import { Button, InlineLoading } from '@carbon/react'
import { Information } from '@carbon/react/icons'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useInsightsOptimizationTotalSaving } from 'src/api/queries'
import { Flex } from 'src/next/components'
import Tooltip from 'src/next/components/Tooltip'
import Typography from 'src/next/components/Typography'
import { createFormatFn } from 'src/next/utils/graph.utils'
import { MetricContainer, TimeRange } from './MetricContainer'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Column = styled.div`
  // Make Columns have the same width when the container (Wrapper) is wide enough.
  flex: 1 1 0px;

  // Align the contents horizontally to the center.
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ContentWrapper = styled.div`
  // Have some gap between the contents.
  padding: var(--cds-spacing-03);

  // Align the contents horizontally to the center.
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SavingsMetrics = () => {
  const { t } = useTranslation()

  const { data, isLoading, isError } = useInsightsOptimizationTotalSaving()
  const formatCurrency = createFormatFn('currency')

  const id = 'dashboard-savings-range'

  return (
    <MetricContainer
      id={id}
      title={
        <>
          {t('Dashboard.Metrics.Savings')}
          <Tooltip
            label={t('Dashboard.Metrics.SavingsTooltip')}
            align="bottom-right"
          >
            <Button
              size="sm"
              kind="ghost"
              hasIconOnly
              tooltipPosition="bottom"
              renderIcon={Information}
            />
          </Tooltip>
          <Flex alignItems="center">
            <TimeRange>{t('Common.PerMonth')}</TimeRange>
          </Flex>
        </>
      }
      primaryAction={{
        label: t('Dashboard.TopMetrics.SavingsAction'),
        href: '/app/insights',
      }}
      enableTimeRange={false}
    >
      {isLoading ? (
        <InlineLoading
          css={css`
            width: auto;
          `}
        />
      ) : isError ? (
        <Typography variant="helper-text-02" color="var(--cds-text-secondary)">
          {t('Common.NoDataAvailable')}
        </Typography>
      ) : (
        <Wrapper>
          {data?.workloadTotalSaving ? (
            <Column>
              <ContentWrapper>
                {t('Insights.Tabs.KubernetesWorkloads')}
                <Typography variant="productive-heading-04" as="span">
                  {formatCurrency(data.workloadTotalSaving)}
                </Typography>
              </ContentWrapper>
            </Column>
          ) : null}
          {data?.clusterTotalSaving ? (
            <Column>
              <ContentWrapper>
                {t('Insights.Tabs.Clusters')}
                <Typography variant="productive-heading-04" as="span">
                  {formatCurrency(data.clusterTotalSaving)}
                </Typography>
              </ContentWrapper>
            </Column>
          ) : null}
          {data?.computeInstanceTotalSavingExcludingDismissed ? (
            <Column>
              <ContentWrapper>
                {t('Insights.Tabs.ComputeInstances')}
                <Typography variant="productive-heading-04" as="span">
                  {formatCurrency(
                    data.computeInstanceTotalSavingExcludingDismissed,
                  )}
                </Typography>
              </ContentWrapper>
            </Column>
          ) : null}
        </Wrapper>
      )}
    </MetricContainer>
  )
}
