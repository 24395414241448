import React from 'react'
import { Add, TrashCan } from '@carbon/react/icons'
import { Button, Select, SelectItem } from '@carbon/react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Org } from '@cloudnatix-types/orgcontroller'
import { UserRole } from '@cloudnatix-types/usercontroller'
import { useOrgs, useUserSelf } from 'src/api'
import { UserFormValues } from './UserActionsModal'

const RoleGroup = styled.div`
  margin: var(--cds-spacing-05) 0 var(--cds-spacing-03) 0;
  display: flex;
  align-items: flex-end;
  gap: var(--cds-spacing-05);
`

const ButtonContainer = styled.div`
  margin: var(--cds-spacing-05) 0 var(--cds-spacing-03) 0;
`

interface RoleBindingsProps {
  buttonTitle?: string
}

export const UserRoleBindingsForm = ({ buttonTitle }: RoleBindingsProps) => {
  const { t } = useTranslation()
  const { data: userData } = useUserSelf()
  const { register } = useFormContext()

  const { data } = useOrgs()

  const { fields, append, update, remove } = useFieldArray<UserFormValues>({
    name: 'roleBindings',
  })

  if (!fields.length) {
    const orgUuid = userData?.roleBindings?.[0].orgUuid || ''
    append({
      orgUuid,
      orgName: data?.orgs?.find(org => org.uuid === orgUuid)?.name,
      role: UserRole.USER_ROLE_VIEWER,
    })
  }

  const addRole = (org: Org) => {
    append({
      orgUuid: org.uuid,
      orgName: org.name,
      role: UserRole.USER_ROLE_VIEWER,
    })
  }

  const availableOrgs = data?.orgs || []

  return (
    <>
      {fields.map((roleBinding, index) => {
        return (
          <RoleGroup key={roleBinding.id}>
            <Select
              id={`userOrg-${index}`}
              labelText={`${t('Users.Form.Organization')}`}
              defaultValue={roleBinding.orgUuid}
              {...register(`roleBindings.${index}.orgUuid` as const, {
                onChange: ({ target }) => {
                  update(index, {
                    orgName: target.options[target.selectedIndex].text,
                    orgUuid: target.value,
                  })
                },
                required: true,
              })}
              required
            >
              <SelectItem
                text={`${t('Users.Form.SelectOrganization')}`}
                value=""
                disabled
              />
              <SelectItem
                key={roleBinding.orgUuid}
                value={roleBinding.orgUuid}
                text={roleBinding.orgName || ''}
              />
              {availableOrgs?.map(org => (
                <SelectItem
                  key={org.uuid}
                  value={org.uuid}
                  text={org.name || ''}
                />
              ))}
            </Select>
            <Select
              id={`userRole-${index}`}
              labelText={`${t('Users.Form.Role')}`}
              defaultValue={roleBinding.role}
              {...register(`roleBindings.${index}.role` as const, {
                required: true,
              })}
            >
              <SelectItem
                value={UserRole.USER_ROLE_VIEWER}
                text={t('UserRoles.Viewer')}
              />
              <SelectItem
                value={UserRole.USER_ROLE_USER}
                text={t('UserRoles.User')}
              />
              <SelectItem
                value={UserRole.USER_ROLE_ADMIN}
                text={t('UserRoles.Admin')}
              />
              <SelectItem
                value={UserRole.USER_ROLE_PATCH}
                text={t('UserRoles.Patch')}
              />
            </Select>
            {fields.length > 1 && (
              <Button
                hasIconOnly
                iconDescription={t('Common.Delete')}
                renderIcon={TrashCan}
                tooltipPosition="left"
                tooltipAlignment="start"
                onClick={() => remove(index)}
                kind="ghost"
                size="md"
              />
            )}
          </RoleGroup>
        )
      })}
      {!!availableOrgs.length && (
        <ButtonContainer>
          <Button
            kind="tertiary"
            size="sm"
            renderIcon={Add}
            onClick={() => {
              addRole(availableOrgs[0])
            }}
          >
            {buttonTitle || t('Users.Form.AddRole')}
          </Button>
        </ButtonContainer>
      )}
    </>
  )
}
