export const i18nOptions: any = {
  fallbackLng: 'en',
  debug: false,
  ns: 'translation',
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    skipOnVariables: false,
  },
  react: {
    // show loading spinner until i18n is ready. This prevents several issues
    // with top-level components being un- and remounted.

    // Since there are issues with Enzyme & Suspense we disable this for Jest tests:
    // https://github.com/enzymejs/enzyme/issues/2205
    // Set this to true once we don't use Enzyme anymore
    useSuspense: import.meta.env.NODE_ENV !== 'test',
  },
}

export default i18nOptions
