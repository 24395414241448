import React from 'react'
import { Link } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components'

interface Props {
  reset: () => void
  activeFiltersCount: number
}

export const ResetFiltersLink = ({ reset, activeFiltersCount }: Props) => {
  const { t } = useTranslation()

  if (!activeFiltersCount) return null

  return (
    <Link
      onClick={reset}
      css={css`
        margin-left: var(--cds-spacing-02);
        cursor: pointer;
      `}
    >
      {t('DataTable.ResetFilters')}
    </Link>
  )
}
