/* eslint-disable react/no-unknown-property */
import React, { useMemo } from 'react'
import { Button } from '@carbon/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDeleteOrg, useManagementOrgs } from 'src/api'
import DataTable, {
  DataTableActionButton,
  useTableControls,
  DataTablePagination,
} from 'src/next/components/DataTable'
import { FeatureInfo } from 'src/next/components/FeatureInfo'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { DeleteModal, useDeleteModal } from 'src/next/components/Modal'
import { currencyFormat } from 'src/next/utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'
import { OrganizationsTableExpandedPanel } from '../ExpandedRow/OrganizationsTableExpandedPanel'
import { OrgActionsModal, useOrgActionsModal } from './OrgActionsModal'
import { Flex } from 'src/next/components/Flex'
import {
  TableFilterToolbarActions,
  useTableFilter,
} from 'src/next/components/TableFilter'

export const OrganizationsTable = () => {
  const { t } = useTranslation()

  const headers = useHeaders()

  const { pagination, orderBy } = useTableControls('mgmt-org-page-size')

  const { resetPage, setPage, ...dataTablePaginationProps } = pagination

  const { filters, methods } = useTableFilter()

  // reset pagination when filters change
  methods.watch(() => resetPage())

  const deleteModalProps = useDeleteModal()
  const actionModalProps = useOrgActionsModal()

  const query = useManagementOrgs({
    filter: filters,
    orderBy: orderBy.value || '',
    pageSize: pagination.pageSize,
  })

  const { data, isFetching, isError } = query

  const currentPageData = data?.pages?.[pagination.page - 1] || {}

  const organizations = useMemo(
    () => currentPageData.orgs || [],
    [currentPageData.orgs],
  )

  const formattedRows = useMemo(
    () =>
      organizations.map(org => {
        return {
          ...org,
          id: org.uuid!,
          csp: org.csps?.join(', ') || '',
          path: org.path?.join(' / ') || '',
          spend: t('Common.Value/hr', {
            value: currencyFormat(org.spend || 0),
          }),
          actions: (
            <>
              <DataTableActionButton
                iconDescription={t('Organizations.EditOrg')}
                onClick={() => {
                  actionModalProps.setOrg(org)
                  actionModalProps.setOpen(true)
                }}
              />
              <DataTableActionButton
                actionType="delete"
                iconDescription={t('Organizations.DeleteOrg')}
                onClick={() => deleteModalProps.setId(org.uuid!)}
              />
            </>
          ),
          createdAt: dayjs(nanoToMilliSeconds(org.createdAtNs || 0)).format(
            'll',
          ),
          updatedAt: dayjs(
            nanoToMilliSeconds(org.lastModifiedAtNs || 0),
          ).format('ll'),
        }
      }),
    [organizations, t, deleteModalProps, actionModalProps],
  )

  return (
    <div>
      <DataTable
        storageKey="orgs-table"
        size="xl"
        headers={headers}
        rows={formattedRows}
        isLoading={isFetching}
        pageSize={pagination.pageSize}
        orderBy={orderBy}
        primaryAction={
          <Button onClick={() => actionModalProps.setOpen(true)}>
            {t('Organizations.CreateOrg')}
          </Button>
        }
        onExpand={id => {
          const org = organizations.find(c => c.uuid === id)
          if (!org) return null

          return <OrganizationsTableExpandedPanel org={org} />
        }}
        toolbar={
          <>
            <Flex width={1}>
              <FeatureInfo definition={t('Organizations.FeatureInfo')} />
            </Flex>
            <TableFilterToolbarActions />
          </>
        }
      />
      {isFetching ? null : isError ? (
        <InlineNotification
          kind="error"
          title={t('Organizations.DataTable.LoadingError')}
        />
      ) : formattedRows.length === 0 ? (
        <InlineNotification title={t('Organizations.DataTable.NoData')} />
      ) : null}
      <DataTablePagination {...dataTablePaginationProps} query={query} />
      <DeleteModal
        title={t('Organizations.ConfirmDelete')}
        messages={{
          success: t('Organizations.Delete.Success'),
          error: t('Organizations.Delete.Error'),
        }}
        query={useDeleteOrg}
        {...deleteModalProps}
      />

      {actionModalProps.open ? <OrgActionsModal {...actionModalProps} /> : null}
    </div>
  )
}

const useHeaders = () => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        key: 'name',
        sort: 'name',
        header: t('Organizations.DataTable.Name'),
      },
      {
        key: 'parentOrgName',
        sort: 'parent_org_name',
        header: t('Organizations.DataTable.Parent'),
      },
      {
        key: 'path',
        sort: 'path',
        header: t('Organizations.DataTable.Path'),
      },
      {
        key: 'description',
        header: t('Organizations.DataTable.Description'),
      },
      {
        key: 'csp',
        sort: 'csps',
        header: t('Organizations.DataTable.Csp'),
      },
      {
        key: 'spend',
        sort: 'spend',
        header: t('Common.Spend'),
      },

      {
        key: 'createdAt',
        sort: 'created_at_ns',
        header: t('Common.Created'),
      },
      {
        key: 'updatedAt',
        sort: 'last_modified_at_ns',
        header: t('Common.Updated'),
      },
      {
        key: 'actions',
        header: t('Organizations.DataTable.Actions'),
      },
    ],
    [t],
  )
}
