import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ComputeInstanceSummary } from '@cloudnatix-types/dashboard'

import { useCurrentOrgInstanceSummaries } from 'src/api'
import {
  useRollupPeriod,
  useTimeRangeOverflowMenu,
} from 'src/next/components/dashboard/timerange'
import { AreaGraphProps, LineGraphProps } from 'src/next/components/Graphs'
import { Graph } from 'src/next/components/Graphs/Graph'
import GraphTooltip from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import Loading from 'src/next/components/Loading'
import { millicoresToUserFriendlySizeLong } from 'src/next/utils'
import { buildDayTicks } from 'src/next/utils/graph.utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

import { MetricContainer } from './MetricContainer'
import { useTooltipHeading } from './useTooltipHeading'

export const CpuMetrics = () => {
  const { t } = useTranslation()

  const id = 'dashboard-metrics-cpu'

  const {
    selectedItem: selectedTimeRange,
    startTimeNs,
    endTimeNs,
  } = useTimeRangeOverflowMenu({
    id: `${id}-time-range`,
  })

  const { selectedItem: duration } = useRollupPeriod({
    id: `${id}-duration`,
    timeRange: selectedTimeRange.id,
  })

  const { data, isLoading } = useCurrentOrgInstanceSummaries({
    duration: duration.id,
    startTimeNs,
    endTimeNs,
  })
  const usedDataPoints = useMemo(
    () =>
      data?.summaries?.map((summary: ComputeInstanceSummary) => ({
        x: new Date(nanoToMilliSeconds(summary.timestampNs!)),
        y: summary.avgCpuUsage,
      })) || [],
    [data],
  )

  const unusedDataPoints = useMemo(
    () =>
      data?.summaries?.map((summary: ComputeInstanceSummary) => ({
        x: new Date(nanoToMilliSeconds(summary.timestampNs!)),
        y: summary.avgCpuCapacity! - summary.avgCpuUsage!,
      })) || [],
    [data],
  )

  const capacityDataPoints = useMemo(
    () =>
      data?.summaries?.map((summary: ComputeInstanceSummary) => ({
        x: new Date(nanoToMilliSeconds(summary.timestampNs!)),
        y: summary.avgCpuCapacity!,
      })) || [],
    [data],
  )

  // The range x may take is the same for the used and unused series. Use either of them to build tick values.
  const xTickValues = useMemo(
    () => buildDayTicks(usedDataPoints),
    [usedDataPoints],
  )

  const graphConfig = [
    {
      id: 'avg_cpu_usage',
      label: t('ComputeInstanceSummary.avg_cpu_usage'),
      tooltipLabel: t('ComputeInstanceSummary.avg_cpu_usage'),
      tooltipValueTransformFn: (value: unknown) =>
        millicoresToUserFriendlySizeLong(Number(value), 2),
      enabled: true,

      type: 'area' as const,
      data: usedDataPoints,
      stack: true,
      props: {
        style: { data: { fill: 'var(--carbonPalette1)' } },
      },
    } as AreaGraphProps,
    {
      id: 'avg_cpu_unused',
      label: t('ComputeInstanceSummary.avg_cpu_unused'),
      tooltipLabel: t('ComputeInstanceSummary.avg_cpu_unused'),
      tooltipValueTransformFn: (value: unknown) =>
        millicoresToUserFriendlySizeLong(Number(value), 2),
      enabled: true,

      type: 'area' as const,
      data: unusedDataPoints,
      stack: true,
      props: {
        style: { data: { fill: 'var(--carbonPalette2)' } },
      },
    } as AreaGraphProps,
    {
      id: 'avg_cpu_capacity',
      label: t('ComputeInstanceSummary.avg_cpu_capacity', 'Avg. CPU capacity'),
      tooltipLabel: t(
        'ComputeInstanceSummary.avg_cpu_capacity',
        'Avg. CPU capacity',
      ),
      tooltipValueTransformFn: (value: unknown) =>
        millicoresToUserFriendlySizeLong(Number(value), 2),
      enabled: true,

      type: 'line' as const,
      data: capacityDataPoints,
      props: {
        style: { data: { stroke: 'var(--carbonPalette4)' } },
      },
    } as LineGraphProps,
  ]

  const getTooltipHeading = useTooltipHeading(duration.id)

  return (
    <MetricContainer
      id={id}
      title={t('Common.CPU')}
      primaryAction={{
        label: t('Dashboard.TopMetrics.CpuAction'),
        href: '/app/workloads',
      }}
    >
      {isLoading ? (
        <Loading centered size="small" withOverlay={false} />
      ) : (
        <>
          <Graph
            data={graphConfig}
            unit="cores"
            height={200}
            xAxis={[
              {
                tickFormat: (x: any) => dayjs(x).format('MM/DD'),
                tickValues: xTickValues,
              },
            ]}
            yAxis={[{}]}
            containerComponent={GraphTooltipContainer(
              <GraphTooltip
                graphConfig={graphConfig}
                heading={getTooltipHeading}
              />,
            )}
          />
        </>
      )}
    </MetricContainer>
  )
}
