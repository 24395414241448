import React from 'react'
import {
  Column,
  FlexGrid,
  Row,
  Select,
  SelectItem,
  TextInput,
} from '@carbon/react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOrgs } from 'src/api'

export const OrgForm = () => {
  const { t } = useTranslation()

  // TODO(kenji): Filter out the selected org or ancestors of the selected org from the list of parent orgs
  // when the org is being updated.
  const { data } = useOrgs()

  const { field: name } = useController({
    name: 'name',
    rules: { required: true },
  })
  const { field: description } = useController({
    name: 'description',
    rules: { required: true },
  })
  const { field: parentOrgUuid } = useController({
    name: 'parentOrgUuid',
    rules: { required: true },
  })

  return (
    <FlexGrid narrow>
      <Row>
        <Column>
          <TextInput
            {...name}
            autoFocus
            id="org-name"
            labelText={t('Organizations.Form.Name')}
            autoComplete="off"
          />
        </Column>
      </Row>
      <br />
      <Row>
        <Column>
          <TextInput
            {...description}
            id="org-description"
            labelText={t('Organizations.Form.Description')}
          />
        </Column>
      </Row>
      <br />
      <Row>
        <Column>
          <Select
            {...parentOrgUuid}
            id="org-parent-org"
            labelText={t('Organizations.Form.ParentOrg')}
          >
            <SelectItem
              text={t('Organizations.Form.SelectParentOrg')}
              value=""
              disabled
            />
            {data?.orgs?.map(org => (
              <SelectItem
                key={org.uuid}
                value={org.uuid}
                text={org.name || ''}
              />
            ))}
          </Select>
        </Column>
      </Row>
    </FlexGrid>
  )
}
