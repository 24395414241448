import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TableFilterAccordionItem } from '.'
import { useActiveFilterTags } from './hooks/useActiveFilterTags'
import { useTableFilter } from './TableFilterContext'

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: var(--cds-spacing-03);
  list-style-type: none;

  // apply negative 'overshoot' - visual fix to left-align rounded elements
  margin-left: calc(var(--cds-spacing-01) * -1);
`

const ListItem = styled.li`
  display: inline-block;
`

// list the active filters
export const TableFilterActiveItems = () => {
  const { t } = useTranslation()
  const { activeFiltersCount } = useTableFilter()
  const tags = useActiveFilterTags()

  return activeFiltersCount ? (
    <div data-testid="active-filters">
      <TableFilterAccordionItem title={t('Filters.ActiveFilters')}>
        <List>
          {tags.map(({ key, element }) => (
            <ListItem key={key}>{element}</ListItem>
          ))}
        </List>
      </TableFilterAccordionItem>
    </div>
  ) : null
}
