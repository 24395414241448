import dayjs from 'dayjs'
import { groupBy, max, sumBy } from 'lodash'
import { Options as BytesOptions } from 'pretty-bytes'

import { BarGraphProps } from 'src/next/components'
import { GraphDataPoint } from 'src/next/components/Graphs'
import { GraphColor } from 'src/next/types/workloads'
import { bytesToUserFriendlySize } from './bytesToUserFriendlySize'
import { currencyFormat } from './currencyFormat'
import {
  millicoresToUserFriendlySizeShort,
  millicoresToUserFriendlySizeLong,
} from './millicoresToUserFriendlySize'

export type MetricFormatType =
  | 'cpu'
  | 'gpu'
  | 'cores'
  | 'memory'
  | 'gpuMemory'
  | 'milliCores'
  | 'bytes'
  | 'disk'
  | 'currency'
  | 'percentage'
  | 'none'

type CreateFormatFnArgs = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  type: MetricFormatType | (string & {}) | undefined,
  options?: {
    cpu?: { shortDisplayValue?: boolean }
    memory?: BytesOptions
    disk?: BytesOptions
    currency?: Intl.NumberFormatOptions
  },
) => ((value: number) => string) | ((value: number | string) => string)

// todo: make this more generic
// todo: check the most used options and set as defaults

// higher-order function for formatting the values in tooltips and axis based
// on the selected accessor key
export const createFormatFn: CreateFormatFnArgs = (
  type,
  options = { cpu: {}, memory: {}, currency: {}, disk: {} },
) => {
  switch (type) {
    case 'cpu':
    case 'cores':
    case 'milliCores':
      return (value: number) =>
        options?.cpu?.shortDisplayValue
          ? millicoresToUserFriendlySizeShort(value, 2)
          : millicoresToUserFriendlySizeLong(value, 2)
    case 'memory':
    case 'gpuMemory':
    case 'bytes':
    case 'disk':
      return (value: number | string) =>
        bytesToUserFriendlySize(value, options?.memory)
    case 'currency':
      return (value: number) => currencyFormat(value, options?.currency)
    case 'gpu':
    case 'none':
    default:
      return (value: number) => `${value}`
  }
}

/**
 * Map selected metric (e.g. 'maxMemoryLimit') to the correct formatting unit.
 */
export const getFormatType = (accessorKey: string) => {
  const matches = accessorKey
    .toLowerCase()
    .match(/cpu|disk|memory|cost|spend|waste/i)
  const [match] = matches || []
  if (['cost', 'spend', 'waste'].includes(match)) return 'currency'
  return match as 'cpu' | 'memory' | 'currency' | 'disk' | undefined
}

/**
 * Get carbonPalette css variable based on index.
 * Limited to 1 to 14, will start at 1 again when index is higher
 *
 * @example
 *   const color = getCarbonPaletteCssVariable(0);
 *   console.log(color); // var(--carbonPalette1)
 */
export const getCarbonPaletteCssVariable = (index: number) => {
  return `var(--carbonPalette${(index % 14) + 1})` as GraphColor
}

// calculate the max Y domain
export const getStackedMaxYDomain = (
  graphConfig: BarGraphProps[],
  yAccessorKey: string,
) => {
  const flat = graphConfig
    .filter(item => item.enabled !== false)
    .flatMap(item => item.data)
  // group by timestamp
  const perTimestamp = Object.values(groupBy(flat, item => item.timestamp))
  // get sum of values (so the total height of stacked bar)
  const maxValuesPerTimestamp = perTimestamp.map(item =>
    // eslint-disable-next-line @typescript-eslint/no-shadow
    sumBy(item, item => Number(item[yAccessorKey])),
  )
  const maxYValue = max(maxValuesPerTimestamp)

  // return the max value with a minimum of 1
  return Number(maxYValue) < 1 ? 1 : Number(maxYValue || 0)
}

export const buildDayTicks = (xSortedDataPoints: GraphDataPoint[]) => {
  const xTickValues: Date[] = []

  let firstXTickValue: dayjs.Dayjs | undefined = undefined
  let lastTimestamp: dayjs.Dayjs | undefined = undefined

  xSortedDataPoints.forEach((point: GraphDataPoint) => {
    const timestamp = dayjs(point.x)

    if (firstXTickValue === undefined) {
      // Ceil to day boundaries.
      firstXTickValue = timestamp.startOf('day')
      if (!timestamp.isSame(firstXTickValue)) {
        firstXTickValue = firstXTickValue.add(1, 'day')
      }
    }

    lastTimestamp = timestamp
  })

  if (firstXTickValue !== undefined && lastTimestamp !== undefined) {
    for (
      let currentDay = firstXTickValue;
      !currentDay.isAfter(lastTimestamp);
      currentDay = currentDay.add(1, 'day')
    ) {
      xTickValues.push(currentDay.toDate())
    }
  }

  return xTickValues
}
