import {
  useInfiniteQuery,
  useQuery,
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from 'react-query'
import {
  GetComputeInstanceOptimizationInsightResponse,
  ListComputeInstanceOptimizationInsightsResponse,
} from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'

export const useComputeInstanceOptimization = (
  request?: any,
  options?: UseQueryOptions<
    GetComputeInstanceOptimizationInsightResponse,
    Error,
    GetComputeInstanceOptimizationInsightResponse
  >,
) => {
  const url = `/v1/insights/optimizations/computeinstances/${request.uuid}`
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<
    GetComputeInstanceOptimizationInsightResponse,
    Error,
    GetComputeInstanceOptimizationInsightResponse
  >(
    [url, params],
    () => {
      return fetchData<GetComputeInstanceOptimizationInsightResponse>(url, {
        ...params,
      })
    },
    {
      ...options,
    },
  )
}

export const useComputeInstanceOptimizations = <
  T = ListComputeInstanceOptimizationInsightsResponse,
>(
  request?: any,
  options?: UseInfiniteQueryOptions<
    ListComputeInstanceOptimizationInsightsResponse,
    Error,
    T
  >,
) => {
  const url = '/v1/insights/optimizations/computeinstancesv2'
  const params = useRequestParams({ request, addOrgFilter: true })

  return useInfiniteQuery<
    ListComputeInstanceOptimizationInsightsResponse,
    Error,
    T
  >(
    [url, params],
    ({ pageParam }) => {
      return fetchData<ListComputeInstanceOptimizationInsightsResponse>(url, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
      ...options,
    },
  )
}
