import { Column, Grid } from '@carbon/react'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import { Box, ErrorBoundary } from 'src/next/components'
import { GenericTabSummaryViewDonutChart } from './GenericTabSummaryViewDonutChart'
import { GenericTabSummaryViewLineChart } from './GenericTabSummaryViewLineChart'

export interface GenericTabSummaryViewChartProps {
  id: string
  groupingKeyKey: `${ComputeInstanceGroupingKeyKey}`
  groupingKeyTagKey: string | undefined
  groupingValue: string
}

export const GenericTabSummaryViewChart = ({
  id,
  groupingKeyKey,
  groupingKeyTagKey,
  groupingValue,
}: GenericTabSummaryViewChartProps) => {
  return (
    <Box
      mt="var(--cds-spacing-05)"
      mb="var(--cds-spacing-05)"
      minHeight="580px"
    >
      <Grid fullWidth narrow>
        <Column sm={4} md={8}>
          <ErrorBoundary
            resetKeys={[groupingKeyKey, groupingValue, groupingKeyTagKey]}
          >
            <GenericTabSummaryViewLineChart
              id={`${id}-line-chart`}
              groupingKeyKey={groupingKeyKey}
              groupingKeyTagKey={groupingKeyTagKey}
              groupingValue={groupingValue}
            />
          </ErrorBoundary>
        </Column>
        <Column sm={4} md={8}>
          <ErrorBoundary
            resetKeys={[groupingKeyKey, groupingValue, groupingKeyTagKey]}
          >
            <GenericTabSummaryViewDonutChart
              id={`${id}-donut-chart`}
              groupingKeyKey={groupingKeyKey}
              groupingKeyTagKey={groupingKeyTagKey}
              groupingValue={groupingValue}
            />
          </ErrorBoundary>
        </Column>
      </Grid>
    </Box>
  )
}
