/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import { IconSkeleton, SkeletonText } from '@carbon/react'
import { ActionId, ActionImpl, KBarResults, useMatches } from 'kbar'
import { Box, Flex } from 'src/next/components'
import Typography from 'src/next/components/Typography'
import * as Styled from './CLUI.styled'
import { CLUIShortcut } from './CLUIShortcut'

export interface CLUIResultsProps {
  isLoading?: boolean
}

export const CLUIResults = ({ isLoading }: CLUIResultsProps) => {
  const { results, rootActionId } = useMatches()

  // see comment in CLUIProvider for the need of a placeholder
  const resultsWithoutPlaceholder = useMemo(
    () =>
      results.filter(
        item => !(typeof item === 'object' && item?.name === 'PLACEHOLDER'),
      ),
    [results],
  )

  return (
    <Styled.Results>
      {isLoading && <ResultItemSkeleton />}

      <KBarResults
        items={resultsWithoutPlaceholder}
        onRender={({ item, active }) =>
          typeof item === 'string' ? (
            <Styled.GroupName>{item}</Styled.GroupName>
          ) : (
            <ResultItem
              action={item}
              active={active}
              currentRootActionId={rootActionId}
            />
          )
        }
      />
    </Styled.Results>
  )
}

interface ResultItemProps {
  action: ActionImpl
  active: boolean
  currentRootActionId?: ActionId | null
}

const ResultItemSkeleton = () => (
  <Styled.ListItem $active={false}>
    <Flex alignItems="center" height="61px">
      <Flex mr={4}>
        <IconSkeleton />
      </Flex>
      <Box>
        <SkeletonText style={{ width: 200 }} />
        <SkeletonText style={{ marginBottom: 0, width: 100 }} />
      </Box>
    </Flex>
  </Styled.ListItem>
)

const ResultItem = React.forwardRef(
  (
    { action, active, currentRootActionId }: ResultItemProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors
      const index = action.ancestors.findIndex(
        ancestor => ancestor.id === currentRootActionId,
      )
      // +1 removes the currentRootAction; only from the third level the
      // ancestor gets displayed, e.g. "Foo > Bar"
      return action.ancestors.slice(index + 1)
    }, [action.ancestors, currentRootActionId])

    const { name, icon, subtitle, shortcut } = action

    return (
      <Styled.ListItem $active={active} ref={ref}>
        <Flex alignItems="center">
          {icon && (
            <Flex mr={4} opacity="0.66" height="20px" width="20px">
              {icon}
            </Flex>
          )}
          <Flex flexDirection="column">
            <div>
              {ancestors.length > 0 &&
                ancestors.map(ancestor => (
                  <React.Fragment key={ancestor.id}>
                    <Box color="text-primary" as="span" mr="3" opacity={0.5}>
                      {ancestor.name}
                    </Box>
                    <Box as="span" mr="3">
                      &rsaquo;
                    </Box>
                  </React.Fragment>
                ))}
              <span>{name}</span>
            </div>
            {subtitle && (
              <Box color="text-placeholder">
                <Typography variant="label-01">{subtitle}</Typography>
              </Box>
            )}
          </Flex>
        </Flex>

        <CLUIShortcut shortcut={shortcut} separateBy="then" />
      </Styled.ListItem>
    )
  },
)
