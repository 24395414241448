import React from 'react'

import {
  TableFilterContainer,
  TableFilterContextProvider,
} from 'src/next/components/TableFilter'

import { ResourcesTable } from './ResourcesTable'
import { ResourcesTableFilters } from './ResourcesTableFilters'

export const Resources = () => {
  return (
    <TableFilterContextProvider localStorageId="admin-resources-filters">
      <TableFilterContainer>
        <ResourcesTableFilters />
        <ResourcesTable />
      </TableFilterContainer>
    </TableFilterContextProvider>
  )
}
