import React from 'react'
import { Button, ButtonProps } from '@carbon/react'
import clsx from 'clsx'
import styled, { css } from 'styled-components'

const StyledButton = styled(Button)<{
  iconDescription?: string
}>`
  display: flex;
  gap: 0.5rem;
  color: var(--cds-icon-primary);

  // Hide tooltip when no iconDescription is provided
  ${props =>
    !props.iconDescription &&
    css`
      ::before,
      .cds--assistive-text {
        display: none !important;
      }
    `}

  &:not(:disabled) {
    &:hover {
      color: var(--cds-text-primary);
    }
  }

  &.cds--btn--ghost .cds--btn__icon {
    order: -1;
    margin-left: 0;
  }

  &.active {
    color: var(--cds-button-interactive) !important;
  }
`

export interface ToolbarButtonProps
  extends Omit<ButtonProps, 'kind' | 'size' | 'hasIconOnly'> {
  active?: boolean
}

export const ToolbarButton = ({
  active,
  className,
  ...props
}: ToolbarButtonProps) => {
  return (
    <StyledButton
      kind="ghost"
      size="md"
      className={clsx(className, { active })}
      hasIconOnly
      {...props}
    />
  )
}
