import React from 'react'
import styled, { css } from 'styled-components'
import Loading from 'src/next/components/Loading'

const Wrapper = styled.div<{
  $height?: string
}>`
  position: relative;

  ${({ $height }) =>
    $height &&
    css`
      height: ${$height};
    `}
`

const ContentWrapper = styled.div<{
  $loading?: boolean
  $height?: string
}>`
  ${({ $height }) =>
    $height &&
    css`
      height: ${$height};
    `}

  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  ${({ $loading }) =>
    $loading &&
    css`
      opacity: 0.2 !important;
      filter: saturate(0%);
      pointer-events: none;

      svg {
        pointer-events: none !important;
      }
    `}
`

const MessageWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 25%;
  right: 25%;

  text-align: center;
`

export interface LoadingGraphProps {
  children: React.ReactNode
  isLoading: boolean
  hideSpinner?: boolean
  message?: string
  height?: string
}

// `height` allows for setting the `height` CSS rule for the wrappers.
// It's useful when you want to stretch the contents to fit e.g. a grid cell by using `100%`.
// It shouldn't be hardcoded because some usecases of this component is wrapping `Graph` directly.
// If the height is set to `100%` for such a case, `BoundingSize` in it will expand the graph area forever.
export const LoadingWrapper = ({
  isLoading,
  hideSpinner,
  message,
  children,
  height,
}: LoadingGraphProps) => {
  return (
    <Wrapper $height={height}>
      <ContentWrapper $loading={isLoading} $height={height}>
        {children}
      </ContentWrapper>
      {isLoading && !hideSpinner && <Loading centered withOverlay={false} />}
      {isLoading && message && <MessageWrapper>{message}</MessageWrapper>}
    </Wrapper>
  )
}
