import React, { useState } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'
import { Button } from '@carbon/react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NamespaceLabel, NamespaceType } from '@cloudnatix-types/orgcontroller'
import { useCreateNamespace } from 'src/api'
import { Flex } from 'src/next/components/Flex'
import { InlineNotification } from 'src/next/components/InlineNotification'
import Loading from 'src/next/components/Loading'
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTrigger,
} from 'src/next/components/Modal'
import { useToaster } from 'src/next/hooks/useToaster'
import { NamespaceForm } from './NamespaceForm'

export interface NamespaceFormValues {
  name: string
  type: NamespaceType
  orgUuid: string
  labels: NamespaceLabel[]
}

export const NamespaceActionsModal = ({ orgId }: { orgId: string }) => {
  const { t } = useTranslation()
  const { addToast } = useToaster()
  const [open, setOpen] = useState(false)

  const { mutate: createNamespace, isLoading, isError } = useCreateNamespace()

  const onClose = () => setOpen(false)

  const handleSuccess = () => {
    addToast({
      title: t('Organizations.Namespaces.Create.Success'),
      kind: 'success',
    })
    onClose()
    formMethods.reset()
  }

  const onSubmit = async (newNamespace: NamespaceFormValues) => {
    createNamespace(newNamespace, { onSuccess: handleSuccess })
  }

  const formMethods = useForm<NamespaceFormValues>({
    mode: 'onChange',
    defaultValues: {
      orgUuid: orgId,
      type: 'FULLY_MANAGED' as NamespaceType,
    },
  })

  return (
    <Modal onOpenChange={setOpen} open={open}>
      <ModalTrigger asChild>
        <Button>{t('Organizations.Namespaces.Create.Namespace')}</Button>
      </ModalTrigger>

      <ModalContent title={t('Organizations.Namespaces.Add')}>
        {isLoading && <Loading withOverlay size="medium" />}

        {isError ? (
          <InlineNotification
            kind="error"
            title={t('Organizations.Namespaces.Create.Error')}
          />
        ) : null}
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            id="org-namespace-form"
          >
            <NamespaceForm />
          </form>
        </FormProvider>
        <ModalFooter isButtonSet>
          <Flex flex={1}>
            <DialogClose asChild>
              <Button kind="secondary">{t('Common.Cancel')}</Button>
            </DialogClose>
            <Button
              kind="primary"
              type="submit"
              form="org-namespace-form"
              disabled={!formMethods.formState.isValid}
            >
              {t('Common.Create')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
