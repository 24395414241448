import { useEffect } from 'react'
import { useSessionstorageState } from 'rooks'
import { useClusters } from 'src/api'

export const useHasClusters = (): {
  isLoading: boolean
  hasClusters: boolean
} => {
  // Keep track if there are clusters, prevent over-fetching during session.
  const [hasClusters, setHasClusters] = useSessionstorageState(
    'hasClusters',
    false,
  )

  const { isLoading, data } = useClusters({
    // Don't use staleTime (one hour by default), because customers might
    // just have added a cluster.
    staleTime: 0,
    // Only fetch when no clusters have been fetched before.
    enabled: !hasClusters,
  })

  const clusterCount = data?.clusters?.length

  useEffect(() => {
    if (!clusterCount) {
      return
    }
    setHasClusters(true)
  }, [clusterCount, setHasClusters])

  return {
    isLoading,
    hasClusters,
  }
}
