import {
  createShouldForwardProp,
  props,
} from '@styled-system/should-forward-prop'
import styled from 'styled-components'
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  minHeight,
  MinHeightProps,
  position,
  PositionProps,
  RequiredTheme,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
  Theme,
  ThemeValue,
} from 'styled-system'

interface GapProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = ThemeValue<'space', ThemeType>,
> {
  gap?: ResponsiveValue<TVal, ThemeType>
  rowGap?: ResponsiveValue<TVal, ThemeType>
  columnGap?: ResponsiveValue<TVal, ThemeType>
}

// prevent props that are also valid HTML-attributes (like 'display' & 'color')
// to leak into the DOM
const shouldForwardProp = createShouldForwardProp([
  ...props,
  'display',
  'color',
])

export type BoxProps = LayoutProps &
  FlexboxProps &
  MinHeightProps &
  PositionProps &
  SpaceProps &
  ColorProps &
  BorderProps &
  GapProps

export const Box = styled.div.withConfig({
  shouldForwardProp: prop => shouldForwardProp(prop),
})<BoxProps>(
  border,
  color,
  layout,
  minHeight,
  position,
  space,
  flexbox,
  system({
    // grid gap properties
    gap: { property: 'gap', scale: 'space' },
    rowGap: {
      property: 'rowGap',
      scale: 'space',
    },
    columnGap: {
      property: 'columnGap',
      scale: 'space',
    },
  }),
)
