import React from 'react'
import { SelectSkeleton } from '@carbon/react'
import { useController, useFormContext } from 'react-hook-form'
import { Slider, SliderProps } from 'src/next/components/Slider'
import { Optional } from 'src/next/types/helpers'
import { useTableFilter } from '../TableFilterContext'
import { css } from 'styled-components'

export interface TableFilterSliderProps
  extends Omit<Optional<SliderProps, 'onChange'>, 'value'> {
  id: string
  isLoading?: boolean
}

export const TableFilterSlider = ({
  id,
  isLoading = false,
  ...props
}: TableFilterSliderProps) => {
  const { control, watch } = useFormContext()
  const { field } = useController({
    control,
    name: id,
  })
  const { contextId } = useTableFilter()
  const value = watch(id) || 0

  return isLoading ? (
    <SelectSkeleton hideLabel />
  ) : (
    <div className="cnx-slider">
      <Slider
        id={`${contextId}-${id}`}
        css={css`
          min-width: 100px;
        `}
        {...field}
        onChange={() => {
          /** Required by Carbon, but we use onRelease instead */
        }}
        onRelease={({ value }: { value: number }) => {
          field.onChange(value)
        }}
        {...props}
        value={value}
      />
    </div>
  )
}
