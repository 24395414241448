import React from 'react'
import { Dropdown } from '@carbon/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { ManagementOrg } from '@cloudnatix-types/dashboard'
import { useInstanceSummaries } from 'src/api'
import {
  Box,
  DataRow,
  DataRowHeading,
  DropdownHeading,
  Flex,
  Legend,
  LegendItem,
} from 'src/next/components'
import { DonutChart } from 'src/next/components/Graphs/DonutChart'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import { GraphColor } from 'src/next/types/workloads'
import { createFormatFn } from 'src/next/utils/graph.utils'
import {
  milliToNanoSeconds,
  nanoToMilliSeconds,
} from 'src/next/utils/unitConversion'

interface Props {
  org: ManagementOrg
}

export const OrgOverviewTab = ({ org }: Props) => {
  return (
    <Flex
      gap="var(--cds-spacing-05)"
      flexWrap="wrap"
      justifyContent="space-between"
      width="100%"
    >
      <OrgSummary org={org} />
      <Flex gap="var(--cds-spacing-05)" flex="2">
        <OrgCostChart orgUUID={org.uuid!} />
        <OrgResourceChart orgUUID={org.uuid!} />
      </Flex>
    </Flex>
  )
}

const OrgSummary = ({ org }: Props) => {
  const { t } = useTranslation()

  const { name, uuid, createdAtNs } = org || {}
  const { clusterCount, instanceCount, namespaceCount } =
    org?.additionalData || {}

  return (
    <Flex flex="1" flexDirection="column">
      <DataRowHeading label={t('Common.Summary')} />
      <DataRow label={t('Common.Name')}>{name}</DataRow>
      <DataRow label={t('Common.UUID')}>{uuid}</DataRow>
      <DataRow label={t('Common.Created')}>
        {createdAtNs && dayjs(nanoToMilliSeconds(createdAtNs)).format('ll')}
      </DataRow>
      {/* todo: user count BE not ready https://cloudnatix.atlassian.net/browse/CNATIX-3069?focusedCommentId=15365 */}
      {/* <DataRow label={t('Organizations.UserCount')}>{`${userCount}`}</DataRow> */}
      <DataRow label={t('Organizations.Clusters')}>{`${clusterCount}`}</DataRow>
      <DataRow label={t('Organizations.Instances')}>
        {`${instanceCount}`}
      </DataRow>
      <DataRow label={t('Organizations.Namespaces.Title')}>
        {`${namespaceCount}`}
      </DataRow>
    </Flex>
  )
}

const OrgCostChart = ({ orgUUID }: { orgUUID: string }) => {
  const { t } = useTranslation()

  const { data, isLoading } = useLatestDailyOrgInstanceSummary(orgUUID)

  const disabled =
    data?.wastedSpend === undefined || data?.totalSpend === undefined

  const waste = data?.wastedSpend ? data?.wastedSpend : 0.1
  const spend = data?.totalSpend ? data?.totalSpend : 0.1
  const utilized = spend - waste

  const donutChartData = [
    {
      label: t('ComputeInstanceSummary.used_spend'),
      color: 'var(--carbonPalette1)' as GraphColor,
      x: 'utilized',
      y: utilized,
      value: t('Common.Value/day', {
        value: createFormatFn('currency')(utilized),
      }),
    },
    {
      label: t('ComputeInstanceSummary.wasted_spend'),
      color: 'var(--carbonPalette2)' as GraphColor,
      x: 'waste',
      y: waste,
      value: t('Common.Value/day', {
        value: createFormatFn('currency')(waste),
      }),
    },
  ]

  return (
    <Flex flex="1" flexDirection="column" alignItems="center">
      <div>
        <DataRowHeading label={t('ComputeInstanceSummary.total_spend')} />
        <OrgDonutChart
          data={donutChartData}
          isLoading={isLoading}
          disabled={disabled}
        />
      </div>
    </Flex>
  )
}

const OrgResourceChart = ({ orgUUID }: { orgUUID: string }) => {
  const { t } = useTranslation()

  const { selectedItem: resourceType, dropdownProps } = usePersistentDropdown(
    'mngmt-org-overview-tab-resource-type',
    [
      {
        id: 'cpu',
        label: t('Common.CPU'),
      },
      {
        id: 'memory',
        label: t('Common.Memory'),
      },
      {
        id: 'disk',
        label: t('Common.Disk', 'Disk'),
      },
    ],
  )

  const formatFn = createFormatFn(resourceType.toLowerCase())

  const { data, isLoading } = useLatestDailyOrgInstanceSummary(orgUUID)

  const capacity =
    resourceType === 'cpu'
      ? data?.maxCpuCapacity
      : resourceType === 'memory'
      ? data?.maxMemoryCapacity
      : data?.maxDiskCapacity

  const used =
    resourceType === 'cpu'
      ? data?.avgCpuUsage
      : resourceType === 'memory'
      ? data?.avgMemoryUsage
      : data?.avgDiskUsage

  const disabled = capacity === undefined && used === undefined

  const usedNum = used ?? 0
  const capacityNum = capacity ?? 0.1
  const unusedNum = capacityNum - usedNum
  const donutChartData = [
    {
      label: t('Common.Used'),
      color: 'var(--carbonPalette4)' as GraphColor,
      x: 'used',
      y: usedNum,
      value: formatFn(usedNum),
    },
    {
      label: t('Common.Unused'),
      color: 'var(--carbonPalette3)' as GraphColor,
      x: 'unused',
      y: unusedNum,
      value: formatFn(unusedNum),
    },
  ]

  return (
    <Flex flex="1" flexDirection="column" alignItems="center">
      <div>
        <DropdownHeading $fontSize="0.875rem">
          <Dropdown
            {...dropdownProps}
            id={`mngmt-org-resource-${orgUUID}`}
            type="inline"
            label={`${t('Workload.TopCharts.ChooseResourceType')}`}
          />
        </DropdownHeading>
        <OrgDonutChart
          data={donutChartData}
          isLoading={isLoading}
          disabled={disabled}
        />
      </div>
    </Flex>
  )
}

interface OrgDonutChartProps {
  data: {
    label: string
    color: GraphColor
    x: string
    y: number
    value: string
  }[]
  isLoading: boolean
  disabled: boolean
}

const OrgDonutChart = ({ data, isLoading, disabled }: OrgDonutChartProps) => {
  return (
    <>
      <Box mb="5" position="relative" width={220} height={220}>
        <DonutChart
          disabled={disabled}
          isLoading={isLoading}
          data={data}
          showDataLabels={false}
          padding={0}
          centerHeading={data[1].label}
          centerValue={data[1].value}
        />
      </Box>

      {!disabled && !isLoading && (
        <Legend>
          {data.map(({ label, color }) => (
            <LegendItem id={label} key={label} color={color as GraphColor}>
              {label}
            </LegendItem>
          ))}
        </Legend>
      )}
    </>
  )
}

const useLatestDailyOrgInstanceSummary = (orgUUID: string) => {
  // Get data from -2 days, -1 day to prevent incomplete data.
  const startTime = dayjs().subtract(2, 'day').startOf('day')
  const endTime = startTime.add(1, 'day')

  const { data, isLoading, isError } = useInstanceSummaries(
    {
      filter: {
        startTimeNs: `${milliToNanoSeconds(startTime.valueOf())}`,
        endTimeNs: `${milliToNanoSeconds(endTime.valueOf())}`,
        grouping: {
          groupingValue: orgUUID,
          duration: 'DAILY' as any,
          groupingKey: {
            key: 'ORG' as any,
          },
        },
      },
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  )

  return {
    // return the latest daily summary
    data: data?.summaries?.at(-1),
    isLoading,
    isError,
  }
}
