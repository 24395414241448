import React, { Dispatch, SetStateAction } from 'react'
import { CarbonIconType } from '@carbon/icons-react/lib/CarbonIcon'
import { Column } from '@carbon/react/icons'
import {
  Checkbox,
  DataTableCustomRenderProps,
  DenormalizedRow,
  TableBatchAction,
  TableBatchActions,
  TableToolbar,
  TableToolbarContent,
  TableToolbarMenu,
} from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'src/next/components'
import Tooltip from 'src/next/components/Tooltip/Tooltip'
import { DataTableHeaders as DataTableHeadersProps } from '../../types/dataTable'
import * as Styled from './DataTableToolbar.styled'

export interface ToolbarMenuItem {
  key: string
  label: string
  checked: boolean
  onChange: () => void
}

type CarbonProps = Pick<
  DataTableCustomRenderProps,
  'getBatchActionProps' | 'getToolbarProps'
>

export interface BatchAction {
  label: string
  onClick: (rows: readonly DenormalizedRow[]) => void
  icon: CarbonIconType
  disabled?: boolean
  tooltip?: string | React.ReactNode
}

interface DataTableToolbarProps extends CarbonProps {
  headers: DataTableHeadersProps
  settings?: ToolbarMenuItem[]
  setVisibleHeaders: Dispatch<SetStateAction<any>>
  toolbar?: React.ReactNode
  visibleHeaders: any
  batchActions?: BatchAction[]
  selectedRows?: readonly DenormalizedRow[]
  primaryAction?: React.ReactNode
}

const DataTableToolbar = ({
  getBatchActionProps,
  getToolbarProps,
  headers,
  settings,
  setVisibleHeaders,
  toolbar,
  visibleHeaders,
  batchActions,
  selectedRows,
  primaryAction,
}: DataTableToolbarProps) => {
  const { t } = useTranslation()

  function handleHeaderToggle(i: number) {
    const updatedHeaders = [...visibleHeaders]
    updatedHeaders[i] = !visibleHeaders[i]
    setVisibleHeaders(updatedHeaders)
  }

  return (
    <TableToolbar {...getToolbarProps()}>
      <TableToolbarContent>
        {toolbar}

        {/* HEADER TOGGLE */}
        <TableToolbarMenu
          iconDescription={t('DataTable.HideColumns', 'Hide columns')}
          renderIcon={Column}
          tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
          data-testid="data-table-header-toggle"
        >
          {headers.map((header, i) => {
            // prevent users from hiding `persistent` table columns
            if (header?.persistent) return null

            return (
              <Flex key={`checkbox-header-${header.key}`} px="5" py="2">
                <Checkbox
                  id={`checkbox-header-${header.key}`}
                  labelText={header.header}
                  checked={visibleHeaders[i]}
                  onChange={() => handleHeaderToggle(i)}
                />
              </Flex>
            )
          })}
        </TableToolbarMenu>

        {/* TABLE SETTINGS */}
        {settings ? (
          <TableToolbarMenu
            iconDescription={t('DataTable.TableSettings', 'Table settings')}
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
          >
            {settings.map(setting => (
              <Flex key={`table-settings-${setting.key}`} px="5" py="2">
                <Checkbox
                  id={`table-settings-${setting.key}`}
                  labelText={setting.label}
                  checked={setting.checked}
                  onClick={setting.onChange}
                />
              </Flex>
            ))}
          </TableToolbarMenu>
        ) : null}

        {primaryAction}

        {/* Batch actions overlap the whole toolbar */}
        <TableBatchActions {...getBatchActionProps()}>
          {batchActions &&
            batchActions.map(action => {
              const actionProps = {
                key: action.label,
                tabIndex: getBatchActionProps().shouldShowBatchActions ? 0 : -1,
                onClick: () => action.onClick(selectedRows || []),
                renderIcon: action.icon,
                disabled: action.disabled,
                children: action.label,
              }

              return action.tooltip ? (
                <Tooltip key={action.label} label={action.tooltip}>
                  <Styled.TableBatchActionWrapper $disabled={action.disabled}>
                    <TableBatchAction
                      {...actionProps}
                      css={{
                        // We need this to make sure the tooltip can be triggered on the whole button
                        pointerEvents: action.disabled ? 'none' : 'auto',
                      }}
                    />
                  </Styled.TableBatchActionWrapper>
                </Tooltip>
              ) : (
                <TableBatchAction {...actionProps} />
              )
            })}
        </TableBatchActions>
      </TableToolbarContent>
    </TableToolbar>
  )
}

export default DataTableToolbar
