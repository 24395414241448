import { useState } from 'react'

export const useOrderBy = (resetPage?: () => void) => {
  const [value, setValue] = useState<string | undefined>(undefined)

  const onChange = (value: string | undefined) => {
    resetPage?.()
    setValue(value)
  }

  return {
    value,
    setValue: onChange,
  }
}

export type UseOrderByProps = ReturnType<typeof useOrderBy>
