import { ReactNode, useMemo } from 'react'
import { DataTableRow } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'src/next/components'
import DataTable, { useOrderBy } from 'src/next/components/DataTable'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { DataTableHeader } from 'src/next/types/dataTable'

interface TabDataTableProps {
  id: string
  title?: string
  headers: DataTableHeader[]
  rows: DataTableRow[]
  overflowMenu?: ReactNode
  isLoading?: boolean
}

export const TabDataTable = ({
  id,
  title,
  headers,
  rows,
  overflowMenu,
  isLoading = false,
}: TabDataTableProps) => {
  const { t } = useTranslation()

  const orderBy = useOrderBy()
  const [column, direction] = orderBy.value?.split(' ') || []

  const orderedRows = useMemo(() => {
    const result = [...rows]

    result.sort((a, b) => {
      if (column === '') {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      }

      const valueA = a[column as keyof DataTableRow]
      const valueB = b[column as keyof DataTableRow]

      if (valueA === undefined) {
        return 1
      }

      if (valueB === undefined) {
        return -1
      }

      return valueA > valueB ? 1 : valueB > valueA ? -1 : 0
    })

    if (direction === 'desc') {
      result.reverse()
    }

    return result
  }, [rows, column, direction])

  return (
    <div data-testid={id}>
      <DataTable
        size="md"
        key={`${column}-${direction}`}
        storageKey={id}
        headers={headers}
        isLoading={isLoading}
        rows={orderedRows}
        orderBy={orderBy}
        toolbar={
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width={title ? 1 : undefined}
          >
            {title}
            {overflowMenu}
          </Flex>
        }
      />
      {!isLoading && !rows?.length ? (
        <InlineNotification
          title={`${t('Dashboard.Common.DataTable.NoData')}`}
        />
      ) : null}
    </div>
  )
}
