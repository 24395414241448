import React from 'react'

import { SubscriptionBanner } from 'src/next/components/SubscriptionBanner'
import { TableFilterContextProvider } from 'src/next/components/TableFilter'

import { UsersTable } from './UsersTable'

export const Users = () => {
  return (
    <>
      <SubscriptionBanner type="USER" />
      <TableFilterContextProvider>
        <UsersTable />
      </TableFilterContextProvider>
    </>
  )
}
