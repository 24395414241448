import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import { ListOptimizationInsightsResponse as ResponseType } from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'
import { InsightTypes } from 'src/next/components/insights'

export const useInsightsOptimizations = <T = ResponseType>(
  type: InsightTypes,
  request?: any,
  options?: UseInfiniteQueryOptions<ResponseType, Error, T>,
) => {
  // Intentionally not generating the URL from the type programatically so that the fully qualified URL of the API endpoint shows up.
  // It's helpful when searching for the API endpoint in the code search.
  const url =
    type === 'kubernetesworkloads'
      ? '/v1/insights/optimizations/kubernetesworkloads'
      : type === 'hpas'
      ? '/v1/insights/optimizations/hpas'
      : '/v1/insights/optimizations/clusters'

  const params = useRequestParams({ request, addOrgFilter: true })

  return useInfiniteQuery<ResponseType, Error, T>(
    [url, params],
    ({ pageParam }) => {
      return fetchData<ResponseType>(url, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
      ...options,
    },
  )
}
