import { useCallback, useEffect, useMemo } from 'react'
import useLocalStorage from '../useLocalStorage'

type DropdownItem<ItemProps, ItemId = string> = ItemProps & {
  id: ItemId
  label: string
}

/**
 * This hook is for creating controlled Carbon dropdown menu's whose state
 * persists in LocalStorage. It stores the id only and finds the related object
 * for `selectedItem`.
 * @example
 * const { dropdownProps } = usePersistentDropdown('localStorageId', dropdownItems);
 * ...
 * <Dropdown label="foobar" {...dropdownProps} />
 */
export const usePersistentDropdown = <
  ItemProps extends object,
  ItemId extends string,
  Item extends DropdownItem<ItemProps, ItemId> = DropdownItem<
    ItemProps,
    ItemId
  >,
>(
  // localStorage id
  id: string,
  items: Item[],
) => {
  // get selected value from LocalStorage, or fallback to first item
  const { id: firstItem } = items[0]
  const [storedId, setStoredId] = useLocalStorage<string>(id, firstItem)

  const findById = useCallback(
    (findId: string) => items.find(({ id: itemId }) => itemId === findId),
    [items],
  )

  // Carbon requires the selectedItem set to be the object.
  const selectedItem = findById(storedId) || items[0]

  const handleOnChange = useCallback(
    ({ selectedItem }: { selectedItem: Item }) => setStoredId(selectedItem.id),
    [setStoredId],
  )

  // make sure the item in localStorage actually exists in the items array.
  // If not, reset to first item
  useEffect(() => {
    if (!selectedItem) setStoredId(firstItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const returnValue = useMemo(
    () => ({
      selectedItem: selectedItem.id,
      setSelectedItem: setStoredId,
      dropdownProps: {
        id,
        items,
        selectedItem,
        onChange: handleOnChange,
        // Carbon calls this method with different arguments. In some cases
        // this is a string, in other cases the item's object:
        // https://github.com/carbon-design-system/carbon/blob/caf3cf11cff8cc87bca8f05c624724906df14715/packages/react/src/components/Dropdown/Dropdown.js
        itemToString: (item: Item | string) =>
          (typeof item === 'string' ? findById(item)?.label : item.label) || '',
      },
    }),
    [findById, handleOnChange, id, items, selectedItem, setStoredId],
  )

  return returnValue
}
