import React, { createContext, ReactNode } from 'react'
import { useTableFilter } from '../TableFilterContext'

export interface TableFilterCheckboxGroupContextProps {
  group: string
  singleSelection?: boolean
}
export const TableFilterCheckboxGroupContext = createContext<
  TableFilterCheckboxGroupContextProps | undefined
>(undefined)
TableFilterCheckboxGroupContext.displayName = 'TableFilterCheckboxGroupContext'

interface TableFilterCheckboxGroupProps {
  children: ReactNode
  group: string
  singleSelection?: boolean
}

export const TableFilterCheckboxGroup = ({
  children,
  group,
  singleSelection,
}: TableFilterCheckboxGroupProps) => {
  // prevents a bug when the first checkbox in a group remains checked when the
  // values are reset
  const { registerDefaultValue } = useTableFilter()
  registerDefaultValue(group, [])

  return (
    <TableFilterCheckboxGroupContext.Provider
      value={{ group, singleSelection }}
    >
      {children}
    </TableFilterCheckboxGroupContext.Provider>
  )
}
