import React from 'react'
import { Button, Link } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from 'src/next/components'
import Typography from 'src/next/components/Typography'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: start;
  margin: 0 auto;
  max-width: 30rem;
  gap: 1rem;
`

const ErrorDetails = styled.div`
  color: var(--cds-text-placeholder);
  line-height: 1.2;
`

export const GlobalError = ({ error }: { error: Error }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Typography variant="productive-heading-03">
        {t('GlobalError.Title')}
      </Typography>
      <Typography variant="body-long">{t('GlobalError.Body')}</Typography>
      <Button
        onClick={() => {
          window.location.reload()
        }}
      >
        {t('GlobalError.TryAgain')}
      </Button>

      <Link href={t('GlobalError.SupportLink')} target="_blank">
        {t('GlobalError.Support')}
      </Link>
      <Box mt={4}>
        <Typography variant="productive-heading-01">
          {t('GlobalError.TechnicalInfo')}
        </Typography>
        <ErrorDetails>
          {error.name}
          <br />
          {error.message}
        </ErrorDetails>
      </Box>
    </Wrapper>
  )
}
