// General util for managing cookies in Typescript.
export const cookie = (() => {
  const set = (name: string, value: string) => {
    let aCookie = `${name}=${value}`
    // max age of 7 days
    aCookie += `;max-age=${60 * 60 * 24 * 7}`
    aCookie += `;domain=${
      import.meta.env.NODE_ENV === 'development'
        ? 'localhost'
        : 'cloudnatix.com'
    }`
    aCookie += ';SameSite=Lax;Secure;path=/'
    document.cookie = aCookie
  }

  const get = (name: string) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)

    if (parts.length === 2) {
      return parts.pop()?.split(';').shift()
    }
  }

  const remove = (name: string) => {
    const date = new Date()

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

    // Set it
    document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`
  }

  return {
    set,
    get,
    delete: remove,
  }
})()
