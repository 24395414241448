import dayjs from 'dayjs'
import { useQuery, UseQueryOptions } from 'react-query'
import { ListSummariesResponse } from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'
import { useOrgSelection } from 'src/next/hooks'
import { TimePeriod } from 'src/next/utils/time'

export function useKubernetesSummaries<T>(
  timeFilter: TimePeriod,
  options?: UseQueryOptions<ListSummariesResponse, Error, T>,
) {
  const [orgUuid] = useOrgSelection()

  if (!['daily', 'weekly', 'monthly'].includes(timeFilter))
    throw new Error(
      `Invalid filter '${timeFilter}' passed to useSummariesQuery.`,
    )

  const summaryInterval = timeFilter.toUpperCase()

  // first round to a whole hour (if now is 07:15 › 07:00) before subtracting
  // the time. This so that React Query can at least store the data for an hour
  const lastHour = dayjs().startOf('hour')
  const endTimeNs = lastHour.valueOf() * 1000000

  const startTimeNs =
    timeFilter === 'daily'
      ? lastHour.subtract(7, 'days').valueOf() * 1000000
      : timeFilter === 'weekly'
      ? lastHour.subtract(7, 'weeks').valueOf() * 1000000
      : timeFilter === 'monthly'
      ? lastHour.subtract(7, 'months').valueOf() * 1000000
      : undefined

  if (!startTimeNs)
    throw new Error(`Not able to get the time for filter ${timeFilter}`)

  const url = '/v1/dashboard/kubernetes/summaries'

  const params = useRequestParams({
    request: {
      orgUuid,
      summaryInterval,
      summaryType: '3', // org
      startTimeNs,
      endTimeNs,
    },
  })

  return useQuery<ListSummariesResponse, Error, T>(
    ['summaries', orgUuid, summaryInterval, startTimeNs, endTimeNs],
    () => fetchData<ListSummariesResponse>(url, params),
    {
      // Cache the data for an hour max. It will still update when the query key changes.
      cacheTime: 3600000, // 60 * 60 * 1000 = 1 hour
      retry: false,
      ...options,
    },
  )
}
