import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ComputeInstanceGroup } from '@cloudnatix-types/dashboard'
import { EfficiencyCell } from 'src/next/components/dashboard/tabs/tables/EfficiencyCell'
import MiddleTruncate from 'src/next/components/MiddleTruncate'
import { currencyFormat } from 'src/next/utils'

export const useFormatRows = (data?: ComputeInstanceGroup[]) => {
  const { t } = useTranslation()

  return useMemo(() => {
    if (!data) {
      return []
    }

    return (
      data.map(row => {
        let cpuWasteRatio: number | undefined
        let cpuFormatted: string | JSX.Element = '---'
        const cpuEfficiency = row.utilizations?.[0]
        if (cpuEfficiency?.utilization !== undefined) {
          cpuWasteRatio = 1 - cpuEfficiency.utilization
          cpuFormatted = (
            <EfficiencyCell
              name={cpuEfficiency.name}
              utilization={cpuEfficiency.utilization}
              wasteRatio={cpuWasteRatio}
            />
          )
        }

        let memoryWasteRatio: number | undefined
        let memoryFormatted: string | JSX.Element = '---'
        const memoryEfficiency = row.utilizations?.[1]
        if (memoryEfficiency?.utilization !== undefined) {
          const memoryWasteRatio = 1 - memoryEfficiency.utilization
          memoryFormatted = (
            <EfficiencyCell
              name={memoryEfficiency.name}
              utilization={memoryEfficiency.utilization}
              wasteRatio={memoryWasteRatio}
            />
          )
        }

        return {
          id: row.uid || `${row.efficiency}-${row.waste}-${row.spend}`,
          uid: row.uid,
          name: row.name,
          nameFormatted: (
            <MiddleTruncate
              charsAtEnd={30}
              charsAtStart={30}
              text={row.name || ''}
            />
          ),
          spend: row.spend!,
          spendFormatted: currencyFormat(row.spend!),
          waste: row.waste!,
          wasteFormatted: currencyFormat(row.waste!),
          efficiency:
            row.efficiency === undefined ? undefined : 1 - row.efficiency,
          efficiencyFormatted:
            row.efficiency === undefined
              ? '---'
              : `${((1 - row.efficiency) * 100).toFixed(0)}%`,
          cpu: cpuWasteRatio,
          cpuFormatted,
          memory: memoryWasteRatio,
          memoryFormatted,
        }
      }) || []
    )
  }, [data])
}

interface useHeadersProps {
  overrideNameColumnHeader?: string
}

export const useHeaders = ({ overrideNameColumnHeader }: useHeadersProps) => {
  const { t } = useTranslation()

  return useMemo(() => {
    return [
      {
        key: 'nameFormatted',
        sort: 'name',
        header:
          overrideNameColumnHeader ?? t('Dashboard.Common.DataTable.Name'),
      },
      {
        key: 'spendFormatted',
        sort: 'spend',
        header: t('ComputeInstanceGroup.spend'),
      },
      {
        key: 'wasteFormatted',
        sort: 'waste',
        header: t('ComputeInstanceGroup.waste'),
      },
      {
        key: 'efficiencyFormatted',
        sort: 'efficiency',
        header: t('ComputeInstanceGroup.efficiency'),
      },
      {
        key: 'cpuFormatted',
        sort: 'cpu',
        header: t('ComputeInstanceGroup.cpu_waste_ratio'),
      },
      {
        key: 'memoryFormatted',
        sort: 'memory',
        header: t('ComputeInstanceGroup.memory_waste_ratio'),
      },
    ]
  }, [t, overrideNameColumnHeader])
}
