import React, { Suspense, useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Location, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Cluster } from '@cloudnatix-types/dashboard'
import {
  DataTablePagination,
  useTableControls,
} from 'src/next/components/DataTable'
import DataTable from 'src/next/components/DataTable/DataTable'
import Loading from 'src/next/components/Loading'
import { StackedBarCell } from 'src/next/components/SimpleStackedBar'
import { Link } from 'src/next/components/ui/Link'
import { WorkloadCluster } from 'src/next/components/Workloads/WorkloadsTable/components'
import { DataTableHeaders } from 'src/next/types/dataTable'
import {
  bytesToUserFriendlySize,
  millicoresToUserFriendlySizeLong,
} from 'src/next/utils'
import { currencyFormat } from 'src/next/utils/currencyFormat'
import { useClustersPaginated } from 'src/api/queries/useClusters'
import { TableFilterToolbarActions, useTableFilter } from '../TableFilter'
import { useDownloadClusters } from 'src/api'
import { DownloadButton } from '../DownloadButton'

// lazy load carbon charts
const ClustersTableExpandedPanel = React.lazy(() =>
  import('./ClustersTableExpandedPanel').then(module => ({
    default: module.ClustersTableExpandedPanel,
  })),
)

const ConnectedCell = styled.div<{ $isConnected: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--cds-spacing-03);

  &::before {
    content: '';
    display: block;
    background-color: var(
      ${({ $isConnected }) =>
        $isConnected ? '--cds-support-success' : '--cds-border-strong-01'}
    );
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
`

const useHeaders = (): DataTableHeaders => {
  const { t } = useTranslation()

  return [
    {
      key: 'name',
      sort: 'name',
      header: t('Common.ClusterName'),
    },
    {
      key: 'region',
      sort: 'region',
      header: t('Common.Region'),
    },
    {
      key: 'accountID',
      header: t('Cluster.AccountID'),
      defaultVisibility: false,
    },
    {
      key: 'isConnected',
      sort: 'is_connected',
      header: t('Common.Connection'),
    },
    {
      key: 'cost',
      sort: 'cost_per_hour',
      header: t('Common.Cost'),
    },
    {
      key: 'cpu',
      header: t('Common.CPU'),
    },
    {
      key: 'memory',
      header: t('Common.Memory'),
    },
    {
      key: 'gpu',
      header: t('Common.GPU'),
    },
    {
      key: 'gpuMemory',
      header: t('Common.GPUMemory'),
    },
    {
      key: 'k8sVersion',
      sort: 'k8s_version',
      header: t('Cluster.KubernetesVersion'),
    },
    {
      key: 'version',
      sort: 'distribution_version',
      header: t('Cluster.CloudNatixVersion'),
      defaultVisibility: false,
    },
    {
      key: 'channel',
      sort: 'channel_name',
      header: t('Cluster.CloudNatixChannel'),
      defaultVisibility: false,
    },
  ]
}

const formatRow = (cluster: Cluster, t: TFunction, location: Location) => {
  const linkState = {
    pathname: location.pathname,
    search: location.search,
  }

  const gpuCapacity = cluster.gpu!.capacity!
  const gpuMemoryCapacity = cluster.gpuMemory!.capacity!

  return {
    id: cluster.uuid!,
    name: (
      <Link to={`/app/clusters/${cluster.uuid}`} state={linkState}>
        {cluster.name}
      </Link>
    ),
    region: (
      <WorkloadCluster cluster={cluster.region || ''} csp={cluster.csp} />
    ),
    accountID: cluster.accountId || '',
    isConnected: (
      <ConnectedCell $isConnected={!!cluster.isConnected}>
        {cluster.isConnected
          ? t('Clusters.Table.Connected')
          : t('Clusters.Table.Disconnected')}
      </ConnectedCell>
    ),
    cost: t('Common.Value/hr', {
      value: currencyFormat(cluster.costPerHour!),
    }),
    cpu: (
      <div data-testid="stacked-bar-cell-cpu" data-prevent-row-expand>
        <StackedBarCell
          value={cluster.cpu?.avgUsage}
          total={cluster.cpu?.capacity}
          formatFn={(value: number) => millicoresToUserFriendlySizeLong(value)}
        />
      </div>
    ),
    memory: (
      <div data-testid="stacked-bar-cell-memory" data-prevent-row-expand>
        <StackedBarCell
          value={cluster.memory?.avgUsage}
          total={cluster.memory?.capacity}
          formatFn={bytesToUserFriendlySize}
        />
      </div>
    ),
    gpu: gpuCapacity === 0 ? null : gpuCapacity,
    gpuMemory:
      gpuMemoryCapacity === 0
        ? null
        : bytesToUserFriendlySize(gpuMemoryCapacity),
    k8sVersion: cluster.kubernetesVersion,
    version: cluster.releaseSpec?.distributionVersion,
    channel: cluster.releaseSpec?.channelName,
  }
}

export const ClustersTable = () => {
  const { t } = useTranslation()

  const location = useLocation()

  const headers = useHeaders()
  const { pagination, orderBy } = useTableControls('clusters-table-page-size')

  const { filters, methods } = useTableFilter()
  // reset pagination when filters change
  methods.watch(() => pagination.resetPage())

  const { setPage, ...dataTablePaginationProps } = pagination

  const queryParams = {
    filter: filters || {},
    orderBy: orderBy.value,
    pageSize: pagination.pageSize,
  }
  const query = useClustersPaginated({}, queryParams)
  const { mutate: download, isLoading: downloadIsLoading } =
    useDownloadClusters(queryParams)
  const data = query.data?.pages?.[pagination.page - 1]

  const rows = useMemo(
    () => data?.clusters?.map(cluster => formatRow(cluster, t, location)),
    [data, location, t],
  )

  return (
    <div>
      <DataTable
        storageKey="datatable-clusters"
        isLoading={query.isFetching}
        headers={headers}
        orderBy={orderBy}
        pageSize={pagination.pageSize}
        setPage={setPage}
        size="xl"
        rows={rows || []}
        onExpand={id => {
          const cluster = data?.clusters?.find(c => c.uuid === id)
          return cluster ? (
            <Suspense fallback={<Loading size="small" withOverlay={false} />}>
              <ClustersTableExpandedPanel
                id={`clusters-${cluster.uuid}`}
                cluster={cluster}
              />
            </Suspense>
          ) : null
        }}
        toolbar={
          <>
            <DownloadButton
              fileTypes={['CSV', { name: 'Excel', extension: 'xlsx' }]}
              download={download}
              isLoading={downloadIsLoading}
              fileBase="clusters"
            />
            <TableFilterToolbarActions />
          </>
        }
      />
      <DataTablePagination {...dataTablePaginationProps} query={query} />
    </div>
  )
}
