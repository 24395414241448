/* eslint-disable @typescript-eslint/ban-types */
import {
  QueryObserverOptions,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from 'react-query'
import {
  User,
  CreateUserRequest,
  GetUserResponse,
  ListUsersRequest,
  ListUsersResponse,
  UpdateUserRequest as UpdateUserRequestApi,
} from '@cloudnatix-types/usercontroller'
import { deleteData, fetchData, mutateData, useRequestParams } from 'src/api'
import { queryClient } from 'src/api/queryClient'

const usersUrl = '/v1/users'
export const usersQueryKey = usersUrl

const selfUrl = '/v1/users/self'

const invalidateUsers = () => {
  queryClient.invalidateQueries(usersQueryKey)
}

export const useUsers = (request: ListUsersRequest) => {
  const params = useRequestParams({ request })
  return useInfiniteQuery<ListUsersResponse, Error>(
    [usersQueryKey, request],
    ({ pageParam }) => {
      return fetchData<ListUsersResponse>(usersUrl, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
      staleTime: 3600000,
    },
  )
}

export const useUserSelf = (
  options?: QueryObserverOptions<GetUserResponse, Error>,
): UseQueryResult<GetUserResponse, Error> => {
  return useQuery<GetUserResponse, Error>(
    [selfUrl],
    () => fetchData<GetUserResponse>(selfUrl),
    {
      staleTime: 60 * 60 * 1000,
      ...options,
    },
  )
}

// make uuid mandatory when updating
export interface UpdateUserRequest extends UpdateUserRequestApi {
  uuid: string
}

export const useUpdateUser = () =>
  useMutation(
    (data: UpdateUserRequest) =>
      mutateData<User, typeof data>(usersUrl, data, 'put'),
    { onSuccess: invalidateUsers },
  )

export const useCreateUser = () =>
  useMutation(
    (data: CreateUserRequest) =>
      mutateData<User, typeof data>(usersUrl, data, 'post'),
    { onSuccess: invalidateUsers },
  )

export const useDeleteUser = () =>
  useMutation((userId: string) => deleteData<{}>(`${usersUrl}/${userId}`), {
    onSuccess: invalidateUsers,
  })
