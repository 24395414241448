import { useState } from 'react'
import { Pause, Power, SkipForward, TrashCan } from '@carbon/react/icons'
import { useTranslation } from 'react-i18next'
import { Workload } from 'src/next/types/workloads'
import { markAction } from './Actions/markAction'
import { Action, MarkedWorkload } from './Actions/types'
import { DataTableRows } from './types'

export const useBatchActions = (workloads: Workload[]) => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState<Action | ''>('')

  const [markedWorkloads, setMarkedWorkloads] = useState<MarkedWorkload[]>([])

  const toggleModal = (
    selectedWorkloads: DataTableRows,
    workloads: Workload[],
    action: Action,
  ) => {
    const selectedWorkloadsIds = selectedWorkloads.map(workload => workload.id)

    const actionWorkloads = workloads.filter(workload =>
      selectedWorkloadsIds.includes(workload.uid),
    )

    const markedWorkloads = markAction(actionWorkloads, action)

    setMarkedWorkloads(markedWorkloads)
  }

  const batchActions = [
    {
      label: t('Workloads.BatchActions.Restart.Rollout'),
      onClick: (selectedWorkloads: DataTableRows) => {
        toggleModal(selectedWorkloads, workloads, 'restart')
        setShowModal('restart')
      },
      icon: Power,
    },
    {
      label: t('Workloads.BatchActions.Pause.Rollout'),
      onClick: (selectedWorkloads: DataTableRows) => {
        toggleModal(selectedWorkloads, workloads, 'pause')
        setShowModal('pause')
      },
      icon: Pause,
    },
    {
      label: t('Workloads.BatchActions.Resume.Rollout'),
      onClick: (selectedWorkloads: DataTableRows) => {
        toggleModal(selectedWorkloads, workloads, 'resume')
        setShowModal('resume')
      },
      icon: SkipForward,
    },
    {
      label: t('Workloads.BatchActions.Delete.Delete'),
      onClick: (selectedWorkloads: DataTableRows) => {
        toggleModal(selectedWorkloads, workloads, 'delete')
        setShowModal('delete')
      },
      icon: TrashCan,
    },
    {
      label: t(
        'Workloads.BatchActions.EnableAutopilot.Rollout',
        'Enable Autopilot',
      ),
      onClick: (selectedWorkloads: DataTableRows) => {
        toggleModal(selectedWorkloads, workloads, 'enable-autopilot')
        setShowModal('enable-autopilot')
      },
    },
    {
      label: t(
        'Workloads.BatchActions.DisableAutopilot.Rollout',
        'Disable Autopilot',
      ),
      onClick: (selectedWorkloads: DataTableRows) => {
        toggleModal(selectedWorkloads, workloads, 'disable-autopilot')
        setShowModal('disable-autopilot')
      },
    },
  ]

  return {
    batchActions,
    markedWorkloads,
    showModal,
    setShowModal,
  }
}
