import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import { Box } from 'src/next/components'
import { ContentSwitcher, Switch } from 'src/next/components/ContentSwitcher'
import { GenericTabSummaryView } from './GenericTabSummaryView'
import { GenericTabTrendView } from './GenericTabTrendView'
import { TagKeySelect } from './TagKeySelect'

interface GenericTabProps {
  id: string
  groupingKeyKey: `${ComputeInstanceGroupingKeyKey}`
  withTags?: boolean
  noLimit?: boolean
}

export const GenericTab = ({
  id,
  groupingKeyKey,
  withTags,
  noLimit,
}: GenericTabProps) => {
  const { t } = useTranslation()

  const chartTypes = [
    {
      label: t('Dashboard.Tabs.SelectDetailed'),
      id: 'detailed',
    },
    {
      label: t('Dashboard.Tabs.SelectSummary'),
      id: 'summary',
    },
  ] as const

  const initiallySelectedChartTypeIndex = 0
  const [selectedChartTypeIndex, setSelectedChartTypeIndex] = useState<number>(
    initiallySelectedChartTypeIndex,
  )
  const selectedChartType = chartTypes[selectedChartTypeIndex].id

  const [groupingKeyTagKey, setGroupingKeyTagKey] = useState<
    string | undefined
  >(undefined)

  return (
    <div>
      {withTags ? (
        <Box mb={5} minWidth="350px" maxWidth="400px">
          <TagKeySelect setTagKey={setGroupingKeyTagKey} />
        </Box>
      ) : null}

      <ContentSwitcher
        onChange={({ index }) => {
          if (index === undefined) return
          setSelectedChartTypeIndex(index)
        }}
        selectedIndex={initiallySelectedChartTypeIndex}
        size="md"
      >
        {chartTypes.map(({ id, label }) => (
          <Switch name={id} text={label} key={id} />
        ))}
      </ContentSwitcher>

      {selectedChartType === 'detailed' ? (
        <GenericTabTrendView
          id={`${id}-trend-view`}
          groupingKeyKey={groupingKeyKey}
          groupingKeyTagKey={groupingKeyTagKey}
          noLimit={noLimit === true}
        />
      ) : selectedChartType === 'summary' ? (
        <GenericTabSummaryView
          id={`${id}-summary-view`}
          groupingKeyKey={groupingKeyKey}
          groupingKeyTagKey={groupingKeyTagKey}
        />
      ) : null}
    </div>
  )
}
