import { useTranslation } from 'react-i18next'
import { currencyFormat } from 'src/next/utils'

export const WorkloadCost = ({ costPerHour }: { costPerHour: number }) => {
  const { t } = useTranslation()

  if (costPerHour === 0) return <>N/A</>

  const costPerHourFormatted = currencyFormat(costPerHour, {
    maximumFractionDigits: 3,
  })

  return (
    <span title={t('Common.Value/hr', { value: costPerHour })}>
      {t('Common.Value/hr', { value: costPerHourFormatted })}
    </span>
  )
}
