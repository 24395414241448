import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { isNil, pickBy, uniqueId } from 'lodash'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { getObjDifferences, localStorageObj } from 'src/next/utils'

export interface TableFilterContextProps {
  // Unique context id that is used as a prefix by child filter components to
  // prevent DOM id's from clashing when multiple filter contexts are on the
  // same page (which f.e. can be the case on the clusters page)
  // Will be generated when none is provided.
  contextId: string
  localStorageId?: string
  isOpen: boolean
  activeFilters: Record<string, any>
  activeFiltersCount: number
  filters: Record<string, any>
  toggleFilters(): void
  registerLabel: React.Dispatch<LabelDict>
  setIsOpen: React.Dispatch<boolean>
  labels: LabelDict
  methods: Exclude<UseFormReturn, 'reset'>
  reset: () => void
  defaultValues: Record<string, any>
  registerDefaultValue(key: string, value: any): void
}

const TableFilterContext = createContext<TableFilterContextProps | undefined>(
  undefined,
)
TableFilterContext.displayName = 'TableFilterContext'

interface TableFilterContextProviderProps {
  contextId?: string
  children: ReactNode
  defaultValues?: any
  defaultOpen?: boolean
  localStorageId?: string
}

type LabelDict = Record<string, string> | undefined

const labelReducer = (state: LabelDict, label: LabelDict): LabelDict => ({
  ...state,
  ...label,
})

export const TableFilterContextProvider = ({
  children,
  defaultValues: defaultValuesProp,
  defaultOpen,
  localStorageId,
  contextId: contextIdProp,
}: TableFilterContextProviderProps) => {
  const defaultValues = useRef(defaultValuesProp || {})
  const contextId = useMemo(() => contextIdProp || uniqueId(), [contextIdProp])

  // prevents issues where state seems out of sync after resetting the form
  const registerDefaultValue = useCallback((key: string, value: any) => {
    if (typeof defaultValues.current[key] === 'undefined') {
      defaultValues.current = {
        ...defaultValues.current,
        [key]: value,
      }
    }
  }, [])

  const methods = useForm({
    mode: 'onChange',
    // we use defaultValues only to set the initial state of the filters and NOT
    // for resetting the filter state. For that `defaultValues.current` is used.
    defaultValues: localStorageObj.get(localStorageId) || defaultValues.current,
  })

  // todo: check if this causes more re-renders
  // seems this can be merged to one filter change event
  // if localStorageId is set update localStorage on filter change
  useEffect(() => {
    if (!localStorageId) return

    const subscription = methods.watch((values: any) => {
      // remove empty array values, conflicts with some api endpoints
      Object.entries(values).forEach(([key, value]) => {
        if (Array.isArray(value) && !value.length) {
          values[key] = undefined
        }
      })
      return localStorageObj.set(localStorageId, values)
    })

    return () => subscription.unsubscribe()
  }, [localStorageId, methods, methods.watch])

  // the actual filters that are used for the query params
  const filters = methods.watch()

  // filter out undefined & empty arrays, breaks some api endpoints
  Object.entries(filters).forEach(([key, value]) => {
    if ((Array.isArray(value) && !value.length) || value === undefined) {
      delete filters[key]
    }
  })

  // the active filters that are displayed inside the sidebar
  const isFalsy = (item: any) => !(isNil(item) || item === false)
  const activeFilters = getObjDifferences(
    // filter out falsy values
    pickBy(filters, isFalsy),
    pickBy(defaultValues.current, isFalsy),
  )

  // method for registering labels that are displayed under 'Active filters'
  const [labels, registerLabel] = useReducer(labelReducer, {})

  // count the amount of nested filters (f.e. the count of provided names,
  // which is an array)
  const nestedFiltersCount = Object.values(activeFilters).reduce(
    (acc: number, item: unknown) =>
      Array.isArray(item) ? acc + item.length : acc,
    0,
  )

  // get all entries, except for arrays, which have been counted above
  const activeFiltersCount =
    Object.entries(activeFilters)
      .filter(item => !!item)
      .filter(([, value]: [string, unknown]) => !Array.isArray(value)).length +
    nestedFiltersCount

  // open the filters when there are active filters on mount. This can be
  // overridden by specifying `defaultOpen`
  const [isOpen, setIsOpen] = useState(defaultOpen || !!activeFiltersCount)

  const reset = useCallback(
    () => methods?.reset(defaultValues.current),
    [methods],
  )

  const contextValue = useMemo(
    () => ({
      toggleFilters: () => setIsOpen(prevState => !prevState),
      isOpen,
      setIsOpen,
      activeFilters,
      activeFiltersCount,
      filters,
      labels,
      registerLabel,
      methods,
      reset,
      defaultValues: defaultValues.current,
      registerDefaultValue,
      localStorageId,
      contextId,
    }),
    [
      activeFilters,
      activeFiltersCount,
      filters,
      isOpen,
      labels,
      methods,
      reset,
      registerDefaultValue,
      localStorageId,
      contextId,
    ],
  )

  return (
    <TableFilterContext.Provider value={contextValue}>
      <FormProvider {...methods}>{children}</FormProvider>
    </TableFilterContext.Provider>
  )
}

// todo: see if we want generics to get typed filters
export function useTableFilter() {
  const context = useContext(TableFilterContext)

  if (!context)
    throw new Error(
      'useTableFilter must be used within <TableFilterContextProvider>',
    )

  return context
}
