import React from 'react'
import { Search } from '@carbon/react/icons'
import { HeaderGlobalAction } from '@carbon/react'
import { useKBar } from 'kbar'
import { useTranslation } from 'react-i18next'
import { isMac } from 'src/next/utils'

export const CLUIToggle = () => {
  const { query } = useKBar()
  const { t } = useTranslation()

  return (
    <HeaderGlobalAction
      aria-label={`${t('CLUI.Label')} (${isMac ? '⌘' : 'ctrl'} + k)`}
      onClick={query?.toggle}
    >
      <Search size={20} />
    </HeaderGlobalAction>
  )
}
