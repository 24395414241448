export const isProduction = () =>
  import.meta.env.VITE_ENV_NAME !== undefined &&
  import.meta.env.VITE_ENV_NAME === 'Production'

export const isStaging = () =>
  import.meta.env.VITE_ENV_NAME !== undefined &&
  import.meta.env.VITE_ENV_NAME === 'Staging'

export const isDevelopment = () =>
  import.meta.env.VITE_ENV_NAME !== undefined &&
  import.meta.env.VITE_ENV_NAME === 'Development'
