import React, { ReactNode } from 'react'
import * as carbonType from '@carbon/type'
import CSS from 'csstype'
import styled from 'styled-components'

// In case you miss any type styles from:
// https://carbondesignsystem.com/guidelines/typography/type-sets
// you can look them up here:
// https://github.com/carbon-design-system/carbon/blob/main/packages/type/src/styles.js
// and add them below + to index.d.ts.

const typeMap = {
  // shorthand aliases for the styles that are used most often
  body: 'body-short-01',
  code: 'code01',
  display: 'display01',
  'heading-01': 'productiveHeading01',
  'heading-02': 'productiveHeading02',
  'heading-03': 'productiveHeading03',
  'heading-04': 'productiveHeading04',
  'heading-05': 'productiveHeading05',
  'helper-text': 'helperText01',
  label: 'label01',

  // Carbon styles
  'body-long-01': 'bodyLong01',
  'body-long-02': 'bodyLong02',
  'body-long': 'bodyLong01',
  'body-short-01': 'bodyShort01',
  'body-short-02': 'bodyShort02',
  'body-short': 'bodyShort01',
  'caption-01': 'caption01',
  'caption-02': 'caption02',
  'code-01': 'code01',
  'code-02': 'code02',
  'display-01': 'display01',
  'display-02': 'display02',
  'display-03': 'display03',
  'display-04': 'display04',
  'expressive-heading-01': 'expressiveHeading01',
  'expressive-heading-02': 'expressiveHeading02',
  'expressive-heading-03': 'expressiveHeading03',
  'expressive-heading-04': 'expressiveHeading04',
  'expressive-heading-05': 'expressiveHeading05',
  'expressive-heading-06': 'expressiveHeading06',
  'expressive-paragraph-01': 'expressiveParagraph01',
  'helper-text-01': 'helperText01',
  'helper-text-02': 'helperText02',
  'label-01': 'label01',
  'label-02': 'label02',
  'productive-heading-01': 'productiveHeading01',
  'productive-heading-02': 'productiveHeading02',
  'productive-heading-03': 'productiveHeading03',
  'productive-heading-04': 'productiveHeading04',
  'productive-heading-05': 'productiveHeading05',
  'productive-heading-06': 'productiveHeading06',
  'productive-heading-07': 'productiveHeading07',
  'quotation-01': 'quotation01',
  'quotation-02': 'quotation02',
}

export interface TypographyProps {
  children: ReactNode
  variant: keyof typeof typeMap
  as?: React.ElementType
  style?: CSS.Properties
  color?: string
  className?: string
}

const Component = styled.p`
  // styles are overwritten in certain places by Carbon (f.e. in modals)
  // so we have to increase specificity
  && {
    ${({ typeStyles }: { typeStyles: string }) => typeStyles}
  }
`

export const Typography = ({
  variant,
  as = 'p',
  style,
  color,
  ...props
}: TypographyProps) => {
  // @ts-ignore - TypeScript complains the added type styles above are not
  // available on carbonType, but the type definitions on DefinitelyTyped are
  // not up-to-date
  const typeStyles = carbonType[typeMap[variant]]
  if (!typeStyles) throw new Error(`No type style found for '${variant}'`)

  return (
    <Component
      as={as}
      typeStyles={typeStyles}
      {...props}
      style={{ ...style, color }}
    />
  )
}

export default Typography
