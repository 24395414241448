import React, { useState } from 'react'
import {
  Button,
  Column,
  ComposedModal,
  DenormalizedRow,
  Grid,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Select,
  SelectItem,
  UnorderedList,
} from '@carbon/react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UserRoleBinding } from '@cloudnatix-types/usercontroller'
import { Box } from 'src/next/components/Box'
import { StyledListItem } from 'src/next/components/helpers/ListHelpers'
import ShowMore from 'src/next/components/wrappers/ShowMore'
import { useUserEditRoles } from './hooks'
import { UserFormValues } from './UserActionsModal'
import { UserRoleBindingsForm } from './UserRoleBindingsForm'
import { UserTableRowData } from './UsersTable'
import { css } from 'styled-components'

interface EditRolesActionProps {
  onSuccess(): void
  showModal: boolean
  setShowModal: (showModal: '' | 'delete') => void
  selectedUsers: readonly DenormalizedRow[]
  users: UserTableRowData[]
}

function getUserDetails(user: DenormalizedRow) {
  const cells = user.cells ?? []
  const [loginId, firstName, lastName] = cells
  return {
    id: `${user.id}`,
    loginId: `${loginId.value}`,
    name: `${firstName.value} ${lastName.value}`,
  }
}

interface UserEditFormValues extends UserFormValues {
  roleBindingsAction: RoleBindingsAction
}

type RoleBindingsAction = '' | 'add' | 'remove'

interface SubmitProps {
  roleBindings: UserRoleBinding[]
  roleBindingsAction: RoleBindingsAction
}

export const EditRolesAction = ({
  onSuccess,
  showModal,
  setShowModal,
  selectedUsers: selectedRows,
  users,
}: EditRolesActionProps) => {
  const { t } = useTranslation()
  const [executingBulkActions, setExecutingBulkActions] = useState(false)
  const { handleAddRoleBindings, handleRemoveRoleBindings } = useUserEditRoles({
    onSuccess,
    selectedRows,
    users,
    setExecutingBulkActions,
    setShowModal,
  })

  const formMethods = useForm<UserEditFormValues>({
    defaultValues: {
      roleBindingsAction: '',
    },
  })
  const [roleBindings, roleBindingsAction] = formMethods.watch([
    'roleBindings',
    'roleBindingsAction',
  ])

  const onSubmit = ({ roleBindings, roleBindingsAction }: SubmitProps) => {
    if (roleBindingsAction === 'add') {
      handleAddRoleBindings(roleBindings)
    } else if (roleBindingsAction === 'remove') {
      handleRemoveRoleBindings(roleBindings)
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <ComposedModal
          size="sm"
          open={showModal}
          onClose={() => setShowModal('')}
        >
          {executingBulkActions && <Loading withOverlay />}

          <ModalHeader title={t('Users.Edit.Bulk.Title')} />

          <ModalBody hasScrollingContent aria-label="Role bindings form">
            <p>
              {t('Users.Edit.Bulk.AffectedUsers', {
                count: selectedRows.length,
              })}
            </p>

            <Box my="4" ml="5">
              <ShowMore maxItems={3} wrapperComponent={<UnorderedList />}>
                {selectedRows.map((user: DenormalizedRow) => {
                  const { loginId, name } = getUserDetails(user)
                  return <StyledListItem key={loginId}>{name}</StyledListItem>
                })}
              </ShowMore>
            </Box>

            <Box mt="6">
              <Grid
                narrow
                css={css`
                  overflow: hidden;
                `}
              >
                <Row>
                  <Column>
                    {/**
                     * As Carbon's Select is a controlled component, we need to use
                     * the Controller component of react-hook-form.
                     * It makes sense to extract this to a reusable component
                     * once we're going to use Select more often within react-hook-form.
                     */}
                    <Controller
                      control={formMethods.control}
                      name="roleBindingsAction"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          onChange={onChange}
                          onBlur={onBlur}
                          id="roleBindingsAction"
                          name="roleBindingsAction"
                          labelText={`${t('Users.Edit.RoleBindings.Label')}`}
                          value={value}
                          required
                          autoFocus
                        >
                          <SelectItem
                            text={`${t('User.Bulk.SelectRoleBindingsAction')}`}
                            value=""
                          />
                          <SelectItem text={`${t('Common.Add')}`} value="add" />
                          <SelectItem
                            text={`${t('Common.Remove')}`}
                            value="remove"
                          />
                        </Select>
                      )}
                    />
                  </Column>
                </Row>
                <UserRoleBindingsForm
                  buttonTitle={t('Users.Form.ChooseRole')}
                />
              </Grid>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button kind="secondary" onClick={() => setShowModal('')}>
              {t('Common.Cancel')}
            </Button>
            <Button
              kind={roleBindingsAction === 'remove' ? 'danger' : 'primary'}
              type="submit"
              disabled={roleBindingsAction === '' || roleBindings.length === 0}
            >
              {roleBindingsAction === 'remove'
                ? t('Common.Remove')
                : t('Common.Add')}
            </Button>
          </ModalFooter>
        </ComposedModal>
      </form>
    </FormProvider>
  )
}
