import React, { useContext } from 'react'
import { Asleep, Light } from '@carbon/react/icons'
import { Priority, useRegisterActions } from 'kbar'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'src/next/contexts/GlobalThemeProvider'

export const useCLUIRegisterThemeSwitch = () => {
  const { t } = useTranslation()

  const { theme: activeTheme, switchTheme } = useContext(
    ThemeContext,
  ) as unknown as {
    switchTheme: () => void
    theme: 'light' | 'dark'
  }

  useRegisterActions(
    !activeTheme || !switchTheme
      ? []
      : [
          {
            id: 'switchTheme',
            name: t('CLUI.SwitchTheme', {
              theme:
                activeTheme === 'light'
                  ? t('CLUI.Dark', 'dark')
                  : t('CLUI.Light', 'light'),
            }),
            icon: activeTheme === 'light' ? <Asleep /> : <Light />,
            perform: switchTheme,
            section: {
              name: t('CLUI.Miscellaneous'),
              priority: Priority.LOW,
            },
          },
        ],
    [activeTheme],
  )
}
