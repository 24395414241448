import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import Toast, { ToastProps } from 'src/next/components/Toast'
import { ApiError } from '../types/api-error'

const ToasterWrapper = styled.div`
  position: fixed;
  right: 0;
  top: var(--cds-spacing-09); // header height
  z-index: 9001;
`

type ToastId = string

export interface ToasterContextProps {
  addToast(toast: ToastProps): ToastId
  addApiErrorToast(error: ApiError): ToastId
  removeToast(id: ToastId): void
}

function parseApiError(error: ApiError) {
  let msg = ''
  if (error && error?.message) {
    msg = error?.message
  } else if (error?.data && error?.data?.message) {
    msg = error?.data?.message
  } else if (error?.data && error?.status) {
    msg = `${error.status} ${error.data}`
  }
  return msg
}

export const ToasterContext = createContext<ToasterContextProps | undefined>(
  undefined,
)
ToasterContext.displayName = 'ToasterContext'

interface ToasterContextProviderProps {
  children: ReactNode
}

export const ToasterContextProvider = ({
  children,
}: ToasterContextProviderProps) => {
  const timerRef = useRef<any>()
  const counterRef = useRef(0)
  const [toasts, setToasts] = useState<ToastProps[]>([])
  const [pauseTimer, setPauseTimer] = useState(false)

  useEffect(() => {
    if (!pauseTimer && toasts.length > 0) {
      timerRef.current = setTimeout(
        () => setToasts(toasts => toasts.slice(1)),
        toasts[0].timeout || 3000,
      )
      return () => clearTimeout(timerRef.current)
    }
  }, [toasts, pauseTimer])

  useEffect(() => {
    if (pauseTimer) {
      clearTimeout(timerRef.current)
    }
  }, [pauseTimer])

  const addToast = useCallback(
    (toast: ToastProps) => {
      counterRef.current++
      const id = toast.id || counterRef.current.toString()
      setToasts(toasts => [...toasts, { ...toast, id, key: id }])
      return id
    },
    [setToasts],
  )

  // standard handling of api errors / catch
  const addApiErrorToast = useCallback(
    (error: any) => {
      counterRef.current++
      const id = counterRef.current.toString()
      setToasts(toasts => [
        ...toasts,
        { kind: 'error', title: parseApiError(error), id, key: id },
      ])
      return id
    },
    [setToasts],
  )

  const removeToast = useCallback(
    (id?: ToastId) => {
      if (!id) return

      setToasts(toasts => toasts.filter(toast => toast.id !== id))
      setPauseTimer(false)
    },
    [setToasts, setPauseTimer],
  )

  const contextValue = useMemo(
    () => ({
      addToast,
      removeToast,
      addApiErrorToast,
    }),
    [addToast, removeToast, addApiErrorToast],
  )

  return (
    <ToasterContext.Provider value={contextValue}>
      {children}
      {createPortal(
        <ToasterWrapper
          id="toaster-container"
          onMouseOver={() => setPauseTimer(true)}
          onMouseOut={() => setPauseTimer(false)}
        >
          {toasts.map(({ timeout, ...toast }, index) => {
            // Don't pass timeout to Toast. Otherwise it will close right after that timeout while it needs to wait
            // until it is at the top of the queue
            return (
              <Toast
                key={index}
                {...toast}
                onCloseButtonClick={() => removeToast(toast.id)}
              />
            )
          })}
        </ToasterWrapper>,
        document.body,
      )}
    </ToasterContext.Provider>
  )
}

export default ToasterContext
