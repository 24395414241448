import styled from 'styled-components'

// Wrapper around dropdown component, since not all contents can be styled
// directly via `styled(Dropdown)`
export const DropdownHeading = styled.div<{ $fontSize?: string }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  font-weight: 600;
  line-height: 1.375;

  .cds--list-box {
    height: auto;
    background: transparent;

    &__field {
      outline-offset: 2px;
      padding: 0;
    }

    &__label {
      font-size: ${({ $fontSize }) => $fontSize || '1rem'};
      font-weight: 600;
      line-height: 1.375;
    }

    &__menu-icon {
      position: relative;
      right: auto;
    }

    &__wrapper--inline {
      grid-gap: var(--cds-spacing-02);
    }

    // dropdown
    &__menu {
      margin-top: var(--cds-spacing-04);

      &:focus {
        outline-color: rgba(var(--RGB_carbonGrayScale1), 0.2);
      }
    }
  }
`

export const DropdownSubheading = styled.span`
  color: var(--cds-text-secondary);
  font-size: 0.8125rem;
  margin-bottom: var(--cds-spacing-02);
  margin-top: calc(var(--cds-spacing-02) * -1);
`

export const DropdownInlineLabel = styled.span`
  font-size: 1rem;
  white-space: pre;
  margin-top: 1px;
`
