import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  LazyTabPanel,
  Tab,
  Tabs,
  TabList,
  TabPanels,
} from 'src/next/components/Tabs'
import { PageTitle } from 'src/next/components/ui'
import {
  AuditLog,
  Configuration,
  Metrics,
  Organizations,
  Resources,
  Rules,
  Users,
} from 'src/next/components/administration'
import useLocalStorage from 'src/next/hooks/useLocalStorage'

export const AdministrationPage = () => {
  const { t } = useTranslation()

  const [selectedTabIndex, setSelectedTabIndex] = useLocalStorage(
    'admin-page',
    0,
  )

  const tabs = [
    {
      id: 'organizations',
      title: t('Organizations.PageTitle'),
      component: <Organizations />,
    },
    {
      id: 'resources',
      title: t('Resources.PageTitle', 'Resources'),
      component: <Resources />,
    },
    {
      id: 'rules',
      title: t('Rules.PageTitle'),
      component: <Rules />,
    },
    {
      id: 'users',
      title: t('Users.PageTitle'),
      component: <Users />,
    },
    {
      id: 'auditlogs',
      title: t('AuditLogs.PageTitle'),
      component: <AuditLog />,
    },
    {
      id: 'metrics',
      title: t('AdministrativeMetrics.PageTitle', 'Metrics'),
      component: <Metrics />,
    },
    {
      id: 'configuration',
      title: t('AdministrativeConfiguration.PageTitle', 'Configuration'),
      component: <Configuration />,
    },
  ]

  return (
    <>
      <PageTitle title={t('NavigationPanel.Administration')} />
      <Tabs
        selectedIndex={selectedTabIndex}
        onChange={({ selectedIndex }) => setSelectedTabIndex(selectedIndex)}
      >
        <TabList aria-label="Administration page tabs">
          {tabs.map(tab => (
            <Tab key={tab.id} id={tab.id}>
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map((tab, index) => (
            <LazyTabPanel
              key={tab.id}
              index={index}
              selectedIndex={selectedTabIndex}
            >
              {tab.component}
            </LazyTabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  )
}
