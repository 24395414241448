import { useState } from 'react'
import { useUrlSearchParams } from 'src/next/hooks'

// `initialTabId` has precedence over the `tabs` query parameter from the URL.
export const useTabSelection = (tabIds: string[], initialTabId?: string) => {
  const { tabs: tabId } = useUrlSearchParams()

  const effectiveInitialTabId = initialTabId ?? tabId
  const tabIndex = effectiveInitialTabId
    ? Math.max(
        0,
        tabIds.findIndex(
          value => value.toLowerCase() === effectiveInitialTabId.toLowerCase(),
        ),
      )
    : 0

  const [selectedTab, setSelectedTab] = useState(tabIndex)

  return { selectedTab, setSelectedTab }
}
