import {
  CodeSnippet as CarbonCodeSnippet,
  ModalFooter as CarbonModalFooter,
  CodeSnippetMultiProps,
  ComposedModal,
} from '@carbon/react'
import styled from 'styled-components'

export const Modal = styled(ComposedModal)`
  .cds--modal-container--sm {
    @media (min-width: 66rem) {
      max-height: 84%;
    }
  }
`

type CodeSnippetMultiType = React.ComponentType<CodeSnippetMultiProps>

export const CodeSnippet = styled<CodeSnippetMultiType>(CarbonCodeSnippet)`
  background: var(--cds-field-02, #393939);

  .cds--snippet-container {
    overflow-y: hidden;
  }

  &.cds--snippet--disabled {
    color: var(--cds-text-disabled);
  }
`

export const ModalFooter = styled(CarbonModalFooter)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  > * + * {
    border-left: 1px solid var(--cds-layer-01, #f4f4f4);
    box-shadow: none !important;
  }

  div > div {
    display: flex;
  }

  .cds--btn--primary {
    flex: 1 0 auto;
    padding-right: 0.875rem;
  }
`

export const ModalHeading = styled.h2`
  font-size: var(--cds-productive-heading-02-font-size);
  font-weight: var(--cds-productive-heading-03-font-weight);
  line-height: var(--cds-productive-heading-03-line-height);
  margin-bottom: var(--cds-spacing-03);
  margin-top: var(--cds-spacing-05);
`
