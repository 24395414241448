import React, { ReactNode } from 'react'
import { GraphColor } from 'src/next/types/workloads'
import * as Styled from './Legend.styled'

export interface LegendItemProps {
  id: string
  color: GraphColor
  checked?: boolean
  onChange?: (checked: boolean) => void
  onMouseOver?: React.MouseEventHandler<HTMLLIElement>
  onMouseMove?: React.MouseEventHandler<HTMLLIElement>
  onMouseOut?: React.MouseEventHandler<HTMLLIElement>
  readOnly?: boolean
  title?: string
  isSelected?: boolean
  children?: any
}

export const LegendItem = ({
  id,
  color,
  checked,
  onChange,
  onMouseOver,
  onMouseMove,
  onMouseOut,
  readOnly,
  isSelected,
  title,
  children,
}: LegendItemProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return onChange?.(event.target.checked)
  }

  const isReadOnly = typeof checked !== 'undefined' && !readOnly

  // throw error when a checked-value is passed without an onChange-handler and
  // readOnly-prop to prevent unintentional static props
  if (isReadOnly && typeof onChange !== 'function')
    throw new Error(
      'A `checked` prop was provided to `LegendItem` without an `onChange`-handler. This will result in a read-only `checked`-value. If you want it to be mutable, provide a `checked`-prop with an `onChange`-handler. If you want it to be readonly, provide a `readOnly`-prop.',
    )

  const showCheckbox = typeof isSelected !== 'undefined' && isSelected

  return (
    <Styled.LegendListItem
      title={title}
      $hasSelection={showCheckbox}
      onMouseOver={onMouseOver}
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}
    >
      <Styled.LegendItemInput
        type="checkbox"
        name={id}
        id={id}
        disabled={!isReadOnly}
        checked={typeof checked === 'undefined' ? true : checked}
        onChange={handleInputChange}
      />
      <Styled.LegendItemLabel htmlFor={id}>
        <Styled.LegendItemColor $color={color} />
        <span>{children}</span>

        {isReadOnly || showCheckbox ? (
          <Styled.LegendItemCheckmark
            focusable="false"
            preserveAspectRatio="xMidYMid meet"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            viewBox="0 0 31 28"
            aria-hidden="true"
          >
            <path d="M13 21.2l-7.1-7.1-1.4 1.4 7.1 7.1L13 24 27.1 9.9l-1.4-1.5z" />
          </Styled.LegendItemCheckmark>
        ) : null}
      </Styled.LegendItemLabel>
    </Styled.LegendListItem>
  )
}

export interface LegendProps {
  children?: ReactNode
}

export const Legend = ({ children }: LegendProps) => {
  return children ? <Styled.LegendList>{children}</Styled.LegendList> : null
}
