import { useQuery, UseQueryOptions } from 'react-query'
import {
  ListComputeInstanceSummariesRequest,
  ListComputeInstanceSummariesResponse as ResponseType,
  SummaryInterval,
} from '@cloudnatix-types/dashboard'
import { fetchData } from 'src/api/axios'
import { useRequestParams } from 'src/api/useRequestParams'
import { TimeRange } from 'src/next/components/dashboard/timerange/TimerangeTypes'
import { useOrgSelection } from 'src/next/hooks'

export const useInstanceSummaries = <T = ResponseType>(
  request: ListComputeInstanceSummariesRequest,
  options?: UseQueryOptions<ResponseType, any, T>,
) => {
  const url = '/v1/dashboard/infrastructure/compute/instancesummaries'

  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<ResponseType, any, T>(
    [url, params],
    () => fetchData<ResponseType>(url, params),
    {
      cacheTime: 3600000, // 60 * 60 * 1000 = 1 hour
      retry: false,
      ...options,
    },
  )
}

interface UseCurrentOrgInstanceSummariesProps {
  duration: SummaryInterval
  startTimeNs: string
  endTimeNs: string
}

export const useCurrentOrgInstanceSummaries = ({
  duration,
  startTimeNs,
  endTimeNs,
}: UseCurrentOrgInstanceSummariesProps) => {
  const [orgUuid] = useOrgSelection()

  return useInstanceSummaries({
    filter: {
      startTimeNs,
      endTimeNs,
      grouping: {
        groupingValue: orgUuid,
        duration,
        groupingKey: {
          key: 'ORG' as any,
        },
      },
    },
  })
}

export const getFilterGroupingDuration = (
  timeRange: TimeRange,
): keyof typeof SummaryInterval => {
  switch (timeRange) {
    case 'week':
      return 'DAILY'
    case 'month':
      return 'DAILY'
    case '6months':
      return 'WEEKLY'
    default:
      return 'DAILY'
  }
}
