import { useQuery, UseQueryOptions } from 'react-query'
import {
  ComputeInstanceGroup,
  ListComputeInstanceGroupsRequest,
  ListComputeInstanceGroupsResponse,
} from '@cloudnatix-types/dashboard'
import { fetchAllPages } from 'src/api'
import { useRequestParams } from 'src/api/useRequestParams'

export function useDashboardInstanceGroups(
  request: ListComputeInstanceGroupsRequest,
  options?: UseQueryOptions<ComputeInstanceGroup[], any>,
) {
  const url = '/v1/dashboard/infrastructure/compute/instancegroups'
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<ComputeInstanceGroup[], Error>(
    [url, params],
    () =>
      fetchAllPages<ComputeInstanceGroup, ListComputeInstanceGroupsResponse>(
        url,
        'instanceGroups',
        params,
      ),
    {
      staleTime: 60000,
      ...options,
    },
  )
}
