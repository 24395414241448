interface SaveBlobAsOptions {
  fileName: string
  type?: string
}

export const saveBlobAs = (
  blob: BlobPart,
  { fileName, type = 'text/plain;charset=utf-8' }: SaveBlobAsOptions,
) => {
  const a = document.createElement('a')
  const file = new Blob([blob], { type })
  a.href = URL.createObjectURL(file)
  a.download = fileName
  a.click()
  URL.revokeObjectURL(a.href)
}

export const fileTypes = {
  txt: 'text/plain;charset=utf-8',
  csv: 'text/csv;charset=utf-8',
  json: 'application/json;charset=utf-8',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
}
