import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { WorkloadResources as WorkloadResourcesType } from '@cloudnatix-types/dashboard'
import { Tooltip, TooltipButton } from 'src/next/components/Tooltip'
import { ProgressDonut } from 'src/next/components/ui'
import {
  bytesToUserFriendlySize,
  millicoresToUserFriendlySizeLong,
} from 'src/next/utils'

const RightAlignedCell = styled.td`
  text-align: right;
  padding-right: 0.2em;
`

const LeftAlignedCell = styled.td`
  text-align: left;
  padding-left: 0.2em;
  padding-right: 0.2em;
`

const StyledTooltipButton = styled(TooltipButton)`
  color: var(--data-table-cell-text-color, var(--cds-text-secondary));
  &&:hover {
    color: var(--data-table-cell-text-color, var(--cds-text-secondary));
  }
`

interface WorkloadResourcesProps {
  resources: WorkloadResourcesType
  hideTooltips?: boolean
}

const formatCPU = (value: number) => {
  if (value === 0) {
    return '---'
  }

  return millicoresToUserFriendlySizeLong(value)
}

const formatMemory = (value: number) => {
  if (value === 0) {
    return '---'
  }

  return bytesToUserFriendlySize(value)
}

interface ResourceFieldProps {
  limit: number
  request: number
  usage: number
}

const CpuLabel = ({ limit, request, usage }: ResourceFieldProps) => {
  if (request === 0) {
    return <div>---</div>
  }

  return (
    <ProgressDonut
      percent={Math.round((usage / (limit || request)) * 100)}
      label={millicoresToUserFriendlySizeLong(request)}
    />
  )
}

const MemoryLabel = ({ limit, request, usage }: ResourceFieldProps) => {
  if (request === 0) {
    return <div>---</div>
  }

  return (
    <ProgressDonut
      percent={Math.round((usage / (limit || request)) * 100)}
      label={bytesToUserFriendlySize(request)}
    />
  )
}

export const WorkloadResources = ({
  resources,
  hideTooltips,
}: WorkloadResourcesProps) => {
  const { t } = useTranslation()

  const cpu = {
    usage: Number(resources?.cpu?.usage),
    limit: Number(resources?.cpu?.limit),
    request: Number(resources?.cpu?.request),
  }

  const memory = {
    usage: Number(resources?.memory?.usage),
    limit: Number(resources?.memory?.limit),
    request: Number(resources?.memory?.request),
  }

  const resourcesLabel = (
    <StyledTooltipButton>
      <CpuLabel {...cpu} />
      <MemoryLabel {...memory} />
    </StyledTooltipButton>
  )

  if (hideTooltips) {
    return resourcesLabel
  }

  const tooltipLabel = (
    <>
      <strong>{t('Common.CPU')}</strong>
      <table>
        <tbody>
          {/* TODO: "used" is not filled in by the back-end yet */}
          {/*<tr>*/}
          {/*  <CellValue>*/}
          {/*    {formatMillis(cpu.usage)} {cpu.label(cpu.usage)}*/}
          {/*  </CellValue>*/}
          {/*  <td>{t('Workload.Resources.used')}</td>*/}
          {/*</tr>*/}
          <tr>
            <LeftAlignedCell>
              {t('Workload.Resources.requested')}
            </LeftAlignedCell>
            <RightAlignedCell>{formatCPU(cpu.request)}</RightAlignedCell>
          </tr>
          <tr>
            <LeftAlignedCell>{t('Workload.Resources.limit')}</LeftAlignedCell>
            <RightAlignedCell>{formatCPU(cpu.limit)}</RightAlignedCell>
          </tr>
        </tbody>
      </table>
      <br />
      <strong>{t('Common.Memory')}</strong>
      <table>
        <tbody>
          {/* "used" is not filled in by the back-end yet */}
          {/*<tr>*/}
          {/*  <CellValue>{usage}</CellValue>*/}
          {/*  <td>{t('Workload.Resources.used')}</td>*/}
          {/*</tr>*/}
          <tr>
            <LeftAlignedCell>
              {t('Workload.Resources.requested')}
            </LeftAlignedCell>
            <RightAlignedCell>{formatMemory(memory.request)}</RightAlignedCell>
          </tr>
          <tr>
            <LeftAlignedCell>{t('Workload.Resources.limit')}</LeftAlignedCell>
            <RightAlignedCell>{formatMemory(memory.limit)}</RightAlignedCell>
          </tr>
        </tbody>
      </table>
    </>
  )

  return (
    <Tooltip label={tooltipLabel} tabIndex={-1} align="bottom">
      {resourcesLabel}
    </Tooltip>
  )
}
