/* eslint-disable no-empty */
if (
  import.meta.env.NODE_ENV === 'development' ||
  import.meta.env.NODE_ENV === 'test'
) {
  const errorFn = global.console.error
  const warnFn = global.console.warn

  global.console.error = (...msg) => {
    // hide carbon chart css error
    if (
      typeof msg[0] === 'string' &&
      msg[0].indexOf('Missing CSS styles for Carbon Charts') > -1
    ) {
      return
    }

    // hide carbon overflow menu warnings we cannot solve
    if (
      msg[1] === 'closeMenu' ||
      msg[1] === 'handleOverflowMenuItemFocus' ||
      msg[1] === 'setPage' ||
      msg[1] === 'resetPage'
    ) {
    } else {
      errorFn(...msg)
    }
  }

  global.console.warn = (...msg) => {
    if (typeof msg[0] !== 'string') return warnFn(msg)
    if (msg[0].indexOf('listener not found') > -1) {
    } else {
      warnFn(...msg)
    }
  }
}

export {}
