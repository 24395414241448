import React from 'react'
import styled, { css } from 'styled-components'
import { isProduction } from 'src/next/utils'

export interface FeatureFlagProps {
  children: React.ReactNode
  $stage?: 'wip' | 'ready'
  $show?: boolean
}

const highlightStyle = css<FeatureFlagProps>`
  outline: 1px dashed ${({ $stage }) => ($stage === 'ready' ? 'orange' : 'red')};
`

const Wrapper = styled.div<FeatureFlagProps>`
  ${({ $show }) => ($show ? highlightStyle : null)}
`

/**
 * The FeatureFlag component will never render on production.
 *
 * $stage="ready" (default) will render an orange dashed line around the
 * component indicating that component will not be rendered on production.
 *
 * $stage="wip" will render a red dashed line to indicate the component is still
 * Work In Progress and is not ready for production at all.
 */
export const FeatureFlag = ({
  children,
  $stage = 'ready',
}: FeatureFlagProps) => {
  const showHighlight = import.meta.env.VITE_CLOUDNATIX_DEV_TOOLS === 'true'

  if (isProduction()) {
    return null
  }

  return (
    <Wrapper $show={showHighlight} $stage={$stage}>
      {children}
    </Wrapper>
  )
}
