import React, { memo, useEffect, useReducer } from 'react'
import InstallCLIModal from 'src/next/components/InstallCLIModal'
import { useCliChannels } from './useCliChannels'
import useCliDistributionVersions from './useCliDistributionVersions'
import useCliDownloadUrl from './useCliDownloadURL'

export interface InstallCLIModalState {
  OS: null | 'darwin' | 'linux' | 'windows'
  Arch: null | 'amd64' | 'arm64'
  version: null | string
  channel: null | string
}

export type InstallCLIModalAction =
  | { type: 'selectOS'; value: InstallCLIModalState['OS'] }
  | { type: 'selectArch'; value: InstallCLIModalState['Arch'] }
  | { type: 'selectVersion'; value: InstallCLIModalState['version'] }
  | { type: 'selectChannel'; value: InstallCLIModalState['channel'] }

const initialState: InstallCLIModalState = {
  OS: null,
  Arch: null,
  version: null,
  channel: null,
}

const formReducer = (
  state: InstallCLIModalState,
  action: InstallCLIModalAction,
) => {
  switch (action.type) {
    case 'selectOS':
      return {
        ...state,
        OS: action.value,
      }
    case 'selectArch':
      return {
        ...state,
        Arch: action.value,
      }
    case 'selectVersion':
      return {
        ...state,
        version: action.value,
      }
    case 'selectChannel':
      return {
        ...state,
        channel: action.value,
      }
    default:
      throw new Error(`Unsupported action: ${action}`)
  }
}

const getCommand = (formState: InstallCLIModalState, downloadUrl?: string) => {
  const { OS } = formState
  if (!downloadUrl) return null

  if (OS === 'linux' || OS === 'darwin') {
    return `curl "${downloadUrl}" --output cnatix &&
sudo mv cnatix /usr/local/bin &&
sudo chmod +x /usr/local/bin/cnatix &&
cnatix`
  }
  // Windows now bundles curl.exe command.
  // https://techcommunity.microsoft.com/t5/containers/tar-and-curl-come-to-windows/ba-p/382409
  return `curl.exe "${downloadUrl}" --output cnatix.exe ; cnatix.exe`
}

interface Props {
  open: boolean
  onClose(): void
}

const InstallCLIModalContainer: React.FC<Props> = props => {
  const [formState, dispatch] = useReducer(formReducer, initialState)

  // fetch distribution versions
  const { data: distributionVersions, status: distributionVersionsListStatus } =
    useCliDistributionVersions({
      enabled: !!formState.OS,
      staleTime: 60000,
    })
  const distributionVersionsList = distributionVersions?.distributionVersions

  const { data: channelsList, status: channelsListStatus } = useCliChannels(
    {
      distributionVersion: formState.version || '',
      kind: 'cli',
    },
    formState.OS || '',
    {
      enabled: !!(formState.OS && formState.version),
      staleTime: 60000,
      select: data => data.channelNames,
    },
  )

  // fetch download URL
  const { data, status: downloadUrlStatus } = useCliDownloadUrl(
    formState.OS,
    formState.Arch,
    formState.version,
    formState.channel,
    {
      enabled: !!(
        formState.OS &&
        formState.Arch &&
        formState.version &&
        formState.channel
      ),
    },
  )

  // set default OS based on user agent
  useEffect(() => {
    const OS: InstallCLIModalState['OS'] =
      navigator.userAgent.indexOf('Mac') >= 0
        ? 'darwin'
        : navigator.userAgent.indexOf('X11') >= 0 ||
          navigator.userAgent.indexOf('Linux') >= 0
        ? 'linux'
        : 'windows'
    dispatch({ type: 'selectOS', value: OS })
  }, [])
  // set default CPU architecture based on user agent
  useEffect(() => {
    // It is actually very hard to detect the CPU architecture from the browser
    // since the Apple Silicon browsers uses 'IntelMac' on its user agent string.
    // navigator.userAgentData.getHighEntropyValues looks promising, although
    // it is not supported in Safari as far as I tried. So far we'll use this
    // API if it's available, and otherwise default to AMD64.
    // cf. https://stackoverflow.com/questions/65146751/detecting-apple-silicon-mac-in-javascript
    const p =
      'userAgentData' in navigator &&
      // @ts-ignore
      'getHighEntropyValues' in navigator.userAgentData
        ? // @ts-ignore
          navigator.userAgentData.getHighEntropyValues(['architecture'])
        : Promise.resolve({ architecture: 'amd64' })
    p.then(({ architecture }: { architecture: string }) => {
      const arch =
        architecture.indexOf('x86') >= 0 || architecture.indexOf('amd64') >= 0
          ? 'amd64'
          : 'arm64'
      dispatch({ type: 'selectArch', value: arch })
    })
  }, [])

  // set to latest version
  useEffect(() => {
    if (distributionVersionsList?.length)
      dispatch({ type: 'selectVersion', value: distributionVersionsList[0] })
  }, [distributionVersionsList])

  // set to first channel in list
  useEffect(() => {
    if (channelsList?.length)
      dispatch({ type: 'selectChannel', value: channelsList[0] })
  }, [channelsList])

  // generate/copy the command
  const command = getCommand(formState, data?.downloadUrl)

  return (
    <InstallCLIModal
      selectedOS={formState.OS}
      selectedArch={formState.Arch}
      selectedVersion={formState.version}
      selectedChannel={formState.channel}
      onChangeOS={(value: InstallCLIModalState['OS']) =>
        dispatch({
          type: 'selectOS',
          value,
        })
      }
      onChangeArch={(value: InstallCLIModalState['Arch']) =>
        dispatch({
          type: 'selectArch',
          value,
        })
      }
      onChangeVersion={(value: InstallCLIModalState['version']) =>
        dispatch({
          type: 'selectVersion',
          value,
        })
      }
      onChangeChannel={(value: InstallCLIModalState['channel']) =>
        dispatch({
          type: 'selectChannel',
          value,
        })
      }
      distributionVersionsList={distributionVersionsList}
      distributionVersionsListStatus={distributionVersionsListStatus}
      channelsList={channelsList}
      channelsListStatus={channelsListStatus}
      downloadUrl={data?.downloadUrl}
      downloadUrlStatus={downloadUrlStatus}
      command={command}
      defaultCommand="sudo chmod +x /usr/local/bin/cnatix && cnatix"
      {...props}
    />
  )
}

export default memo(InstallCLIModalContainer)
