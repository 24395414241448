import React, { useMemo } from 'react'
import { CheckboxSkeleton, SkeletonText } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { useClusters } from 'src/api'
import {
  TableFilterAccordionItem,
  TableFilterCheckbox,
  TableFilterCheckboxGroup,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { Region } from './Region'
import { useDashboardInstanceGroups } from 'src/api'
import { useTimeRangeOverflowMenu } from '../../dashboard/timerange'

export const TableRegionFilter = () => {
  const { t } = useTranslation()

  const { isOpen } = useTableFilter()
  const regionSet = new Set()
  const uniqueRegions: { key: string; name: string; csp: string }[] = []

  const groupingKey = 'REGION'
  const { TimeRangeMenu, items, selectedItem, startTimeNs, endTimeNs } =
    useTimeRangeOverflowMenu({
      id: `dashboard-tab-${groupingKey}`,
      show24HoursOption: true,
    })
  const { isLoading, data } = useDashboardInstanceGroups({
    groupingKey: {
      key: groupingKey as any,
    },
    filter: {
      startTimeNs,
      endTimeNs,
    },
  })

  const igs = data || []
  igs.forEach(ig => {
    ig?.regions?.forEach(region => {
      const csp = region.csp || 'none'
      if (csp === '') {
        return
      }
      const name = region.name || 'none'
      if (name === '') {
        return
      }

      const key = `${csp}-${name}`
      if (regionSet.has(key)) {
        return
      }
      regionSet.add(key)

      uniqueRegions.push({
        key,
        name: name || '',
        csp: csp || '',
      })
    })
  })

  const { data: clusterData, isLoading: clusterIsLoading } = useClusters({
    enabled: isOpen,
  })
  const activeClusters = clusterData?.clusters || []
  activeClusters.forEach(cluster => {
    const name = cluster.region || ''
    const csp = cluster.csp || ''
    if (name === '' || csp === '') {
      return
    }

    const key = `${csp}-${name}`
    if (regionSet.has(key)) {
      return
    }
    regionSet.add(key)

    uniqueRegions.push({
      key,
      name,
      csp,
    })
  })

  uniqueRegions.sort((a, b) => {
    if (a.csp !== b.csp) {
      return a.csp.localeCompare(b.csp)
    }

    return a.name.localeCompare(b.name)
  })

  const RegionItems = useMemo(() => {
    return (
      uniqueRegions.map(region => {
        return (
          <TableFilterCheckbox
            key={region.key}
            id={region.name}
            activeFilterText={region.key}
            labelText={
              <Region region={region?.name || ''} csp={region?.csp} /> || ''
            }
          />
        )
      }) || []
    )
  }, [data])

  return (
    <TableFilterAccordionItem
      title={t('Workloads.VMTable.Filters.Regions', 'Regions')}
    >
      <TableFilterCheckboxGroup group="regions">
        {isLoading && clusterIsLoading ? (
          <>
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <SkeletonText width="90px" />
          </>
        ) : (
          RegionItems
        )}
      </TableFilterCheckboxGroup>
    </TableFilterAccordionItem>
  )
}
