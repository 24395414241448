import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

export type SetValue<T> = Dispatch<SetStateAction<T>>

// By setting keepInSync to `true` all components that make use of the hook will
// change to the new value.
//
// Do note that keepInSync dispatches an event with name 'local-storage', which
// probably causes all components to re-render (and not just the one with the
// same localStorage key). We might want to change this in the future.
function useLocalStorage<T>(
  key: string,
  initialValue: T,
  keepInSync = false,
): [T, SetValue<T>] {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = (): T => {
    // Prevent build error "window is undefined", but keep working
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)
      return item ? (JSON.parse(item) as T) : initialValue
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error)
      return initialValue
    }
  }

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue)

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<T> = useCallback(
    value => {
      // Prevent build error "window is undefined" but keeps working
      if (typeof window === 'undefined') {
        console.warn(
          `Tried setting localStorage key “${key}” even though environment is not a client`,
        )
      }

      try {
        // Allow value to be a function so we have the same API as useState
        const newValue = value instanceof Function ? value(storedValue) : value

        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(newValue))

        // Save state
        setStoredValue(newValue)

        // We dispatch a custom event so every useLocalStorage hook are notified
        if (keepInSync) window.dispatchEvent(new Event('local-storage'))
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error)
      }
    },
    [keepInSync, key, storedValue],
  )

  useEffect(() => {
    setStoredValue(readValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (keepInSync) {
      const handleStorageChange = () => {
        setStoredValue(readValue())
      }

      // this only works for other documents, not the current one
      window.addEventListener('storage', handleStorageChange)

      // this is a custom event, triggered in writeValueToLocalStorage
      window.addEventListener('local-storage', handleStorageChange)

      return () => {
        window.removeEventListener('storage', handleStorageChange)
        window.removeEventListener('local-storage', handleStorageChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const memoizedReturn = useMemo<[T, SetValue<T>]>(
    () => [storedValue, setValue],
    [storedValue, setValue],
  )

  return memoizedReturn
}

export default useLocalStorage
