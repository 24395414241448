import React from 'react'
import { SkeletonText } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from 'src/next/components'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { currencyFormat } from 'src/next/utils'

interface Props {
  totalSavings: number | undefined
  isLoading: boolean
}

export const InsightsSavingsText = ({ totalSavings, isLoading }: Props) => {
  const { t } = useTranslation()

  return (
    <Box minHeight={30}>
      {isLoading ? (
        <SkeletonText style={{ width: 200, height: 24 }} />
      ) : totalSavings === undefined ? (
        <InlineNotification
          kind="error"
          title={t('Insights.SavingsTitleError')}
        />
      ) : totalSavings > 0 ? (
        <>
          <Typography variant="heading-03" as="h2">
            {t('Insights.SavingsTitle', {
              savings: currencyFormat(totalSavings),
            })}
          </Typography>
        </>
      ) : null}
    </Box>
  )
}
