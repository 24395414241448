import React from 'react'
import { useTranslation } from 'react-i18next'
import { RecommendationStatus, Workload } from '@cloudnatix-types/dashboard'
import { getControlledResource } from 'src/next/components/Workloads/utils'

interface WorkloadModeProps {
  status?: RecommendationStatus
  recommendationConfig?: Workload['recommendationConfig']
}

export const WorkloadMode = ({
  status,
  recommendationConfig,
}: WorkloadModeProps) => {
  const { t } = useTranslation()

  let mode = t(`WorkloadRecommendation.status.${status}`)

  if (
    status === RecommendationStatus.AUTOPILOT ||
    status === RecommendationStatus.INITIAL
  ) {
    const autopilotManagedResources =
      recommendationConfig?.autopilotManagedResources
    const appliedByAnnotation = recommendationConfig?.appliedByAnnotation
    const annotation = t('WorkloadRecommendation.status.ANNOTATION')

    const controlledResource = getControlledResource(autopilotManagedResources)
    const remarkTexts: string[] = []
    if (controlledResource === 'cpu')
      remarkTexts.push(t('WorkloadRecommendation.status.AUTOPILOT_CPU'))
    else if (controlledResource === 'memory')
      remarkTexts.push(t('WorkloadRecommendation.status.AUTOPILOT_MEMORY'))
    if (appliedByAnnotation) remarkTexts.push(annotation)
    if (remarkTexts.length > 0) mode += ` (${remarkTexts.join(', ')})`
  }

  return <span>{mode}</span>
}
