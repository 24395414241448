import React from 'react'
import { useTranslation } from 'react-i18next'
import { InlineNotification } from 'src/next/components/InlineNotification'

export interface LimitReachedProps {
  used: number | string
  total: number | string
  unit: string
}

export const LimitReached = ({ used, total, unit }: LimitReachedProps) => {
  const { t } = useTranslation()

  return (
    <InlineNotification
      kind="error"
      role="alert"
      title={t('LimitReachedBanner.Title')}
      subtitle={t('LimitReachedBanner.SubTitle', {
        used,
        total,
        unit,
      })}
      // hideCloseButton={false} // If you can close it, when do we show it again?
    >
      <span
        dangerouslySetInnerHTML={{ __html: t('LimitReachedBanner.LinkText') }}
      />
    </InlineNotification>
  )
}
