/* eslint-disable react/jsx-key */
import React from 'react'
import { DataTableCustomRenderProps, TableHeader } from '@carbon/react'
import { DataTableHeader } from 'src/next/types/dataTable'
import { UseOrderByProps } from './useOrderBy'

interface DataTableHeadersProps {
  headers: DataTableHeader[]
  orderBy?: UseOrderByProps
  getHeaderProps: DataTableCustomRenderProps['getHeaderProps']
}

export const DataTableHeaders = ({
  getHeaderProps,
  headers,
  orderBy,
}: DataTableHeadersProps) => {
  const { value, setValue } = orderBy || {}

  const [orderValue, orderDirection] = value?.split(' ') || []

  const handleSort = (header: DataTableHeader) => {
    const direction =
      orderBy?.value === `${header.sort} asc`
        ? 'desc'
        : orderBy?.value === `${header.sort} desc`
        ? ''
        : 'asc'
    setValue?.(direction ? `${header.sort} ${direction}` : '')
  }

  return (
    <>
      {headers.map(({ style, ...header }) => {
        const isSortHeader = header.sort === orderValue
        const sortDirection = !isSortHeader
          ? 'NONE'
          : orderDirection === 'asc'
          ? 'DESC'
          : 'ASC'

        return (
          <TableHeader
            {...getHeaderProps({
              header,
              isSortable: !!header.sort,
            })}
            isSortHeader={isSortHeader}
            sortDirection={sortDirection}
            style={style}
            onClick={() => handleSort(header)}
          >
            {header.header}
          </TableHeader>
        )
      })}
    </>
  )
}
