/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
import React, { Suspense, useMemo } from 'react'
import { TabProps } from '@carbon/react/es/components/Tabs/Tabs'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Box } from 'src/next/components'
import Loading from 'src/next/components/Loading'
import {
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from 'src/next/components/Tabs'
import { useTabSelection } from 'src/next/hooks'
import { GenericTab, InstancesTab, NamespaceTab, RegionTab } from './tabs'

const StyledTabList = styled(TabList)`
  /* tabs = ul */
  .cds--tabs--scrollable__nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  /* tab = li */
  .cds--tabs--scrollable__nav-item {
    flex: 1;
  }

  /* button */
  .cds--tab--list {
    width: 100%;
  }
`

const SuspenseFallback = () => <Loading size="small" withOverlay={false} />

export const DashboardTabs = () => {
  const { t } = useTranslation()

  const tabs = useMemo(
    () =>
      [
        ['Region', <RegionTab />, t('Dashboard.Tabs.Region')],
        [
          'CSP',
          <GenericTab id="dashboard-csp" groupingKeyKey="CSP" />,
          t('Dashboard.Tabs.CSP'),
        ],
        [
          'Organization',
          <GenericTab id="dashboard-org" groupingKeyKey="ORG" noLimit />,
          t('Dashboard.Tabs.Organization'),
        ],
        [
          'AccountID',
          <GenericTab id="dashboard-account" groupingKeyKey="ACCOUNT" />,
          t('Dashboard.Tabs.AccountID'),
        ],
        [
          'Cluster',
          <GenericTab id="dashboard-cluster" groupingKeyKey="CLUSTER" />,
          t('Dashboard.Tabs.Cluster'),
        ],
        ['Namespace', <NamespaceTab />, t('Dashboard.Tabs.Namespace')],
        [
          'Tag',
          <GenericTab
            id="dashboard-tag"
            groupingKeyKey="TAG"
            withTags={true}
          />,
          t('Dashboard.Tabs.Tag'),
        ],
        ['Instances', <InstancesTab />, t('Dashboard.Tabs.Instances')],
      ] as const,
    [t],
  )

  const navigate = useNavigate()
  const location = useLocation()

  const tabIds = tabs.map(([tabId]) => tabId)
  const { selectedTab, setSelectedTab } = useTabSelection(tabIds)

  const handleTabClick = ({ selectedIndex }) => {
    setSelectedTab(selectedIndex)
    const params = new URLSearchParams({ tabs: tabs[selectedIndex][0] })
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  return (
    <Box position="relative" mb="100px" minHeight="800px">
      <Tabs selectedIndex={selectedTab} onChange={handleTabClick}>
        <StyledTabList
          data-testid="dashboard-tabs"
          aria-label="Dashboard page tabs"
        >
          {tabs.map(([tabKey, , text], index) => (
            <DashboardTab index={index} key={tabKey}>
              {text}
            </DashboardTab>
          ))}
        </StyledTabList>
        <TabPanels>
          {tabs.map(([tabKey, tabContent], index) => (
            <TabPanel key={tabKey}>
              <Suspense fallback={<SuspenseFallback />}>
                {selectedTab === index ? tabContent : null}
              </Suspense>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

interface DashboardTabProps extends TabProps {
  index: number
}

const DashboardTab = React.forwardRef<any, DashboardTabProps>((props, ref) => {
  const { index, children, ...rest } = props

  return (
    <Tab ref={ref} id={`dashboard-tabs-${index! + 1}`} {...rest}>
      {children}
    </Tab>
  )
})
