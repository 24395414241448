import React from 'react'
import { TextInput } from '@carbon/react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UserRoleBindingsForm } from './UserRoleBindingsForm'

export const UserForm = ({
  defaultLoginId,
}: {
  defaultLoginId: string | undefined
}) => {
  const { t } = useTranslation()

  const rules = { required: true }
  const { field: firstName } = useController({ name: 'firstName', rules })
  const { field: lastName } = useController({ name: 'lastName', rules })
  const { field: loginId } = useController({ name: 'loginId', rules })

  return (
    <>
      <TextInput
        {...firstName}
        autoFocus
        id="firstName"
        labelText={`${t('Users.Form.Firstname')}`}
      />
      <br />
      <TextInput
        {...lastName}
        id="lastName"
        labelText={`${t('Users.Form.Lastname')}`}
      />
      <br />
      <TextInput
        {...loginId}
        id="loginId"
        labelText={`${t('Users.Form.Email')}`}
        type="email"
        readOnly={!!defaultLoginId}
      />
      <br />
      {t('Users.Form.AccessRoles')}
      <UserRoleBindingsForm />
    </>
  )
}
