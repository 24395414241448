import styled from 'styled-components'
import './Table.scss'

// Used for both Clusters, K8s- & VM-table
export const TableExpandedPanel = styled.div<{ $translateY?: string }>`
  & {
    animation: show 500ms 0ms cubic-bezier(0.16, 1, 0.3, 1) forwards;

    opacity: 0;
    transform: translateY(${({ $translateY }) => $translateY || '-20px'});
    transform-origin: top center;
  }

  @keyframes show {
    100% {
      opacity: 1;
      transform: none;
    }
  }

  // Only add spacing in an expandable row since we currently use the expandable row content also
  // on the workload detail page (k8s & vm)
  .cds--expandable-row & {
    margin-left: 3.5rem;
    margin-bottom: 1rem;
  }

  .tab-content {
    background: var(--cds-layer-01);
    padding: 1rem 0;
  }
`
