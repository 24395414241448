// get/set object in localStorage
export const localStorageObj = {
  get: (id: string | undefined): Record<string, any> | void => {
    if (!id) return

    const storageItem = localStorage.getItem(id)
    if (storageItem) {
      try {
        return JSON.parse(storageItem)
      } catch (error) {
        /* empty */
      }
    }
  },
  set: (id: string, value: Record<string, any>): void => {
    if (!id) return

    try {
      localStorage.setItem(id, JSON.stringify(value))
    } catch (error) {
      /* empty */
    }
  },
}
