import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  SimpleStackedBar,
  SimpleStackedBarData,
} from 'src/next/components/SimpleStackedBar/SimpleStackedBar'
import { Status } from 'src/next/types/workloads'
import { Tooltip, TooltipButton } from '../../../Tooltip'

const StatusLabel = styled.div`
  white-space: nowrap;
  padding-bottom: 0.4rem;
`

const LegendColor = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  background: ${props => props.color};
  vertical-align: middle;
  margin-right: 0.5em;
`

const StyledTooltipButton = styled(TooltipButton)`
  color: var(--data-table-cell-text-color, var(--cds-text-secondary));
  &&:hover {
    color: var(--data-table-cell-text-color, var(--cds-text-secondary));
  }
`

interface WorkloadStatusProps {
  status: Status
  isDeleted: boolean
  hideTooltips?: boolean
}

export const WorkloadStatus = ({
  status,
  isDeleted,
  hideTooltips,
}: WorkloadStatusProps) => {
  const { t } = useTranslation()

  if (isDeleted) {
    return (
      <>
        <StatusLabel>
          {t('Common.Deleted', {
            defaultValue: 'deleted',
          })}
        </StatusLabel>
        <SimpleStackedBar data={[]} total={0} />
      </>
    )
  }

  const data = ['Pending', 'Running', 'Succeeded', 'Failed', 'Unknown'].map(
    (state: string, i) => ({
      label: state,
      // @ts-ignore
      value: status[state] || 0,
      color: `var(--carbonPalette${i + 1})`,
    }),
  ) as SimpleStackedBarData

  const totalStatusCount = (status: Status) =>
    Object.values(status).reduce((total, count) => total + count, 0)

  const statusLabel = (
    <StyledTooltipButton>
      <StatusLabel>
        {t('Workload.StatusLabel', {
          defaultValue: '{{runningWorkloads}} of {{totalWorkloads}} running',
          runningWorkloads: status.Running || 0,
          totalWorkloads: totalStatusCount(status),
        })}
      </StatusLabel>
      <SimpleStackedBar data={data} total={totalStatusCount(status)} />
    </StyledTooltipButton>
  )

  if (hideTooltips) {
    return statusLabel
  }

  const tooltipLabel = data.map((item, i) => (
    <div key={item.label}>
      <LegendColor color={`var(--carbonPalette${i + 1})`} /> {item.value}{' '}
      {item.label}
    </div>
  ))
  return (
    <Tooltip label={tooltipLabel} tabIndex={-1} align="bottom">
      {statusLabel}
    </Tooltip>
  )
}
