import React from 'react'
import { ChevronDown } from '@carbon/react/icons'
import { Button } from '@carbon/react'
import styled from 'styled-components'

const Toggle = styled.div<{ open: boolean }>`
  position: absolute;
  right: 0;
  top: -32px;

  svg {
    transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
    transition: all 150ms cubic-bezier(0.2, 0, 0.38, 0.9); // From accordion arrow
  }
`

export interface CollapseButtonProps {
  open: boolean
  onClick: () => void
  iconDescription: string
  testId?: string
}

export const CollapseButton = ({
  open,
  onClick,
  iconDescription,
  testId,
}: CollapseButtonProps) => (
  <Toggle open={open}>
    <Button
      size="sm"
      kind="ghost"
      onClick={onClick}
      hasIconOnly
      iconDescription={iconDescription}
      tooltipAlignment="end"
      renderIcon={ChevronDown}
      data-testid={testId || ''}
    />
  </Toggle>
)
