import assign from 'lodash/assign'
import { DisabledGraphColor, GraphColor } from '../types/workloads'

// Original theme:
// https://github.com/FormidableLabs/victory/blob/main/packages/victory-core/src/victory-theme/grayscale.js

export const colors: GraphColor[] = [
  'var(--carbonPalette1)',
  'var(--carbonPalette2)',
  'var(--carbonPalette3)',
  'var(--carbonPalette4)',
  'var(--carbonPalette5)',
  'var(--carbonPalette6)',
  'var(--carbonPalette7)',
  'var(--carbonPalette8)',
  'var(--carbonPalette9)',
  'var(--carbonPalette10)',
  'var(--carbonPalette11)',
  'var(--carbonPalette12)',
  'var(--carbonPalette13)',
  'var(--carbonPalette14)',
]

export const disabledColors: DisabledGraphColor[] = ['var(--carbonGrayScale2)']

const charcoal = '#515151'
const grey = '#969696'
const flyoutBackground = '#f0f0f0'

const letterSpacing = 'normal'
const fontSize = 12

const baseProps = {
  width: 450,
  height: 300,
  padding: 50,
  colorScale: colors,
}

const baseLabelStyles = {
  fontFamily: 'var(--cn-font-family--condensed)',
  fontSize,
  letterSpacing,
  padding: 10,
  fill: 'var(--cds-text-helper)',
  stroke: 'transparent',
}

const flyoutStyles = {
  stroke: charcoal,
  strokeWidth: 1,
  fill: flyoutBackground,
  pointerEvents: 'none',
}

const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles)

const strokeLinecap = 'round'
const strokeLinejoin = 'round'

const theme = {
  area: assign(
    {
      style: {
        data: {
          fill: charcoal,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps,
  ),
  axis: assign(
    {
      style: {
        axis: {
          fill: 'transparent',
          stroke: charcoal,
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin,
        },
        axisLabel: assign({}, centeredLabelStyles, {
          padding: 25,
        }),
        grid: {
          fill: 'none',
          stroke: 'var(--cds-border-subtle-02)',
          pointerEvents: 'painted',
        },
        ticks: {
          fill: 'transparent',
          size: 1,
          stroke: 'transparent',
        },
        tickLabels: baseLabelStyles,
      },
    },
    baseProps,
  ),
  bar: assign(
    {
      style: {
        data: {
          fill: charcoal,
          padding: 8,
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps,
  ),
  boxplot: assign(
    {
      style: {
        max: { padding: 8, stroke: charcoal, strokeWidth: 1 },
        maxLabels: assign({}, baseLabelStyles, { padding: 3 }),
        median: { padding: 8, stroke: charcoal, strokeWidth: 1 },
        medianLabels: assign({}, baseLabelStyles, { padding: 3 }),
        min: { padding: 8, stroke: charcoal, strokeWidth: 1 },
        minLabels: assign({}, baseLabelStyles, { padding: 3 }),
        q1: { padding: 8, fill: grey },
        q1Labels: assign({}, baseLabelStyles, { padding: 3 }),
        q3: { padding: 8, fill: grey },
        q3Labels: assign({}, baseLabelStyles, { padding: 3 }),
      },
      boxWidth: 20,
    },
    baseProps,
  ),
  candlestick: assign(
    {
      style: {
        data: {
          stroke: charcoal,
          strokeWidth: 1,
        },
        labels: assign({}, baseLabelStyles, { padding: 5 }),
      },
      candleColors: {
        positive: '#ffffff',
        negative: charcoal,
      },
    },
    baseProps,
  ),
  chart: baseProps,
  errorbar: assign(
    {
      borderWidth: 8,
      style: {
        data: {
          fill: 'transparent',
          stroke: charcoal,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps,
  ),
  group: assign(
    {
      colorScale: colors,
    },
    baseProps,
  ),
  histogram: assign(
    {
      style: {
        data: {
          fill: grey,
          stroke: charcoal,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps,
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      labels: baseLabelStyles,
      title: assign({}, baseLabelStyles, { padding: 5 }),
    },
  },
  line: assign(
    {
      style: {
        data: {
          fill: 'transparent',
          stroke: charcoal,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps,
  ),
  pie: {
    style: {
      data: {
        padding: 10,
        stroke: 'transparent',
        strokeWidth: 1,
      },
      labels: assign({}, baseLabelStyles, {
        padding: 20,
        fill: grey,
      }),
    },
    colorScale: colors,
    width: 400,
    height: 400,
    padding: 50,
  },
  scatter: assign(
    {
      style: {
        data: {
          fill: charcoal,
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps,
  ),
  stack: assign(
    {
      colorScale: colors,
    },
    baseProps,
  ),
  tooltip: {
    style: assign({}, baseLabelStyles, { padding: 0, pointerEvents: 'none' }),
    flyoutStyle: flyoutStyles,
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: assign(
    {
      style: {
        data: {
          fill: 'transparent',
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: assign({}, baseLabelStyles, {
          padding: 5,
          pointerEvents: 'none',
        }),
        flyout: flyoutStyles,
      },
    },
    baseProps,
  ),
}

export default theme
