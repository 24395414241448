import React, { useRef, useState } from 'react'
import {
  HeaderGlobalAction,
  HeaderPanel,
  Switcher,
  SwitcherItem,
} from '@carbon/react'
import LanguageDetector from 'i18next-browser-languagedetector'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
import { useOutsideClick } from 'rooks'
import styled from 'styled-components'
import { useCLUIRegisterLanguageSwitch } from 'src/next/components/CLUI/actions/useCLUIRegisterLanguageSwitch'
import LangList, { Language } from 'src/next/utils/LanguageList'

const StyledHeaderPanel = styled(HeaderPanel)`
  background-color: var(--cds-background);
  color: var(--cds-text-primary);

  .cds--switcher__item-link {
    color: var(--cds-text-primary);
  }
`

export const ChangeLanguage = () => {
  const { i18n } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const ref = useRef(null)

  const toggleRightPanel = () => setExpanded(!expanded)
  useOutsideClick(ref, toggleRightPanel, expanded)

  const currentLang = i18n.use(LanguageDetector)
  const langCode =
    LangList.find(lang => (lang.code === currentLang.language ? lang : null)) ||
    LangList[0]
  const [selectedLang, setSelectedLang] = useState(langCode || LangList[0])

  const changeLanguage = (lang: Language) => {
    setSelectedLang(lang)
    i18n.changeLanguage(lang.code)
    setExpanded(false)
  }

  useCLUIRegisterLanguageSwitch(langCode, changeLanguage)

  return (
    <div ref={ref}>
      <HeaderGlobalAction
        id="language-action"
        aria-label="Language"
        onClick={toggleRightPanel}
        isActive={expanded}
      >
        {selectedLang && (
          <ReactCountryFlag
            svg
            countryCode={selectedLang.countryCode}
            aria-label={selectedLang.ariaLabel}
          />
        )}
      </HeaderGlobalAction>

      <StyledHeaderPanel
        id="Header-Panel"
        aria-label="Header Panel"
        expanded={expanded}
      >
        <Switcher aria-label="Switcher Container">
          {LangList.map(language => (
            <SwitcherItem
              aria-label="Language Switcher"
              id={`switch-${language.code}`}
              href="#"
              key={language.code}
              onClick={() => changeLanguage(language)}
            >
              {' '}
              <ReactCountryFlag
                svg
                countryCode={language.countryCode}
                aria-label={language.ariaLabel}
              />{' '}
              {language.LabelText}
            </SwitcherItem>
          ))}
        </Switcher>
      </StyledHeaderPanel>
    </div>
  )
}
