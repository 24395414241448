import { OktaAuth } from '@okta/okta-auth-js'

export const AuthClient = () => {
  const authClient = new OktaAuth({
    issuer: import.meta.env.VITE_OKTA_ISSUER,
    clientId: import.meta.env.VITE_CLIENT_ID,
    redirectUri: `${window.location.origin}/implicit/callback`,
    pkce: true,
    tokenManager: {
      autoRenew: true,
      autoRemove: true,
      secure: true,
      storage: 'cookie',
      expireEarlySeconds: 60 * 40,
    },
    postLogoutRedirectUri: window.location.origin,
  })

  const getAccessToken = () => {
    const token = authClient.getAccessToken()

    if (!token || authClient.tokenManager.hasExpired(token)) {
      authClient.signOut()
    }

    return token
  }

  return { authClient, getAccessToken }
}
