import React from 'react'
import { Translate } from '@carbon/react/icons'
import { Priority, useRegisterActions } from 'kbar'
import { useTranslation } from 'react-i18next'
import LangList, { Language } from 'src/next/utils/LanguageList'

export const useCLUIRegisterLanguageSwitch = (
  currentLanguage: Language,
  changeLanguage: (language: Language) => void,
) => {
  const { t } = useTranslation()

  const inactiveLanguage = LangList.find(lang => lang !== currentLanguage) as
    | Language
    | undefined

  useRegisterActions(
    inactiveLanguage
      ? [
          {
            id: 'switchLanguage',
            name: t('CLUI.SwitchLanguage', {
              language: inactiveLanguage?.LabelText,
            }),
            icon: <Translate size={20} />,
            perform: () => changeLanguage(inactiveLanguage),
            section: {
              name: t('CLUI.Miscellaneous'),
              priority: Priority.LOW,
            },
          },
        ]
      : [],
    [inactiveLanguage],
  )
}
