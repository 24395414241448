import React from 'react'
import { Add, TrashCan } from '@carbon/react/icons'
import { Button, Select, SelectItem, TextInput } from '@carbon/react'
import { useController, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Flex } from 'src/next/components'
import { NamespaceFormValues } from './NamespaceActionsModal'

const RoleGroup = styled.div`
  margin-top: var(--cds-spacing-04);
  padding-bottom: var(--cds-spacing-04);
  border-bottom: 1px solid var(--cds-border-subtle);
`

const ButtonContainer = styled.div`
  margin: var(--cds-spacing-05) 0 var(--cds-spacing-03) 0;
`

export const NamespaceForm = () => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { fields, append, remove } = useFieldArray<NamespaceFormValues>({
    name: 'labels',
  })

  const { field: type } = useController({
    name: 'type',
  })

  return (
    <>
      <TextInput
        autoFocus
        id="ns-name"
        {...register('name', {
          required: t('Common.RequiredField'),
          pattern: {
            value: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/,
            message: t('Organizations.Namespaces.Create.NameHelperText'),
          },
        })}
        labelText={t('Organizations.Namespaces.Form.Name')}
        helperText={t('Organizations.Namespaces.Create.NameHelperText')}
        invalid={!!errors.name}
        invalidText={Object.values(errors).map((error: any) => error.message)}
      />
      <br />
      <Select
        {...type}
        id="ns-type"
        labelText={t('Organizations.Namespaces.Form.Type')}
        helperText={t('Organizations.Namespaces.Create.TypeHelperText')}
      >
        <SelectItem value="FULLY_MANAGED" text="FULLY_MANAGED" />
        <SelectItem value="ORG_ASSOCIATION_ONLY" text="ORG_ASSOCIATION_ONLY" />
      </Select>
      <br />
      {t('Organizations.Namespaces.Form.Title')}
      {fields.map((label, index) => {
        return (
          <RoleGroup key={label.id}>
            <Flex gap={4} mb={3} mr={10}>
              <TextInput
                id={`ns-key-${index}`}
                labelText={t('Organizations.Namespaces.Form.Key')}
                {...register(`labels.${index}.key` as const, {
                  required: true,
                })}
                defaultValue={label.key}
              />
              <TextInput
                id={`ns-value-${index}`}
                labelText={t('Organizations.Namespaces.Form.Value')}
                {...register(`labels.${index}.value` as const, {})}
                defaultValue={label.value}
              />
            </Flex>
            <Flex gap={6} alignItems="flex-end">
              <TextInput
                id={`ns-description-${index}`}
                labelText={t('Organizations.Namespaces.Form.Description')}
                {...register(`labels.${index}.description` as const, {})}
                defaultValue={label.description}
              />
              <Button
                hasIconOnly
                iconDescription={t('Common.Delete')}
                renderIcon={TrashCan}
                tooltipPosition="left"
                tooltipAlignment="start"
                onClick={() => remove(index)}
                kind="ghost"
                size="md"
              />
            </Flex>
          </RoleGroup>
        )
      })}
      <ButtonContainer>
        <Button
          kind="tertiary"
          size="sm"
          renderIcon={Add}
          onClick={() => {
            append({
              description: '',
              key: '',
              value: '',
            })
          }}
        >
          {t('Organizations.Namespaces.Form.Add')}
        </Button>
      </ButtonContainer>
    </>
  )
}
