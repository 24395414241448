import { useTranslation } from 'react-i18next'
import {
  TableFilter,
  TableFilterAccordion,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterSearchSelection,
} from 'src/next/components/TableFilter'

const RulesTableFilters = () => {
  const { t } = useTranslation()

  return (
    <TableFilter>
      <TableFilterActiveItems />
      <TableFilterAccordion>
        <TableFilterAccordionItem
          title={t('Resources.Allocation.Filters.Name')}
        >
          <TableFilterSearchSelection
            id="name"
            placeholder={t('Resources.Allocation.Filters.NamePlaceHolder')}
            singleSelection={true}
          />
        </TableFilterAccordionItem>

        <TableFilterAccordionItem
          title={t('Resources.Allocation.Filters.TargetOrgName')}
        >
          <TableFilterSearchSelection
            id="targetOrgName"
            placeholder={t(
              'Resources.Allocation.Filters.TargetOrgNamePlaceHolder',
            )}
            singleSelection={true}
          />
        </TableFilterAccordionItem>
      </TableFilterAccordion>
    </TableFilter>
  )
}

export default RulesTableFilters
