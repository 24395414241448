import React, { useRef, useState } from 'react'
import { ArrowLeft, Calendar } from '@carbon/react/icons'
import * as Popover from '@radix-ui/react-popover'
import { Button } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { DateTimePicker, maxDate as defaultMaxDate } from '../DateTimePicker'
import { TimeInterval, TimeIntervalProps } from '../TimeInterval'

export const ClearDateButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--cds-text-primary);
  cursor: pointer;
  padding: var(--cds-spacing-04);

  &:hover {
    background: var(--cds-background-hover);
  }
`

const StyledPopoverTrigger = styled(Popover.Trigger)`
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: var(--cds-spacing-04);
  &:hover {
    background: var(--cds-background-hover);
  }
`

const StyledCalendarIcon = styled(Calendar)`
  color: var(--cds-icon-primary);
  height: 100%;
  &.active {
    color: var(--cds-button-interactive);
  }
`

const StyledDatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledGoBack = styled.div`
  display: flex;
  align-items: center;
  gap: var(--cds-spacing-01);
  cursor: pointer;
  width: max-content;
  margin-bottom: 5px;

  &:hover {
    text-decoration: underline;
  }
`

const StyledCustomDateHeading = styled.h5`
  width: 100%;
  margin-bottom: 1rem;
`

const StyledButtonContainer = styled.div`
  margin-top: 24px;
`

const StyledContent = styled(Popover.Content)`
  padding: 1rem;
  display: flex;
  background-color: var(--cds-layer-01);
  margin-right: 32px;
  gap: var(--cds-spacing-05);
`

export interface DatePickerProps extends TimeIntervalProps {
  id: string
  withTimeInput?: boolean
  maxDate?: Date
  setDates: (date: Date[]) => void
}

export const DatePickerMenu = ({
  id,
  withTimeInput,
  maxDate = defaultMaxDate,
  timeInterval,
  setTimeInterval,
  setDates,
}: DatePickerProps) => {
  const { t } = useTranslation()

  const calendarRef = useRef<any>(null)
  const containerRef = useRef<any>(null)

  // isCustomDate controls whether to show the time interval selection dialog or the custom date picker dialog.
  // Note that it's independent from which mode is actually selected.
  const [isCustomDate, setIsCustomDate] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)

  const [selectedDateTimes, setSelectedDateTimes] = useState<Date[]>([])
  const [isSelectedDateTimesValid, setIsSelectedDateTimesValid] =
    useState<boolean>(false)

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <Popover.Root open={openMenu}>
        <StyledPopoverTrigger
          aria-label="calendar-icon-button"
          onClick={() => setOpenMenu(!openMenu)}
        >
          <StyledCalendarIcon className={`${openMenu && 'active'}`} />
        </StyledPopoverTrigger>

        <Popover.PopoverPortal>
          <StyledContent
            ref={containerRef}
            style={{ boxShadow: '0 2px 12px var(--cds-shadow)' }}
            onOpenAutoFocus={e => e.preventDefault()}
            onInteractOutside={e => {
              e.preventDefault()
              if (
                containerRef &&
                containerRef.current &&
                !containerRef.current.contains(e.target)
              ) {
                if (
                  (calendarRef && !calendarRef.current) ||
                  (calendarRef &&
                    calendarRef.current &&
                    !calendarRef.current.calendar.calendarContainer.contains(
                      e.target,
                    ))
                ) {
                  setOpenMenu(false)
                }
              }
            }}
          >
            {!isCustomDate && (
              <TimeInterval
                timeInterval={timeInterval}
                setTimeInterval={val => {
                  if (val === 'custom') {
                    setIsCustomDate(true)
                  } else {
                    setTimeInterval(val)
                    setDates([])

                    setOpenMenu(false)
                  }
                }}
              />
            )}

            {isCustomDate && (
              <StyledDatePickerContainer>
                <StyledGoBack onClick={() => setIsCustomDate(false)}>
                  <ArrowLeft />
                  {t('DateTimeRangePicker.BackBtn')}
                </StyledGoBack>
                <StyledCustomDateHeading>
                  {t('DateTimeRangePicker.CustomRange')}
                </StyledCustomDateHeading>
                <DateTimePicker
                  ref={calendarRef}
                  id={id}
                  withTimeInput={withTimeInput}
                  maxDate={maxDate}
                  setValid={setIsSelectedDateTimesValid}
                  value={selectedDateTimes}
                  setValue={setSelectedDateTimes}
                />
                <StyledButtonContainer>
                  <Button
                    onClick={() => {
                      setOpenMenu(false)
                    }}
                    style={{ width: '50%' }}
                    kind="secondary"
                  >
                    {t('DateTimeRangePicker.CancelBtn')}
                  </Button>
                  <Button
                    disabled={!isSelectedDateTimesValid}
                    onClick={() => {
                      if (!isSelectedDateTimesValid) {
                        return
                      }

                      setTimeInterval('custom')
                      setDates(selectedDateTimes)

                      setOpenMenu(false)
                    }}
                    style={{ width: '50%' }}
                    kind="primary"
                  >
                    {t('DateTimeRangePicker.ApplyBtn')}
                  </Button>
                </StyledButtonContainer>
              </StyledDatePickerContainer>
            )}
          </StyledContent>
        </Popover.PopoverPortal>
      </Popover.Root>
      {timeInterval !== 'days' ? (
        <ClearDateButton
          onClick={() => {
            setSelectedDateTimes([])

            setTimeInterval('days')
            setDates([])

            setIsCustomDate(false)
          }}
        >
          {t('DateTimeRangePicker.ClearDate')}
        </ClearDateButton>
      ) : null}
    </div>
  )
}
