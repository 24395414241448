import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useClusters } from 'src/api'
import {
  TableFilterAccordionItem,
  TableFilterFilterableMultiSelect,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { WorkloadCluster } from 'src/next/components/Workloads/WorkloadsTable/components/WorkloadCluster'

interface TableClusterFilterProps {
  includeVMs?: boolean
}

export const TableClusterFilter = ({
  includeVMs,
}: TableClusterFilterProps): JSX.Element => {
  const { t } = useTranslation()

  const { isOpen, registerLabel } = useTableFilter()

  const { data, isLoading } = useClusters({
    enabled: isOpen,
  })

  const clusters = useMemo(
    () => data?.clusters?.filter(cluster => includeVMs || !cluster.isVm),
    [data],
  )

  useEffect(() => {
    clusters?.forEach(cluster => {
      if (cluster.uuid && cluster.name) {
        registerLabel({ [cluster.uuid]: cluster.name })
      }
    })
  }, [clusters, registerLabel])

  return (
    <TableFilterAccordionItem title={t('Workloads.K8sTable.Filters.Clusters')}>
      <TableFilterFilterableMultiSelect
        id="clusterUuids"
        placeholder={t('Workloads.K8sTable.Filters.ClustersPlaceHolder')}
        isLoading={isLoading}
        filterItems={(items, _ref) => {
          const { inputValue } = _ref
          return items.filter(item => {
            if (!inputValue) {
              return true
            }
            return item.name.toLowerCase().includes(inputValue.toLowerCase())
          })
        }}
        items={clusters}
        itemToElement={item => (
          <WorkloadCluster cluster={item?.name || ''} csp={item?.csp} />
        )}
        itemToString={item => {
          return item.uuid
        }}
        itemsToValues={items => {
          return items.map(item => item.uuid)
        }}
        stringsToItems={strings => {
          const s = new Set(strings)
          return clusters?.filter(cluster => s.has(cluster.uuid)) || []
        }}
      />
    </TableFilterAccordionItem>
  )
}
