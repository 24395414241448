import dayjs from 'dayjs'
import { useCallback } from 'react'

import { TimeRangeFromPeriod } from 'src/next/utils/time'

export const useTooltipHeading = timePeriod => {
  return useCallback(
    activePoint => {
      const date = dayjs(activePoint?.x)
      return date.isValid() ? (
        <TimeRangeFromPeriod
          start={date}
          timePeriod={timePeriod.toLowerCase()}
        />
      ) : (
        ''
      )
    },
    [timePeriod],
  )
}
