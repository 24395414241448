import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SimpleStackedBar } from 'src/next/components/SimpleStackedBar'

const Waste = styled.div`
  margin-bottom: var(--cds-spacing-02);
`

const Efficiency = styled.div`
  max-width: 120px;
`

function getSimpleBarData(
  name: string | undefined,
  utilization: number,
  wasteRatio: number,
) {
  return [
    {
      label: name || '',
      value: utilization,
    },
    {
      label: '', // Waste
      value: wasteRatio,
    },
  ]
}

interface EfficiencyCellProps {
  name: string | undefined
  utilization: number
  wasteRatio: number
}

export const EfficiencyCell = ({
  name,
  utilization,
  wasteRatio,
}: EfficiencyCellProps) => {
  const { t } = useTranslation()

  const formattedWastePercent = (wasteRatio * 100).toFixed(0)

  return (
    <Efficiency>
      <Waste>{`${formattedWastePercent}%`}</Waste>
      <SimpleStackedBar
        data={getSimpleBarData(name, utilization, wasteRatio)}
      />
    </Efficiency>
  )
}
