import { useTranslation } from 'react-i18next'
import { useClusters } from 'src/api'
import {
  TableFilter,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterSearchSelection,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { TableRegionFilter } from 'src/next/components/Filters/TableRegionFilter'
import { TableCSPFilter } from 'src/next/components/Filters/TableCSPFilter'
import { ClustersTableNameDropdownFilter } from './ClustersTableNameDropdownFilter'

export const ClustersTableFilters = () => {
  const { t } = useTranslation()

  const { isOpen } = useTableFilter()

  const { data: clusters, isLoading: isClustersLoading } = useClusters({
    enabled: isOpen,
  })

  return (
    <TableFilter>
      <TableFilterActiveItems />

      <TableCSPFilter
        clusters={clusters?.clusters}
        isLoading={isClustersLoading}
        group="csps"
      />

      <TableRegionFilter />

      <TableFilterAccordionItem title={t('Common.AccountIDs')}>
        <TableFilterSearchSelection
          id="accountIds"
          placeholder={t('Clusters.Filters.AccountIDs')}
        />
      </TableFilterAccordionItem>

      <TableFilterAccordionItem title={t('Clusters.Filters.Name')}>
        <ClustersTableNameDropdownFilter />
      </TableFilterAccordionItem>
    </TableFilter>
  )
}
