import { useMemo } from 'react'
import { CheckboxSkeleton, SkeletonText } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { Cluster } from '@cloudnatix-types/dashboard'
import {
  TableFilterAccordionItem,
  TableFilterCheckbox,
  TableFilterCheckboxGroup,
} from 'src/next/components/TableFilter'
import { CSP } from './CSP'

interface TableCSPFilterProps {
  clusters: Cluster[]
  isLoading: boolean
  group: string
  singleSelection?: boolean
}

export const TableCSPFilter = ({
  clusters,
  isLoading,
  group,
  singleSelection,
}: TableCSPFilterProps): JSX.Element => {
  const { t } = useTranslation()

  const cspItems = useMemo(() => {
    const csps: string[] = []

    const cspset = new Set()
    clusters?.forEach(cluster => {
      if (!cluster.csp) return
      if (cspset.has(cluster.csp)) return
      cspset.add(cluster.csp)
      csps.push(cluster.csp)
    })
    csps.sort((a, b) => a.localeCompare(b))

    return (
      csps.map(csp => {
        return (
          <TableFilterCheckbox
            key={csp}
            id={csp}
            activeFilterText={csp}
            labelText={<CSP key={csp} csp={csp} />}
          />
        )
      }) || []
    )
  }, [clusters])

  return (
    <TableFilterAccordionItem title={t('Clusters.Filters.CSPs')}>
      <TableFilterCheckboxGroup group={group} singleSelection={singleSelection}>
        {isLoading ? (
          <>
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <SkeletonText width="90px" />
          </>
        ) : (
          cspItems
        )}
      </TableFilterCheckboxGroup>
    </TableFilterAccordionItem>
  )
}
