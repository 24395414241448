import React, { FunctionComponent } from 'react'
import { FilterTagProps, Tag } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import MiddleTruncate from '../../MiddleTruncate'
import { useTableFilter } from '../TableFilterContext'

const StyledTag = styled<FunctionComponent<FilterTagProps>>(Tag)`
  margin: 0;
`

export const useActiveFilterTags = () => {
  const { t } = useTranslation()
  const { activeFilters, labels, methods, defaultValues } = useTableFilter()

  return Object.entries(activeFilters).reduce(
    (acc: { key: string; element: JSX.Element }[], [key, value]) => {
      const tagProps = (key: string, value: any, label: string) => ({
        filter: true as const,
        onClick: () => {
          const filterValue = (activeFilters as Record<string, any>)[key]
          let updatedValue = filterValue

          // reset (single) boolean fields to initial value
          if (typeof filterValue === 'boolean') {
            return methods.setValue(key, defaultValues[key] ?? false)
          }
          if (typeof filterValue === 'string')
            return methods.setValue(key, defaultValues[key] ?? undefined)
          // if it's an array, filter the value from the array
          if (Array.isArray(filterValue) && value) {
            updatedValue = filterValue.filter((item: any) => item !== value)
          }

          methods.setValue(key, updatedValue)
        },
        title: t('Filters.ClearFilter'),
        'aria-label': t('Filters.ClearFilter'),
        children: (
          <MiddleTruncate text={label} charsAtStart={15} charsAtEnd={15} />
        ),
      })

      // parse as string array type
      if (typeof value === 'string') value = [value]

      // string[] - loop over the array & return a flattened list
      if (Array.isArray(value)) {
        return [
          ...acc,
          ...value.map(item => {
            // lookup the label from `labels` if any, falls back to the `item` by default
            const label = labels?.[item] || item
            return {
              key: `${item}-${key}`,
              element: <StyledTag {...tagProps(key, item, label)} />,
            }
          }),
        ]
      }
      //  boolean - look up the label by their key
      if (typeof value === 'boolean') {
        return [
          ...acc,
          {
            key,
            element: (
              <StyledTag {...tagProps(key, value, labels?.[key] || '')} />
            ),
          },
        ]
      }

      return acc
    },
    [],
  )
}
