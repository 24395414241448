import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'src/next/components'
import { DashboardMetrics, DashboardTabs } from 'src/next/components/dashboard'
import {
  NoClustersBanner,
  useHasClusters,
} from 'src/next/components/NoClustersBanner'
import { SubscriptionBanner } from 'src/next/components/SubscriptionBanner'
import { PageTitle } from 'src/next/components/ui'

export const DashboardPage = () => {
  const { t } = useTranslation()
  const { isLoading, hasClusters } = useHasClusters()

  return (
    <>
      <PageTitle title={t('Dashboard.PageTitle')} />

      {!isLoading && !hasClusters ? (
        <Flex mt={-6} mb={6} justifyContent="center">
          <NoClustersBanner />
        </Flex>
      ) : null}

      <Flex mt={-6} mb={6}>
        <SubscriptionBanner type="CORE" />
      </Flex>

      <Box mb={6}>
        <DashboardMetrics />
      </Box>

      <PageTitle title={t('Dashboard.SubHeading')} />
      <DashboardTabs />
    </>
  )
}
