import { useQuery, UseQueryOptions } from 'react-query'
import {
  ListChannelsRequest,
  ListChannelsResponse,
} from '@cloudnatix-types/multiclustercontroller'
import { useRequestParams } from 'src/api'
import { fetchData } from 'src/api/axios'

const url = '/v1/channels'

export function useCliChannels<T = ListChannelsResponse>(
  request: ListChannelsRequest = {},
  os?: string,
  options?: UseQueryOptions<ListChannelsResponse, Error, T>,
) {
  const params = useRequestParams({ request })

  return useQuery<ListChannelsResponse, Error, T>(
    [url, params, os],
    () => fetchData<ListChannelsResponse>(url, params),
    {
      ...options,
    },
  )
}
