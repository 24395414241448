import React from 'react'
import {
  CloudServiceManagement as AdministrationIcon,
  DataVis_1 as ClustersIcon,
  Dashboard as DashboardIcon,
  WatsonHealthAiResults as InsightsIcon,
  Network_2 as OrgsIcon,
  Network_1 as WorkloadIcon,
} from '@carbon/react/icons'
import { ReactComponent as KubernetesIcon } from 'src/images/logo--kubernetes.svg'
import { Action, KBarProvider, Priority } from 'kbar'
import { useNavigate } from 'react-router-dom'
import { CLUI } from 'src/next/components/CLUI'
import styled from 'styled-components'

const StyledKubernetesIcon = styled(KubernetesIcon)`
  fill: var(--cds-icon-primary);
`

export interface CLUIProviderProps {
  children: React.ReactNode
}

export const CLUIProvider = ({ children }: CLUIProviderProps) => {
  const navigate = useNavigate()

  const defaultActions: Action[] = [
    {
      id: 'dashboardAction',
      name: 'Dashboard',
      shortcut: ['o', 'd'],
      keywords: 'home',
      section: {
        name: 'Navigation',
        priority: Priority.LOW,
      },
      perform: () => navigate('/app/dashboard'),
      icon: <DashboardIcon size={20} />,
    },
    {
      id: 'insightsAction',
      name: 'Insights',
      shortcut: ['o', 'i'],
      section: 'Navigation',
      perform: () => navigate('/app/insights'),
      icon: <InsightsIcon size={20} />,
    },
    {
      id: 'workloadsAction',
      name: 'Workloads',
      shortcut: ['o', 'w'],
      section: 'Navigation',
      perform: () => navigate('/app/workloads'),
      icon: <WorkloadIcon size={20} />,
    },
    {
      id: 'clustersAction',
      name: 'Clusters',
      shortcut: ['o', 'c'],
      section: 'Navigation',
      perform: () => navigate('/app/clusters'),
      icon: <ClustersIcon size={20} />,
    },
    {
      id: 'kubernetesAction',
      name: 'Kubernetes resources',
      shortcut: ['o', 'k'],
      section: 'Navigation',
      perform: () => navigate('/app/kubernetes-resources'),
      icon: <StyledKubernetesIcon />,
    },
    {
      id: 'iamAction',
      name: 'Administration',
      shortcut: ['o', 'a'],
      section: 'Navigation',
      keywords: 'users',
      subtitle: 'Manage users, roles and organizations',
      perform: () => navigate('/app/administration'),
      icon: <AdministrationIcon size={20} />,
    },
    {
      id: 'workloadFinder',
      name: 'Workload…',
      shortcut: ['>'],
      keywords: 'find',
      section: { priority: Priority.NORMAL, name: 'Go to' },
      icon: <WorkloadIcon size={20} />,
    },
    {
      id: 'clusterFinder',
      name: 'Cluster…',
      shortcut: ['@'],
      keywords: 'find',
      section: 'Go to',
      icon: <ClustersIcon size={20} />,
    },
    {
      id: 'kubernetesClusterFinder',
      name: 'Kubernetes cluster…',
      shortcut: ['#'],
      keywords: 'find',
      section: 'Go to',
      icon: <StyledKubernetesIcon />,
    },
    {
      id: 'kubernetesResourceFinder',
      name: 'Kubernetes resource…',
      shortcut: ['$'],
      keywords: 'find',
      section: 'Go to',
      icon: <StyledKubernetesIcon />,
    },
    {
      id: 'orgsSwitch',
      name: 'Organizations…',
      shortcut: ['/'],
      keywords: 'find',
      section: { priority: Priority.NORMAL, name: 'Switch' },
      icon: <OrgsIcon size={20} />,
    },
    // You can directly open the workloads and clusters finder by using the '>'-
    // & '@'-shortcuts. However, this shortcut  does not work when there are no child
    // items yet (which is the case, when the workloads or clusters haven't been fetched
    // yet). With below placeholder it does work. It is filtered out in the results.
    {
      id: 'placeholder',
      name: 'PLACEHOLDER',
      parent: 'workloadFinder',
    },
    {
      id: 'placeholder',
      name: 'PLACEHOLDER',
      parent: 'clusterFinder',
    },
    {
      id: 'placeholder',
      name: 'PLACEHOLDER',
      parent: 'kubernetesClusterFinder',
    },
    {
      id: 'placeholder',
      name: 'PLACEHOLDER',
      parent: 'kubernetesResourceFinder',
    },
    {
      id: 'placeholder',
      name: 'PLACEHOLDER',
      parent: 'orgsSwitch',
    },
  ]

  return (
    <KBarProvider actions={defaultActions}>
      {children}
      <CLUI />
    </KBarProvider>
  )
}
