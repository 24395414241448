/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { Tooltip as CarbonTooltip } from '@carbon/react'
import { TooltipProps as CarbonTooltipProps } from '@carbon/react/lib/components/Tooltip/Tooltip'
import styled from 'styled-components'

const TooltipWrapper = styled.span`
  .cds--popover--open > .cds--popover {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9) forwards;
    // regular opacity can't be animated due to inline styles on the element
    filter: opacity(0);

    @keyframes tooltip-fade {
      100% {
        filter: opacity(1);
      }
    }
  }

  .cds--popover-content {
    font-family: 'IBM Plex Sans', Arial, sans-serif;
    font-size: inherit;
    cursor: pointer;

    &:focus {
      outline: none;
      cursor: default;
    }
  }
`

const StyledTooltip = styled(CarbonTooltip)<{
  $textAlign?: React.CSSProperties['textAlign']
}>`
  text-align: ${({ $textAlign }) => $textAlign || 'left'};
`

export const Tooltip: React.FC<CarbonTooltipProps<any>> = ({
  children,
  onChange,
  label,
  className,
  ...rest
}) => {
  return (
    // Tooltip does not open on hover by default, the wrapper handles this.
    <TooltipWrapper>
      <StyledTooltip
        label={label}
        className={`cnatix-tooltip ${className}`}
        onChange={onChange || (() => {})}
        {...rest}
      >
        {children}
      </StyledTooltip>
    </TooltipWrapper>
  )
}

export default Tooltip
