import {
  ComputeInstanceSummary,
  Summary,
  SummaryMetrics,
} from '@cloudnatix-types/dashboard'
import { TabSummariesChartSettingMetric } from 'src/next/components/KubernetesWorkloadCharts/types'

export type KubernetesWorkloadSummaryMetricMap = Record<
  Exclude<keyof typeof SummaryMetrics, 'SUMMARY_METRICS_UNSPECIFIED'>,
  keyof Summary
>

export type ComputeInstanceSummaryMetricMap = Record<
  TabSummariesChartSettingMetric,
  keyof ComputeInstanceSummary
>

// A map from the ID of the Kubernetes workload summary metrics to the name of
// the corresponding field on the API response.
//
// This map should cover all the IDs listed in
// `useKubernetesWorkloadTopSummariesDropdownItems()`.
//
// Note that the columns on the table on the Namespace tab come in the same
// order as this map.
export const kubernetesWorkloadSummaryMetricMap: KubernetesWorkloadSummaryMetricMap =
  {
    TOTAL_COST: 'totalCost',
    CPU_AVG: 'avgCpu',
    CPU_MAX: 'maxCpu',
    AVG_CPU_REQUESTED: 'avgCpuRequested',
    MAX_CPU_REQUESTED: 'maxCpuRequested',
    AVG_CPU_LIMIT: 'avgCpuLimit',
    MAX_CPU_LIMIT: 'maxCpuLimit',
    AVG_CPU_CAPACITY: 'avgCpuCapacity',
    MAX_CPU_CAPACITY: 'maxCpuCapacity',
    MEMORY_AVG: 'avgMemory',
    MEMORY_MAX: 'maxMemory',
    AVG_MEMORY_REQUESTED: 'avgMemoryRequested',
    MAX_MEMORY_REQUESTED: 'maxMemoryRequested',
    AVG_MEMORY_LIMIT: 'avgMemoryLimit',
    MAX_MEMORY_LIMIT: 'maxMemoryLimit',
    AVG_MEMORY_CAPACITY: 'avgMemoryCapacity',
    MAX_MEMORY_CAPACITY: 'maxMemoryCapacity',
  }

// A map from the ID of the compute instance summary metrics to the name of the
// corresponding field on the API response.
//
// This map should cover all the IDs listed in
// `useComputeInstanceSummariesMetricsDropdownItems()`.
//
// Note that the columns on the table on the dashboard tabs come in the same
// order as this map.
export const computeInstanceSummaryMetricMap: ComputeInstanceSummaryMetricMap =
  {
    TOTAL_SPEND: 'totalSpend',
    WASTED_SPEND: 'wastedSpend',
    AVG_CPU_USAGE: 'avgCpuUsage',
    MAX_CPU_USAGE: 'maxCpuUsage',
    CI_AVG_CPU_CAPACITY: 'avgCpuCapacity',
    CI_MAX_CPU_CAPACITY: 'maxCpuCapacity',
    AVG_MEMORY_USAGE: 'avgMemoryUsage',
    MAX_MEMORY_USAGE: 'maxMemoryUsage',
    CI_AVG_MEMORY_CAPACITY: 'avgMemoryCapacity',
    CI_MAX_MEMORY_CAPACITY: 'maxMemoryCapacity',
    // AVG_DISK_USAGE: 'avgDiskUsage',
    // MAX_DISK_USAGE: 'maxDiskUsage',
    CI_AVG_DISK_CAPACITY: 'avgDiskCapacity',
    CI_MAX_DISK_CAPACITY: 'maxDiskCapacity',
  }
