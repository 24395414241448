import React from 'react'
import { OpenPanelFilledLeft } from '@carbon/react/icons'
import { useTranslation } from 'react-i18next'
import { Toolbar } from 'src/next/components/Toolbar'
import { ToolbarButtonProps } from 'src/next/components/Toolbar/ToolbarButton/ToolbarButton'

interface SidebarToggleProps extends ToolbarButtonProps {
  open: boolean
  onToggle: (open: boolean) => void
}

export const SidebarToggle = ({
  open,
  onToggle,
  ...rest
}: SidebarToggleProps) => {
  const { t } = useTranslation()

  return (
    <Toolbar.Button
      active={open}
      iconDescription={t('Common.SideBarToggle', 'Toggle sidebar')}
      tooltipAlignment="end"
      renderIcon={OpenPanelFilledLeft}
      onClick={() => onToggle(!open)}
      {...rest}
    />
  )
}
