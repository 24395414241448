/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useRef, useState } from 'react'
import { ComboBox } from '@carbon/react'
import { ComboBoxProps } from '@carbon/react/lib/components/ComboBox/ComboBox'
import styled from 'styled-components'

const StyledComboBox = styled(ComboBox<string>)`
  // hide clear button
  .cds--list-box__selection {
    display: none;
  }

  .cds--list-box__menu-icon {
    pointer-events: none;
  }
`

export interface FilterableSelectProps
  extends Omit<ComboBoxProps<string>, 'onChange'> {
  onChange: (value: string) => void
  initiallySelectedItem?: string
}
type SelectedItem = ComboBoxProps<string>['selectedItem']
type ShouldFilterItem = ComboBoxProps<string>['shouldFilterItem']

const shouldFilterItem: ShouldFilterItem = ({ item, inputValue }) =>
  item?.toLowerCase().includes(inputValue || '')

/**
 * @component FilterableSelect
 * Clears input field on focus so that the user can start searching
 * immediately. Restores value to the last selected value on blur.
 * For now only supports a list with strings.
 */

export const FilterableSelect = ({
  items,
  onChange,
  initiallySelectedItem,
  ...props
}: FilterableSelectProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [selectedItem, setSelectedItem] = useState<SelectedItem>(
    initiallySelectedItem === undefined && items.length > 0
      ? items[0]
      : initiallySelectedItem,
  )

  // Remove the selected value on focus so that the user can directly search in the input field.
  // Ideally, the original value should be recovered when blurred without selecting a new value, but we haven't implemented such a behavior yet.
  // See https://cloudnatix.atlassian.net/browse/CNATIX-5141
  const onFocusHandler = () => {
    setSelectedItem(null)
  }

  const onChangeHandler = ({
    selectedItem: newlySelectedItem,
  }: {
    selectedItem: SelectedItem
  }) => {
    if (!newlySelectedItem) {
      return
    }

    setSelectedItem(newlySelectedItem)
    onChange(newlySelectedItem)

    // Make the input blurred so that re-clicking the input field will clear the input field, not resuming incremental search.
    inputRef.current?.blur()
  }

  return (
    <StyledComboBox
      items={items}
      onChange={onChangeHandler}
      selectedItem={selectedItem}
      shouldFilterItem={shouldFilterItem}
      ref={inputRef}
      // We want to hook onBlur to recover the last selected item to the input box, but simply setting onBlur here doesn't work.
      // See https://cloudnatix.atlassian.net/browse/CNATIX-5141
      onFocus={onFocusHandler}
      {...props}
    />
  )
}

export default FilterableSelect
