import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from 'src/next/components'
import { Tooltip } from 'src/next/components/ui/Tooltip'
import { SimpleStackedBar } from './SimpleStackedBar'

const StyledTooltip = styled(Tooltip)`
  & {
    padding-bottom: var(--cds-spacing-01);
    padding-top: var(--cds-spacing-01);
  }
`

const StyledSimpleStackedBar = styled(SimpleStackedBar)`
  && {
    min-width: 75px;
    background: var(--carbonPalette2);
    pointer-events: none;
  }
`

export const StackedBarCell = ({
  value,
  total,
  formatFn,
}: {
  value?: number
  total?: number
  formatFn: (number: number) => string
}) => {
  const { t } = useTranslation()

  return (
    <StyledTooltip
      tooltipText={t('Common.ValueOfTotal', {
        value: formatFn(value || 0),
        total: formatFn(total || 0),
      })}
    >
      <Box py="3">
        <StyledSimpleStackedBar
          data={[{ value: value || 0 }]}
          total={total || 0}
        />
      </Box>
    </StyledTooltip>
  )
}
