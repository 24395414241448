import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'

export interface ShowMoreProps {
  children: ReactNode
  maxItems: number
  collapsed?: boolean
  onChange?(): void
  readOnly?: boolean
  expandLabel?: string | React.ReactNode | null | any
  collapseLabel?: string | React.ReactNode | null | any
  wrapperComponent?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  > // I.e. to pass UL in case of list items

  // With the maxItemsTolerance you can provide a minimum count of children
  // before they're being trimmed.

  // let's say you have two ShowMore components. One with 5 children and the
  // other 8, both have `maxItems` set to 4. The children of both elements are
  // limit to 4. You may not want to hide the single item for the one with 5
  // items (the 'show more'-link takes the same amount of space as the single
  // hidden item). This is what the maxItemsTolerance is for. If in the previous
  // example the `maxItemsTolerance` is set to 1 the first will show all 5 (and
  // have no show more link) and the other will be limited to 4.
  // For a visual example see the Storybook
  maxItemsTolerance?: number
}

const StyledButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;

  color: var(--cds-link-primary);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--cds-body-short-01-font-size);
  font-weight: var(--cds-body-short-01-font-weight);
  letter-spacing: var(--cds-body-short-01-letter-spacing);
  line-height: var(--cds-body-short-01-line-height);
  margin-top: var(--cds-spacing-02);
  text-decoration: none;
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
`

const ShowMore = ({
  children,
  maxItems,
  maxItemsTolerance = 0,
  expandLabel = 'Show more…',
  collapseLabel = 'Show less…',
  collapsed: controlledCollapsed,
  onChange,
  readOnly,
  wrapperComponent,
}: ShowMoreProps) => {
  const [collapsedState, setIsCollapsedState] = useState(() =>
    typeof controlledCollapsed !== 'undefined' ? controlledCollapsed : true,
  )

  if (
    typeof controlledCollapsed !== 'undefined' &&
    !readOnly &&
    typeof onChange !== 'function'
  )
    throw new Error(
      'A `collapsed` prop was provided to `ShowMore` without an `onChange`-handler. This will result in a read-only `collapsed`-value. If you want it to be controlled, also provide an `onChange`-handler. If you want it to be uncontrolled, remove the `collapsed`-value. Alternatively, if you want it to be readonly, pass a `readOnly`-prop',
    )

  const collapsedIsControlled = typeof controlledCollapsed !== 'undefined'
  const collapsed = collapsedIsControlled ? controlledCollapsed : collapsedState

  const handleChange = () =>
    collapsedIsControlled && onChange
      ? onChange()
      : setIsCollapsedState(!collapsedState)

  const exceedsMaxItems =
    React.Children.toArray(children).length > maxItems + maxItemsTolerance

  const filteredChildren =
    exceedsMaxItems && collapsed
      ? React.Children.map(children, (child, index) => {
          if (index + 1 > maxItems) return null
          return child
        })
      : children

  const label = collapsed ? expandLabel : collapseLabel

  const toggleButton =
    typeof label === 'string' ? (
      <StyledButton
        aria-expanded={!collapsed}
        onClick={handleChange}
        type="button"
      >
        {label}
      </StyledButton>
    ) : typeof label === 'function' ? (
      // @ts-ignore
      label(handleChange)
    ) : null

  return (
    <>
      {wrapperComponent
        ? React.cloneElement(wrapperComponent, { children: filteredChildren })
        : filteredChildren}
      {exceedsMaxItems && label && toggleButton}
    </>
  )
}

export default ShowMore
