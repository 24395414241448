import { useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { TopSummariesChartSettingMetric } from './types'

export const getDurationTranslations = (t: TFunction) => ({
  daily: t('Common.TimePeriod.Daily'),
  weekly: t('Common.TimePeriod.Weekly'),
  monthly: t('Common.TimePeriod.Monthly'),
})

export const useKubernetesWorkloadTopSummariesMetricDropdownItems = (): (Record<
  'id',
  TopSummariesChartSettingMetric
> & { label: string })[] => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        id: 'CPU_AVG',
        label: t('Resources.AvgCpuUsage'),
      },
      {
        id: 'CPU_MAX',
        label: t('Resources.MaxCpuUsage'),
      },
      {
        id: 'MEMORY_AVG',
        label: t('Resources.AvgMemoryUsage'),
      },
      {
        id: 'MEMORY_MAX',
        label: t('Resources.MaxMemoryUsage'),
      },
      {
        id: 'TOTAL_COST',
        label: t('Resources.TotalCost'),
      },
      {
        id: 'AVG_CPU_REQUESTED',
        label: t('Resources.AvgCpuRequested'),
      },
      {
        id: 'MAX_CPU_REQUESTED',
        label: t('Resources.MaxCpuRequested'),
      },
      {
        id: 'AVG_MEMORY_REQUESTED',
        label: t('Resources.AvgMemoryRequested'),
      },
      {
        id: 'MAX_MEMORY_REQUESTED',
        label: t('Resources.MaxMemoryRequested'),
      },
      {
        id: 'AVG_CPU_LIMIT',
        label: t('Resources.AvgCpuLimit'),
      },
      {
        id: 'MAX_CPU_LIMIT',
        label: t('Resources.MaxCpuLimit'),
      },
      {
        id: 'AVG_MEMORY_LIMIT',
        label: t('Resources.AvgMemoryLimit'),
      },
      {
        id: 'MAX_MEMORY_LIMIT',
        label: t('Resources.MaxMemoryLimit'),
      },
      {
        id: 'AVG_CPU_CAPACITY',
        label: t('Resources.AvgCpuCapacity'),
      },
      {
        id: 'MAX_CPU_CAPACITY',
        label: t('Resources.MaxCpuCapacity'),
      },
      {
        id: 'AVG_MEMORY_CAPACITY',
        label: t('Resources.AvgMemoryCapacity'),
      },
      {
        id: 'MAX_MEMORY_CAPACITY',
        label: t('Resources.MaxMemoryCapacity'),
      },
    ],
    [t],
  )
}
