import React, { useMemo } from 'react'
import { Button } from '@carbon/react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Org } from '@cloudnatix-types/orgcontroller'
import { User, UserRole } from '@cloudnatix-types/usercontroller'
import { useOrgs, useUsers, useUserSelf } from 'src/api'
import DataTable, {
  DataTableActionButton,
  DataTablePagination,
  usePagination,
} from 'src/next/components/DataTable'
import {
  InlineNotification,
  ResetFiltersLink,
} from 'src/next/components/InlineNotification'
import {
  TableFilter,
  TableFilterAccordion,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterCheckbox,
  TableFilterCheckboxGroup,
  TableFilterContainer,
  TableFilterToolbarActions,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { DataTableHeaders } from 'src/next/types/dataTable'
import { useUserActions } from './hooks'
import { UserRoles } from './UserRoles'

export interface UserTableRowData {
  id: string
  firstName: string
  lastName: string
  loginId: string
  roleBindings: [
    {
      role: UserRole
      orgUuid: string
      orgName: string
    },
  ]
}

export const UsersTable = () => {
  const { t } = useTranslation()

  // const usersTableRef = useRef<any>();

  const { data: orgsData } = useOrgs()
  const { data: user } = useUserSelf()

  const rootOrg = orgsData?.orgs?.find(org => org.parentOrgUuid === '')

  const isRootAdmin =
    user &&
    rootOrg?.uuid &&
    !!user.roleBindings?.find(
      roleBinding =>
        roleBinding.role === 'USER_ROLE_ADMIN' &&
        roleBinding.orgUuid === rootOrg?.uuid,
    )

  const pagination = usePagination('users-table-page-size')

  const { resetPage, setPage, ...dataTablePaginationProps } = pagination

  const { filters, activeFiltersCount, methods, reset } = useTableFilter()

  const headers = useHeaders()

  const { addUser, editUser, deleteUser, UserActionsModal, userModalProps } =
    useUserActions()
  methods.watch(() => resetPage())

  // const { batchActions, BatchActions } = useUserBatchActions(users || []);

  const query = useUsers({
    pageSize: pagination.pageSize,
    filter: filters,
  })

  const currentPageData = query.data?.pages?.[pagination.page - 1] || {}

  const users = useMemo(
    () => currentPageData.users || [],
    [currentPageData.users],
  )

  // User data to table data conversion
  const formatRowData = useMemo(
    () =>
      formatUserData(
        orgsData?.orgs,
        !!isRootAdmin,
        users,
        editUser,
        deleteUser,
        t,
      ),
    [users, editUser, deleteUser, isRootAdmin, t, orgsData?.orgs],
  )

  return (
    <>
      <TableFilterContainer>
        <TableFilter>
          <TableFilterActiveItems />
          <TableFilterAccordion>
            <TableFilterAccordionItem title={t('Users.Form.Organization')}>
              <TableFilterCheckboxGroup group="orgUuids">
                {orgsData?.orgs?.map(({ uuid, name }) => {
                  if (!uuid || !name) return null

                  return (
                    <TableFilterCheckbox
                      key={uuid}
                      id={uuid}
                      labelText={name || ''}
                    />
                  )
                })}
              </TableFilterCheckboxGroup>
            </TableFilterAccordionItem>
            <TableFilterAccordionItem title={t('UserRoles.Roles')}>
              <TableFilterCheckboxGroup group="roles">
                <TableFilterCheckbox
                  id={UserRole.USER_ROLE_ADMIN}
                  labelText={t('UserRoles.Admin')}
                />
                <TableFilterCheckbox
                  id={UserRole.USER_ROLE_USER}
                  labelText={t('UserRoles.User')}
                />
                <TableFilterCheckbox
                  id={UserRole.USER_ROLE_VIEWER}
                  labelText={t('UserRoles.Viewer')}
                />
                <TableFilterCheckbox
                  id={UserRole.USER_ROLE_PATCH}
                  labelText={t('UserRoles.Patch')}
                />
              </TableFilterCheckboxGroup>
            </TableFilterAccordionItem>
          </TableFilterAccordion>
        </TableFilter>
        <div data-testid="users-table">
          <DataTable
            // ref={usersTableRef}
            size="xl"
            storageKey="datatable-users"
            isLoading={query.isFetching}
            headers={isRootAdmin ? headers : headers.slice(0, 3)}
            rows={formatRowData}
            pageSize={pagination.pageSize}
            toolbar={<TableFilterToolbarActions />}
            primaryAction={
              isRootAdmin ? (
                <Button onClick={addUser}>{t('Users.AddUser')}</Button>
              ) : null
            }
            // batchActions={batchActions}
          />
          {!users?.length && !query.isFetching && !query.isError ? (
            <InlineNotification
              title={t('Users.NoneFound')}
              subtitle={activeFiltersCount ? t('Filters.NoResults') : null}
            >
              <ResetFiltersLink
                activeFiltersCount={activeFiltersCount}
                reset={reset}
              />
            </InlineNotification>
          ) : null}

          {query.isError ? (
            <InlineNotification
              title={t('Users.FetchUsersFailed')}
              kind="error"
            />
          ) : null}

          {/* <BatchActions
            onSuccess={() => {
              if (usersTableRef?.current?.deselectAll)
                usersTableRef.current.deselectAll();
            }}
          /> */}

          <DataTablePagination {...dataTablePaginationProps} query={query} />
        </div>
      </TableFilterContainer>
      {userModalProps.open && <UserActionsModal {...userModalProps} />}
    </>
  )
}

const formatUserData = (
  orgs: Org[] | undefined,
  isAdmin: boolean,
  users: User[],
  editUser: (user: UserTableRowData) => void,
  deleteUser: (user: UserTableRowData) => Promise<void>,
  t: TFunction,
) => {
  return (
    users?.map(user => {
      const transformedUser = {
        id: user.uuid!,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        loginId: user.loginId || '',
        roleBindings: user.roleBindings?.map(rb => ({
          role: rb.role!,
          orgUuid: rb.orgUuid!,
          orgName: orgs?.find(org => org.uuid === rb.orgUuid)?.name || '',
        })),
      } as UserTableRowData

      return {
        ...transformedUser,
        ...(isAdmin && {
          roles: <UserRoles roles={transformedUser.roleBindings} />,
        }),
        ...(isAdmin && {
          menu: (
            <>
              <DataTableActionButton
                iconDescription={`${t('Users.EditUser')}`}
                onClick={() => editUser(transformedUser)}
              />
              <DataTableActionButton
                actionType="delete"
                iconDescription={`${t('Users.DeleteUser')}`}
                onClick={() => deleteUser(transformedUser)}
              />
            </>
          ),
        }),
      }
    }) || []
  )
}

const useHeaders = () => {
  const { t } = useTranslation()

  return [
    {
      key: 'loginId',
      header: t('Users.Datatable.Username', 'Username'),
    },
    {
      key: 'firstName',
      header: t('Users.Datatable.FirstName', 'First name'),
    },
    {
      key: 'lastName',
      header: t('Users.Datatable.LastName', 'Last name'),
    },
    {
      key: 'roles',
      header: t('Users.Datatable.AccessRoles', 'Access roles'),
    },
    {
      key: 'menu',
      header: t('Users.Datatable.Actions', 'Actions'),
    },
  ] as DataTableHeaders
}
