import { ReactComponent as AmazonIcon } from 'src/images/AWS.svg'
import { ReactComponent as DockerIcon } from 'src/images/Docker.svg'
import { ReactComponent as GoogleCloudIcon } from 'src/images/GCP.svg'
import { ReactComponent as MicrosoftIcon } from 'src/images/Microsoft.svg'
import { ReactComponent as OracleCloudIcon } from 'src/images/OCI.svg'

interface ClusterIconProps {
  type?: string
}

export const ClusterIcon = ({ type, ...props }: ClusterIconProps) => {
  switch (type) {
    case 'aws':
      return <AmazonIcon {...props} />
    case 'gcp':
      return <GoogleCloudIcon {...props} />
    case 'azure':
      return <MicrosoftIcon {...props} />
    case 'docker':
      return <DockerIcon {...props} />
    case 'oci':
      return <OracleCloudIcon {...props} />
    default:
      return null
  }
}
