import { useTranslation } from 'react-i18next'
import {
  InlineLoading,
  OverflowMenuItem,
  OverflowMenuProps,
} from '@carbon/react'
import { Download } from '@carbon/react/icons'
import { UseMutateFunction } from 'react-query'
import { styled } from 'styled-components'
import { Box, OverflowMenu } from 'src/next/components'
import { useToaster } from 'src/next/hooks/useToaster'
import { saveBlobAs } from 'src/next/utils'

const StyledDownloadIcon = styled(Download)`
  color: var(--cds-icon-primary);
  height: 100%;
  &.active {
    color: var(--cds-button-interactive);
  }
`

interface DownloadButtonProps extends OverflowMenuProps {
  fileTypes: ({ name: string; extension: string } | string)[]
  download: UseMutateFunction<ArrayBuffer, unknown, string, unknown>
  isLoading: boolean
  fileBase: string
}

export const DownloadButton = ({
  fileTypes = ['CSV', 'JSON', { name: 'Excel', extension: 'xlsx' }],
  download,
  isLoading,
  fileBase,
  ...rest
}: DownloadButtonProps) => {
  const { t } = useTranslation()

  const { addToast } = useToaster()

  const handleDownloadClick = async (extension: string) => {
    download(extension, {
      onSuccess: data => {
        saveBlobAs(data, {
          fileName: `${fileBase}.${extension}`,
          type: fileTypes[extension],
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        // We assumes the download converts the data into an array buffer,
        // and actually axios also converts the error.data into an array buffer
        // as well, which causes trouble showing the content of the error.
        let message = error?.data
        if (message instanceof ArrayBuffer) {
          message = new TextDecoder().decode(message)
        }
        // Sometimes the error message is wrapped in a JSON string object,
        // and the true message appears to be in 'message' field.
        try {
          const parsed = JSON.parse(message)
          if (parsed.message) {
            message = parsed.message
          }
        } catch (e) {
          // do nothing
        }
        addToast({
          title: message,
          kind: 'error',
        })
      },
    })
  }

  return (
    <>
      {isLoading ? (
        <Box ml="3" p="2">
          <InlineLoading />
        </Box>
      ) : (
        <OverflowMenu
          flipped
          renderIcon={StyledDownloadIcon}
          iconDescription={t('Common.DownloadAs')}
          ariaLabel={t('Common.DownloadAs')}
          {...rest}
        >
          {fileTypes.map(fileType => {
            const name = typeof fileType === 'string' ? fileType : fileType.name
            const extension =
              typeof fileType === 'string'
                ? fileType.toLowerCase()
                : fileType.extension
            return (
              <OverflowMenuItem
                key={extension}
                onClick={() => {
                  handleDownloadClick(extension)
                }}
                itemText={t('Common.DownloadAs', { type: name })}
              />
            )
          })}
        </OverflowMenu>
      )}
    </>
  )
}
