import { useCLUIRegisterClusters } from './useCLUIRegisterClusters'
import { useCLUIRegisterKubernetesResources } from './useCLUIRegisterKubernetesResources'
import { useCLUIRegisterOrgsSwitch } from './useCLUIRegisterOrgsSwitch'
import { useCLUIRegisterThemeSwitch } from './useCLUIRegisterThemeSwitch'
import { useCLUIRegisterWorkloads } from './useCLUIRegisterWorkloads'

export const useCLUI = (enabled: boolean) => {
  // Workloads and clusters are directly fetched when the CLUI is opened. For
  // calls that are too expensive we can delay fetching to when a certain action
  // is active, like so:
  // const { isLoading: isLoadingWorkloads } = useCLUIWorkloadsActions(
  //   currentRootActionId === 'workloadFinder'
  // );

  // we pass 'enabled' to prevent React Query from calling the API directly when
  // the CLUI is mounted (resulting in an authentication error when the user
  // logs in)
  const { isLoading: isLoadingWorkloads } = useCLUIRegisterWorkloads(enabled)
  const { isLoading: isLoadingClusters } = useCLUIRegisterClusters(enabled)
  const { isLoading: isLoadingKubernetesResources } =
    useCLUIRegisterKubernetesResources(enabled)
  useCLUIRegisterOrgsSwitch(enabled)

  useCLUIRegisterThemeSwitch()

  return {
    isLoadingWorkloads,
    isLoadingClusters,
    isLoadingKubernetesResources,
  }
}
