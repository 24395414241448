import React from 'react'
import { Terminal } from '@carbon/react/icons'
import { Priority, useRegisterActions } from 'kbar'
import { useTranslation } from 'react-i18next'

export const useCLUIRegisterInstallCLIModal = (
  setOpenCliModal: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { t } = useTranslation()

  useRegisterActions([
    {
      id: 'openInstallCLIModal',
      name: t('CLUI.InstallCLI'),
      icon: <Terminal size={20} />,
      keywords: t('CLUI.Terminal'),
      perform: () => setOpenCliModal(true),
      section: {
        name: t('CLUI.Miscellaneous'),
        priority: Priority.LOW,
      },
    },
  ])
}
