import React from 'react'
import { ToastNotification, ToastNotificationProps } from '@carbon/react'

export type ToastProps = ToastNotificationProps

const Toast = ({ ...rest }: ToastProps) => {
  return <ToastNotification kind="info" {...rest} />
}

export default Toast
