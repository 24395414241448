import styled from 'styled-components'

export const OverflowMenuWrapper = styled.div<{ $minWidth?: string }>`
  position: relative;

  .cds--overflow-menu-options {
    padding: 0;
    min-width: ${({ $minWidth }) => $minWidth};
  }

  .cds--overflow-menu-options__btn {
    max-width: none;
  }
`
