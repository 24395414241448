import React, { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Summary, ListSummariesResponse } from '@cloudnatix-types/dashboard'
import { AreaGraphProps, GraphDataPoint, useLegend } from 'src/next/components'
import { Graph } from 'src/next/components/Graphs/Graph'
import GraphTooltip from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import Loading from 'src/next/components/Loading'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { usePersistentMetricDropdown } from 'src/next/hooks/usePersistentMetricDropdown'
import {
  bytesToUserFriendlySize,
  millicoresToUserFriendlySizeLong,
} from 'src/next/utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'
import ChartContainer from './ChartContainer'
import { ResourceType } from './types'
import { TimeRangeFromPeriod, TimePeriod } from 'src/next/utils/time'
import { useKubernetesSummaries } from './useKubernetesSummaries'
import { getDurationTranslations } from './KubernetesWorkloadCharts.translations'
import { KubernetesWorkloadResourceTypeHeading } from './KubernetesWorkloadResourceTypeHeading'

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
  max-height: 375px;
`

const formatTooltipValue = (value: unknown, type: ResourceType) =>
  type === 'cpu'
    ? millicoresToUserFriendlySizeLong(Number(value), 2)
    : bytesToUserFriendlySize(Number(value))

const getGraphConfig = (resourceType: ResourceType, t: TFunction) => {
  return [
    {
      type: 'area' as const,
      id: resourceType === 'cpu' ? 'avgUsedCpu' : 'avgUsedMemory',
      label: t('Workloads.TopCharts.Donuts.AvgUsed'),
      tooltipLabel: t('Workloads.TopCharts.Donuts.AvgUsed'),
      tooltipValueTransformFn: (value: unknown) =>
        formatTooltipValue(value, resourceType),
      enabled: true,
      data: [],
      stack: true,
      props: {
        style: { data: { fill: 'var(--carbonPalette1)' } },
      },
    },
    {
      id: resourceType === 'cpu' ? 'avgUnusedCpu' : 'avgUnusedMemory',
      label: t('Workloads.TopCharts.Donuts.AvgUnused'),
      tooltipLabel: t('Workloads.TopCharts.Donuts.AvgUnused'),
      tooltipValueTransformFn: (value: unknown) =>
        formatTooltipValue(value, resourceType),
      enabled: true,
      type: 'area' as const,
      data: [],
      stack: true,
      props: {
        style: { data: { fill: 'var(--carbonPalette2)' } },
      },
    },
  ]
}

const tickFormatMap: Record<TimePeriod, string> = {
  daily: 'MM/DD',
  weekly: 'MMM D',
  monthly: 'MMM D',
}

const KubernetesWorkloadLineChart = () => {
  const { t } = useTranslation()

  const durationTranslations = getDurationTranslations(t)

  const { selectedItem: resourceType, dropdownProps } =
    usePersistentMetricDropdown('workloads-stream-resource-type')

  const graphConfigBase = useMemo(
    () => getGraphConfig(resourceType, t),
    [resourceType, t],
  )

  const { Legend, graphConfig, legendProps } =
    useLegend<AreaGraphProps>(graphConfigBase)

  const timeFilterItems = useMemo(
    () => [
      {
        id: 'daily',
        label: t('Workloads.TopCharts.Filters.DailyRange'),
      },
      {
        id: 'weekly',
        label: t('Workloads.TopCharts.Filters.WeeklyRange'),
      },
      {
        id: 'monthly',
        label: t('Workloads.TopCharts.Filters.MonthlyRange'),
      },
    ],
    [t],
  )
  const [timeFilter, setTimeFilter] = useLocalStorage<TimePeriod>(
    'workloads-stream-chart-time-filter',
    'daily',
  )

  interface Result {
    avgUsedCpu: GraphDataPoint[]
    avgUnusedCpu: GraphDataPoint[]
    avgUsedMemory: GraphDataPoint[]
    avgUnusedMemory: GraphDataPoint[]
  }
  type Key = keyof Result

  const transformToGraphPoints = (data: ListSummariesResponse) =>
    data.orgSummaries?.reduce(
      (acc: Result, item: Summary) => {
        const date = new Date(nanoToMilliSeconds(item.timestampNs ?? 0))

        acc.avgUsedCpu.push({
          x: date,
          y: item.avgCpu,
        })

        acc.avgUnusedCpu.push({
          x: date,
          y: item.avgCpuCapacity - item.avgCpu,
        })

        acc.avgUsedMemory.push({
          x: date,
          y: item.avgMemory,
        })

        acc.avgUnusedMemory.push({
          x: date,
          y: item.avgMemoryCapacity - item.avgMemory,
        })

        return acc
      },
      {
        avgUsedCpu: [],
        avgUnusedCpu: [],
        avgUsedMemory: [],
        avgUnusedMemory: [],
      },
    )

  const { data: chartSummariesData, isLoading } = useKubernetesSummaries(
    timeFilter,
    {
      select: transformToGraphPoints,
    },
  )

  const getTooltipHeading = useCallback(
    activePoint => {
      const date = dayjs(activePoint?.x)
      return date.isValid() ? (
        <TimeRangeFromPeriod start={date} timePeriod={timeFilter} />
      ) : (
        ''
      )
    },
    [timeFilter],
  )

  // when switching between resourceType, we need to wait for useLegend to update
  const isLegendEnabled = graphConfig.find(c => c.enabled)

  if (!isLegendEnabled) return null

  // Filter graphs based on the legend
  const filteredGraphConfig = graphConfig.map(config => {
    return {
      ...config,
      data: chartSummariesData?.[config.id as Key]!,
    }
  })

  const noData = Object.values(chartSummariesData || {}).flat().length === 0

  if (!isLoading && noData) throw new Error('No data available')

  return (
    <ChartContainer
      selectedItem={timeFilter}
      onChange={setTimeFilter}
      menuItems={timeFilterItems}
      heading={
        <KubernetesWorkloadResourceTypeHeading
          id="stream-chart-resource-type"
          label={`${durationTranslations[timeFilter]} ${t(
            'Workloads.TopCharts.Labels.Range',
          )}`}
          dropdownProps={dropdownProps}
        />
      }
    >
      <ChartWrapper>
        {isLoading ? (
          <Loading centered size="small" withOverlay={false} />
        ) : (
          <>
            <Graph
              data={filteredGraphConfig}
              unit={resourceType === 'memory' ? 'bytes' : 'cores'}
              width={640}
              height={340}
              xAxis={[
                {
                  tickFormat: (x: number) =>
                    dayjs(x).format(tickFormatMap[timeFilter]),
                },
              ]}
              yAxis={[{}]}
              containerComponent={GraphTooltipContainer(
                <GraphTooltip
                  graphConfig={filteredGraphConfig}
                  heading={getTooltipHeading}
                />,
              )}
            />
            <Legend {...legendProps} />
          </>
        )}
      </ChartWrapper>
    </ChartContainer>
  )
}

export default KubernetesWorkloadLineChart
