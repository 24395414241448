import React, { Dispatch, SetStateAction } from 'react'
import { SelectItem, TimePicker, TimePickerSelect } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export type MeridiemType = 'AM' | 'PM'

const StyledTimePicker = styled(TimePicker)`
  position: relative;
  display: flex;
  width: 100%;
  cursor: pointer;

  .cds--time-picker {
    &__input {
      width: 100%;
    }
    &__select {
      width: fit-content;
    }
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  .cds--select-input {
    display: flex;
    justify-content: end;
  }

  .cds--select__arrow {
    display: none;
  }

  &::-webkit-datetime-edit-ampm-field {
    display: none;
  }
`
export interface TimeInputProps {
  id: string
  label: string
  time: string
  setTime: Dispatch<SetStateAction<string>>
  meridiem: MeridiemType
  setMeridiem: Dispatch<SetStateAction<MeridiemType>>
  valid: boolean
}

export const TimeInput = ({
  id,
  label,
  time,
  setTime,
  meridiem,
  setMeridiem,
  valid,
}: TimeInputProps) => {
  const { t } = useTranslation()

  return (
    <StyledTimePicker
      id={id}
      invalid={!valid}
      invalidText={t('DateTimeRangePicker.InvalidText')}
      labelText={label}
      onChange={e => setTime(e.target.value)}
      pattern="/^(1[012]|0?[1-9]):[0-5][0-9]$/"
      value={time}
    >
      <TimePickerSelect
        id="time-picker-select-1"
        value={meridiem}
        onChange={e => setMeridiem(e.target.value as MeridiemType)}
      >
        <SelectItem value="AM" text="AM" />
        <SelectItem value="PM" text="PM" />
      </TimePickerSelect>
    </StyledTimePicker>
  )
}
