import styled, { css } from 'styled-components'

const disabledStyles = css`
  > button[disabled] {
    border-color: transparent;
    background: transparent;
    color: var(--cn-disabled-text-on-accent) !important;
  }
`

export const TableBatchActionWrapper = styled.div<{
  $disabled: boolean | undefined
}>`
  // We need this to make sure the tooltip can be triggered on the whole button
  cursor: ${$disabled => ($disabled ? 'not-allowed' : 'pointer')};

  ${$disabled => ($disabled ? disabledStyles : '')};
`
