import { BareMetalServer } from '@carbon/react/icons'
import { ReactComponent as AmazonIcon } from 'src/images/AWS.svg'
import { ReactComponent as GoogleCloudIcon } from 'src/images/GCP.svg'
import { ReactComponent as MicrosoftIcon } from 'src/images/Microsoft.svg'
import { ReactComponent as OracleCloudIcon } from 'src/images/OCI.svg'
import { Text } from 'src/next/components/Text'

interface CSPIconProps {
  type?: string
}

const CSPIcon = ({ type, ...props }: CSPIconProps) => {
  switch (type) {
    case 'aws':
      return <AmazonIcon {...props} />
    case 'gcp':
      return <GoogleCloudIcon {...props} />
    case 'azure':
      return <MicrosoftIcon {...props} />
    case 'oci':
      return <OracleCloudIcon {...props} />
    case 'baremetal':
      return <BareMetalServer {...props} />
    default:
      return null
  }
}

const getCSPText = (type: string) => {
  switch (type) {
    case 'aws':
      return 'AWS'
    case 'gcp':
      return 'GCP'
    case 'azure':
      return 'Azure'
    case 'oci':
      return 'OCI'
    case 'baremetal':
      return 'Baremetal'
    default:
      return null
  }
}

interface CSPProps {
  csp: string
}

export const CSP = ({ csp }: CSPProps) => {
  const icon = CSPIcon({ type: csp }) && <CSPIcon type={csp} />

  return <Text icon={icon}>{getCSPText(csp)}</Text>
}
