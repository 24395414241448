import { ClusterIcon } from 'src/next/components/CSP/CSP'
import { Text } from 'src/next/components/Text'

interface RegionProps {
  region: string
  csp?: string // Cluster Service Provider, e.g. aws, gcp
}

export const Region = ({ region, csp }: RegionProps) => {
  if (!region) {
    return null
  }

  return <Text icon={<ClusterIcon type={csp} />}>{region}</Text>
}
