import { useOrgSelection } from 'src/next/hooks'

interface UseRequestParamsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request?: Record<string, any>
  addOrgFilter?: boolean
}

// @todo: see if we need to cache or optimize this
export const useRequestParams = ({
  request = {},
  addOrgFilter = false,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseRequestParamsProps): Record<string, any> => {
  const [orgUuid] = useOrgSelection()

  if (addOrgFilter) {
    request['filter.orgUuid'] = orgUuid
  }

  return flattenObj(request)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const flattenObj = (obj: any): any => {
  const result = {}

  for (const i in obj) {
    if (
      typeof obj[i] !== 'undefined' &&
      typeof obj[i] === 'object' &&
      !Array.isArray(obj[i])
    ) {
      const tmp = flattenObj(obj[i])
      for (const j in tmp) {
        // @ts-ignore
        result[`${i}.${j}`] = tmp[j]
      }
    } else if (Array.isArray(obj[i]) && obj[i].length == 0) {
      // Do not bring an empty array to the result.
      // It will end up with sending as if it has
      // a single element like [""].
      // See CNATIX-3690.
    } else {
      // @ts-ignore
      result[i] = obj[i]
    }
  }
  return result
}
