/* eslint-disable react/no-unknown-property */
import { PiggyBank, DataVis_1 } from '@carbon/react/icons'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { OptimizationPlan } from '@cloudnatix-types/dashboard'
import { Link, Typography } from 'src/next/components'
import { Text } from 'src/next/components/Text'
import { currencyFormat } from 'src/next/utils'

export const Container = styled.div`
  position: relative;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
`

export const Left = styled.div`
  flex: 1;
  display: flex;
  min-width: 70%;
  flex-direction: column;
  background: var(--cds-layer-02);
  padding: var(--cds-spacing-05);
  gap: var(--cds-spacing-05);
`

export const Right = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: var(--cds-layer-hover-02);
  width: 30%;
  min-width: 200px;
  padding: var(--cds-spacing-05);
  align-items: center;
  justify-content: center;
  gap: var(--cds-spacing-05);
`

const getRecommendationLink = (
  id: string | undefined,
  type: string | undefined,
) => {
  if (type === 'cluster-rightscaler') {
    return `/app/clusters/${id}?tabs=recommendations`
  }

  if (type === 'cluster-rightscaler-spot') {
    return `/app/clusters/${id}?tabs=spot-recommendations`
  }

  return `/app/workload/${encodeURIComponent(id!)}?tabs=recommendations`
}

const getResourceIcon = (type?: string) => {
  switch (type) {
    case 'cluster-rightscaler':
      // eslint-disable-next-line react/jsx-pascal-case
      return <DataVis_1 />
    default:
      return null
  }
}

export interface InsightsCardProps {
  plan: OptimizationPlan
}

export const InsightsCard = ({ plan }: InsightsCardProps) => {
  const { t } = useTranslation()

  const location = useLocation()

  if (!plan.resource) {
    return null
  }

  const { id, type } = plan.resource

  const recommendationLink = getRecommendationLink(id, type)
  const title = plan.name?.split(' Type (')[0]

  const description = plan.description

  const linkState = {
    pathname: location.pathname,
    search: location.search,
  }

  return (
    <Container>
      <Left>
        <Text icon={<PiggyBank />}>
          <Typography
            variant="productive-heading-03"
            style={{ lineHeight: 1.1, marginTop: '-2px' }}
          >
            {title}
          </Typography>
        </Text>
        <Text icon={getResourceIcon(plan.resource.type)}>
          <Typography
            variant="productive-heading-02"
            style={{ lineHeight: 1.1 }}
          >
            {plan.resource.name}
          </Typography>
        </Text>

        <Link to={recommendationLink} state={linkState}>
          {t('Common.Recommendation')}: {description}
        </Link>
      </Left>
      <Right>
        <Typography
          variant="productive-heading-03"
          css={css`
            font-weight: bold;
          `}
        >
          {t('PlannerCard.cost_saving')}
        </Typography>
        <div
          css={css`
            width: 100%;
            text-align: center;
          `}
        >
          <h3
            css={css`
              font-weight: bold;
            `}
          >
            {currencyFormat(plan.metrics?.projectedSaving || 0)}
          </h3>
        </div>
      </Right>
    </Container>
  )
}
