import React from 'react'
import { LoginCallback, useOktaAuth } from '@okta/okta-react'
import * as Sentry from '@sentry/react'
import { Navigate, Outlet, Route } from 'react-router-dom'

import { AuthClient } from 'src/auth/AuthClient'
import Loading from 'src/next/components/Loading'
import { SentryRoutes } from 'src/next/utils/sentry'

import { AppContainer } from './AppContainer'
import { SignIn } from './SignIn'

export const AppRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<SignIn />} />
      <Route
        path="/implicit/callback/"
        element={<LoginCallback errorComponent={ErrorRedirect} />}
      />
      <Route path="/app/*" element={<RequiredAuth />}>
        <Route path="*" element={<AppContainer />} />
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<SignIn />} />
    </SentryRoutes>
  )
}

const RequiredAuth = () => {
  const { authState } = useOktaAuth()

  if (!authState) {
    return <Loading withOverlay={false} centered />
  }

  if (!authState.isAuthenticated) {
    return <Navigate to={{ pathname: '/' }} />
  }

  return <Outlet />
}

const Logout = () => {
  AuthClient().authClient.signOut()
  return null
}

const ErrorRedirect = ({ error }: { error: Error }) => {
  Sentry.captureException(error)
  return <Navigate to={{ pathname: '/' }} />
}
