import prettyBytes, { Options } from 'pretty-bytes'
import i18n from '../../App/i18n'

export const bytesToUserFriendlySize = (
  bytes: number | string,
  { fallback, ...options }: Options & { fallback?: string } = {},
) => {
  const _bytes = Number(bytes)

  if (isNaN(_bytes)) {
    return fallback || i18n.t<string>('Common.NA', 'N/A')
  }

  return prettyBytes(_bytes, { binary: true, ...options })
}
