// Identical to Victory's Portal component*, except the rendered element is a
// regular <div> in stead of an <svg>, so that the tooltip is not scaled to its viewbox:
// *https://github.com/FormidableLabs/victory/blob/v36.2.0/packages/victory-core/src/victory-portal/portal.js

import React from 'react'
import { keys } from 'lodash'
import { VictoryPortalProps } from 'victory'

export default class Portal extends React.Component<VictoryPortalProps> {
  static displayName = 'CnVictoryPortal'

  map: any

  index: number

  constructor(props: VictoryPortalProps) {
    super(props)

    this.map = {}
    this.index = 1
    this.portalUpdate = this.portalUpdate.bind(this)
    this.portalRegister = this.portalRegister.bind(this)
    this.portalDeregister = this.portalDeregister.bind(this)
  }

  portalRegister() {
    return ++this.index
  }

  portalUpdate(key: any, element: any) {
    this.map[key] = element
    this.forceUpdate()
  }

  portalDeregister(key: any) {
    delete this.map[key]
    this.forceUpdate()
  }

  getChildren() {
    return keys(this.map).map(key => {
      const el = this.map[key]
      return el ? React.cloneElement(el, { key }) : el
    })
  }

  render() {
    // removed wrapping <svg> element
    return this.getChildren()
  }
}
