import React from 'react'
import { ComboBox, SelectSkeleton } from '@carbon/react'
import { ComboBoxProps } from '@carbon/react/lib/components/ComboBox/ComboBox'
import { useController, useFormContext } from 'react-hook-form'
import { Optional } from 'src/next/types/helpers'
import { useTableFilter } from '../TableFilterContext'

export interface Props<ItemType = string>
  extends Optional<ComboBoxProps<ItemType>, 'onChange'> {
  id: string
  isLoading?: boolean
}

export const TableFilterDropdown = <ItemType = string,>({
  id,
  isLoading = false,
  items,
  ...props
}: Props<ItemType>): JSX.Element => {
  const { control, watch } = useFormContext()
  const { field } = useController({
    control,
    name: id,
  })
  const { contextId } = useTableFilter()

  const selectedId = watch(id) || ''

  return isLoading ? (
    <SelectSkeleton hideLabel />
  ) : (
    <ComboBox
      id={`${contextId}-${id}`}
      selectedItem={selectedId}
      items={items}
      onChange={({ selectedItem }) => {
        field.onChange(selectedItem)
      }}
      {...props}
    />
  )
}
