import { useEffect, useState } from 'react'
import './useBreakpoint.css'

/*
  Import the breakpoints directly from the CSS values in the DOM, simple and lightweight. More info:
  https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
 */

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<string | undefined>()
  const isMobile = breakpoint === 'smartphone'
  const isTablet = breakpoint === 'tablet'
  const isDesktop = breakpoint === 'desktop'

  useEffect(() => {
    const handleResize = () => {
      const body: HTMLBodyElement | null = document.querySelector('body')

      if (body) {
        const breakpoint = window
          .getComputedStyle(body, ':before')
          .getPropertyValue('content')
          .replace(/"/g, '')
        setBreakpoint(breakpoint)
      }
    }

    window.addEventListener('resize', handleResize)

    // Execute once to update the initial value:
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { breakpoint, isMobile, isTablet, isDesktop }
}

export default useBreakpoint
