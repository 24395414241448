import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'src/next/components'
import { ContentSwitcher, Switch } from 'src/next/components/ContentSwitcher'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { ClustersCompareChart } from './ClustersCompareChart'
import { ClustersSummaryChart } from './ClustersSummaryChart'

type ChartMode = 'summary' | 'comparison'

export const ClustersOverviewChart = () => {
  const { t } = useTranslation()

  const [chartMode, setChartMode] = useLocalStorage<ChartMode>(
    'clusters-overview-chartmode',
    'summary',
  )

  return (
    <Box position="relative">
      <Box width={250} position="absolute" top="4px">
        <ContentSwitcher
          size="sm"
          selectedName={chartMode}
          onChange={({ name }) => setChartMode(name as ChartMode)}
        >
          <Switch name="summary" text={t('Common.Summary')} />
          <Switch name="comparison" text={t('Common.Comparison')} />
        </ContentSwitcher>
      </Box>
      {chartMode === 'summary' ? (
        <ClustersSummaryChart />
      ) : (
        <ClustersCompareChart />
      )}
    </Box>
  )
}
