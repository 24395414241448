import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useClusters } from 'src/api'
import {
  TableFilter,
  TableFilterAccordion,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterCheckbox,
  TableFilterCheckboxGroup,
  TableFilterDropdown,
  TableFilterSearchSelection,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { TableCSPFilter } from 'src/next/components/Filters/TableCSPFilter'

export const ResourcesTableFilters = () => {
  const { t } = useTranslation()

  const { isOpen } = useTableFilter()

  const { data: clusters, isLoading: isClustersLoading } = useClusters({
    enabled: isOpen,
  })

  const clusterNames = useMemo(() => {
    return clusters?.clusters?.map(c => c.name || c.uuid!) || []
  }, [clusters])

  return (
    <TableFilter>
      <TableFilterActiveItems />
      <TableFilterAccordion>
        <TableFilterAccordionItem title={t('Organizations.Type')}>
          <TableFilterCheckboxGroup group="type" singleSelection>
            <TableFilterCheckbox id="CLUSTER" labelText={t('Common.Cluster')} />
            <TableFilterCheckbox
              id="COMPUTE_INSTANCE"
              labelText={t('Common.ComputeInstance')}
            />
          </TableFilterCheckboxGroup>
        </TableFilterAccordionItem>

        <TableFilterAccordionItem title={t('Common.Region')}>
          <TableFilterSearchSelection
            id="region"
            placeholder={t('Common.Region')}
          />
        </TableFilterAccordionItem>

        <TableFilterAccordionItem title={t('Common.Cluster')}>
          <TableFilterDropdown
            id="clusterName"
            placeholder={t('ConnectCluster.SelectCluster')}
            items={clusterNames}
            isLoading={isClustersLoading}
          />
        </TableFilterAccordionItem>

        <TableCSPFilter
          clusters={clusters?.clusters || []}
          isLoading={isClustersLoading}
          group="csp"
          singleSelection
        />
      </TableFilterAccordion>
    </TableFilter>
  )
}
