/* eslint-disable react/display-name */
import React from 'react'
import { Tag as CarbonTag } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ManagementResourceLabel } from '@cloudnatix-types/dashboard'
import ShowMore from 'src/next/components/wrappers/ShowMore'
import LabelPopover from './WorkloadLabelPopover'

export const Tag = styled(CarbonTag)`
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--cds-border-inverse);
  }
`

interface LabelProps {
  children: any
  'data-testid'?: string
}

// todo: fix showmore to render object?
const getLabel = (props: LabelProps) => (onClick: () => void) =>
  <Tag type="gray" size="sm" onClick={onClick} {...props} />

interface WorkloadLabelsProps {
  labels: Record<string, string> | ManagementResourceLabel[]
}

export const WorkloadLabels = ({ labels }: WorkloadLabelsProps) => {
  const { t } = useTranslation()

  const visibleLabels = 2
  const nrOfLabels = Object.keys(labels).length

  if (!Array.isArray(labels)) {
    labels = Object.entries(labels).map(([key, value]) => ({ key, value }))
  }

  return (
    <div>
      <ShowMore
        maxItems={visibleLabels}
        expandLabel={getLabel({
          children: `+${nrOfLabels - visibleLabels}`,
          'data-testid': 'show-all-labels',
        })}
        collapseLabel={getLabel({
          children: t('Workloads.VMTable.Labels.HideLabels', 'Hide labels'),
        })}
      >
        {labels.map(({ key, value }) => (
          <LabelPopover key={key} labelKey={key || ''} value={value} />
        ))}
      </ShowMore>
    </div>
  )
}
