import {
  SubscriptionBannerTypes,
  useSubscriptionBanner,
} from './useSubscriptionBanner'

interface SubscriptionBannerProps {
  type: SubscriptionBannerTypes
}

export const SubscriptionBanner = ({ type }: SubscriptionBannerProps) => {
  const { Banner } = useSubscriptionBanner(type)

  return <Banner />
}
