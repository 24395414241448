import React, { createContext, ReactNode } from 'react'
import { RadioButtonGroup, RadioButtonGroupProps } from '@carbon/react'
import { Controller, useFormContext } from 'react-hook-form'

// fixme: check if we can use the form context ??
export type TableFilterRadioButtonGroupContextProps = string
export const TableFilterRadioButtonGroupContext = createContext<
  TableFilterRadioButtonGroupContextProps | undefined
>(undefined)
TableFilterRadioButtonGroupContext.displayName =
  'TableFilterRadioButtonGroupContext'

interface TableFilterRadioButtonGroupProps
  extends Omit<RadioButtonGroupProps, 'name' | 'valueSelected'> {
  id: string
  children: ReactNode
}

export const TableFilterRadioButtonGroup = ({
  children,
  id,
  defaultSelected,
  ...props
}: TableFilterRadioButtonGroupProps) => {
  const { control } = useFormContext()

  return (
    <TableFilterRadioButtonGroupContext.Provider value={id}>
      <Controller
        defaultValue={defaultSelected}
        control={control}
        name={id}
        render={({ field: { onChange, value } }) => (
          <RadioButtonGroup
            name={id}
            onChange={(_value, _id, event) => onChange(event)}
            valueSelected={value}
            orientation="vertical"
            {...props}
          >
            {children}
          </RadioButtonGroup>
        )}
      />
    </TableFilterRadioButtonGroupContext.Provider>
  )
}
