import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Close } from '@carbon/react/icons'

export interface SidebarProps {
  width?: string
  open: boolean
  setIsOpen: React.Dispatch<boolean>
  children: ReactElement | ReactElement[]
  className?: string
}

const StyledSidebar = styled.div<{ $open?: boolean; $width?: string | number }>`
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  min-width: ${({ $width }) => ($width ? $width : '250px')};
`

const StyledCloseContainer = styled.div`
  display: flex;
  justify-content: end;
  cursor: pointer;
  margin-bottom: var(--cds-spacing-09);
`

const StyledCloseButton = styled(Close)`
  &:active {
    outline: 2px solid var(--cds-focus, #0f62fe);
  }
`

export const Sidebar = ({
  width,
  open,
  children,
  setIsOpen,
  className,
}: SidebarProps) => {
  console.log(className)
  return (
    <StyledSidebar className={className} $open={open} $width={width}>
      <StyledCloseContainer>
        <StyledCloseButton size={24} onClick={() => setIsOpen(false)} />
      </StyledCloseContainer>

      <div>{children}</div>
    </StyledSidebar>
  )
}
