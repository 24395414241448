import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import {
  UpdateOptimizationPlanRequest,
  UpdateOptimizationPlanResponse,
} from '@cloudnatix-types/costengine'
import { mutateData } from 'src/api'

interface GRPCStatus {
  message: string
}

export const useUpdateOptimizationPlan = (
  uuid: string,
  options?: UseMutationOptions<
    UpdateOptimizationPlanResponse,
    AxiosResponse<GRPCStatus>,
    UpdateOptimizationPlanRequest
  >,
) => {
  const url = `/v1/optimizationplans/${uuid}`

  return useMutation(
    (data: UpdateOptimizationPlanRequest) =>
      mutateData<UpdateOptimizationPlanResponse, UpdateOptimizationPlanRequest>(
        url,
        data,
        'put',
      ),
    {
      ...options,
    },
  )
}
