import { useTranslation } from 'react-i18next'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'

export interface MetricDropdownItem {
  id: 'cpu' | 'memory' | 'gpu' | 'gpuMemory'
  label: string
}

export const usePersistentMetricDropdown = (id: string) => {
  const { t } = useTranslation()

  const metricDropdownItems: MetricDropdownItem[] = [
    {
      id: 'cpu',
      label: t('Common.CPU'),
    },
    {
      id: 'memory',
      label: t('Common.Memory'),
    },
    {
      id: 'gpu',
      label: t('Common.GPU'),
    },
    {
      id: 'gpuMemory',
      label: t('Common.GPUMemory'),
    },
  ]

  return usePersistentDropdown<MetricDropdownItem, MetricDropdownItem['id']>(
    id,
    metricDropdownItems,
  )
}
