import React, { useEffect, useLayoutEffect, useRef } from 'react'
import {
  FilterableMultiSelect,
  FilterableMultiSelectProps,
  SelectSkeleton,
} from '@carbon/react'
import { useController, useFormContext } from 'react-hook-form'
import { Optional } from 'src/next/types/helpers'
import { useTableFilter } from '../TableFilterContext'

export interface TableFilterFilterableMultiSelectProps
  extends Optional<FilterableMultiSelectProps, 'onChange'> {
  id: string
  isLoading?: boolean
  itemToString?: any
  itemsToStrings?: any
  stringsToItems?: any
}

export const TableFilterFilterableMultiSelect = ({
  id,
  isLoading = false,
  itemToString = item => item,
  itemsToValues = items => items,
  stringsToItems = strings => strings,
  ...props
}: TableFilterFilterableMultiSelectProps) => {
  const { control, watch } = useFormContext()
  const { field } = useController({
    control,
    name: id,
  })
  const currentItems = watch(id) || []

  // register the defaultValue only on initial render
  const { contextId, registerDefaultValue } = useTableFilter()
  // eslint-disable-next-line react-hooks/exhaustive-deps, prettier/prettier
  useEffect(() => {
    registerDefaultValue(id, [])
  }, [])

  // Unlike `MultiSelect` Carbon's `FilterableMultiSelect` does not accept a
  // `selectedItems`-prop. This means we have no (full) control over the
  // component's state. To work around this we set the `initialSelectedItems`
  // and fully rerender the component by changing the `key` when the selected
  // items change, as suggested here:
  // (https://github.com/carbon-design-system/carbon/issues/9151#issuecomment-882804030)
  // This way the component's state stays up-to-date, also when a filter is
  // removed under 'Active filters'

  const inputRef = useRef<FilterableMultiSelect<string>>(null)
  const isOpenRef = useRef(false)
  useLayoutEffect(() => {
    if (isOpenRef.current) {
      ;(inputRef.current as any)?.textInput?.current?.focus?.()
      isOpenRef.current = false
    }
  })

  return isLoading ? (
    <SelectSkeleton hideLabel />
  ) : (
    <FilterableMultiSelect
      id={`${contextId}-${id}`}
      key={currentItems.join(',')}
      ref={inputRef}
      downshiftProps={{
        stateReducer: (state: any, changes: any) => {
          switch (changes.type) {
            case '__autocomplete_keydown_enter__':
            case '__autocomplete_click_item__':
              isOpenRef.current = true
              return { ...state, ...changes, isOpen: true }

            default:
              return { ...state, ...changes }
          }
        },
      }}
      initialSelectedItems={stringsToItems(currentItems)}
      onChange={({ selectedItems }) => {
        field.onChange(itemsToValues(selectedItems))
      }}
      itemToString={itemToString}
      {...props}
    />
  )
}
