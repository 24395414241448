import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import {
  ListManagementResourcesRequest,
  ListManagementResourcesResponse,
} from '@cloudnatix-types/dashboard'
import { fetchData } from 'src/api/axios'
import { useRequestParams } from 'src/api/useRequestParams'

export const useManagementResources = (
  request: ListManagementResourcesRequest,
  options?: UseInfiniteQueryOptions<ListManagementResourcesResponse, Error>,
) => {
  const url = '/v1/management/resources'

  const params = useRequestParams({ request })

  return useInfiniteQuery<ListManagementResourcesResponse, Error>(
    [url, params],
    ({ pageParam }) => {
      return fetchData<ListManagementResourcesResponse>(url, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
      ...options,
    },
  )
}
