import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import {
  ListClustersRequest,
  ListClustersResponse,
} from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'

const url = '/v1/dashboard/clusters'

export const useClusters = <T = ListClustersResponse>(
  options?: UseQueryOptions<ListClustersResponse, Error, T>,
  request?: ListClustersRequest,
) => {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<ListClustersResponse, Error, T>(
    [url, params],
    () => fetchData<ListClustersResponse>(url, params),
    {
      staleTime: 60 * 60 * 1000,
      ...options,
    },
  )
}

export const useClustersPaginated = (
  options?: UseInfiniteQueryOptions<
    ListClustersResponse,
    Error,
    ListClustersResponse
  >,
  request?: ListClustersRequest,
) => {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useInfiniteQuery<ListClustersResponse, Error, ListClustersResponse>(
    [url, params],
    ({ pageParam }) =>
      fetchData<ListClustersResponse>(url, { ...params, pageToken: pageParam }),
    {
      ...options,
      getNextPageParam: lastPage => lastPage.nextPageToken,
    },
  )
}

export const useCluster = (id: string) =>
  useClusters({
    select: data => data?.clusters?.find(c => c.uuid === id),
  })
