import { ListItem } from '@carbon/react'
import styled from 'styled-components'

export const Padding = styled.div`
  padding: var(--cds-spacing-04) var(--cds-spacing-05) 0;
`

export const StyledListItem = styled(ListItem)`
  color: var(--cds-text-color--dark);
`
