import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { ChevronDown } from '@carbon/react/icons'
import * as Popover from '@radix-ui/react-popover'
import styled from 'styled-components'
import Typography from '../../Typography'

const StyledTimeItem = styled.div<{ $active: boolean }>`
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  width: 100%;
  &:hover {
    background: var(--cds-background-hover);
  }

  ${({ $active }) => $active && 'background: var(--cds-background-hover);'}
`

const StyledPopoverTrigger = styled(Popover.Trigger)`
  background-color: transparent;
  height: 100%;
  border: none;
  cursor: pointer;

  color: var(--cds-icon-primary);

  &:hover {
    background: var(--cds-background-hover);
  }

  &:active {
    outline: 2px solid var(--cds-focus, #0f62fe);
  }
`

const StyledContent = styled(Popover.Content)`
  display: flex;
  flex-direction: column;
  background-color: var(--cds-layer-01);
  max-height: 15rem;
  outline: 2px solid var(--cds-focus, #0f62fe);
  overflow-y: scroll;
`

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  gap: var(--cds-spacing-03);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export interface DropdownProps {
  items: { id: string; label: string }[]
  setItem: Dispatch<SetStateAction<any>>
  selectedItem: any
  heading: string
}

export const Dropdown = ({
  items,
  setItem,
  selectedItem,
  heading,
}: DropdownProps) => {
  const [openMenu, setOpenMenu] = useState(false)
  const triggerRef = useRef<any>(null)

  return (
    <Popover.Root open={openMenu}>
      <StyledPopoverTrigger
        ref={triggerRef}
        aria-label="calendar-icon-button"
        type="button"
        onClick={() => {
          setOpenMenu(!openMenu)
        }}
      >
        <StyledTypography variant="body-long-01">
          {items.find(item => item.id === selectedItem)?.label || heading}
          <ChevronDown
            size={20}
            width={20}
            css={`
              transform: rotate(${openMenu ? '0.5' : '0'}turn);
              transition-duration: 0.2s;
            `}
          />
        </StyledTypography>
      </StyledPopoverTrigger>
      <Popover.PopoverPortal>
        <StyledContent
          style={{ boxShadow: '0 2px 12px var(--cds-shadow)' }}
          onOpenAutoFocus={e => e.preventDefault()}
          onInteractOutside={e => {
            e.preventDefault()
            if (
              triggerRef &&
              triggerRef.current &&
              !triggerRef.current.contains(e.target)
            ) {
              setOpenMenu(!openMenu)
            }
          }}
        >
          {items.map(item => {
            return (
              <StyledTimeItem
                onClick={() => {
                  setItem(item.id)
                  setOpenMenu(false)
                }}
                $active={selectedItem === item.id}
                key={item.id}
              >
                {item.label}
              </StyledTimeItem>
            )
          })}
        </StyledContent>
      </Popover.PopoverPortal>
    </Popover.Root>
  )
}
