import React from 'react'
import { Column, Grid, Row } from '@carbon/react'
import { ErrorBoundary } from 'react-error-boundary'
import { Box } from 'src/next/components'
import { CollapseComponent } from 'src/next/components/ui'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { KubernetesWorkloadDonutChart } from './KubernetesWorkloadDonutChart'
import { KubernetesWorkloadStackedBarChart } from './KubernetesWorkloadStackedBarChart'
import KubernetesWorkloadLineChart from './KubernetesWorkloadLineChart'
import { css } from 'styled-components'

export const KubernetesWorkloadCharts = () => {
  const [showCharts, setShowCharts] = useLocalStorage(
    'show-workloads-charts',
    true,
  )

  return (
    /**
     * the usecase now is that some customers don't have k8s workloads, hide all charts on error
     * todo: finegrained error handling, once CNATIX-2298 is in place
     * // https://cloudnatix.atlassian.net/browse/CNATIX-2298
     */
    <ErrorBoundary
      onError={() => setShowCharts(false)}
      fallbackRender={() => <></>}
    >
      <Box position="relative">
        <CollapseComponent show={showCharts}>
          <Grid
            fullWidth
            data-testid="workload-charts"
            css={css`
              padding-top: var(--cds-spacing-04);
            `}
          >
            <Column sm={16} lg={5}>
              <KubernetesWorkloadDonutChart />
            </Column>
            <Column sm={16} lg={5}>
              <KubernetesWorkloadLineChart />
            </Column>
            <Column sm={16} lg={6}>
              <KubernetesWorkloadStackedBarChart />
            </Column>
          </Grid>
        </CollapseComponent>
      </Box>
    </ErrorBoundary>
  )
}
