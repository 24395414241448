import React from 'react'
import { OverflowMenuProps } from '@carbon/react'
import { OverflowMenuItemWithCheck } from 'src/next/components/OverflowMenu'
import { Toolbar } from 'src/next/components/Toolbar/Toolbar'

interface ToggleOverflowMenuItems {
  id: string
  label: string
}

export interface ToolbarToggleOverflowMenuProps
  extends Omit<OverflowMenuProps, 'children'> {
  items: ToggleOverflowMenuItems[]
  selectedItemId: string
  setSelectedItemId: (id: string) => void
}

export const ToolbarToggleOverflowMenu = ({
  items,
  selectedItemId,
  setSelectedItemId,
  ...props
}: ToolbarToggleOverflowMenuProps) => {
  return (
    <Toolbar.OverflowMenu {...props}>
      {items.map(item => (
        <OverflowMenuItemWithCheck
          key={item.id}
          id={item.id}
          label={item.label}
          checked={item.id === selectedItemId}
          onClick={setSelectedItemId}
        />
      ))}
    </Toolbar.OverflowMenu>
  )
}
