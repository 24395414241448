import { Tag as CarbonTag } from '@carbon/react'
import styled from 'styled-components'

export const Tag = styled(CarbonTag)<any>`
  background-color: var(--cds-layer-accent-01);
  color: var(--cds-layer-selected-inverse);

  button.cds--tag__close-icon:hover {
    background-color: var(--cds-toggle-off);
  }
`
