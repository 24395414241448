import React, { ReactNode, useEffect, useImperativeHandle, useRef } from 'react'
import {
  DataTable as CarbonDataTable,
  DataTableProps as CarbonDataTableProps,
  DataTableCustomRenderProps,
  DataTableRow,
  DataTableSkeleton,
  DenormalizedRow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandedRow,
  TableExpandHeader,
  TableExpandRow,
  TableHead,
  TableRow,
  TableSelectAll,
  TableSelectRow,
} from '@carbon/react'
import clsx from 'clsx'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { DataTableHeaders as DataTableHeadersProps } from 'src/next/types/dataTable'
import { DataTableHeaders } from './DataTableHeaders'
import { DataTableStyles } from './DataTableStyles'
import DataTableToolbar, {
  BatchAction,
  ToolbarMenuItem,
} from './DataTableToolbar'
import { UseOrderByProps } from './useOrderBy'

interface CustomRowProps extends DataTableRow {
  onClick?(val: { id?: string }): void
}

export interface DataTableProps
  extends Omit<CarbonDataTableProps<CustomRowProps>, 'headers'> {
  headers: DataTableHeadersProps
  isLoading?: boolean
  onExpand?: (id: string) => ReactNode
  orderBy?: UseOrderByProps
  pageSize?: number
  setPage?: (page: number) => void
  settings?: ToolbarMenuItem[]
  size?: 'xs' | 'sm' | 'md' | 'xl'
  /** storageKey is used to store user customizations for this table (e.g. filter) in local storage */
  storageKey: string
  toolbar?: ReactNode
  batchActions?: BatchAction[]
  onRowSelect?: (selectedRows: DenormalizedRow[]) => void
  primaryAction?: ReactNode
  radio?: boolean
  showToolbar?: boolean
}

interface DataTableRenderProps
  extends Omit<DataTableCustomRenderProps, 'headers'> {
  headers: DataTableHeadersProps
}

const DataTable = (
  {
    headers,
    isLoading,
    onExpand,
    orderBy,
    pageSize,
    rows: rowsProp,
    setPage,
    settings,
    size = 'md',
    storageKey,
    toolbar,
    batchActions,
    primaryAction,
    onRowSelect,
    showToolbar = true,
    ...props
  }: DataTableProps,
  ref: React.ForwardedRef<{ deselectAll: () => void }>,
) => {
  const [visibleHeaders, setVisibleHeaders] = useLocalStorage(
    `${storageKey}-visible-headers`,
    new Array(headers.length).fill(true),
  )

  useEffect(() => {
    setVisibleHeaders(
      headers.map(header =>
        // If defaultVisiblity is not set, should be visible by default.
        header.defaultVisibility === undefined
          ? true
          : header.defaultVisibility,
      ),
    )
    // The following dependencies are not exhausting intentionally. Still not sure
    // why, but having setVisibleHeaders as a dependency will cause an infinite
    // loop of updating visibleHeaders.  Also use JSON.stringify to stabilize the
    // dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(headers)])

  const headerConfig = [...headers]

  // attach imperative handle to forwarded ref, so that we can deselect the
  // table rows in a parent component
  const batchActionPropsRef = useRef<any>()
  useImperativeHandle(ref, () => ({
    deselectAll: () => batchActionPropsRef?.current.onCancel(),
  }))

  // store mouse position on table row mouse down, for checking if the user dragged
  const mousePosRef = useRef<[number, number]>([0, 0])
  const handleTableExpandRowMouseDown = ({
    clientX,
    clientY,
  }: React.MouseEvent) => (mousePosRef.current = [clientX, clientY])

  const handleTableExpandRowMouseUp = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    onExpandFn: any,
  ) => {
    const { clientX, clientY } = event
    const [prevClientX, prevClientY] = mousePosRef.current
    const isInteractiveElement =
      event.target instanceof Element &&
      !!event.target.closest(
        'button, a, input, label, [data-prevent-row-expand]',
      )

    if (
      // don't expand when the user is dragging (for example selecting text).
      // Allow for 5px grace movement
      Math.abs(prevClientX - clientX) < 5 &&
      Math.abs(prevClientY - clientY) < 5 &&
      // don't expand when the user clicked an interactive element (including label for checkboxes)
      !isInteractiveElement
    ) {
      onExpandFn?.(event)
    }

    mousePosRef.current = [0, 0]
  }

  return (
    <DataTableStyles>
      {/* @ts-ignore */}
      <CarbonDataTable
        rows={rowsProp}
        headers={headerConfig.filter((_, i) => visibleHeaders[i])}
        isSortable
        {...props}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getRowProps,
          getSelectionProps,
          getBatchActionProps,
          getToolbarProps,
          getTableContainerProps,
          selectedRows,
        }: DataTableRenderProps) => {
          onRowSelect?.(selectedRows as DenormalizedRow[])

          // attach batch action props to forwarded ref (see comment above about `useImperativeHandle`)
          if (ref) batchActionPropsRef.current = getBatchActionProps()

          return (
            <TableContainer {...getTableContainerProps()}>
              {/* IMPORTANT: Keep the toolbar skeleton (above) in sync with changes to the DataTableToolbar */}
              {showToolbar ? (
                <DataTableToolbar
                  getBatchActionProps={getBatchActionProps}
                  getToolbarProps={getToolbarProps}
                  headers={headerConfig}
                  settings={settings}
                  visibleHeaders={visibleHeaders}
                  setVisibleHeaders={setVisibleHeaders}
                  toolbar={toolbar}
                  batchActions={batchActions}
                  selectedRows={selectedRows}
                  primaryAction={primaryAction}
                />
              ) : null}

              {isLoading ? (
                <DataTableSkeleton
                  showToolbar={false}
                  showHeader={false}
                  rowCount={pageSize || rows.length || 3}
                  columnCount={headers.length || 1}
                  className={clsx(size && `bx--data-table--${size}`)}
                />
              ) : (
                <Table {...getTableProps()} size={size}>
                  <TableHead>
                    <TableRow>
                      {onExpand ? <TableExpandHeader /> : null}

                      {props.radio && <th scope="col" />}

                      {batchActions ? (
                        <TableSelectAll {...getSelectionProps()} />
                      ) : null}
                      <DataTableHeaders
                        getHeaderProps={getHeaderProps}
                        headers={headers}
                        orderBy={orderBy}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {onExpand
                      ? rows.map(row => {
                          const rowProps = getRowProps({ row })

                          return (
                            <React.Fragment key={row.id}>
                              <TableExpandRow
                                onMouseDown={handleTableExpandRowMouseDown}
                                onMouseUp={event => {
                                  handleTableExpandRowMouseUp(
                                    event,
                                    rowProps.onExpand,
                                  )
                                }}
                                {...rowProps}
                              >
                                {batchActions ? (
                                  <TableSelectRow
                                    {...getSelectionProps({ row })}
                                  />
                                ) : null}
                                {row.cells.map(cell => (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                ))}
                              </TableExpandRow>
                              <TableExpandedRow colSpan={headers.length + 2}>
                                <div>
                                  {row.isExpanded ? onExpand(row.id) : null}
                                </div>
                              </TableExpandedRow>
                            </React.Fragment>
                          )
                        })
                      : rows.map(row => {
                          const { onClick, ...rowData } =
                            rowsProp.find(
                              ({ id }: { id: string }) => id === row.id,
                            ) || {}

                          return (
                            <TableRow
                              key={row.id}
                              isSelected={row.isSelected}
                              onClick={() => onClick?.(rowData)}
                            >
                              {batchActions || props.radio ? (
                                <TableSelectRow
                                  {...getSelectionProps({ row })}
                                />
                              ) : null}
                              {row.cells.map((cell, i) => {
                                // Use same alignment as the header
                                const style = {
                                  textAlign: headers[i]?.style?.textAlign,
                                }

                                return (
                                  <TableCell key={cell.id} style={style}>
                                    {cell.value}
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          )
                        })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )
        }}
      </CarbonDataTable>
    </DataTableStyles>
  )
}

export default React.forwardRef(DataTable)
