/* eslint-disable react/display-name */
import React, { ReactElement } from 'react'
import { Checkmark } from '@carbon/react/icons'
import { OverflowMenuItem } from '@carbon/react'
import styled from 'styled-components'

export interface OverflowMenuItemWithCheckProps {
  id: string
  label: string | ReactElement
  checked: boolean
  onClick(id: string): void
}

const StyledOverflowMenuItem = styled(OverflowMenuItem)`
  min-width: 100%;
`

const StyledSpan = styled.div`
  display: flex;
  flex: 1;
`

const StyledText = styled.span`
  flex: 1;
`

export const OverflowMenuItemWithCheck = React.forwardRef(
  (
    { id, label, checked, onClick, ...props }: OverflowMenuItemWithCheckProps,
    ref: React.ForwardedRef<any>,
  ) => {
    return (
      <StyledOverflowMenuItem
        ref={ref}
        itemText={
          <StyledSpan>
            <StyledText>{label}</StyledText>
            {checked ? <Checkmark /> : null}
          </StyledSpan>
        }
        onClick={() => onClick(id)}
        aria-selected={checked}
        {...props}
      />
    )
  },
)
