import React, { useEffect, useState } from 'react'
import {
  Tabs,
  Tab,
  TabListProps as CarbonTabListProps,
  TabList as CarbonTabList,
  TabPanel as CarbonTabPanel,
  TabPanels,
} from '@carbon/react'
import styled from 'styled-components'

const StyledTabList = styled(CarbonTabList)`
  .cds--tabs__nav-link {
    width: auto;
    min-width: 9rem;
  }

  &:not(.cds--tabs--light) {
    .cds--tabs--__nav-item--selected {
      // In the Carbon styleguide the page background is almost black so the active
      // tab background stands out. In our app the background is the same color as
      // the active tab so we want to outline the start and end of the tab bar a bit.
      box-shadow: 1px 0 0 inset var(--cds-layer-04);

      &:first-child {
        box-shadow: 1px 0 0 inset var(--cds-layer-03);
      }

      &:last-child {
        box-shadow: -1px 0 0 inset var(--cds-layer-03) !important;
      }
    }
  }
`

export const TabPanel = styled(CarbonTabPanel)`
  padding-left: 0;
  padding-right: 0;
`

export const LazyTabPanel = ({ children, index, selectedIndex, ...rest }) => {
  const [everOpened, setEverOpened] = useState(selectedIndex === index)
  useEffect(() => {
    if (!everOpened && selectedIndex === index) setEverOpened(true)
  }, [everOpened, index, selectedIndex])
  return <TabPanel {...rest}>{everOpened ? children : null}</TabPanel>
}

export const TabList = ({
  children,
  contained = true,
  ...rest
}: CarbonTabListProps) => (
  <StyledTabList contained={contained} {...rest}>
    {children}
  </StyledTabList>
)

export { Tab, Tabs, TabPanels }
