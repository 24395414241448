import styled from 'styled-components'
import { grid, GridProps } from 'styled-system'
import { Box } from '../Box'

// TODO: Why does this give errors for all props when you use it?
//  "Element Grid doesn't have required attribute ...", See ScheduleForm.
export const Grid = styled(Box)<GridProps>`
  display: grid;
  ${grid};
`
