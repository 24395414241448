import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { UserRole as UserRoleEnum } from '@cloudnatix-types/usercontroller'
import { getUserRoleNames } from 'src/next/constants/userRoleNames'

const Role = styled.span`
  white-space: nowrap;
`

export interface UserRole {
  role: keyof typeof UserRoleEnum
  orgUuid: string
  orgName?: string
}

export interface UserRolesProps {
  roles: UserRole[] | undefined
}

export const UserRoles = ({ roles }: UserRolesProps) => {
  const { t } = useTranslation()

  const userRoleNames = getUserRoleNames(t)

  if (!roles?.length) {
    return <>---</>
  }

  return (
    <>
      {roles.map((role, i) => (
        <Role key={`role-${role.role}-${role.orgUuid}`}>
          {userRoleNames[role.role]}
          {role.orgName ? ` (${role.orgName})` : null}
          {i !== roles.length - 1 ? ', ' : ''}
        </Role>
      ))}
    </>
  )
}
