import {
  breakpoints as carbonBreakpoints,
  spacing as carbonSpacing,
} from '@carbon/layout'

type BreakpointsProp = string[] & {
  sm?: string
  md?: string
  lg?: string
  xl?: string
  xxl?: string
}

const breakpoints: BreakpointsProp = Object.values(carbonBreakpoints).map(
  breakpoint => (breakpoint as any)?.width,
)
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]
breakpoints.xxl = breakpoints[4]

export const cnxTheme = {
  breakpoints,
  space: [0, ...carbonSpacing],
  colors: {
    interactive: 'var(--cds-interactive)',
    'button-primary': 'var(--cds-button-primary)',
    'button-secondary': 'var(--cds-button-secondary)',
    'button-tertiary': 'var(--cds-button-tertiary)',
    'border-interactive': 'var(--cds-border-interactive)',
    background: 'var(--cds-background)',
    'layer-01': 'var(--cds-layer-01)',
    'layer-02': 'var(--cds-layer-02)',
    'layer-accent-01': 'var(--cds-layer-accent-01)',
    'border-subtle-01': 'var(--cds-border-subtle-01)',
    'toggle-off': 'var(--cds-toggle-off)',
    'border-strong': 'var(--cds-border-strong)',
    'border-inverse': 'var(--cds-border-inverse)',
    'layer-selected-inverse': 'var(--cds-layer-selected-inverse)',
    'text-primary': 'var(--cds-text-primary)',
    'text-secondary': 'var(--cds-text-secondary)',
    'text-placeholder': 'var(--cds-text-placeholder)',
    'text-on-color': 'var(--cds-text-on-color)',
    'text-helper': 'var(--cds-text-helper)',
    'text-error': 'var(--cds-text-error)',
    'icon-primary': 'var(--cds-icon-primary)',
    'icon-secondary': 'var(--cds-icon-secondary)',
    'icon-on-color': 'var(--cds-icon-on-color)',
    'link-primary': 'var(--cds-link-primary)',
    'link-secondary': 'var(--cds-link-secondary)',
    'link-inverse': 'var(--cds-link-inverse)',
    'field-01': 'var(--cds-field-01)',
    'field-02': 'var(--cds-field-02)',
    'icon-inverse': 'var(--cds-icon-inverse)',
    'focus-inset': 'var(--cds-focus-inset)',
    'text-inverse': 'var(--cds-text-inverse)',
    'background-inverse': 'var(--cds-background-inverse)',
    'support-success': 'var(--cds-support-sucess)',
    'support-error': 'var(--cds-support-error)',
    'support-warning': 'var(--cds-support-warning)',
    'support-info': 'var(--cds-support-info)',
    'support-success-inverse': 'var(--cds-support-sucess-inverse)',
    'support-error-inverse': 'var(--cds-support-error-inverse)',
    'support-warning-inverse': 'var(--cds-support-warning-inverse)',
    'support-info-inverse': 'var(--cds-support-info-inverse)',
    overlay: 'var(--cds-overlay)',
    'button-danger-primary': 'var(--cds-button-danger-primary)',
    'button-danger-secondary': 'var(--cds-button-danger-secondary)',
    focus: 'var(--cds-focus)',
    'focus-inverse': 'var(--cds-focus-inverse)',
    'button-primary-hover': 'var(--cds-button-primary-hover)',
    'button-primary-active': 'var(--cds-button-primary-active)',
    'link-primary-hover': 'var(--cds-link-primary-hover)',
    'button-secondary-hover': 'var(--cds-button-secondary-hover)',
    'button-secondary-active': 'var(--cds-button-secondary-active)',
    'button-tertiary-hover': 'var(--cds-button-tertiary-hover)',
    'button-tertiary-active': 'var(--cds-button-tertiary-active)',
    'background-hover': 'var(--cds-background-hover)',
    'layer-hover-01': 'var(--cds-layer-hover-01)',
    'field-hover-01': 'var(--cds-field-hover-01)',
    'field-hover-02': 'var(--cds-field-hover-02)',
    'layer-hover-02': 'var(--cds-layer-hover-02)',
    'background-selected-hover': 'var(--cds-background-selected-hover)',
    'layer-accent-hover-01': 'var(--cds-layer-accent-hover-01)',
    'layer-selected-hover-01': 'var(--cds-layer-selected-hover-01)',
    'layer-active-01': 'var(--cds-layer-active-01)',
    'background-active': 'var(--cds-background-active)',
    'layer-accent-active-01': 'var(--cds-layer-accent-active-01)',
    'border-subtle-selected-01': 'var(--cds-border-subtle-selected-01)',
    'layer-active-02': 'var(--cds-layer-active-02)',
    'layer-selected-01': 'var(--cds-layer-selected-01)',
    'background-selected': 'var(--cds-background-selected)',
    'layer-selected-02': 'var(--cds-layer-selected-02)',
    'background-inverse-hover': 'var(--cds-background-inverse-hover)',
    'button-danger-hover': 'var(--cds-button-danger-hover)',
    'button-danger-active': 'var(--cds-button-danger-active)',
    'link-visited': 'var(--cds-link-visited)',
    'text-disabled': 'var(--cds-text-disabled)',
    'icon-disabled': 'var(--cds-icon-disabled)',
    'button-disabled': 'var(--cds-button-disabled)',
    'border-disabled': 'var(--cds-border-disabled)',
    'icon-on-color-disabled': 'var(--cds-icon-on-color-disabled)',
    'layer-selected-disabled': 'var(--cds-layer-selected-disabled)',
    'text-on-color-disabled': 'var(--cds-text-on-color-disabled)',
    highlight: 'var(--cds-highlight)',
    'border-subtle-02': 'var(--cds-border-subtle-02)',
    'button-separator': 'var(--cds-button-separator)',
    'skeleton-background': 'var(--cds-skeleton-background)',
    'skeleton-element': 'var(--cds-skeleton-element)',
    'background-brand': 'var(--cds-background-brand)',
    'border-subtle': 'var(--cds-border-subtle)',
    shadow: 'var(--cds-shadow)',
    'layer-active': 'var(--cds-layer-active)',
    'layer-hover': 'var(--cds-layer-hover)',
    'field-hover': 'var(--cds-field-hover)',
    'layer-selected': 'var(--cds-layer-selected)',
    'layer-selected-hover': 'var(--cds-layer-selected-hover)',
    'border-subtle-selected': 'var(--cds-border-subtle-selected)',
    danger: 'var(--cds-danger)',
  },
} as const
