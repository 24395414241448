import React from 'react'
import { useRegisterActions } from 'kbar'
import { useNavigate } from 'react-router-dom'
import { useCLUIKubernetesResources } from 'src/api'
import { ReactComponent as KubernetesIcon } from 'src/images/logo--kubernetes.svg'
import styled from 'styled-components'

const StyledKubernetesIcon = styled(KubernetesIcon)`
  fill: var(--cds-icon-primary);
`
export const useCLUIRegisterKubernetesResources = (enabled = true) => {
  const navigate = useNavigate()

  const query = useCLUIKubernetesResources(
    {},
    {
      enabled: enabled,
    },
  )

  useRegisterActions(
    query.data?.map(resource => ({
      id: `kubernetes-resource-${resource.uid || ''}`,
      name: resource.name || '',
      subtitle: resource.namespace
        ? `${resource.namespace} / ${resource.clusterName} / ${resource.apiName}`
        : `${resource.clusterName} / ${resource.apiName}`,
      icon: <StyledKubernetesIcon />,
      parent: 'kubernetesResourceFinder',
      perform: () =>
        navigate(
          `/app/kubernetes-resources/${resource.clusterUuid}/${resource.apiName}/${resource.uid}`,
        ),
    })) || [],
    [query.data],
  )

  return query
}
