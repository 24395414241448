import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, FeatureFlag } from 'src/next/components'
import { Insights, ComputeInstanceInsights } from 'src/next/components/insights'
import { TableFilterContextProvider } from 'src/next/components/TableFilter'
import {
  LazyTabPanel,
  Tab,
  Tabs,
  TabList,
  TabPanels,
} from 'src/next/components/Tabs'
import { PageTitle } from 'src/next/components/ui'
import { useUrlSearchParams } from 'src/next/hooks'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { isProduction } from 'src/next/utils'

type InsightTypes =
  | 'kubernetesworkloads'
  | 'hpas'
  | 'clusters'
  | 'computeinstances'

interface TabType {
  id: InsightTypes
  label: string
  panel: React.ReactNode | null
}

export const InsightsPage = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [savedSelectedTabIndex, setSavedSelectedTabIndex] = useLocalStorage(
    'insights-page',
    0,
  )

  const tabs = [
    {
      id: 'kubernetesworkloads',
      label: t('Insights.Tabs.KubernetesWorkloads'),
      panel: <Insights type="kubernetesworkloads" />,
    },
    ...(isProduction() === false
      ? [
          {
            id: 'hpas',
            label: t('Insights.Tabs.HPAs'),
            panel: (
              <FeatureFlag>
                <Insights type="hpas" />
              </FeatureFlag>
            ),
          },
        ]
      : []),
    {
      id: 'clusters',
      label: t('Insights.Tabs.Clusters'),
      panel: <Insights type="clusters" />,
    },
    {
      id: 'computeinstances',
      label: t('Insights.Tabs.ComputeInstances'),
      panel: <ComputeInstanceInsights />,
    },
  ] as TabType[]

  const { tab: tabIdFromUrl } = useUrlSearchParams()

  const initiallySelectedTabIndex = tabIdFromUrl
    ? tabs.findIndex(tab => tab.id === tabIdFromUrl)
    : -1

  const [overrideInitialSelectedTab, setOverrideInitialSelectedTab] =
    useState(false)

  const selectedTabIndex =
    overrideInitialSelectedTab || initiallySelectedTabIndex === -1
      ? savedSelectedTabIndex
      : initiallySelectedTabIndex

  const handleTabClick = ({ selectedIndex }) => {
    setSavedSelectedTabIndex(selectedIndex)
    setOverrideInitialSelectedTab(true)

    const params = new URLSearchParams({ tab: tabs[selectedIndex].id })
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  return (
    <>
      <PageTitle title={t('Insights.PageTitle')} />

      <Box mb={5}>
        <Tabs selectedIndex={selectedTabIndex} onChange={handleTabClick}>
          <TabList aria-label="Insights page tabs">
            {tabs.map(tab => (
              <Tab key={tab.id}>{tab.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((tab, i) => (
              <LazyTabPanel
                key={tab.id}
                index={i}
                selectedIndex={selectedTabIndex}
              >
                <TableFilterContextProvider
                  defaultValues={{ clusterUuids: [] }}
                  defaultOpen
                >
                  {tab.panel}
                </TableFilterContextProvider>
              </LazyTabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  )
}
