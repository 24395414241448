import React from 'react'
import styled from 'styled-components'

const BarArea = styled.div`
  display: flex;
  height: 6px;
  background: var(--cds-layer-02);
  overflow: hidden;
`

interface BarSegmentProps {
  total: number
  $value: number
  $color: string
}

const BarSegment = styled.div<BarSegmentProps>`
  background: ${({ $color }) => $color};
  height: 6px;
  width: ${props => (100 / props.total) * props.$value}%;
`

export type SimpleStackedBarData = SimpleStackedBarDataItem[]

interface SimpleStackedBarDataItem {
  value: number
  label?: string
  color?: string
}

interface BarProps {
  data: SimpleStackedBarData
  total?: number
  className?: string
}

export const SimpleStackedBar = ({ data, total, className }: BarProps) => {
  const filteredData = data.filter(
    (item: SimpleStackedBarDataItem) => item.value >= 0,
  )

  const computedTotal =
    total || data.reduce((result, item) => result + item.value, 0)

  return computedTotal ? (
    <BarArea className={className}>
      {filteredData.map((item: SimpleStackedBarDataItem, i) => (
        <BarSegment
          key={i}
          $value={item.value}
          total={computedTotal}
          $color={item.color || `var(--carbonPalette${i + 1})`}
          title={item.label}
        />
      ))}
    </BarArea>
  ) : null
}
