/* eslint-disable react/display-name */
import React, { memo } from 'react'
import { HeaderGlobalBar } from '@carbon/react'
import { ChangeLanguage } from './ChangeLanguage'
import { CLUIToggle } from './CLUIToggle'
import { HeaderThemeSwitch } from './HeaderThemeSwitch'
import { HeaderUser } from './HeaderUser'

export const HeaderRightMenu = memo(() => {
  return (
    <HeaderGlobalBar>
      <CLUIToggle />
      <ChangeLanguage />
      <HeaderThemeSwitch />
      <HeaderUser />
    </HeaderGlobalBar>
  )
})
