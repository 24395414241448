/* eslint-disable @typescript-eslint/ban-types */
import { useInfiniteQuery, useMutation } from 'react-query'
import {
  ListManagementNamespacesRequest,
  ListManagementNamespacesResponse,
} from '@cloudnatix-types/dashboard'
import {
  CreateNamespaceRequest,
  Namespace,
} from '@cloudnatix-types/orgcontroller'
import { deleteData, fetchData, mutateData, useRequestParams } from 'src/api'
import { queryClient } from 'src/api/queryClient'

const url = '/v1/management/namespaces'
const crudUrl = '/v1/namespaces'

const invalidateNamespaces = () => {
  // todo: check for invalidating per org
  queryClient.invalidateQueries(url)
}

export const useNamespaces = (request?: ListManagementNamespacesRequest) => {
  const params = useRequestParams({ request: request || {} })

  return useInfiniteQuery<ListManagementNamespacesResponse, Error>(
    [url, request],
    ({ pageParam }) => {
      return fetchData<ListManagementNamespacesResponse>(url, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
    },
  )
}

export const useCreateNamespace = () =>
  useMutation(
    (data: CreateNamespaceRequest) =>
      mutateData<Namespace, typeof data>(crudUrl, data, 'post'),
    { onSuccess: invalidateNamespaces },
  )

export const useDeleteNamespace = () =>
  useMutation((id: string) => deleteData<{}>(`${crudUrl}/${id}`), {
    onSuccess: invalidateNamespaces,
  })
