import { useQuery } from 'react-query'
import { GetDownloadURLResponse } from '@cloudnatix-types/artifact-download-gateway'
import { fetchData } from 'src/api'
import { InstallCLIModalState } from './InstallCLIModalContainer'

const useCliDownloadUrl = (
  OS: InstallCLIModalState['OS'],
  Arch: InstallCLIModalState['Arch'],
  version: InstallCLIModalState['version'],
  channel: InstallCLIModalState['channel'],
  options = {},
) =>
  useQuery<GetDownloadURLResponse>(
    ['installCli', 'distributionVersions', OS, Arch, version, channel],
    async () =>
      fetchData('/v1/artifact-download-url', {
        name: 'cli',
        osArch: `${OS}-${Arch}`,
        distributionVersion: version,
        channel,
      }),
    options,
  )

export default useCliDownloadUrl
