import React from 'react'
import { KBarPortal, useKBar, VisualState } from 'kbar'
import { isMac } from 'src/next/utils'
import { Box } from '../Box'
import { useCLUI } from './actions/useCLUI'
import * as Styled from './CLUI.styled'
import { CLUIResults } from './CLUIResults'
import { CLUIShortcut } from './CLUIShortcut'

export const CLUI = () => {
  const { searchQuery, query, currentRootActionId, actions, visualState } =
    useKBar(state => state)

  const isHidden = visualState === VisualState.hidden
  const {
    isLoadingWorkloads,
    isLoadingClusters,
    isLoadingKubernetesResources,
  } = useCLUI(!isHidden)

  const isOnRoot = !currentRootActionId

  const { shortcut } = Object.values(actions).find(
    action => action.id === currentRootActionId,
  ) || { shortcut: [isMac ? '⌘' : 'ctrl', 'k'] }

  // if a user types '>' or '@' directly switch to the relevant finder
  if (isOnRoot) {
    if (searchQuery.startsWith('>'))
      query.setCurrentRootAction('workloadFinder')
    else if (searchQuery.startsWith('@'))
      query.setCurrentRootAction('clusterFinder')
    else if (searchQuery.startsWith('/'))
      query.setCurrentRootAction('orgsSwitch')
    else if (searchQuery.startsWith('#'))
      query.setCurrentRootAction('kubernetesClusterFinder')
    else if (searchQuery.startsWith('$'))
      query.setCurrentRootAction('kubernetesResourceFinder')
  }

  return (
    <KBarPortal>
      <Styled.Positioner>
        <Styled.Animator>
          <Box position="relative">
            <Styled.Search className="bx--text-input" />
            <Box position="absolute" right="6" top="5">
              <CLUIShortcut shortcut={shortcut} />
            </Box>
          </Box>
          <CLUIResults
            isLoading={
              (currentRootActionId === 'workloadFinder' &&
                isLoadingWorkloads) ||
              (currentRootActionId === 'clusterFinder' && isLoadingClusters) ||
              (currentRootActionId === 'kubernetesResourceFinder' &&
                isLoadingKubernetesResources)
            }
          />
        </Styled.Animator>
      </Styled.Positioner>
    </KBarPortal>
  )
}
