import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OverflowMenu, OverflowMenuItemWithCheck } from 'src/next/components'
import { TimePeriod } from 'src/next/utils/time'

interface ChartContainerProps {
  children: ReactNode
  heading: ReactNode
  menuItems: {
    id: string
    label: string
  }[]
  selectedItem: string
  onChange(id: TimePeriod): void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const ChartContainer = ({
  children,
  heading,
  menuItems,
  selectedItem,
  onChange,
  ...rest
}: ChartContainerProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper {...rest}>
      <HeaderWrapper>
        <HeadingWrapper>{heading}</HeadingWrapper>
        <OverflowMenu
          data-testid="workload-time-range"
          ariaLabel={t(
            'WorkloadRecommendation.filter.menuLabel',
            'Choose a time filter',
          )}
          flipped
          minWidth="230px"
        >
          {menuItems.map(({ id, label }) => (
            <OverflowMenuItemWithCheck
              key={id}
              id={id}
              label={label}
              checked={selectedItem === id}
              onClick={onChange}
            />
          ))}
        </OverflowMenu>
      </HeaderWrapper>
      {children}
    </Wrapper>
  )
}

export default ChartContainer
