import React, { Suspense } from 'react'
import { Security } from '@okta/okta-react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'

import { queryClient } from 'src/api/queryClient'
import { AppRoutes } from 'src/App/AppRoutes'
import { AuthClient } from 'src/auth/AuthClient'
import Loading from 'src/next/components/Loading'
import { GlobalThemeProvider, ToasterContextProvider } from 'src/next/contexts'
import { cookie } from 'src/next/utils/cookies'
import { GlobalErrorBoundary } from './GlobalErrorBoundary'

export const App = () => {
  const restoreOriginalUri = () => window.location.replace('/')
  return (
    <GlobalErrorBoundary>
      <Suspense fallback={<Loading withOverlay={false} centered />}>
        <ToasterContextProvider>
          <GlobalThemeProvider theme={cookie.get('theme') || 'dark'}>
            <BrowserRouter>
              <Security
                oktaAuth={AuthClient().authClient}
                restoreOriginalUri={restoreOriginalUri}
              >
                <QueryClientProvider client={queryClient}>
                  <AppRoutes />
                  {import.meta.env.VITE_REACT_QUERY_DEVTOOLS === 'true' && (
                    <ReactQueryDevtools
                      initialIsOpen={false}
                      position="bottom-right"
                    />
                  )}
                </QueryClientProvider>
              </Security>
            </BrowserRouter>
          </GlobalThemeProvider>
        </ToasterContextProvider>
      </Suspense>
    </GlobalErrorBoundary>
  )
}
