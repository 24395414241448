import React from 'react'
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

Sentry.init({
  dsn: 'https://59722979f8da4a9aae8e322072c5dc31@o932210.ingest.sentry.io/5880804',
  integrations: [
    new BrowserTracing({
      tracingOrigins: [`${import.meta.env.VITE_API_URL}`],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new ExtraErrorDataIntegration({
      depth: 12,
    }),
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
    // Prevent all console logs to come from `instrument.js`.
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  enabled: import.meta.env.NODE_ENV === 'production',
  environment: `${import.meta.env.VITE_ENV_NAME}`.toLowerCase(),
  release: import.meta.env.npm_package_version,
  tracesSampleRate: 1.0,
  // This is 404 error for kubernetes APIs. Those are normal and so
  // shouldn't be sent to sentry.
  ignoreErrors: [/Response returned an error code 404/],
})

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
