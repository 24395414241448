import React from 'react'
import styled from 'styled-components'
import { Flex } from '..'
import Typography from '../Typography'

interface CLUIShortcutProps {
  shortcut?: string[]
  separateBy?: string
}

export const StyledShortcut = styled.kbd`
  &,
  [data-theme='dark'] & {
    --kbd-color-background: var(--cds-layer-01);
    --kbd-color-border: var(--cds-border-subtle-01);
    --kbd-color-text: var(--cds-text-secondary);
  }

  box-shadow: 0 1px 2px var(--kbd-color-border);
  padding: 4px 6px;
  border-radius: 4px;
`

export const CLUIShortcut = ({
  shortcut = [],
  separateBy,
}: CLUIShortcutProps) =>
  shortcut?.length ? (
    <div
      aria-hidden
      style={{ display: 'grid', gridAutoFlow: 'column', gap: '4px' }}
    >
      {shortcut.map((sc, i) => (
        <React.Fragment key={`${sc}-${i}`}>
          {i !== 0 && separateBy && (
            <Flex alignItems="center" color="text-helper" mb="1">
              <Typography variant="helper-text-01">{separateBy}</Typography>
            </Flex>
          )}
          <StyledShortcut>{sc}</StyledShortcut>
        </React.Fragment>
      ))}
    </div>
  ) : null
