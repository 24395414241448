import React from 'react'
import styled from 'styled-components'
import {
  createContainer,
  VictoryPortal,
  VictoryTooltipProps,
  VictoryVoronoiContainer,
  VictoryVoronoiContainerProps,
  VictoryZoomContainerProps,
} from 'victory'
import { ContainerType } from 'victory-create-container'
import Portal from '../Portal'

const Wrapper = styled.div`
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

const TooltipWrapper = ({
  tooltipComponent,
  ...props
}: Partial<VictoryTooltipProps> & { tooltipComponent: React.ReactElement }) => {
  // this component implicitly receives a lot of properties from the Portal,
  // but we only need the active points to show in the tooltip (for now)
  const tooltipWithExtraProps = React.cloneElement(tooltipComponent, {
    activePoints: props.activePoints,
  })

  return <Wrapper>{tooltipWithExtraProps}</Wrapper>
}

export type GraphTooltipContainerProps = VictoryVoronoiContainerProps &
  VictoryZoomContainerProps & {
    containerType?: ContainerType
  }

const GraphTooltipContainer = (
  tooltipComponent: React.ReactElement,
  containerProps?: GraphTooltipContainerProps,
) => {
  const { containerType } = containerProps || {}

  // make it possible to specify an extra container type, for example for ZoomContainer (brush)
  const Container = containerType
    ? createContainer('voronoi', containerType)
    : VictoryVoronoiContainer

  return (
    <Container
      voronoiDimension="x"
      portalComponent={<Portal />}
      labelComponent={
        <VictoryPortal>
          <TooltipWrapper tooltipComponent={tooltipComponent} />
        </VictoryPortal>
      }
      labels={() => ' '} // Needed to render labelComponent
      {...containerProps}
    />
  )
}

export default GraphTooltipContainer
