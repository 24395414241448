import { useTranslation } from 'react-i18next'
import {
  TableFilterDropdown,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { SelectSkeleton } from '@carbon/react'
import { useClusters } from 'src/api'

export const ClustersTableNameDropdownFilter = () => {
  const { t } = useTranslation()

  const { isOpen } = useTableFilter()

  const { data, isLoading } = useClusters({
    enabled: isOpen,
  })

  const items = data?.clusters?.map(cluster => cluster.name || '') || []

  return isLoading ? (
    <SelectSkeleton hideLabel />
  ) : (
    <TableFilterDropdown
      id="name"
      isLoading={isLoading}
      items={items}
      placeholder={t('Clusters.Filters.NamePlaceHolder')}
    />
  )
}
