import { Button } from '@carbon/react'
import styled from 'styled-components'

// TooltipButton is a button container for tooltip.
// It appears that the tooltip is not working with a child other than
// buttons, and so this button would be used to have no extra styling
// but satisfies the tooltip.
export const TooltipButton = styled(Button)`
  border: none;
  box-shadow: none;
  background: transparent;
  display: inline-block;
  &&:hover {
    background: transparent;
  }
  &&:focus {
    border: none;
    box-shadow: none;
  }

  padding-block: initial;
  padding-inline: initial;
  min-block-size: initial;
`
