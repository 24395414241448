export interface Language {
  countryCode: 'US' | 'JP'
  ariaLabel: 'United States' | 'Japan'
  LabelText: 'English' | 'Japanese'
  code: 'en-US' | 'ja'
}

// The first entry will be used as the default when
// - language detection fails
// - no entry found for the result of language detection
const LangList: Language[] = [
  {
    countryCode: 'US',
    ariaLabel: 'United States',
    LabelText: 'English',
    code: 'en-US',
  },
  {
    countryCode: 'JP',
    ariaLabel: 'Japan',
    LabelText: 'Japanese',
    code: 'ja',
  },
]

export default LangList
