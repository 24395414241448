import { KBarAnimator, KBarPositioner, KBarSearch } from 'kbar'
import styled, { css } from 'styled-components'

export const Positioner = styled(KBarPositioner)`
  z-index: 400; // prevent SVG in dashboard map going over the CLUI
`

export const Search = styled(KBarSearch)`
  background: var(--cds-layer-01);
  border-bottom: 1px solid var(--cds-layer-02);
  box-sizing: border-box;
  color: var(--cds-text-primary);
  font-size: 16px;
  height: auto;
  outline: none;
  padding: 16px 16px;
  width: 100%;
`

export const Animator = styled(KBarAnimator)`
  max-width: 600px;
  width: 100%;
  background: var(--cds-layer-01);
  color: var(--cds-text-primary);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
`

export const GroupName = styled.div`
  padding: 8px 16px;
  font-size: 10px;
  text-transform: uppercase;
  opacity: 0.5;
`

export const Results = styled.div`
  > div {
    background: var(--cds-layer-01);
  }
`

export const ListItem = styled.div<{ $active: boolean }>`
  background-color: ${({ $active }) =>
    $active ? 'var(--cds-background-hover)' : 'transparent'};

  padding: var(--cds-spacing-04) var(--cds-spacing-05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${({ $active }) => $active && activeIndicator}
`

const activeIndicator = css`
  &:after {
    content: '';
    background: var(--cds-button-primary);
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 2px;
  }
`
