import { useLocation } from 'react-router-dom'
import MiddleTruncate from 'src/next/components/MiddleTruncate'
import { Link } from 'src/next/components/ui/Link'
import { isDevelopment } from 'src/next/utils'

interface WorkloadNameProps {
  name: string
  /** Provide id to create a link to the detail page */
  id?: string
}

export const WorkloadName = ({ name, id }: WorkloadNameProps) => {
  const location = useLocation()

  const linkState = {
    pathname: location.pathname,
    search: location.search,
  }

  const text = <MiddleTruncate text={name} />

  // TODO: Only for development right now, we need to solve returning to a
  //  certain data table page first (CNATIX-2770)
  if (id && isDevelopment()) {
    return (
      <Link to={`/app/workload/${id}`} state={linkState}>
        {text}
      </Link>
    )
  }

  return text
}
