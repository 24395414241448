import React, { useEffect, useMemo } from 'react'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { SelectSkeleton } from '@carbon/react'

import FilterableSelect from 'src/next/components/FilterableSelect/FilterableSelect'
import { InlineNotification } from 'src/next/components/InlineNotification'

import { useInstanceTagKeysQuery } from './hooks/useInstanceTagKeysQuery'

interface TagKeySelectProps {
  setTagKey: (tagKey: string) => void
}

export const TagKeySelect = ({ setTagKey }: TagKeySelectProps) => {
  const { t } = useTranslation()

  const { isLoading, isError, data } = useInstanceTagKeysQuery()

  // sort the items, since the order of items being returned is not stable
  const sortedItems = useMemo(
    () => orderBy(data?.keys || [], item => item.toLowerCase()),
    [data],
  )

  useEffect(() => {
    if (sortedItems.length > 0) setTagKey(sortedItems[0])
  }, [sortedItems])

  return isLoading ? (
    <SelectSkeleton hideLabel />
  ) : isError ? (
    <InlineNotification
      title={t('Dashboard.Tabs.TagKey.LoadingError', 'Failed to load tag keys')}
      kind="error"
    />
  ) : sortedItems.length === 0 ? (
    <InlineNotification
      title={t('Dashboard.Tabs.TagKey.NoData', 'No tag keys found')}
      kind="info"
    />
  ) : (
    <FilterableSelect
      items={sortedItems}
      onChange={setTagKey}
      initialSelectedItem={sortedItems[0]}
      placeholder={t('Dashboard.Tabs.SelectTagKey')}
      id="instance-tag-keys"
    />
  )
}
