/* eslint-disable react/display-name */
import React, {
  forwardRef,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { DatePicker, DatePickerInput } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from '../Box'
import { MeridiemType, TimeInput } from '../TimeInput'

export const maxDate = new Date()

export const timeFormatRegex = /^(1[012]|0?[1-9]):([0-5][0-9])$/

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: var(--cds-spacing-05);
`

const TimeInputContainer = styled.div`
  width: 50%;
  height: 100%;
  gap: var(--cds-spacing-05);
`

const StyledDatePicker = styled(DatePicker)`
  .cds--date-picker {
    &-container {
      width: 100%;
    }

    &--range {
      display: flex;
      flex-direction: column;
      gap: var(--cds-spacing-05);
      width: 100%;
    }

    span {
      width: 100%;
    }

    &__input {
      width: 100%;
    }
  }
`

export interface DateTimePickerProps {
  id: string
  withTimeInput: boolean | undefined
  maxDate: Date | undefined
  setValid: Dispatch<SetStateAction<boolean>>
  value: Date[]
  setValue: Dispatch<SetStateAction<Date[]>>
}

export const DateTimePicker = forwardRef<DatePicker, DateTimePickerProps>(
  ({ id, withTimeInput, maxDate, value, setValid, setValue }, ref) => {
    const { i18n, t } = useTranslation()

    const [localStartTime, setLocalStartTime] = useState('')
    const [localStartMeridiem, setLocalStartMeridiem] =
      useState<MeridiemType>('PM')
    const isLocalStartTimeValid = useMemo(
      () => localStartTime === '' || timeFormatRegex.test(localStartTime),
      [localStartTime],
    )

    const [localEndTime, setLocalEndTime] = useState('')
    const [localEndMeridiem, setLocalEndMeridiem] = useState<MeridiemType>('PM')
    const isLocalEndTimeValid = useMemo(
      () => localEndTime === '' || timeFormatRegex.test(localEndTime),
      [localEndTime],
    )

    const updateTimePart = (
      value: Date,
      localTime: string,
      localMeridiem?: MeridiemType,
    ) => {
      // Clone not to make any change on the original date object.
      const dateObj = new Date(value)

      const result = timeFormatRegex.exec(localTime)
      if (result === null) {
        return undefined
      }

      let localHour = parseInt(result[1], 10)
      if (localMeridiem === 'PM') {
        if (localHour != 12) {
          localHour += 12
        }
      } else if (localHour == 12) {
        localHour = 0
      }

      const localMinute = parseInt(result[2], 10)

      return new Date(
        dateObj.getFullYear(),
        dateObj.getMonth(),
        dateObj.getDate(),
        localHour,
        localMinute,
      )
    }

    // Note that when called by the DatePicker via onChange, date here is set to
    // the beginning of the picked date in the local timezone.
    const updateValue = (dates: Date[]) => {
      const startDate = dates[0]
      const endDate = dates[1]

      // When the time input is left empty,
      // Carbon DatePicker returns the start of the picked day in the local timezone.
      // Therefore, simply use `startDate` here to set the start time to the beginning of the picked day.
      const updatedStart =
        localStartTime === ''
          ? new Date(startDate)
          : updateTimePart(startDate, localStartTime, localStartMeridiem)
      if (updatedStart === undefined) {
        setValid(false)
        return
      }

      if (endDate) {
        const updatedEnd =
          localEndTime === ''
            ? updateTimePart(endDate, '11:59', 'PM')
            : updateTimePart(endDate, localEndTime, localEndMeridiem)
        if (updatedEnd === undefined) {
          setValid(false)
          return
        }

        setValid(true)
        setValue([updatedStart, updatedEnd])
      } else {
        setValid(true)
        setValue([updatedStart])
      }
    }

    const getTimeAndMeridiem = (time: Date) => {
      return time
        .toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
        .split(' ')
    }

    useEffect(() => {
      if (value[0]) {
        const start = getTimeAndMeridiem(value[0])
        setLocalStartTime(start[0])
        setLocalStartMeridiem(start[1] as MeridiemType)
      }
      if (value[1]) {
        const end = getTimeAndMeridiem(value[1])
        setLocalEndTime(end[0])
        setLocalEndMeridiem(end[1] as MeridiemType)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (value.length !== 0) updateValue(value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStartTime, localEndTime, localStartMeridiem, localEndMeridiem])

    return (
      <Container>
        <StyledDatePicker
          ref={ref}
          className="your-date-picker-container"
          maxDate={maxDate}
          datePickerType="range"
          value={value.map(
            x => new Date(x.getFullYear(), x.getMonth(), x.getDate()),
          )}
          onChange={e => {
            updateValue(e)
          }}
          locale={i18n.language}
        >
          <DatePickerInput
            id={`${id}-date-picker-start`}
            placeholder="MM/DD/YYYY"
            labelText={t('DateTimeRangePicker.StartDate')}
            size="md"
          />

          {
            <DatePickerInput
              id={`${id}-date-picker-end`}
              placeholder="MM/DD/YYYY"
              labelText={t('DateTimeRangePicker.EndDate')}
              size="md"
            />
          }
        </StyledDatePicker>

        {withTimeInput && (
          <TimeInputContainer>
            <TimeInput
              id={`${id}-time-input-start`}
              label={t('DateTimeRangePicker.StartTime')}
              time={localStartTime}
              setTime={setLocalStartTime}
              meridiem={localStartMeridiem}
              setMeridiem={setLocalStartMeridiem}
              valid={isLocalStartTimeValid}
            />

            {
              <Box marginTop="1rem">
                <TimeInput
                  id={`${id}-time-input-end`}
                  label={t('DateTimeRangePicker.EndTime')}
                  time={localEndTime}
                  setTime={setLocalEndTime}
                  meridiem={localEndMeridiem}
                  setMeridiem={setLocalEndMeridiem}
                  valid={isLocalEndTimeValid}
                />
              </Box>
            }
          </TimeInputContainer>
        )}
      </Container>
    )
  },
)
