import React from 'react'
import dayjs from 'dayjs'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { VictoryLabel } from 'victory'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import {
  getFilterGroupingDuration,
  useInstanceSummariesAggregate,
} from 'src/api'
import i18n from 'src/App/i18n'
import { GraphColor } from 'src/next/types/workloads'
import { Legend, LegendItem } from '../../../Graphs'
import { DonutChart } from '../../../Graphs/DonutChart'
import { useTimeRangeOverflowMenu } from '../../timerange'
import { GenericTabSummaryViewChartHeading } from './GenericTabSummaryViewChartHeading'

const DonutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--cds-spacing-04);
  height: 500px;
`

export interface GenericTabSummaryViewDonutChartProps {
  id: string
  groupingKeyKey: `${ComputeInstanceGroupingKeyKey}`
  groupingKeyTagKey: string | undefined
  groupingValue: string
}

export const GenericTabSummaryViewDonutChart = ({
  id,
  groupingKeyKey,
  groupingKeyTagKey,
  groupingValue,
}: GenericTabSummaryViewDonutChartProps) => {
  const { t } = useTranslation()

  const {
    TimeRangeMenu,
    items,
    selectedItem,
    startTime,
    endTime,
    startTimeNs,
    endTimeNs,
  } = useTimeRangeOverflowMenu({
    id: `${id}-time-range`,
    show24HoursOption: false,
  })

  const { data, isLoading } = useInstanceSummariesAggregate(
    {
      filter: {
        startTimeNs,
        endTimeNs,
        grouping: {
          groupingKey: {
            key: groupingKeyKey as any,
            tagKey: groupingKeyTagKey,
          },
          groupingValue,
          // todo: hardcoded for now duration needs to be selected
          duration: getFilterGroupingDuration(selectedItem.id) as any,
        },
      },
    },
    {
      useErrorBoundary: true,
      select: ({ aggregatedSummary }) => aggregatedSummary,
    },
  )

  const periodStart = dayjs(startTime)
    .locale(i18n.resolvedLanguage)
    .format('LLL')
  const periodEnd = dayjs(endTime).locale(i18n.resolvedLanguage).format('LLL')

  const efficiency = round(data?.efficiency! * 100, 2)
  const waste = round(data?.waste! * 100, 2)

  const formattedData = [
    {
      id: 'efficiency',
      donutChartData: {
        x: 0,
        y: efficiency,
        value: `${efficiency}%`,
        label: t('Dashboard.Tabs.Charts.Efficiency'),
        color: 'var(--carbonPalette1)' as GraphColor,
      },
    },
    {
      id: 'waste',
      donutChartData: {
        x: 1,
        y: waste,
        value: `${waste}%`,
        label: t('Common.Waste'),
        color: 'var(--carbonPalette2)' as GraphColor,
      },
    },
  ]

  const centerData = formattedData[0].donutChartData

  return (
    <>
      <GenericTabSummaryViewChartHeading
        title={t('Dashboard.Common.ResourceEfficiency')}
        overflowMenu={
          <TimeRangeMenu label={selectedItem.label} items={items} />
        }
      />

      <DonutWrapper>
        <DonutChart
          isLoading={isLoading}
          data={formattedData.map(({ donutChartData }) => donutChartData)}
          tooltipHeading={
            <>
              {periodStart} –<br />
              {periodEnd}
            </>
          }
          labelComponent={<VictoryLabel text={({ datum }) => datum.value} />}
          centerHeading={centerData.label}
          centerValue={centerData.value}
        />
        <Legend>
          {formattedData.map(
            ({ id: itemID, donutChartData: { label, color } }) => (
              <LegendItem
                id={`${id}-legend-${itemID}`}
                key={label}
                color={color}
              >
                {label}
              </LegendItem>
            ),
          )}
        </Legend>
      </DonutWrapper>
    </>
  )
}
