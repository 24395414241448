import dayjs from 'dayjs'

export const milliToNanoSeconds = (timestamp: string | number): number =>
  Number(timestamp) * 1_000_000

export const nanoToMilliSeconds = (timestamp: string | number): number =>
  Number(timestamp) / 1_000_000

export const nanoSecondsToDate = (timestamp: string | number): Date =>
  dayjs(nanoToMilliSeconds(timestamp)).toDate()
