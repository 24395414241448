import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

interface Props {
  label?: string
  percent: number
}

export const ProgressDonut = ({ label }: Props) => {
  /* const width = 14;
  const radius = 6;
  const cx = width / 2;
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray - (dashArray * percent) / 100; */

  return (
    <Wrapper>
      {/* donut disable until we have usage data https://cloudnatix.atlassian.net/browse/CNATIX-1777 */}
      {/* <svg
        width={`${width}px`}
        height={`${width}px`}
        viewBox={`0 0 ${width} ${width}`}
      >
        <circle
          cx={cx}
          cy={cx}
          r={radius}
          fill="transparent"
          strokeWidth="2"
          stroke="var(--carbonPalette2)"
        />
        <circle
          cx={cx}
          cy={cx}
          r={radius}
          stroke="var(--carbonPalette1)"
          strokeWidth="2"
          fill="transparent"
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          transform={`rotate(-90 ${cx} ${cx})`}
        />
        );
      </svg> */}
      {label && <span>{label}</span>}
    </Wrapper>
  )
}
