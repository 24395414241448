import React, { ReactNode } from 'react'
import { Calendar, Time } from '@carbon/react/icons'
import { Loading, Tile } from '@carbon/react'
import { OverflowMenu } from '@carbon/react/lib/components/OverflowMenu'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Box, Flex, Link } from 'src/next/components'
import Typography from 'src/next/components/Typography'
import { useRollupPeriod, useTimeRangeOverflowMenu } from '../../timerange'

const StyledTile = styled(Tile)`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--cds-layer-02);
  padding: var(--cds-spacing-02) var(--cds-spacing-05);
`

const Heading = styled.div`
  display: flex;
  padding-top: var(--cds-spacing-03);
  margin-right: 40px; // Overflow menu button
`

export const TimeRange = styled.div`
  color: var(--cds-text-secondary);
  font-size: 0.8125rem;
  margin-bottom: var(--cds-spacing-01);
`

const Content = styled.div`
  padding-bottom: var(--cds-spacing-03);
  padding-top: var(--cds-spacing-03);
  flex: 1 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  display: flex;
  padding-bottom: var(--cds-spacing-04);
  padding-top: var(--cds-spacing-03);
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  place-items: center;
`

const ErrorWrapper = styled.div`
  display: grid;
  place-items: center;
  padding: var(--cds-spacing-03);
  height: 100%;
`

export interface MetricContainerProps {
  id: string
  children: ReactNode
  title: string | React.ReactNode
  enableTimeRange?: boolean
  loading?: boolean
  error?: string
  primaryAction?: {
    label: string
    href: string
  }
}

export const MetricContainer = ({
  id,
  children,
  title,
  enableTimeRange = true,
  loading = false,
  error,
  primaryAction,
}: MetricContainerProps) => {
  const { t } = useTranslation()

  const {
    items: timeRangeOverflowMenuItems,
    selectedItem: timeRangeSelectedItem,
  } = useTimeRangeOverflowMenu({
    id: `${id}-time-range`,
  })

  const {
    items: durationOverflowMenuItems,
    selectedItem: durationSelectedItem,
  } = useRollupPeriod({
    id: `${id}-duration`,
    timeRange: timeRangeSelectedItem.id,
  })

  return (
    <StyledTile>
      <Heading>
        <Typography variant="heading-03" as="h2">
          {title}
          {enableTimeRange ? (
            <>
              <Flex alignItems="center">
                <TimeRange>{timeRangeSelectedItem.label}</TimeRange>
              </Flex>
              <Flex alignItems="center">
                <TimeRange>{durationSelectedItem.label}</TimeRange>
              </Flex>
            </>
          ) : null}
        </Typography>
        {enableTimeRange ? (
          <Box
            top={0}
            right={0}
            position="absolute"
            data-floating-menu-container
          >
            <OverflowMenu
              data-testid={`time-range-${id}`}
              flipped
              iconDescription={t('Common.DateFilter.SelectTimeRange')}
              aria-label={t('Common.DateFilter.SelectTimeRange')}
              renderIcon={Calendar}
            >
              {timeRangeOverflowMenuItems}
            </OverflowMenu>
            <OverflowMenu
              data-testid={`duration-${id}`}
              flipped
              iconDescription={t('Common.DateFilter.SelectRollupPeriod')}
              aria-label={t('Common.DateFilter.SelectRollupPeriod')}
              renderIcon={Time}
            >
              {durationOverflowMenuItems}
            </OverflowMenu>
          </Box>
        ) : null}
      </Heading>
      {loading ? (
        <LoadingWrapper>
          <Loading small withOverlay={false} />
        </LoadingWrapper>
      ) : error ? (
        <ErrorWrapper>{error}</ErrorWrapper>
      ) : (
        <>
          <Content>{children}</Content>
          <Footer>
            {primaryAction && (
              <Link to={primaryAction.href}>{primaryAction.label}</Link>
            )}
          </Footer>
        </>
      )}
    </StyledTile>
  )
}
