import React from 'react'
import { Link } from '@carbon/react'
import {
  ErrorBoundaryPropsWithRender,
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Optional } from 'src/next/types/helpers'
import {
  InlineNotification,
  InlineNotificationProps,
} from '../InlineNotification'
import { css } from 'styled-components'

type ErrorFallbackProps = Partial<FallbackProps> &
  Optional<InlineNotificationProps, 'title' | 'kind'>

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
  title: titleProp,
  ...inlineNotificationProps
}: ErrorFallbackProps) => {
  const { t } = useTranslation()
  const title = `${titleProp || t('ErrorBoundary.ErrorOccurred')}${
    error?.message ? ':' : ''
  }
  `

  return (
    <InlineNotification
      title={title}
      subtitle={error?.message || null}
      kind="error"
      {...inlineNotificationProps}
    >
      <Link
        inline
        onClick={resetErrorBoundary}
        css={css`
          margin-left: var(--cds-spacing-02);
          cursor: pointer;
        `}
      >
        {t('ErrorBoundary.TryAgain')}
      </Link>
    </InlineNotification>
  )
}

export interface ErrorBoundaryProps
  extends Optional<ErrorBoundaryPropsWithRender, 'fallbackRender'> {
  children?: React.ReactNode
  fallbackProps?: ErrorFallbackProps
}

export const ErrorBoundary = ({
  children,
  fallbackProps,
  ...errorBoundaryProps
}: ErrorBoundaryProps) => (
  <ReactErrorBoundary
    fallbackRender={fallbackRenderProps => (
      <ErrorFallback {...fallbackRenderProps} {...fallbackProps} />
    )}
    {...errorBoundaryProps}
  >
    {children}
  </ReactErrorBoundary>
)
