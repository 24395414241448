import React from 'react'

export { GenericTab } from './GenericTab'
export { InstancesTab } from './InstancesTab'
export { NamespaceTab } from './NamespaceTab'

export const RegionTab = React.lazy(() =>
  import('./RegionTab').then(({ RegionTab }) => ({
    default: RegionTab,
  })),
)
