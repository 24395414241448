import { Dropdown } from '@carbon/react'
import styled from 'styled-components'

/* todo: fixme: exception */
export const ToolbarDropdown = styled(Dropdown)`
  background: transparent;
  border-bottom: none;

  & .cds--dropdown {
    background-color: transparent;
  }

  &:hover {
    background: var(--cds-background-hover);
  }

  button {
    padding-right: var(--cds-spacing-02);
    padding-left: var(--cds-spacing-04);
  }

  .cds--list-box__menu-icon {
    position: unset;
  }

  .cds--list-box__field {
    gap: var(--cds-spacing-02);
  }
`
