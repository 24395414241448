import React, { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'src/next/components'
import Loading from 'src/next/components/Loading'
import {
  TableFilterContainer,
  TableFilterContextProvider,
} from 'src/next/components/TableFilter'
import {
  LazyTabPanel,
  Tab,
  Tabs,
  TabList,
  TabPanels,
} from 'src/next/components/Tabs'
import Typography from 'src/next/components/Typography'
import {
  KubernetesWorkloadsTableFilters,
  KubernetesWorkloadsTable,
} from 'src/next/components/Workloads/WorkloadsTable'
import { KubernetesWorkloadCharts } from 'src/next/components/KubernetesWorkloadCharts'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { isProduction } from 'src/next/utils'
import { FeatureFlag } from 'src/next/components'

const ComputeInstancesTableFilters = lazy(
  () =>
    import(
      'src/next/components/Workloads/WorkloadsTable/filters/ComputeInstancesTableFilters'
    ),
)
const ComputeInstancesTable = lazy(
  () =>
    import(
      'src/next/components/Workloads/WorkloadsTable/ComputeInstancesTable'
    ),
)

const EcsTable = lazy(
  () => import('src/next/components/Workloads/WorkloadsTable/EcsTable'),
)

const Workloads = () => {
  const { t } = useTranslation()

  const [savedSelectedTabIndex, setSavedSelectedTabIndex] = useLocalStorage(
    'workloads-page-category-index',
    0,
  )

  const tabs = [
    {
      id: 'kubernetes',
      label: t('Common.Kubernetes'),
      panel: (
        <>
          <KubernetesWorkloadCharts />
          <TableFilterContextProvider
            localStorageId="kubernetes-page-filters"
            defaultValues={{
              hideSystemWorkload: true,
              hideCnatixManagedWorkload: true,
              hideDeletedWorkload: false,
            }}
          >
            <TableFilterContainer data-testid="kubernetes-table-filters">
              <KubernetesWorkloadsTableFilters />
              <KubernetesWorkloadsTable />
            </TableFilterContainer>
          </TableFilterContextProvider>
        </>
      ),
    },
    {
      id: 'vm',
      label: t('Common.compute_instances'),
      panel: (
        <TableFilterContextProvider
          localStorageId="vm-page-filters"
          defaultValues={{}}
        >
          <TableFilterContainer data-testid="vm-table-filters">
            <Suspense fallback={<Loading withOverlay={false} centered />}>
              <ComputeInstancesTableFilters />
            </Suspense>
            <Suspense fallback={<Loading withOverlay={false} centered />}>
              <ComputeInstancesTable />
            </Suspense>
          </TableFilterContainer>
        </TableFilterContextProvider>
      ),
    },
    ...(isProduction() === false
      ? [
          {
            id: 'ecs',
            label: <FeatureFlag>{t('Common.ecs')}</FeatureFlag>,
            panel: (
              <Suspense fallback={<Loading withOverlay={false} centered />}>
                <EcsTable />
              </Suspense>
            ),
          },
        ]
      : []),
  ]

  return (
    <>
      <Box mb={6}>
        <Typography variant="heading-03" as="h4">
          {t('Workload.workloads', 'Workloads')}
        </Typography>
      </Box>

      <Tabs
        selectedIndex={savedSelectedTabIndex}
        onChange={({ selectedIndex }) => {
          setSavedSelectedTabIndex(selectedIndex)
        }}
      >
        <TabList aria-label="Workloads page tabs">
          {tabs.map(tab => (
            <Tab key={tab.id}>{tab.label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map((tab, i) => (
            <LazyTabPanel
              key={tab.id}
              index={i}
              selectedIndex={savedSelectedTabIndex}
            >
              {tab.panel}
            </LazyTabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  )
}

export default Workloads
