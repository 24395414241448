import React from 'react'
import { ContentSwitcher as CarbonContentSwitcher, Switch } from '@carbon/react'
import { ContentSwitcherProps as CarbonContentSwitcherProps } from '@carbon/react/lib/components/ContentSwitcher/ContentSwitcher'
import styled, { css } from 'styled-components'

const StyledContentSwitcher = styled(CarbonContentSwitcher)<{
  $minSwitchWidth: number | string
  $inline: boolean
}>`
  justify-content: flex-start;

  .cds--content-switcher-btn {
    border: none;

    ${props => {
      const width =
        typeof props.$minSwitchWidth === 'number'
          ? `${props.$minSwitchWidth}px`
          : props.$minSwitchWidth

      return props.$inline
        ? css`
            flex-basis: ${width};
          `
        : null
    }};

    &:not(.cds--content-switcher--selected) {
      background: var(--cds-layer-accent-01);
    }
  }
`

// get index of item to select when selectedName is provided
const getSelectedIndexByName = (
  searchForName: string,
  children: JSX.Element | JSX.Element[],
) => {
  return React.Children.toArray(children).findIndex(child => {
    if (React.isValidElement(child)) {
      const { name } = child.props
      return name === searchForName
    }
    return false
  })
}

export interface ContentSwitcherProps extends CarbonContentSwitcherProps {
  selectedName?: string
  inline?: boolean
  // minSwitchWidth in pixels
  minSwitchWidth?: number | string
}

export const ContentSwitcher = ({
  children,
  selectedName,
  selectedIndex,
  inline = true,
  minSwitchWidth = 150,
  ...rest
}: ContentSwitcherProps) => {
  if (selectedName && typeof selectedIndex !== 'undefined')
    throw new Error(
      'Both selectedName & selectedIndex are provided as props. Choose either one.',
    )

  return (
    <StyledContentSwitcher
      $inline={inline}
      $minSwitchWidth={minSwitchWidth}
      {...rest}
      selectedIndex={
        selectedName
          ? getSelectedIndexByName(selectedName, children as JSX.Element[])
          : selectedIndex
      }
    >
      {children}
    </StyledContentSwitcher>
  )
}

export { Switch }
