import React from 'react'
import { useTranslation } from 'react-i18next'
import { InlineNotification } from 'src/next/components/InlineNotification'

interface Props {
  hasItems: boolean
  loading: boolean
  isError: boolean
  children?: React.ReactNode
}

export const WorkloadNotifications = ({
  hasItems,
  loading,
  isError,
  children,
}: Props) => {
  const { t } = useTranslation()

  if (isError) {
    return (
      <InlineNotification
        title={t('Workloads.LoadingDataErrorMessage')}
        kind="error"
        invert
        hideCloseButton
        fullWidth
        hideBorder
      >
        {children}
      </InlineNotification>
    )
  }

  if (!hasItems && !loading) {
    return (
      <InlineNotification title={t('Workloads.NoDataMessage')}>
        {children}
      </InlineNotification>
    )
  }

  return null
}
