import React, { useState } from 'react'
import styled from 'styled-components'
import { VictoryContainer, VictoryPie, VictoryPieProps } from 'victory'
import { SliceProps } from 'victory-pie'
import BoundingSize from 'src/next/components/BoundingSize'
import { Box } from 'src/next/components/Box'
import Loading from 'src/next/components/Loading'
import theme from 'src/next/themes/victory-carbon-theme'
import GraphTooltip from '../GraphTooltip'

const CenterLabel = styled.div<{ size: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  font-family: var(--cn-font-family--condensed);

  // Font size is based on size of the donut and results in a nice average size.
  // To customize, dont change the calculation below but use "em" values in the
  // "centerLabel" prop to change this for a certain situation.
  font-size: ${props => props.size / 20}px;
`

const loadingStateData = [
  {
    label: '',
    value: '',
    color: 'var(--carbonGrayScale2)',
    x: 1,
    y: 1,
  },
]

export interface DonutChartProps extends VictoryPieProps {
  disabled?: boolean
  isLoading?: boolean
  hasError?: boolean
  showDataLabels?: boolean
  tooltipHeading?: React.ReactNode
  centerHeading: string
  centerValue: string
  defaultWidth?: number
  defaultHeight?: number
}

export const DonutChart = ({
  disabled,
  isLoading = false,
  hasError = false,
  data,
  showDataLabels = true,
  tooltipHeading,
  centerHeading,
  centerValue,
  defaultWidth,
  defaultHeight,
  ...rest
}: DonutChartProps) => {
  const [mouseOver, setMouseOver] = useState(false)

  // Even if the data is too small to render, we still want to see a little line
  const totalValue = data?.reduce((acc, current) => acc + current.y, 0)
  const minDataWidth = totalValue / 720 // 360 / 720 = 0.5 degrees

  const displayData =
    isLoading || disabled
      ? loadingStateData
      : data?.map(dataPoint => ({
          ...dataPoint,
          y: Math.max(dataPoint.y, minDataWidth),
        }))

  const showTooltips = mouseOver && !isLoading && !disabled
  const showCenterLabel =
    !disabled && !isLoading && (centerHeading || centerValue)

  return (
    <BoundingSize
      defaultWidth={defaultWidth}
      defaultHeight={defaultHeight}
      render={({ width, height }) => {
        const size = Math.min(width, height)

        return (
          <>
            {isLoading && <Loading size="small" centered withOverlay={false} />}

            <Box position="relative" height={height} width={width}>
              {showCenterLabel && size >= 200 ? (
                <CenterLabel size={size} data-testid="donut-chart-center-label">
                  <span style={{ fontSize: 'min(1.5rem, 1.75em)' }}>
                    {centerValue}
                  </span>
                  <span style={{ fontSize: 'min(1rem, 1.25em)' }}>
                    {centerHeading}
                  </span>
                </CenterLabel>
              ) : hasError && !isLoading ? (
                <CenterLabel size={size}>---</CenterLabel>
              ) : null}

              <VictoryPie
                theme={theme}
                data={displayData}
                innerRadius={({ radius }: SliceProps) => {
                  if (typeof radius === 'number') {
                    return radius * 0.75
                  }
                  return 0
                }}
                labelComponent={
                  /* "undefined" will result in fallback to default labelComponent */
                  showDataLabels && !isLoading && !disabled ? undefined : <></>
                }
                labelRadius={({ radius }) => Number(radius) + 15}
                width={width}
                height={height}
                style={{
                  data: {
                    fill: ({ datum }) => datum.color,
                  },
                }}
                containerComponent={
                  <VictoryContainer
                    portalComponent={
                      showTooltips ? (
                        <GraphTooltip heading={tooltipHeading} rows={data} />
                      ) : (
                        <></>
                      )
                    }
                  />
                }
                events={[
                  {
                    target: 'parent',
                    eventHandlers: {
                      onMouseOver: () => setMouseOver(true),
                      onMouseOut: () => setMouseOver(false),
                    },
                  },
                ]}
                padding={30}
                {...rest}
              />
            </Box>
          </>
        )
      }}
    />
  )
}
