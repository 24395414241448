import React from 'react'
import { LightFilled } from '@carbon/react/icons'
import { HeaderGlobalAction } from '@carbon/react'
import { useThemeContext } from 'src/next/contexts'

export const HeaderThemeSwitch = () => {
  const { switchTheme } = useThemeContext()

  return (
    <HeaderGlobalAction
      id="theme-action"
      aria-label="Theme"
      onClick={switchTheme}
    >
      <LightFilled size={20} />
    </HeaderGlobalAction>
  )
}
