import { ClusterIcon } from 'src/next/components/CSP/CSP'
import { Text } from 'src/next/components/Text'

interface WorkloadClusterProps {
  cluster: string
  csp?: string // Cluster Service Provider, e.g. aws, gcp
}

export const WorkloadCluster = ({ cluster, csp }: WorkloadClusterProps) => {
  if (!cluster && !csp) {
    return null
  }

  return <Text icon={<ClusterIcon type={csp} />}>{cluster}</Text>
}
