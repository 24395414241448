import {
  useMutation,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from 'react-query'
import {
  CreateConversionRuleRequest,
  CreateConversionRuleResponse,
  ListConversionRulesRequest,
  ListConversionRulesResponse,
  UpdateConversionRuleRequest,
  UpdateConversionRuleResponse,
} from '@cloudnatix-types/orgcontroller'
import { deleteData, fetchData, mutateData } from 'src/api/axios'
import { queryClient } from 'src/api/queryClient'
import { useRequestParams } from 'src/api/useRequestParams'

const url = '/v1/conversion-rules'

const invalidateRules = () => {
  queryClient.invalidateQueries(url)
}

export const useConversionRules = <T = ListConversionRulesResponse>(
  request: ListConversionRulesRequest = {},
  options?: UseInfiniteQueryOptions<ListConversionRulesResponse, any, T>,
) => {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useInfiniteQuery<ListConversionRulesResponse, any, T>(
    [url, params],
    ({ pageParam }) => {
      return fetchData<ListConversionRulesResponse>(url, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
      ...options,
    },
  )
}

export interface UpdateOrgRequest extends UpdateConversionRuleRequest {
  uuid: string
}

export const useUpdateConversionRule = (id: string) =>
  useMutation((data: UpdateOrgRequest) =>
    mutateData<UpdateConversionRuleResponse, typeof data>(
      `${url}/${id}`,
      data,
      'put',
    ),
  )

export const useCreateConversionRule = () =>
  useMutation((data: CreateConversionRuleRequest) =>
    mutateData<CreateConversionRuleResponse, CreateConversionRuleRequest>(
      url,
      data,
      'post',
    ),
  )

export const useDeleteRule = () =>
  // eslint-disable-next-line @typescript-eslint/ban-types
  useMutation((id: string) => deleteData<{}>(`${url}/${id}`), {
    onSuccess: invalidateRules,
  })
