import { useEffect } from 'react'
import { useOrgs, useUserSelf } from 'src/api'
import useLocalStorage from './useLocalStorage'

export const useOrgSelection = (): [
  orgUuid: string | undefined,
  setOrgUuid: (newOrgUuid: string | undefined) => void,
] => {
  const { data: orgs, status: orgsStatus } = useOrgs()

  const { data: self } = useUserSelf()

  const [storedOrgUuid, setStoredOrgUuid] = useLocalStorage<string | undefined>(
    'org-selection',
    undefined,
    true,
  )

  const storedOrgIdHasExpired =
    storedOrgUuid &&
    orgsStatus === 'success' &&
    !orgs?.orgs?.some(org => org.uuid === storedOrgUuid)

  useEffect(() => {
    if (storedOrgIdHasExpired) {
      setStoredOrgUuid(undefined)
    }
  }, [storedOrgIdHasExpired, setStoredOrgUuid])

  const effectiveOrgUuid =
    (storedOrgIdHasExpired ? undefined : storedOrgUuid) ??
    self?.roleBindings?.[0].orgUuid

  return [effectiveOrgUuid, setStoredOrgUuid]
}
