import React from 'react'
import { middleEllipsis } from 'src/next/utils/middle-ellipsis'

interface MiddleTruncateProps {
  text: string
  charsAtStart?: number
  charsAtEnd?: number
}

export const MiddleTruncate = ({
  text,
  charsAtStart,
  charsAtEnd,
}: MiddleTruncateProps) => (
  <span title={text}>{middleEllipsis(text, charsAtStart, charsAtEnd)}</span>
)

export default MiddleTruncate
