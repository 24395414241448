import React, { memo } from 'react'
import {
  Button,
  CodeSnippetSkeleton,
  FormLabel,
  Loading,
  ModalBody,
  ModalHeader,
  Select,
  SelectItem,
} from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { QueryStatus } from 'react-query'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { InstallCLIModalState } from 'src/next/components/InstallCLIModalContainer/InstallCLIModalContainer'
import Feedback from '../Feedback'
import * as Styled from './installCLIModal.styled'

interface Props {
  selectedOS: InstallCLIModalState['OS']
  selectedArch: InstallCLIModalState['Arch']
  selectedVersion: InstallCLIModalState['version']
  selectedChannel: InstallCLIModalState['channel']
  onChangeOS(value: InstallCLIModalState['OS']): void
  onChangeArch(value: InstallCLIModalState['Arch']): void
  onChangeVersion(value: InstallCLIModalState['version']): void
  onChangeChannel(value: InstallCLIModalState['channel']): void
  distributionVersionsList?: string[]
  distributionVersionsListStatus: QueryStatus
  channelsListStatus: QueryStatus
  downloadUrlStatus: QueryStatus
  channelsList?: string[]
  downloadUrl?: string
  command: null | string
  defaultCommand: string
  open: boolean
  onClose(): void
}

const InstallCLIModal = ({
  selectedOS,
  selectedArch,
  selectedVersion,
  selectedChannel,
  onChangeOS,
  onChangeArch,
  onChangeVersion,
  onChangeChannel,
  distributionVersionsList,
  distributionVersionsListStatus,
  channelsList,
  channelsListStatus,
  downloadUrl,
  downloadUrlStatus,
  command,
  defaultCommand,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation()
  const handleCopyCommandClick = () =>
    typeof command === 'string'
      ? navigator.clipboard.writeText(command)
      : undefined

  const isFetchingDistributionVersionsList =
    distributionVersionsListStatus === 'loading'
  const isErrorDistributionVersionsList =
    distributionVersionsListStatus === 'error'

  const isFetchingChannels = channelsListStatus === 'loading'
  const isSuccessChannels = channelsListStatus === 'success'
  const isErrorChannels = channelsListStatus === 'error'

  const isFetchingDownloadUrl = downloadUrlStatus === 'loading'
  const isSuccessDownloadUrl = downloadUrlStatus === 'success'
  const isErrorDownloadUrl = downloadUrlStatus === 'error'

  return (
    <Styled.Modal size="sm" open={open} onClose={onClose}>
      <ModalHeader title={t('CLIDownload.heading')} />
      <ModalBody>
        <p>{t('CLIDownload.subheading')}</p>

        <Styled.ModalHeading>
          {t('CLIDownload.secondary_heading')}
        </Styled.ModalHeading>

        <form>
          <Select
            id="cli-select-os"
            value={selectedOS ?? 'default'}
            labelText={t('CLIDownload.select_os_label')}
            invalidText={t('CLIDownload.error_invalid_value')}
            onChange={e =>
              onChangeOS(e.target.value as InstallCLIModalState['OS'])
            }
          >
            <SelectItem
              value="default"
              text={t('CLIDownload.select_default_option')}
              disabled
            />
            <SelectItem text={t('CLIDownload.OS_X')} value="darwin" />
            <SelectItem text={t('CLIDownload.Linux')} value="linux" />
            <SelectItem text={t('CLIDownload.Windows')} value="windows" />
          </Select>

          <Select
            id="cli-select-arch"
            value={selectedArch ?? 'default'}
            labelText={t('CLIDownload.select_arch_label')}
            invalidText={t('CLIDownload.error_invalid_value')}
            onChange={e =>
              onChangeArch(e.target.value as InstallCLIModalState['Arch'])
            }
          >
            <SelectItem
              value="default"
              text={t('CLIDownload.select_default_option')}
              disabled
            />
            <SelectItem text={t('CLIDownload.AMD64')} value="amd64" />
            <SelectItem
              text={t(
                selectedOS === 'darwin'
                  ? 'CLIDownload.ARM64_Mac'
                  : 'CLIDownload.ARM64',
              )}
              value="arm64"
            />
          </Select>

          <br />

          <FormLabel id="cli-select-version" htmlFor="cli-select-version">
            {t('CLIDownload.select_version_label')}
            {isFetchingDistributionVersionsList && (
              <Loading
                small
                withOverlay={false}
                data-testid="loading-versions"
              />
            )}
          </FormLabel>

          <Select
            id="cli-select-version"
            value={selectedVersion ?? 'default'}
            invalid={isErrorDistributionVersionsList}
            invalidText={
              isErrorDistributionVersionsList
                ? t('CLIDownload.error_fetching_versions')
                : t('CLIDownload.error_invalid_value')
            }
            hideLabel
            onChange={e =>
              onChangeVersion(e.target.value as InstallCLIModalState['version'])
            }
            disabled={
              !(
                selectedOS &&
                distributionVersionsList?.length &&
                !isErrorDistributionVersionsList
              )
            }
          >
            <SelectItem
              value="default"
              text={t('CLIDownload.select_default_option')}
              disabled
            />
            {distributionVersionsList?.map(node => (
              <SelectItem text={node} value={node} key={node} />
            ))}
          </Select>

          <br />

          <FormLabel id="cli-select-channel" htmlFor="cli-select-channel">
            {t('CLIDownload.select_channel_label')}
            {isFetchingChannels && (
              <Loading
                small
                withOverlay={false}
                data-testid="loading-channels"
              />
            )}
          </FormLabel>
          <Select
            id="cli-select-channel"
            value={selectedChannel ?? 'default'}
            invalid={!!isErrorChannels}
            invalidText={
              isErrorChannels
                ? t('CLIDownload.error_fetching_channels')
                : t('CLIDownload.error_invalid_value')
            }
            hideLabel
            onChange={e =>
              onChangeChannel(e.target.value as InstallCLIModalState['channel'])
            }
            disabled={!isSuccessChannels}
          >
            <SelectItem
              value="default"
              text={t('CLIDownload.select_default_option')}
              disabled
            />
            {isSuccessChannels &&
              channelsList?.map(node => (
                <SelectItem text={node} value={node} key={node} />
              ))}
          </Select>

          <br />

          <Styled.ModalHeading>
            {t('CLIDownload.copy_heading')}
          </Styled.ModalHeading>

          <FormLabel>{t('CLIDownload.copy_label')}</FormLabel>

          {isErrorDownloadUrl && (
            <InlineNotification
              title={t('CLIDownload.error_fetching_download_url')}
              kind="error"
            />
          )}

          {isFetchingDownloadUrl ? (
            <CodeSnippetSkeleton data-testid="loading-download-url" />
          ) : (
            <Styled.CodeSnippet
              type="multi"
              disabled={!command}
              wrapText
              maxCollapsedNumberOfRows={8}
              feedback={t('Common.Copied')}
              data-testid="download-url"
            >
              {command ?? defaultCommand}
            </Styled.CodeSnippet>
          )}
        </form>
      </ModalBody>

      <Styled.ModalFooter>
        <Button onClick={onClose} kind="secondary">
          {t('Common.Cancel')}
        </Button>

        <Feedback content={t('Common.CommandCopiedToClipboard')}>
          <Button disabled={!command} onClick={handleCopyCommandClick}>
            {t('CLIDownload.copy_button_caption')}
          </Button>
        </Feedback>
        <Button disabled={!isSuccessDownloadUrl} href={downloadUrl || ''}>
          {t('CLIDownload.download_button_caption')}
        </Button>
      </Styled.ModalFooter>
    </Styled.Modal>
  )
}

export default memo(InstallCLIModal)
