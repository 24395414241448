import React, { useState } from 'react'
import { Pagination } from '@carbon/react'
import { PaginationProps } from '@carbon/react/lib/components/Pagination/Pagination'
import { useTranslation } from 'react-i18next'
import { UseInfiniteQueryResult } from 'react-query'
import styled from 'styled-components'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { removeFocusFromButton } from 'src/next/utils'

const StyledPagination = styled(Pagination)`
  .cds--select--inline .cds--select-input {
    background-color: var(--cds-layer-01);
    width: inherit;
  }
`

interface PaginationChange {
  page: number
  pageSize: number
}

export const usePagination = (key: string, initialPageSize = 10) => {
  const [pageSize, setPageSize] = useLocalStorage(key, initialPageSize)
  const [page, setPage] = useState(1)

  const resetPage = () => setPage(1)

  const onChange = ({ page, pageSize }: PaginationChange) => {
    setPage(page)
    setPageSize(pageSize)
  }

  return {
    onChange,
    pageSize,
    page,
    setPage,
    resetPage,
  }
}

interface DataTablePaginationProps
  extends Omit<PaginationProps, 'pageSizes' | 'onChange'> {
  page?: number
  pageSizes?: number[]
  onChange?(data: PaginationChange): void
  query?: UseInfiniteQueryResult<any, Error>
}

const inferTotalItems = (data: any, loadedAllPages: boolean) => {
  const pages = data?.pages
  if (pages === undefined) return undefined

  if (pages.length === 0) return undefined

  const lastPage = pages[pages.length - 1]
  if (lastPage === undefined) return undefined

  // Some of our CloudNatix Global Controller APIs have the `totalSize` field.
  const totalSize = lastPage.totalSize
  if (totalSize === -1) {
    // When `totalSize` is set to `-1`, it indicates the total size is unknown.
    // One typical case is listing events in the "Clusters" page.
    // Once we finish loading the last page, we should be able to calculate the total size by summing up the number of items in each page.
    // However, so far it's impossible for the frontend side as the field name is not consistent.

    // Normalize to `undefined`.
    return undefined
  }
  if (totalSize !== undefined) {
    // When `totalSize` is set to the actual number of items, just return it.
    return totalSize
  }

  if (lastPage.rows === undefined) {
    return undefined
  }

  // Consider a response with `rows` field as a response from a Kubernetes API.

  // Once the last page is loaded, just sum up the number of items in each page.
  if (loadedAllPages) {
    return pages.reduce((acc, page) => {
      // Not handling this as an error but just gracefully return `acc` assuming all the pages have the same structure.
      if (page.rows === undefined) {
        return acc
      }

      return acc + page.rows.length
    }, 0)
  }

  const remainingCount = lastPage.metadata?.remainingItemCount
  if (remainingCount === undefined) {
    // For example, queries with a `fieldSelector` get a response without `remainingItemCount` set.
    // One typical case is listing pods of a certain node.
    return undefined
  }

  // For example, a simple query to list pods without any selector gets a response with `remainingItemCount` set.
  const loadedCount = pages.reduce((acc, page) => {
    if (page.rows !== undefined) {
      return acc + page.rows.length
    }
    return acc
  }, 0)

  return loadedCount + remainingCount
}

export const DataTablePagination = ({
  pageSize,
  page = 1,
  onChange,
  query,
  pageSizes = [10, 20, 30, 40, 50],
  totalItems,
  ...rest
}: DataTablePaginationProps) => {
  const { t } = useTranslation()

  const { data, fetchNextPage, hasNextPage } = query || {}

  const loadedAllPages = hasNextPage !== undefined && !hasNextPage

  // If `totalItems` is not provided through the props, we try to calculate it from the data.
  if (totalItems === undefined) {
    totalItems = inferTotalItems(data, loadedAllPages)
  }

  const numPagesLoaded = data?.pages?.length

  const handleChange = ({ page: newPage, pageSize }: PaginationChange) => {
    // Remove focus from the next/previous page button to hide the tooltip
    removeFocusFromButton()

    onChange?.({ page: newPage, pageSize })

    if (
      // The next page button was pressed.
      newPage === page + 1 &&
      // The page to show is not yet loaded.
      numPagesLoaded !== undefined &&
      newPage > numPagesLoaded
    )
      fetchNextPage?.()
  }

  const pagesUnknown = totalItems === undefined

  const isLastPage =
    // At the last page of all loaded pages.
    numPagesLoaded !== undefined &&
    page >= numPagesLoaded &&
    // There's no more page to load.
    loadedAllPages

  return (
    <StyledPagination
      pageInputDisabled
      pageSizes={pageSizes}
      pageSize={pageSize}
      page={page}
      isLastPage={isLastPage}
      totalItems={totalItems}
      pagesUnknown={pagesUnknown}
      onChange={handleChange}
      disabled={query === undefined || query.isError || query.isFetching}
      backwardText={t('Pagination.PreviousPage')}
      forwardText={t('Pagination.NextPage')}
      itemText={(min, max) =>
        query === undefined || query.isError
          ? ''
          : t('Pagination.ItemText', { min, max })
      }
      itemRangeText={(min, max, total) =>
        query === undefined || query.isError
          ? ''
          : t('Pagination.ItemRangeText', {
              min,
              max,
              total,
              count: total, // Pluralization
            })
      }
      itemsPerPageText={t('Pagination.ItemsPerPage')}
      pageNumberText={t('Pagination.PageNumber')}
      {...rest}
    />
  )
}
