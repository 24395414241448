import { useMemo } from 'react'
import { flatten, groupBy, sumBy } from 'lodash'
import max from 'lodash/max'
import maxBy from 'lodash/maxBy'
import min from 'lodash/min'
import minBy from 'lodash/minBy'

import { GraphDataPoint } from 'src/next/components/Graphs'

import { GraphConfig } from '../index'

export const useGraphBoundaries = (graphs: GraphConfig[]) => {
  const boundariesPerGraph = useMemo(() => {
    const stackedGraphDataPoints: GraphDataPoint[][] = []
    const graphDataPoints: GraphDataPoint[][] = []

    graphs
      .filter(graph => graph.enabled ?? true)
      .forEach(graph => {
        switch (graph.type) {
          case 'area':
            if (graph.stack) {
              // For stacked graphs, we need to calculate the boundaries by adding all
              // values per timestamp together.
              //
              // 1. Flatten all graphs into single array;
              // 2. Group by timestamp;
              // 3. Sum all values per timestamp
              // 4. Create new array with the summed value per timestamp.
              stackedGraphDataPoints.push(graph.data)
              return
            }

            graphDataPoints.push(graph.data)
            return
          case 'bar':
            graphDataPoints.push(graph.data)
            return
          case 'line':
            graphDataPoints.push(graph.data)
            return
          case 'setting':
            // For now, y of setting graphs are not taken into account.
            return
          case 'event':
          case 'state':
          default:
            return
        }
      })

    if (stackedGraphDataPoints.length > 0) {
      const summedPerTimestamp: GraphDataPoint[] = Object.values(
        groupBy(flatten(stackedGraphDataPoints), 'x'),
      ).map(values => ({
        x: values[0].x,
        y: sumBy(values, 'y'),
      }))
      graphDataPoints.push(summedPerTimestamp)
    }

    // For normal graphs just find the highest and lowest values.
    return graphDataPoints.map(graphData => ({
      minX: minBy(graphData, d => d.x)?.x,
      maxX: maxBy(graphData, d => d.x)?.x,
      minY: minBy(graphData, d => d.y)?.y,
      maxY: maxBy(graphData, d => d.y)?.y,
    }))
  }, [graphs])

  return useMemo(
    () => ({
      minX: min(boundariesPerGraph.map(b => b.minX)),
      maxX: max(boundariesPerGraph.map(b => b.maxX)),
      minY: min(boundariesPerGraph.map(b => b.minY)),
      maxY: max(boundariesPerGraph.map(b => b.maxY)),
    }),
    [boundariesPerGraph],
  )
}
