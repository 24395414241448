import React from 'react'
import { Network_2 as OrgsIcon } from '@carbon/react/icons'
import { useRegisterActions } from 'kbar'
import { useOrgs } from 'src/api'
import { useOrgSelection } from 'src/next/hooks'

export const useCLUIRegisterOrgsSwitch = (enabled: boolean) => {
  const query = useOrgs({ enabled })

  // const { setOrgId } = useUserContext();

  const [orgUuid, setOrgUuid] = useOrgSelection()

  const sortedList = [...(query?.data?.orgs || [])].sort(a => {
    const isRootId = !a.parentOrgUuid

    if (isRootId) return -1
    return 1
  })

  useRegisterActions(
    sortedList.map(org => ({
      id: org.uuid || '',
      name: org.name || '',
      subtitle:
        query?.data?.orgs?.find(item => item.uuid === org.parentOrgUuid)
          ?.name || '',
      icon: <OrgsIcon size={20} />,
      parent: 'orgsSwitch',
      perform: () => setOrgUuid(org.uuid!),
    })) || [],
    [query?.data],
  )

  return query
}
