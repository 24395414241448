import React, { useEffect, useState } from 'react'
import { Search, SearchProps } from '@carbon/react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { MiddleTruncate } from 'src/next/components/MiddleTruncate'
import { Tag } from 'src/next/components/ui/Tag'
import { Optional } from 'src/next/types/helpers'

import { useTableFilter } from '../TableFilterContext'

const StyledSearch = styled(Search)`
  margin-bottom: var(--cds-spacing-03);
`

interface useTableFilterSearchSelectionProps {
  id: string
  singleSelection?: boolean
}

export const useTableFilterSearchSelection = ({
  id,
  singleSelection = false,
}: useTableFilterSearchSelectionProps) => {
  const { control, watch } = useFormContext()
  const { field } = useController({
    control,
    name: id,
  })
  const currentItems = watch(id) || []

  return (name?: string) => {
    if (
      !name ||
      currentItems.includes(name) ||
      (singleSelection && currentItems.length >= 1)
    )
      return

    field.onChange([...currentItems, name])
  }
}

interface TableFilterSearchSelectionProps
  extends Omit<Optional<SearchProps, 'labelText'>, 'placeholder'> {
  id: string
  placeholder: string
  singleSelection?: boolean
}

export const TableFilterSearchSelection = ({
  id,
  placeholder,
  labelText = placeholder,
  singleSelection = false,
  ...props
}: TableFilterSearchSelectionProps) => {
  const { t } = useTranslation()

  const [input, setInput] = useState('')

  const { control, watch } = useFormContext()
  const { field } = useController({
    control,
    name: id,
  })
  const currentItems = watch(id) || []

  const { contextId, registerDefaultValue } = useTableFilter()

  // todo: fix me
  // register the defaultValue only on initial render
  useEffect(() => {
    registerDefaultValue(id, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addValue = (name: string) => {
    if (
      !name ||
      currentItems.includes(name) ||
      (singleSelection && currentItems.length >= 1)
    )
      return

    field.onChange([...currentItems, name])
  }

  const removeValue = (value: string) => {
    const newList = currentItems.filter((name: string) => name !== value)
    field.onChange(newList)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return

    addValue(event.currentTarget.value.trim())
    setInput('')

    event.preventDefault()
  }

  return (
    <div>
      <StyledSearch
        id={`${contextId}-${id}-search`}
        size="sm"
        onKeyDown={handleKeyDown}
        value={input}
        placeholder={placeholder}
        labelText={labelText}
        onChange={e => setInput(e.target.value)}
        {...props}
      />
      {currentItems.map((name: string) => (
        <Tag
          key={name}
          title={t('Filters.ClearFilter')}
          aria-label={t('Filters.ClearFilter')}
          onClick={() => removeValue(name)}
          filter
        >
          <MiddleTruncate text={name} charsAtStart={15} charsAtEnd={15} />
        </Tag>
      ))}
    </div>
  )
}
