import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { GraphConfig } from 'src/next/components/Graphs'
import { GraphColor } from 'src/next/types/workloads'
import { Legend, LegendItem } from './Legend'

export const useLegend = <T extends GraphConfig>(
  graphConfig: T[],
  onChange?: (legendItems: string[]) => void,
) => {
  const [allLegendIds, setAllLegendIds] = useState([''])

  const [checkedLegendIds, setCheckedLegendIds] = useState([''])

  const legendIds = useMemo(
    () => graphConfig.map(({ id }) => id),
    [graphConfig],
  )

  useLayoutEffect(() => {
    if (!legendIds.length) return

    // Don't reset changes on the legends if the list of IDs are the same.
    // For example, when the metrics or time range is changed, but the list of series (e.g. cluster IDs) is unchanged,
    // it's convenient if the changes are kept.
    if (isEqual(allLegendIds, legendIds)) return

    setAllLegendIds(legendIds)
    setCheckedLegendIds(legendIds)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legendIds])

  const handleLegendChange = (id: string) => {
    const newSelection = checkedLegendIds.filter(item => item !== id)

    // make sure at least 1 item is selected
    if (!newSelection.length) return false

    const newLegendItems = checkedLegendIds.includes(id)
      ? newSelection
      : [...checkedLegendIds, id]

    setCheckedLegendIds(newLegendItems)
  }

  useEffect(() => onChange?.(checkedLegendIds), [checkedLegendIds, onChange])

  const legendItems = graphConfig.map(({ id, props, label }) => {
    const color =
      props?.style?.data?.fill ||
      props?.style?.data?.stroke ||
      'var(--carbonPalette1)'

    return (
      <LegendItem
        id={id}
        key={id}
        color={color as GraphColor}
        onChange={() => handleLegendChange(id)}
        checked={checkedLegendIds.includes(id)}
      >
        {label}
      </LegendItem>
    )
  })

  const returnValue = useMemo(
    () => ({
      Legend,
      graphConfig: graphConfig.map(config => ({
        ...config,
        enabled: checkedLegendIds.includes(config.id),
      })),
      legendProps: {
        children: legendItems,
      },
    }),
    [graphConfig, legendItems, checkedLegendIds],
  )

  return returnValue
}
