/* eslint-disable react/display-name */
import React, { memo } from 'react'
import { HeaderName } from '@carbon/react'
import styled from 'styled-components'
import { OrgSelector } from 'src/next/components/OrgSelector'

const StyledHeaderName = styled(HeaderName)`
  padding-right: var(--cds-spacing-05) !important;

  &,
  &:hover {
    color: var(--cds-text-primary) !important;
    font-size: 1rem !important;
  }
`

export const HeaderTitle = memo(() => {
  return (
    <>
      <StyledHeaderName href="/" prefix="CloudNatix">
        {' '}
      </StyledHeaderName>
      {'/ '}
      <OrgSelector />
    </>
  )
})
