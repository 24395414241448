/**
 * currencyFormat: Format a number to a currency formatted string
 * Currently only supports en-US locale.
 */
export const currencyFormat = (
  value: number,
  options?: Intl.NumberFormatOptions,
) => {
  if (isNaN(value) || value === Infinity) {
    return ''
  }
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    unitDisplay: 'narrow',
    currencyDisplay: 'narrowSymbol',
    ...options,
  }).format(value)
}
