import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from 'src/next/components'
import { CollapseButton } from 'src/next/components/ui'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { CpuMetrics } from './CpuMetrics'
import { GpuMetrics } from './GpuMetrics'
import { GpuMemoryMetrics } from './GpuMemoryMetrics'
import { MemoryMetrics } from './MemoryMetrics'
import { NumAccountsMetrics } from '../../administration/Metrics/NumAccountsMetrics'
import { NumClustersMetrics } from './NumClustersMetrics'
import { NumInstancesMetrics } from './NumInstancesMetrics'
import { SavingsMetrics } from './SavingsMetrics'
import { SpendMetrics } from './SpendMetrics'

const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--cds-spacing-04);

  > * {
    flex: 1 0 320px;
  }
`

export const DashboardMetrics = () => {
  const { t } = useTranslation()
  const [showMetrics, setShowMetrics] = useLocalStorage<boolean>(
    'show-dashboard-metrics',
    true,
  )

  return (
    <Box position="relative" mb="6">
      <CollapseButton
        open={showMetrics}
        onClick={() => setShowMetrics(!showMetrics)}
        iconDescription={
          showMetrics
            ? t('Dashboard.TopMetrics.Hide', 'Hide metrics')
            : t('Dashboard.TopMetrics.Show', 'Show metrics')
        }
        testId="toggle-dashboard-metrics"
      />
      {showMetrics && (
        <Wrapper>
          <SpendMetrics />
          <SavingsMetrics />
          <CpuMetrics />
          <MemoryMetrics />
          <GpuMetrics />
          <GpuMemoryMetrics />
          <NumInstancesMetrics />
          <NumClustersMetrics />
        </Wrapper>
      )}
    </Box>
  )
}
