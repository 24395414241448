/* eslint-disable react/no-unknown-property */
import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { ListActivitiesRequestOrderBy } from '@cloudnatix-types/activity-tracker'
import { useAuditLogs } from 'src/api'
import { DatePickerMenu, Flex, maxDate } from 'src/next/components'
import DataTable, {
  useTableControls,
  DataTablePagination,
} from 'src/next/components/DataTable'
import { FeatureInfo } from 'src/next/components/FeatureInfo'
import {
  InlineNotification,
  ResetFiltersLink,
} from 'src/next/components/InlineNotification'
import {
  TableFilterToolbarActions,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { useGetTimeframe } from 'src/next/hooks'
import { Timeframe, TimeIntervals } from 'src/next/types'

const parseOrderBy = (orderBy: string) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [value, direction] = orderBy?.split(' ')

  return {
    orderBy: value as ListActivitiesRequestOrderBy,
    asc: direction === 'asc',
  }
}

export const AuditLogTable = () => {
  const { t } = useTranslation()

  // Pin the current time on the first render so that useGetTimeframe() returns
  // the same interval while the result table with pagination is used by the
  // user.
  const [pinnedEnd, setPinnedEnd] = useState<Date>(() => new Date())

  const [timeInterval, setTimeInterval] = useState<TimeIntervals>('days')

  const [dates, setDates] = useState<Timeframe[]>([])
  const datePicked = dates.length !== 0

  const { startTimeNs, endTimeNs } = useGetTimeframe({
    timeInterval,
    start: datePicked ? dates[0] : undefined,
    end: datePicked ? dates[1] : pinnedEnd,
  })

  // Even when the mode is switched between 2 non-custom modes (e.g. 2 hours
  // and 2 days), `DatePickerMenu` sets a new empty array to trigger this
  // useEffect().
  useEffect(() => {
    if (datePicked) {
      return
    }

    setPinnedEnd(new Date())
  }, [dates])

  const { orderBy, pagination } = useTableControls('mngmt-audit-page-size')

  const { resetPage, setPage, ...dataTablePaginationProps } = pagination

  const headers = useHeaders()

  const { filters, activeFiltersCount, methods, reset } = useTableFilter()

  // reset pagination when filters change
  methods.watch(() => resetPage())

  const query = useAuditLogs({
    pageSize: pagination.pageSize,
    filter: {
      ...filters,
      startTimeNs: startTimeNs.toString(),
      endTimeNs: endTimeNs.toString(),
    },
    ...(orderBy.value && parseOrderBy(orderBy.value)),
  })

  const { data, isFetching, isError } = query

  const currentPageData = data?.pages?.[pagination.page - 1] || {}

  const activities = useMemo(
    () => currentPageData.activities || [],
    [currentPageData.activities],
  )

  const formattedRows = useMemo(
    () =>
      activities?.map(activity => {
        return {
          ...activity,
          id: activity.uuid!,
          // You can safely ignore the type error that `dayjs()` cannot accept
          // `GoogleProtobufTimestamp.Timestamp`.
          //
          // The generated type for the `timestamp` field on `Activity` is
          // GoogleProtobufTimestamp.Timestamp, but the actual value to be
          // received over HTTP encoded in JSON is an RFC 3339 formatted string.
          // See https://github.com/protocolbuffers/protobuf/blob/a9b11dd7bdb197f861d3e20c3e447e831d587c80/src/google/protobuf/timestamp.proto#L108
          // for details.
          timestamp: dayjs(activity.timestamp).format('llll'),
          eventType: activity.eventType?.verb,
        }
      }) || [],
    [activities],
  )

  return (
    <Flex flexDirection="column" overflow="auto">
      <DataTable
        storageKey="audit-logs-table"
        headers={headers}
        rows={formattedRows}
        isLoading={isFetching}
        pageSize={pagination.pageSize}
        orderBy={orderBy}
        size="xl"
        setPage={setPage}
        toolbar={
          <Flex justifyContent="space-between" width={1}>
            <FeatureInfo definition={t('AuditLogs.FeatureInfo')} />
            <Flex>
              <TableFilterToolbarActions />
              <Flex>
                <DatePickerMenu
                  id="date-time-picker-menu"
                  withTimeInput
                  maxDate={maxDate}
                  timeInterval={timeInterval}
                  setTimeInterval={setTimeInterval}
                  setDates={setDates}
                />
              </Flex>
            </Flex>
          </Flex>
        }
      />
      {(!isFetching && !formattedRows?.length) || isError ? (
        <InlineNotification
          title={t('AuditLogs.DataTable.NoData')}
          subtitle={activeFiltersCount ? t('Filters.NoResults') : null}
        >
          {activeFiltersCount ? (
            <ResetFiltersLink
              activeFiltersCount={activeFiltersCount}
              reset={reset}
            />
          ) : null}
        </InlineNotification>
      ) : (
        <DataTablePagination {...dataTablePaginationProps} query={query} />
      )}
    </Flex>
  )
}

const useHeaders = () => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        key: 'subject',
        header: t('AuditLogs.DataTable.Subject'),
        sort: 'SUBJECT',
      },
      {
        key: 'dataSource',
        header: t('AuditLogs.DataTable.DataSource'),
        sort: 'DATA_SOURCE',
      },
      {
        key: 'timestamp',
        header: t('AuditLogs.DataTable.Timestamp'),
        sort: 'TIMESTAMP',
      },
      {
        key: 'targetType',
        header: t('AuditLogs.DataTable.TargetType'),
      },
      {
        key: 'target',
        header: t('AuditLogs.DataTable.Target'),
        sort: 'TARGET',
      },
      {
        key: 'action',
        header: t('AuditLogs.DataTable.Action'),
      },
      {
        key: 'responseCode',
        header: t('AuditLogs.DataTable.ResponseCode'),
      },
      {
        key: 'eventType',
        header: t('AuditLogs.DataTable.EventType'),
      },
      {
        key: 'description',
        header: t('AuditLogs.DataTable.Description'),
      },
    ],
    [t],
  )
}
