import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { PaddingProps, VictoryBarProps } from 'victory'

import { ComputeInstanceSummary, Summary } from '@cloudnatix-types/dashboard'

import { Legend, LegendItem } from 'src/next/components'
import { BarGraphProps } from 'src/next/components/Graphs'
import { GraphTooltipContainerProps } from 'src/next/components/Graphs/GraphTooltipContainer/GraphTooltipContainer'
import StackedBarChart from 'src/next/components/StackedBarChart'
import { GraphColor } from 'src/next/types/workloads'
import {
  createFormatFn,
  getFormatType,
  getStackedMaxYDomain,
} from 'src/next/utils/graph.utils'
import { middleEllipsis } from 'src/next/utils/middle-ellipsis'
import { TimeRangeFromPeriod, TimePeriod } from 'src/next/utils/time'

const LegendWrapper = styled.div`
  margin-left: var(--cds-spacing-04);
  width: 100%;
`

interface TransformedSummary {
  id: string
}

const createGraphConfig = (
  data: TransformedSummary[][],
  yAccessorKey: string,
  colorMap: ColorMap,
  selectedId: string | undefined,
): BarGraphProps[] => {
  const formatTooltipValue = createFormatFn(getFormatType(yAccessorKey))

  return data.map((itemData, index) => ({
    id: `stacked-bar-${index}`,
    // For `StackedBarChart`, the number of elements in `activePoints` that `GraphTooltip` receives is the same as the number of `VictoryBar`s created.
    // For timestamps that doesn't have the same number of data as the number of `VictoryBar`s, there will be some meaningless elements in `activePoints`.
    // Not to show an empty tooltip rows for them, return `undefined` so that `GraphTooltip` ignores them.
    tooltipLabel: ({ groupingName }) =>
      groupingName ? middleEllipsis(groupingName, 15, 25) : undefined,
    type: 'bar',
    data: itemData.map(summary => {
      const { id } = summary
      return {
        ...summary,
        style: {
          data: {
            fill: colorMap.find(u => u.id === id)?.color,
            opacity:
              selectedId === undefined || selectedId === '' || selectedId === id
                ? 1
                : 0.3,
          },
        },
      }
    }),
    tooltipValueTransformFn: (_value: any, pointData: any) =>
      formatTooltipValue(pointData[yAccessorKey]) || '',
  }))
}

export type ColorMap = { id: string; name: string; color: GraphColor }[]

interface StackedSummariesChartProps {
  data: TransformedSummary[][]
  timePeriod: TimePeriod
  selectedId: string | undefined
  setSelectedId: ((id: string) => void) | undefined
  yAccessorKey: keyof Summary | keyof ComputeInstanceSummary | string
  colorMap: ColorMap
  containerProps?: GraphTooltipContainerProps | undefined
  width: number
  height: number
  padding: PaddingProps | undefined
}

export const StackedSummariesChart = ({
  data,
  timePeriod,
  selectedId,
  setSelectedId,
  yAccessorKey,
  colorMap,
  containerProps,
  width,
  height,
  padding,
}: StackedSummariesChartProps) => {
  const yFormatFn = useCallback(
    value =>
      createFormatFn(getFormatType(yAccessorKey), {
        cpu: { shortDisplayValue: true },
        memory: { maximumFractionDigits: 0 },
        disk: { maximumFractionDigits: 0 },
        currency: { minimumFractionDigits: 0 },
      })(value),
    [yAccessorKey],
  )

  const [checkedLegendItems, setCheckedLegendItems] = useState(
    colorMap.map(({ id }) => id),
  )

  useEffect(() => {
    setCheckedLegendItems(colorMap.map(f => `${f.id}`))
  }, [colorMap])

  const graphConfig = useMemo(() => {
    if (data.length === 0) throw new Error('graphConfig could not be generated')

    return createGraphConfig(data, yAccessorKey, colorMap, selectedId)
  }, [data, yAccessorKey, selectedId, colorMap])

  const filteredGraphConfig = useMemo(() => {
    return graphConfig.map(config => {
      const filteredData = config.data.filter(d => {
        const data = d as TransformedSummary
        return data && checkedLegendItems.includes(data.id)
      })

      return {
        ...config,
        data: filteredData,
      } as BarGraphProps
    })
  }, [graphConfig, checkedLegendItems])

  const maxYDomain = getStackedMaxYDomain(filteredGraphConfig, yAccessorKey)

  const handleLegendChange = (id: string) => {
    if (!checkedLegendItems.includes(id)) {
      setCheckedLegendItems([...checkedLegendItems, id])
      return
    }

    const legendItems = checkedLegendItems.filter(checkedID => checkedID !== id)

    // Make sure at least 1 item is selected.
    if (legendItems.length === 0) return false

    setCheckedLegendItems(legendItems)
  }

  const barProps: VictoryBarProps | undefined = useMemo(() => {
    if (setSelectedId === undefined) {
      return undefined
    }

    return {
      events: [
        {
          target: 'data',
          eventHandlers: {
            onClick: (_, { datum }: { datum: TransformedSummary }) => {
              const id = datum?.id!
              setSelectedId(selectedId === id ? '' : id)
            },
          },
        },
      ],
    }
  }, [selectedId, setSelectedId])

  return (
    <>
      <StackedBarChart
        graphConfig={graphConfig}
        graphConfigFiltered={filteredGraphConfig || null}
        yAccessorKey={yAccessorKey}
        yDomain={[0, maxYDomain]}
        yTickFormat={yFormatFn}
        tooltipHeading={(selectedId?: string | null, activePoint?: any) => {
          if (selectedId) {
            const name = colorMap.find(f => f.id === selectedId)?.name
            return middleEllipsis(name, 20, 20)
          }

          const date = dayjs(activePoint?._x)
          if (!date.isValid()) return ''

          return <TimeRangeFromPeriod start={date} timePeriod={timePeriod} />
        }}
        containerProps={containerProps}
        barProps={barProps}
        selectedId={selectedId}
        width={width}
        height={height}
        padding={padding}
      />
      <LegendWrapper>
        <Legend>
          {colorMap.map(({ id, name, color }) => {
            return (
              <LegendItem
                id={id || `unnamed-${color}`}
                key={id}
                title={name}
                color={color}
                onChange={() => handleLegendChange(id)}
                checked={checkedLegendItems.includes(id)}
              >
                {middleEllipsis(name, 10, 10)}
              </LegendItem>
            )
          })}
        </Legend>
      </LegendWrapper>
    </>
  )
}
