import { Edit, EventSchedule, TrashCan } from '@carbon/react/icons'
import { Button, ButtonProps } from '@carbon/react'

interface DataTableActionButtonProps extends ButtonProps {
  actionType?: 'edit' | 'delete' | 'schedule'
}

const iconMap = {
  edit: Edit,
  delete: TrashCan,
  schedule: EventSchedule,
}

export const DataTableActionButton = ({
  ...props
}: DataTableActionButtonProps) => {
  const { actionType, ...buttonProps } = props

  return (
    <Button
      kind="ghost"
      tooltipPosition="left"
      hasIconOnly
      renderIcon={iconMap[actionType || 'edit']}
      {...buttonProps}
    />
  )
}
