import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import { Box, Flex } from 'src/next/components'
import { ContentSwitcher, Switch } from 'src/next/components/ContentSwitcher'
import { GenericTab } from './GenericTab'

type InstancesGroupingKey = Extract<
  keyof typeof ComputeInstanceGroupingKeyKey,
  'INSTANCE_TYPE' | 'INSTANCE_ID'
>

export const InstancesTab = () => {
  const { t } = useTranslation()

  const [groupingKey, setGroupingKey] =
    useState<InstancesGroupingKey>('INSTANCE_ID')

  return (
    <>
      <Flex my="5">
        <Box flex="1">
          <ContentSwitcher
            onChange={({ name }) =>
              setGroupingKey(name as InstancesGroupingKey)
            }
            selectedIndex={0}
            size="md"
            data-testid="grouping-key"
          >
            <Switch name="INSTANCE_ID" text={t('Common.Id')} />
            <Switch name="INSTANCE_TYPE" text={t('Common.Type')} />
          </ContentSwitcher>
        </Box>
      </Flex>

      <GenericTab
        id={
          groupingKey === 'INSTANCE_ID'
            ? 'dashboard-instance-id'
            : 'dashboard-instance-type'
        }
        groupingKeyKey={groupingKey}
        key={groupingKey}
      />
    </>
  )
}
