/* eslint-disable react/display-name */
import React, { memo, useCallback, useMemo } from 'react'
import { Content, Header, HeaderMenuButton, SkipToContent } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { SideNav } from 'src/App/SideNav'
import useBreakpoint from 'src/next/hooks/useBreakpoint'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { HeaderRightMenu, HeaderTitle } from './AppHeader'

const StyledHeaderMenuButton = styled(HeaderMenuButton)`
  > svg {
    fill: var(--cds-icon-primary);
  }

  :hover > svg {
    fill: var(--cds-icon-on-color);
  }
`

const StyledHeader = styled(Header)`
  background-color: var(--cds-background);
  border-bottom: 0;

  .cds--header__global {
    .cds--btn--primary {
      color: var(--cds-icon-primary);
    }

    .cds--btn--primary:hover {
      color: var(--cds-text-on-color);
    }
  }
`

const StyledContent = styled(Content)<{
  $sideBarWidth: number
}>`
  position: relative;
  z-index: 0;
  padding-top: 70px;

  ${({ $sideBarWidth }) => `
    margin-left: ${$sideBarWidth}px;
    width: calc(100% - ${$sideBarWidth}px);
  `};
`

interface AppShellProps {
  children: React.ReactNode
}

export const AppShell = memo(({ children }: AppShellProps) => {
  const { t } = useTranslation()

  const { isDesktop } = useBreakpoint()

  const [isSideNavExpanded, setSideNavExpanded] = useLocalStorage(
    'sidebar-expanded',
    false,
  )

  const menuButtonLabel = useMemo(() => {
    if (!isSideNavExpanded) {
      return t('SideBar.AriaLabel.OpenMenu')
    }

    if (isDesktop) {
      // When the screen size is the `Large` breakpoint or larger, we keep the side nav shown in the icon-only form when collapsed.
      // Therefore, the effect of the button is to minimize it
      return t('SideBar.AriaLabel.MinimizeMenu')
    }

    // When the screen size is smaller than the `Large` breakpoint, we hide the side nav when collapsed.
    // Therefore, the effect of the button is to close it.
    return t('SideBar.AriaLabel.CloseMenu')
  }, [t, isDesktop, isSideNavExpanded])

  const sideNavWidth = useMemo(() => {
    if (!isDesktop) {
      // When the screen size is smaller than the `Large` breakpoint, let the side nav overlap the content.
      return 0
    }

    // Otherwise, move the content to the right not to make it overlap with the sidebar.

    if (isSideNavExpanded) {
      const sideNavExpandedWidth = 256
      return sideNavExpandedWidth
    }

    const sideNavIconsOnlyWidth = 48
    return sideNavIconsOnlyWidth
  }, [isDesktop, isSideNavExpanded])

  const handleSideNavToggle = useCallback(() => {
    setSideNavExpanded(!isSideNavExpanded)
  }, [setSideNavExpanded, isSideNavExpanded])

  return (
    <>
      <StyledHeader aria-label="CloudNatix">
        <SkipToContent />
        <StyledHeaderMenuButton
          aria-label={menuButtonLabel}
          isCollapsible
          onClick={handleSideNavToggle}
          isActive={isSideNavExpanded}
        />
        <HeaderTitle />
        <HeaderRightMenu />
      </StyledHeader>
      <div
        css={css`
          display: flex;
          width: 100%;
          position: relative;
          z-index: 0;
        `}
      >
        {/* When the screen size is smaller than the `Large` breakpoint, show the side nav only when expanded. */}
        {isDesktop || isSideNavExpanded ? (
          <div
            css={css`
              position: relative;
              z-index: 10;
            `}
          >
            <SideNav
              isSideNavExpanded={isSideNavExpanded}
              onSideNavToggle={handleSideNavToggle}
            />
          </div>
        ) : null}
        <StyledContent
          tagName="div"
          id="main-content"
          $sideBarWidth={sideNavWidth}
        >
          {children}
        </StyledContent>
      </div>
    </>
  )
})
