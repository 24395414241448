import { useInfiniteQuery } from 'react-query'
import {
  ListVMConfigRequest,
  ListVMConfigResponse,
} from '@cloudnatix-types/multiclustercontroller'
import { fetchData, useRequestParams } from 'src/api'

const url = '/v1/vmconfigs'
const key = url

export const useVMConfigs = (request: ListVMConfigRequest) => {
  const params = useRequestParams({ request })
  return useInfiniteQuery<ListVMConfigResponse, Error>(
    [key, request],
    ({ pageParam }) => {
      return fetchData<ListVMConfigResponse>(url, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage =>
        lastPage.nextPageToken === '' ? undefined : lastPage.nextPageToken,
      staleTime: 60 * 60 * 1000,
    },
  )
}
