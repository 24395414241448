import { TFunction } from 'react-i18next'
import { TimeRange, TimeRangeOption } from './TimerangeTypes'

// Disabled in CNATIX-2199 - will be enabled again once supported by BE
// {
//   id: 'hour',
//   label: t('Common.PastX', { val: '1 $t(Common.Time.Hour)' }),
// },
// {
//   id: '12_hours',
//   label: t('Common.PastX', { val: '12 $t(Common.Time.Hours)' }),
// },

interface Props {
  t: TFunction
  show24HoursOption: boolean
  show1YearOption: boolean
}

export const getTimeRangeOptions = ({
  t,
  show24HoursOption = true,
  show1YearOption = false,
}: Props): TimeRangeOption[] => [
  ...(show24HoursOption
    ? [
        {
          id: '24_hours' as TimeRange,
          label: t('Common.PastX', { val: `24 ${t('Common.Time.Hours')}` }),
        } as TimeRangeOption,
      ]
    : []),
  {
    id: 'week',
    label: t('Common.PastX', { val: `1 ${t('Common.Time.Week')}` }),
  },
  {
    id: 'month',
    label: t('Common.PastX', { val: `1 ${t('Common.Time.Month')}` }),
  },
  {
    id: '6months',
    label: t('Common.PastX', { val: `6 ${t('Common.Time.Months')}` }),
  },
  ...(show1YearOption
    ? [
        {
          id: '1year',
          label: t('Common.PastX', { val: `1 ${t('Common.Time.Year')}` }),
        } as TimeRangeOption,
      ]
    : []),
]

// t(Common.Time.Hour)
