import React, { useState } from 'react'
import { ChevronDown } from '@carbon/react/icons'
import { Button } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface CollapseComponentProps {
  children: any
  testId?: string
  show?: boolean
}

const StyledToggle = styled.div<{ open: boolean }>`
  svg {
    transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
    transition: all 150ms cubic-bezier(0.2, 0, 0.38, 0.9); // From accordion arrow
  }
`

const StyledToggleContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`

const StyledCollapseComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CollapseComponent = ({
  children,
  testId = '',
  show = true,
}: CollapseComponentProps) => {
  const { t } = useTranslation()
  const [showComponent, setShowComponent] = useState<boolean>(show)
  return (
    <StyledCollapseComponentContainer>
      <StyledToggleContainer>
        <StyledToggle open={showComponent}>
          <Button
            aria-label="collapse-dropdown-button"
            size="sm"
            kind="ghost"
            onClick={() => setShowComponent(!showComponent)}
            hasIconOnly
            iconDescription={
              showComponent ? t('Common.Hide') : t('Common.Show')
            }
            tooltipAlignment="end"
            renderIcon={ChevronDown}
            data-test-id={testId}
          />
        </StyledToggle>
      </StyledToggleContainer>
      {showComponent && children}
    </StyledCollapseComponentContainer>
  )
}
