import React, { ReactNode } from 'react'
import { Box, Flex } from 'src/next/components'
import Typography from 'src/next/components/Typography'

interface DataRowProps {
  label: string | ReactNode
  flexWrap?: string
  children?: ReactNode
}

export const DataRow = ({ label, flexWrap, children }: DataRowProps) => {
  return (
    <Flex
      borderTop="1px solid"
      borderColor="border-subtle"
      alignItems="flex-start"
      flexWrap={flexWrap}
      color="text-secondary"
      py="3"
      pl="5"
    >
      {/* `minWidth` is set to make the first column wide enough so that the head of the cells on the second column is aligned. */}
      <Box flexGrow={0} minWidth="180px">
        {label}
      </Box>
      {children ? (
        <Flex flexGrow={1} color="text-primary">
          {children}
        </Flex>
      ) : null}
    </Flex>
  )
}

export const DataRowHeading = ({ label }: { label: string | ReactNode }) => {
  return (
    <Box mb="4">
      <Typography variant="productive-heading-01">{label}</Typography>
    </Box>
  )
}
