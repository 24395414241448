import React from 'react'
import styled from 'styled-components'

const StyledTitle = styled.span`
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  font-weight: bold;
`

interface ToolbarTitleProps {
  children: string | null
}

export const ToolbarTitle = ({ children }: ToolbarTitleProps) => (
  <StyledTitle>{children}</StyledTitle>
)
