import { workloadTypes } from 'src/next/components/Workloads/WorkloadsTable/Actions/workloadTypes'
import { Workload } from 'src/next/types/workloads'
import { Action } from './types'

export const markAction = (workloads: Workload[], action: Action) => {
  const actionTypes = workloadTypes[action]

  return workloads.map(workload => {
    const workloadType = workload.type?.toLowerCase() ?? ''
    return {
      ...workload,
      actionable: actionTypes.includes(workloadType),
    }
  })
}
