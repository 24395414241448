import styled from 'styled-components'
import { OverflowMenu } from 'src/next/components/OverflowMenu'

// TODO: export normal and checkbox menu items

export const ToolbarOverflowMenu = styled(OverflowMenu)`
  &&:hover {
    background: var(--cds-background-hover);
  }
`
