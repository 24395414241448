import { middleEllipsis } from 'src/next/utils/middle-ellipsis'
import { WorkloadCellPopover } from './WorkloadCellPopover'
import { Tag } from './WorkloadLabels'
import { css } from 'styled-components'

export const ComputeInstanceId = ({ id }: { id: string }) => {
  return (
    <WorkloadCellPopover
      buttonContents={
        <Tag
          css={css`
            color: var(--cds-text-secondary);
            border: none;
            cursor: pointer;
            white-space: nowrap;
          `}
        >
          {/* It is hacky, but wrapping the text in span will prevent
          the text from being shown in the title field, as Tag will set the
          title field only when the child is a string.
          Ideally the title field should be the untruncated id, but that seems
          not possible with the current Tag component implementation.
          Nevertheless, the untruncated version of text can be shown as popover.
          See: https://github.com/carbon-design-system/carbon/blob/69a6edb874eeaafeafcfd899e852aed0e29c181b/packages/react/src/components/Tag/Tag.tsx#L187
          */}
          <span>{middleEllipsis(id, 7, 7)}</span>
        </Tag>
      }
      codeSnippet={id}
      id="instance-id"
    />
  )
}
