import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export type TimePeriod = 'daily' | 'weekly' | 'monthly'

export function getSubtractedCurrentMinuteUnixTimestamp(
  number: number,
  unit: dayjs.ManipulateType,
) {
  return dayjs().startOf('minute').subtract(number, unit).valueOf()
}

export function getCurrentMinuteUnixTimestamp() {
  return dayjs().startOf('minute').valueOf()
}

export const getTimePeriod = (timePeriod: string) => {
  const lastHour = dayjs().startOf('hour')

  const startTime =
    timePeriod === 'daily'
      ? lastHour.subtract(7, 'days')
      : timePeriod === 'weekly'
      ? lastHour.subtract(7, 'weeks')
      : timePeriod === 'monthly'
      ? lastHour.subtract(7, 'months')
      : lastHour.subtract(7, 'days')
  const startTimeNs = (startTime.valueOf() * 1_000_000).toString()

  const endTimeNs = (lastHour.valueOf() * 1_000_000).toString()

  return { startTimeNs, endTimeNs }
}

interface TimeRangeFromPeriodProps {
  start: dayjs.Dayjs
  timePeriod: TimePeriod
}

export const TimeRangeFromPeriod = ({
  start,
  timePeriod,
}: TimeRangeFromPeriodProps) => {
  const end =
    timePeriod === 'daily'
      ? start.add(1, 'day')
      : timePeriod === 'weekly'
      ? start.add(1, 'week')
      : start.add(1, 'month')

  return <TimeRange start={start} end={end} />
}

interface TimeRangeProps {
  start: dayjs.Dayjs
  end: dayjs.Dayjs
}

export const TimeRange = ({ start, end }: TimeRangeProps) => {
  const { i18n } = useTranslation()

  return (
    <>
      {start.locale(i18n.resolvedLanguage).format('lll (Z)')} -<br />
      {end.locale(i18n.resolvedLanguage).format('lll (Z)')}
    </>
  )
}
