import React from 'react'
import { Tag } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { middleEllipsis } from 'src/next/utils/middle-ellipsis'
import { WorkloadCellPopover } from './WorkloadCellPopover'

const StyledTag = styled(Tag)`
  border: 1px solid var(--cds-border-subtle-01);
  white-space: nowrap;
  max-inline-size: none;
`

interface WorkloadLabelPopoverProps {
  labelKey: string
  value: any
  elide: boolean
}

const WorkloadLabelPopover = ({
  labelKey,
  value,
  elide = true,
}: WorkloadLabelPopoverProps) => {
  const { t } = useTranslation()

  return (
    <WorkloadCellPopover
      id="instance-label-popover"
      buttonContents={
        <StyledTag type="gray" size="sm" key={labelKey}>
          <span
            title={t(
              'Workloads.VMTable.Labels.ShowFullLabel',
              'Show full label',
            )}
          >
            {elide
              ? middleEllipsis(`${labelKey}:${value}`, 5, 5)
              : `${labelKey}: ${value}`}
          </span>
        </StyledTag>
      }
      codeSnippet={`${labelKey}: ${value}`}
    />
  )
}

export default WorkloadLabelPopover
