import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { ToolbarButton } from './ToolbarButton'
import { ToolbarDropdown } from './ToolbarDropdown'
import { ToolbarGroup } from './ToolbarGroup'
import { ToolbarOverflowMenu } from './ToolbarOverflowMenu'
import { ToolbarSpacer } from './ToolbarSpacer'
import { ToolbarTitle } from './ToolbarTitle'
import { ToolbarToggleOverflowMenu } from './ToolbarToggleOverflowMenu'

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 40px;
`

const LeftSlot = styled.div`
  display: flex;
  flex-grow: 1;
`

interface ToolbarProps {
  children: ReactNode
}

export const Toolbar = ({ children, ...rest }: ToolbarProps) => {
  const rightAligned = [] as ReactNode[]
  return (
    <ToolbarContainer {...rest}>
      <LeftSlot>
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            if (child.props.pullRight) {
              rightAligned.push(child)
            } else {
              return child
            }
          }
        })}
      </LeftSlot>
      {rightAligned}
    </ToolbarContainer>
  )
}

Toolbar.Group = ToolbarGroup
Toolbar.Title = ToolbarTitle
Toolbar.Spacer = ToolbarSpacer
Toolbar.Button = ToolbarButton
Toolbar.Dropdown = ToolbarDropdown
Toolbar.OverflowMenu = ToolbarOverflowMenu
Toolbar.ToggleOverflowMenu = ToolbarToggleOverflowMenu
