import { useMutation } from 'react-query'
import { ListClustersRequest } from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'
import { useOrgSelection } from 'src/next/hooks'

export const useDownloadClusters = (request: ListClustersRequest) => {
  const [orgUuid] = useOrgSelection()

  const params = useRequestParams({
    request: {
      ...request,
      filter: {
        ...request.filter,
        orgUuids: orgUuid,
      },
    },
  })

  return useMutation((filetype: string) => {
    return fetchData<ArrayBuffer>(
      '/v1/dashboard/clusters:download',
      {
        ...params,
        filetype,
      },
      { responseType: 'arraybuffer' },
    )
  })
}
