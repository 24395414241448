import React from 'react'
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom'

export const Link = ({ to, children, ...rest }: ReactRouterLinkProps) => {
  return (
    <ReactRouterLink className="cds--link-primary" to={to} {...rest}>
      {children}
    </ReactRouterLink>
  )
}
