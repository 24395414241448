import React from 'react'

import { SubscriptionBanner } from 'src/next/components/SubscriptionBanner'
import {
  TableFilterContainer,
  TableFilterContextProvider,
} from 'src/next/components/TableFilter'

import { OrganizationsTable } from './Table/OrganizationsTable'
import { OrganizationsTableFilters } from './Table/OrganizationsTableFilters'

export const Organizations = () => {
  return (
    <>
      <SubscriptionBanner type="ORG" />
      <TableFilterContextProvider
        localStorageId="org-page-filters"
        defaultValues={{}}
      >
        <TableFilterContainer data-testid="org-table-filters">
          <OrganizationsTableFilters />
          <OrganizationsTable />
        </TableFilterContainer>
      </TableFilterContextProvider>
    </>
  )
}
