import React from 'react'
import { useRegisterActions } from 'kbar'
import { useNavigate } from 'react-router-dom'
import { useCLUIWorkloads } from 'src/api'
import { WorkloadType } from '../../Workloads/WorkloadsTable/components'

export const useCLUIRegisterWorkloads = (enabled = true) => {
  const navigate = useNavigate()

  const query = useCLUIWorkloads({
    staleTime: 10000,
    enabled,
  })

  useRegisterActions(
    query.data?.map(workload => ({
      id: `workload-${workload.uid}` || '',
      name: workload.name || '',
      subtitle: `${workload.namespace} / ${workload.clusterName}`,
      icon: <WorkloadType type={workload.kind} text="" />,
      parent: 'workloadFinder',
      perform: () => navigate(`/app/workload/${workload.uid}`),
    })) || [],
    [query?.data],
  )

  return query
}
