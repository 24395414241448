import React from 'react'
import {
  ComposedModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@carbon/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export const ChunkLoadErrorModal = () => {
  const { t } = useTranslation()
  const storageKey = 'page-refresh-after-chunk-error'

  const pageHasBeenRefreshed = Number(
    window.localStorage.getItem(storageKey) || 0,
  )

  const handleRefresh = () => {
    // We store the timestamp to detect old storage keys
    window.localStorage.setItem(storageKey, dayjs().valueOf().toString())
    return window.location.reload()
  }

  const renderParagraphs = (content: string) =>
    content
      .split('\n')
      .map((line, i) => (
        <p key={i} dangerouslySetInnerHTML={{ __html: `${line}&nbsp;` }} />
      ))

  const recently = (timestamp?: number) => {
    if (!timestamp) {
      return false
    }
    return dayjs().diff(dayjs(timestamp), 'minutes') < 15
  }

  if (recently(pageHasBeenRefreshed)) {
    return (
      <ComposedModal size="sm" open preventCloseOnClickOutside>
        <ModalHeader title={t('ChunkLoadErrorModal.ErrorHeader')} />
        <ModalBody>
          {renderParagraphs(t('ChunkLoadErrorModal.ErrorBody'))}
        </ModalBody>
      </ComposedModal>
    )
  }

  return (
    <ComposedModal size="sm" open preventCloseOnClickOutside>
      <ModalHeader title={t('ChunkLoadErrorModal.Header')} />
      <ModalBody>{renderParagraphs(t('ChunkLoadErrorModal.Body'))}</ModalBody>
      <ModalFooter
        primaryButtonText={t('Common.Refresh')}
        onRequestSubmit={handleRefresh}
      />
    </ComposedModal>
  )
}
