import React from 'react'
import { Modal, ModalProps } from '@carbon/react'
import { useTranslation } from 'react-i18next'

interface ConfirmProps extends ModalProps {
  onCancel: () => void
  onConfirm: () => void
  onClose?: () => void
}

export const Confirm = ({
  onCancel,
  onConfirm,
  onClose,
  ...rest
}: ConfirmProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      id="confirm-dialog"
      size="xs"
      preventCloseOnClickOutside
      secondaryButtonText={t('Common.Cancel')}
      onSecondarySubmit={onCancel}
      onRequestSubmit={onConfirm}
      onRequestClose={onClose}
      {...rest}
    />
  )
}
