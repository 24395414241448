import React, { useEffect, useRef, useState, ReactNode } from 'react'
import { Popover, PopoverContent } from '@carbon/react'
import { compose } from 'lodash/fp'
import styled from 'styled-components'

const Content = styled(PopoverContent)`
  padding: var(--cds-spacing-03);
`

interface FeedbackProps {
  children: ReactNode
  content: string
  feedbackTimeout?: number
}

const Feedback: React.FC<FeedbackProps> = ({
  children,
  content,
  feedbackTimeout = 2000,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const timeoutRef = useRef<number | undefined>(undefined)

  const handleClick = () => {
    if (timeoutRef.current !== undefined) {
      window.clearTimeout(timeoutRef.current)
    }

    setTooltipOpen(true)
    timeoutRef.current = window.setTimeout(() => {
      setTooltipOpen(false)
    }, feedbackTimeout)
  }

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }
  }, [])

  const childrenWithClickProp = React.Children.map(children, child => {
    const wrappedChild = React.isValidElement(child) ? (
      child
    ) : (
      <span>{child}</span>
    )

    return React.cloneElement(wrappedChild, {
      onClick: compose(
        ...[handleClick, wrappedChild.props?.onClick].filter(Boolean),
      ),
    })
  })

  return (
    <Popover
      align="top"
      caret={true}
      open={tooltipOpen}
      dropShadow={true}
      highContrast={true}
    >
      {childrenWithClickProp}
      <Content>{content}</Content>
    </Popover>
  )
}

export default Feedback
