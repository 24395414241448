import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Flex, Legend, LegendItem } from 'src/next/components'
import { DonutChart } from 'src/next/components/Graphs/DonutChart'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { usePersistentMetricDropdown } from 'src/next/hooks/usePersistentMetricDropdown'
import { GraphColor } from 'src/next/types/workloads'
import {
  bytesToUserFriendlySize,
  millicoresToUserFriendlySizeBase,
  millicoresToUserFriendlySizeShort,
  millicoresToUserFriendlySizeLong,
} from 'src/next/utils'
import ChartContainer from './ChartContainer'
import { TimePeriod, TimeRange } from 'src/next/utils/time'
import { useKubernetesAggregatedSummaries } from './useKubernetesAggregatedSummaries'
import { getDurationTranslations } from './KubernetesWorkloadCharts.translations'
import { KubernetesWorkloadResourceTypeHeading } from './KubernetesWorkloadResourceTypeHeading'

const DonutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--cds-spacing-04);
  height: 300px;
`

const getMemoryValues = ({
  used,
  unused,
}: {
  used?: number
  unused?: number
}) => {
  const usedFriendly = bytesToUserFriendlySize(Number(used))
  const unusedFriendly = bytesToUserFriendlySize(Number(unused))

  return {
    usedForLabel: usedFriendly,
    used,
    unused,
    usedTooltip: usedFriendly,
    unusedTooltip: unusedFriendly,
  }
}

const getCpuValues = ({ used, unused }: { used?: number; unused?: number }) => {
  const { isMillicores } = millicoresToUserFriendlySizeBase(used, 2)

  // The space at the center of the pie chart is limited.
  // Use the short form for millicores while keeping the long form for cores to clarify the unit.
  const usedForLabel = isMillicores
    ? millicoresToUserFriendlySizeShort(used)
    : millicoresToUserFriendlySizeLong(used)

  return {
    usedForLabel,
    used,
    unused,
    usedTooltip: millicoresToUserFriendlySizeLong(used, 4),
    unusedTooltip: millicoresToUserFriendlySizeLong(unused, 4),
  }
}

export const KubernetesWorkloadDonutChart = () => {
  const { t, i18n } = useTranslation()

  const durationTranslations = getDurationTranslations(t)

  const { selectedItem: resourceType, dropdownProps } =
    usePersistentMetricDropdown('workloads-donut-resource-type')

  const timeFilterItems = useMemo(
    () => [
      {
        id: 'daily',
        label: t('Workloads.TopCharts.Filters.DailyAverage'),
      },
      {
        id: 'weekly',
        label: t('Workloads.TopCharts.Filters.WeeklyAverage'),
      },
      {
        id: 'monthly',
        label: t('Workloads.TopCharts.Filters.MonthlyAverage'),
      },
    ],
    [t],
  )
  const [timeFilter, setTimeFilter] = useLocalStorage<TimePeriod>(
    'workloads-donut-chart-time-filter',
    'daily',
  )

  const { data: chartSummariesData, isLoading } =
    useKubernetesAggregatedSummaries(timeFilter)

  const { avgCpu, avgCpuCapacity, avgMemory, avgMemoryCapacity } =
    chartSummariesData?.aggregatedSummary || {}

  const avgCpuNum = Number(avgCpu)
  const avgCpuCapacityNum = Number(avgCpuCapacity)
  const avgMemoryNum = Number(avgMemory)
  const avgMemoryCapacityNum = Number(avgMemoryCapacity)
  const hasError =
    isNaN(avgCpuNum) ||
    isNaN(avgCpuCapacityNum) ||
    isNaN(avgMemoryNum) ||
    isNaN(avgMemoryCapacityNum)

  const { aggregatedSummary, endOfSummaryTimestamp } = chartSummariesData || {}

  const timestampStart = dayjs(
    Number(aggregatedSummary?.timestampNs) / 1_000_000,
  )
  const timestampEnd = dayjs(Number(endOfSummaryTimestamp) / 1_000_000)

  const { usedForLabel, used, unused, usedTooltip, unusedTooltip } =
    resourceType === 'cpu'
      ? getCpuValues({ used: avgCpuNum, unused: avgCpuCapacityNum - avgCpuNum })
      : getMemoryValues({
          used: avgMemoryNum,
          unused: avgMemoryCapacityNum - avgMemoryNum,
        })

  const donutChartData = [
    {
      x: 1,
      y: used || 1,
      label: t('Workloads.TopCharts.Donuts.AvgUsed'),
      value: usedTooltip,
      color: 'var(--carbonPalette1)',
    },
    {
      x: 2,
      y: unused || 1,
      label: t('Workloads.TopCharts.Donuts.AvgUnused'),
      value: unusedTooltip,
      color: 'var(--carbonPalette2)',
    },
  ]

  const chartColors = donutChartData.map(({ color }) => color)
  const [checkedChartColors, setCheckedChartColors] = useState(chartColors)

  const handleLegendChange = (color: string) => {
    const chartColors = checkedChartColors.filter(c => c !== color)
    // make sure at least 1 item is selected
    if (!chartColors.length) return false
    const newLegendItems = checkedChartColors.includes(color)
      ? chartColors
      : [...checkedChartColors, color]

    setCheckedChartColors(newLegendItems)
  }

  const activeRows = donutChartData.filter(({ color }) =>
    checkedChartColors.includes(color),
  )

  return (
    <ChartContainer
      selectedItem={timeFilter}
      onChange={setTimeFilter}
      menuItems={timeFilterItems}
      heading={
        <KubernetesWorkloadResourceTypeHeading
          id="donut-chart-resource-type"
          label={`${durationTranslations[timeFilter]} ${t(
            'Workloads.TopCharts.Labels.Average',
          )}`}
          dropdownProps={dropdownProps}
        />
      }
      data-testid="donut-chart-wrapper"
    >
      <Flex flexDirection="column" justifyContent="space-between" flex="1">
        <DonutWrapper>
          <DonutChart
            data={activeRows}
            isLoading={isLoading}
            showDataLabels={false}
            tooltipHeading={
              <TimeRange start={timestampStart} end={timestampEnd} />
            }
            disabled={hasError}
            hasError={hasError}
            centerHeading={t('Workloads.TopCharts.Donuts.AvgUsed')}
            centerValue={usedForLabel}
            padding={10}
            defaultWidth={300}
            defaultHeight={300}
          />
        </DonutWrapper>
        <Legend>
          {donutChartData.map(({ label, color }) => (
            <LegendItem
              id={label}
              key={label}
              color={color as GraphColor}
              onChange={() => handleLegendChange(color)}
              readOnly
              checked={checkedChartColors.includes(color)}
            >
              {label}
            </LegendItem>
          ))}
        </Legend>
      </Flex>
    </ChartContainer>
  )
}
