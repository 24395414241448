import {
  flip,
  offset,
  shift,
  useClientPoint,
  useInteractions,
  useFloating,
} from '@floating-ui/react'

export const useGraphTooltip = () => {
  const { context, x, y, isPositioned, floatingStyles, refs } = useFloating({
    placement: 'bottom-start',
    strategy: 'absolute',
    middleware: [
      offset({ mainAxis: 10, crossAxis: 10 }),
      flip({ padding: 50 }),
      shift(),
    ],
  })

  const clientPoint = useClientPoint(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([clientPoint])

  return {
    // Manually check `x` and `y` to catch the case `isPositioned` doesn't cover.
    isPositioned: isPositioned && x !== 0 && y !== 0 && x !== 10 && y !== 10,
    setReference: refs.setReference,
    setFloating: refs.setFloating,
    getReferenceProps,
    getFloatingProps,
    floatingStyles,
  }
}
