import { useQuery } from 'react-query'
import { fetchData } from 'src/api'
import { ListDistributionVersionsResponse } from '@cloudnatix-types/multiclustercontroller'

const useCliDistributionVersions = (options = {}) =>
  useQuery<ListDistributionVersionsResponse>(
    ['installCli', 'distributionVersions'],
    async () => fetchData('/v1/distributionversions', { kind: 'cli' }),
    { ...options },
  )

export default useCliDistributionVersions
