import React, { ReactElement } from 'react'
import { DefinitionTooltip } from '@carbon/react'
import styled from 'styled-components'

const StyledDefinitionTooltip = styled(DefinitionTooltip)`
  display: flex;
  align-items: center;
  white-space: nowrap;

  .cds--definition-tooltip {
    max-inline-size: none;
  }

  .cds--definition-term {
    font-size: var(--cds-body-01-font-size);
    border-bottom: none;
    color: inherit;
    cursor: pointer;
  }
`

export interface TooltipProps {
  children: string | ReactElement | ReactElement[]
  tooltipText: string
}

export const Tooltip = ({ children, tooltipText }: TooltipProps) => {
  return (
    <StyledDefinitionTooltip
      align="bottom"
      definition={tooltipText}
      openOnHover={true}
    >
      {children}
    </StyledDefinitionTooltip>
  )
}
