import { useEffect, useMemo, useState } from 'react'
import {
  Dropdown,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
} from '@carbon/react'
import { Information } from '@carbon/react/icons'
import groupBy from 'lodash/groupBy'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { VictoryBarProps } from 'victory'

import { GetTopSummariesResponse, Summary } from '@cloudnatix-types/dashboard'

import { kubernetesWorkloadSummaryMetricMap } from 'src/next/constants/summary'
import { Box, Flex, LegendItem } from 'src/next/components'
import { InlineNotification } from 'src/next/components/InlineNotification'
import Loading from 'src/next/components/Loading'
import MiddleTruncate from 'src/next/components/MiddleTruncate'
import Typography from 'src/next/components/Typography'
import { CollapseComponent } from 'src/next/components/ui'
import { useKubernetesTopSummaries } from 'src/next/components/KubernetesWorkloadCharts'
import { useKubernetesWorkloadTopSummariesMetricDropdownItems } from 'src/next/components/KubernetesWorkloadCharts'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import { GraphColor } from 'src/next/types/workloads'
import {
  createFormatFn,
  getCarbonPaletteCssVariable,
  getFormatType,
} from 'src/next/utils/graph.utils'
import { getTimePeriod, TimePeriod } from 'src/next/utils/time'

import { useGroupingHeaders } from './useGroupingHeaders'
import { KubernetesTopSummariesChart } from './charts/KubernetesTopSummariesChart'
import { useTimeRangeOptions } from './charts/useFilterOptions'
import { TabDataTable } from './tables'
import { TopSummariesChartSettingMetric } from '../../KubernetesWorkloadCharts/types'

const LegendWrapper = styled.div`
  display: inline-block;

  li {
    margin: 0;
  }
`

interface AggregatedTopSummaries extends Omit<Summary, 'name'> {
  id: string
  name: string
  nameFormatted: JSX.Element
  color: GraphColor
}

const aggregateTopSummaries = (
  data: GetTopSummariesResponse,
): AggregatedTopSummaries[] => {
  const topSummariesMsgBySummaryTime = data.topSummaries!

  // Flatten all arrays from data.topSummaries[timestamp].summaries
  const flattenedSummaries = Object.values(topSummariesMsgBySummaryTime)
    .map(({ summaries }) => summaries!)
    .flat()

  // Group values with name as key
  const groupedSummaries = groupBy(
    flattenedSummaries,
    item => item.name || item.groupingName,
  )

  // Sum (or average) all number values per group (and ungroup at the same time).
  const aggregatedSummaries = Object.entries(groupedSummaries).map(
    ([groupName, summaries]) => {
      const nameElement = (
        <MiddleTruncate text={groupName} charsAtStart={30} charsAtEnd={30} />
      )
      const aggregatedValues = summaries.reduce(
        (currentSums, summary) => {
          Object.entries(summary!).forEach(([field, value]) => {
            // We sum all number fields (e.g. avgCpu). The others e.g.
            // timestampNs, groupingName, and groupingValue are dropped here.
            if (typeof value !== 'number') {
              return
            }

            if (currentSums[field]) {
              currentSums[field] = Number(currentSums[field]) + value
            } else {
              currentSums[field] = value
            }
          })

          // At this point we also have no grouping anymore
          return currentSums
        },
        {
          id: groupName,
          name: groupName,
          nameFormatted: nameElement,
        } as Omit<AggregatedTopSummaries, 'color'>,
      ) as Omit<AggregatedTopSummaries, 'color'>

      // Convert sum values to average except the columns representing cost.
      //
      // 'totalCost' is for the Kubernetes workload summary. 'totalSpend' is for
      // the compute instance summary.
      //
      // TODO(kenji): Move the logic to the backend.
      Object.entries(aggregatedValues).forEach(([column, sum]) => {
        if (
          typeof sum === 'number' &&
          column !== 'totalCost' &&
          column !== 'totalSpend' &&
          column !== 'wastedSpend'
        ) {
          aggregatedValues[column] = Number(sum) / summaries.length
        }
      })

      return aggregatedValues
    },
  )

  // Convert to DataTable rows
  return aggregatedSummaries
    .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
    .map((item, i) => ({
      color: getCarbonPaletteCssVariable(i),
      ...item,
    }))
}

const useLimitDropdownItems = (): (Record<'id', string> & {
  label: string
})[] => {
  const { t } = useTranslation()
  return useMemo(() => {
    return [
      ...[5, 10, 25].map(value => ({
        id: `${value}`,
        label: t('Common.TopX', {
          value,
        }),
      })),
      {
        // 500 as a sane 'all' default? to prevent rendering / ux problems
        id: '500',
        label: t('Common.ShowAll'),
      },
    ]
  }, [t])
}

export const NamespaceTab = () => {
  const { t } = useTranslation()

  const timeRangeDropdownItems = useTimeRangeOptions()
  const metricDropdownItems =
    useKubernetesWorkloadTopSummariesMetricDropdownItems()
  const limitDropdownItems = useLimitDropdownItems()

  const {
    dropdownProps: { selectedItem: timeRange, ...timeRangeDropdownProps },
  } = usePersistentDropdown(
    'dashboard-namespace-tab-time-range',
    timeRangeDropdownItems,
  )

  const {
    dropdownProps: { selectedItem: metric, ...metricDropdownProps },
  } = usePersistentDropdown(
    'dashboard-namespace-tab-metric',
    metricDropdownItems,
  )

  const {
    dropdownProps: { selectedItem: limit, ...limitDropdownProps },
  } = usePersistentDropdown('dashboard-namespace-tab-limit', limitDropdownItems)

  const { startTimeNs, endTimeNs } = getTimePeriod(timeRange.id)

  const { isLoading, isError, data } = useKubernetesTopSummaries({
    summaryType: 'NAMESPACE' as any,
    summaryInterval: timeRange.id.toUpperCase() as any,
    summaryMetrics: metric.id as any,
    limit: Number(limit.id),
    filter: {
      startTimeNs,
      endTimeNs,
    },
  })

  return (
    <>
      <Flex alignItems="center">
        <Box mt={5} mb={3}>
          <Flex alignItems="flex-end">
            <Typography variant="productive-heading-03">
              {t('Dashboard.Tabs.KubernetesSpecific.Heading')}
            </Typography>
            <Toggletip>
              <ToggletipButton label="information">
                <Information />
              </ToggletipButton>
              <ToggletipContent>
                <div>{t('Dashboard.Tabs.KubernetesSpecific.Tooltip')}</div>
              </ToggletipContent>
            </Toggletip>
          </Flex>
          <Box color="text-secondary">
            <Typography variant="label-02">
              {t('Dashboard.Tabs.KubernetesSpecific.Subheading')}
            </Typography>
          </Box>
        </Box>
        <Flex ml="auto" gap="var(--cds-spacing-04)">
          <Dropdown
            label="Time period"
            selectedItem={timeRange}
            {...timeRangeDropdownProps}
          />
          <Dropdown
            label={t('Dashboard.Namespace.Metric')}
            selectedItem={metric}
            {...metricDropdownProps}
          />
          <Dropdown
            label={t('Dashboard.Namespace.Quantity')}
            selectedItem={limit}
            {...limitDropdownProps}
          />
        </Flex>
      </Flex>

      {isLoading ? (
        <Box height="450px" position="relative">
          <Loading centered withOverlay={false} />
        </Box>
      ) : isError || data === undefined ? (
        <InlineNotification
          kind="error"
          title={t('Workloads.TopCharts.Rollup.LoadingError')}
        />
      ) : (
        <Chart data={data} timeRange={timeRange.id} metric={metric.id} />
      )}
    </>
  )
}

interface ChartProps {
  data: GetTopSummariesResponse
  timeRange: TimePeriod
  metric: TopSummariesChartSettingMetric
}

const Chart = ({ data, timeRange, metric }: ChartProps) => {
  const { t } = useTranslation()

  const aggregatedSummaries = useMemo(() => {
    return aggregateTopSummaries(data)
  }, [data])

  const headers = useGroupingHeaders({
    metrics: kubernetesWorkloadSummaryMetricMap,
  })

  const [selectedId, setSelectedId] = useState<string>('')

  // reset selection when different filter options are chosen
  useEffect(() => {
    setSelectedId('')
  }, [aggregatedSummaries])

  const formattedAggregatedSummaries = useMemo(() => {
    return aggregatedSummaries.map(summary => {
      const isSelected = selectedId === summary.id

      const formatted = {
        ...summary,
        legendSelection: (
          <LegendWrapper>
            <LegendItem
              id={`datatable-legend-${summary.id}`}
              color={summary.color}
              readOnly
              checked={selectedId ? isSelected : true}
              isSelected={!!selectedId}
            />
          </LegendWrapper>
        ),
        onClick: (row: AggregatedTopSummaries) => {
          setSelectedId(selectedId === row.id ? '' : row.id)
        },
        isSelected,
      }

      Object.values(kubernetesWorkloadSummaryMetricMap).forEach(metric => {
        // Here, `summary` contains not only what the `Summary` protobuf, but more fields added by `transformGroupingData()`.
        // Though `metric` is `keyof Summary`, the inferred type of `summary[metric]` may include `JSX.Element`.
        //
        // Also, assert that it never becomes `undefined`.
        const metricSummary = summary[metric] as number | string
        const formatFn = createFormatFn(getFormatType(metric), {
          cpu: { shortDisplayValue: true },
        })
        // Possibly due to some limitation of type checking (possibly specific to VSCode), the type of `formatFn` is set to `(value: number) => string`, not `((value: number) => string) | ((value: number | string) => string)`.
        // Therefore, it shows a type error for now.
        formatted[`${metric}Formatted`] = formatFn(metricSummary)
      })

      return formatted as AggregatedTopSummaries
    })
  }, [aggregatedSummaries, selectedId])

  const colorMap = useMemo(
    () =>
      aggregatedSummaries.map(({ id, color }) => ({
        id,
        name: id,
        color,
      })),
    [aggregatedSummaries],
  )

  return (
    <>
      <CollapseComponent>
        <KubernetesTopSummariesChart
          data={data}
          timePeriod={timeRange}
          summaryMetric={metric}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          colorMap={colorMap}
          containerProps={{ voronoiDimension: undefined }}
          width={1616}
          height={680}
          padding={{ left: 60, top: 20, bottom: 50, right: 0 }}
        />
      </CollapseComponent>
      <Box mb="calc(var(--cds-spacing-08) * -1)" mt={8}>
        <Typography variant="productive-heading-03">
          {t('Dashboard.Common.DataTable.Heading')}
        </Typography>
        <Box color="text-secondary">
          <Typography variant="label-02">
            {t('Dashboard.Common.DataTable.Subheading')}
          </Typography>
        </Box>
      </Box>
      <TabDataTable
        id="dashboard-namespace-table"
        headers={headers}
        rows={formattedAggregatedSummaries}
        isLoading={false}
      />
    </>
  )
}
