import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDeleteNamespace, useNamespaces } from 'src/api'
import DataTable, {
  DataTableActionButton,
  DataTableNestedPagination,
  useTableControls,
} from 'src/next/components/DataTable'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { DeleteModal, useDeleteModal } from 'src/next/components/Modal'
import { currencyFormat } from 'src/next/utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'
import { NamespaceActionsModal } from './NamespaceActionsModal'

interface Props {
  id: string
}

export const OrgNamespacesTable = ({ id }: Props) => {
  const { t } = useTranslation()
  const headers = useHeaders()

  const deleteModalProps = useDeleteModal()

  const { pagination, orderBy } = useTableControls(
    'org-namespaces-table-page-size',
  )
  const { resetPage, setPage, ...dataTablePaginationProps } = pagination

  const query = useNamespaces({
    filter: {
      orgUuid: id,
    },
    orderBy: orderBy.value || '',
    pageSize: pagination.pageSize,
  })

  const currentPageData = query.data?.pages?.[pagination.page - 1] || {}

  const namespaces = useMemo(
    () => currentPageData.namespaces || [],
    [currentPageData.namespaces],
  )

  const formattedRows = useMemo(
    () =>
      namespaces.map(ns => {
        return {
          ...ns,
          id: ns.uuid!,
          spend: t('Common.Value/day', {
            value: currencyFormat(ns.spend || 0),
          }),
          labels: ns.labels?.map(l => l.value).join(', '),
          createdAt: dayjs(nanoToMilliSeconds(ns.createdAtNs || 0)).format(
            'll',
          ),
          updatedAt: dayjs(nanoToMilliSeconds(ns.lastModifiedAtNs || 0)).format(
            'll',
          ),
          actions: (
            <DataTableActionButton
              actionType="delete"
              iconDescription={t('Organizations.Namespaces.Remove')}
              onClick={() => deleteModalProps.setId(ns.uuid!)}
              size="sm"
            />
          ),
        }
      }) || [],
    [namespaces, deleteModalProps, t],
  )

  if (query.isError) {
    return (
      <InlineNotification
        title={t('Organizations.Namespaces.FetchFailed')}
        kind="error"
      />
    )
  }

  return (
    <>
      <DataTable
        storageKey="org-namespaces-table"
        size="sm"
        headers={headers}
        rows={formattedRows}
        isLoading={query.isFetching}
        pageSize={pagination.pageSize}
        orderBy={orderBy}
        primaryAction={<NamespaceActionsModal orgId={id} />}
      />
      {!query.isFetching && !formattedRows?.length ? (
        <InlineNotification title={t('Organizations.Namespaces.NoData')} />
      ) : (
        <DataTableNestedPagination
          {...dataTablePaginationProps}
          query={query}
        />
      )}

      <DeleteModal
        title={t('Organizations.Namespaces.ConfirmDelete')}
        messages={{
          success: t('Organizations.Namespaces.Delete.Success'),
          error: t('Organizations.Namespaces.Delete.Error'),
        }}
        query={useDeleteNamespace}
        {...deleteModalProps}
      />
    </>
  )
}

const useHeaders = () => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      { key: 'name', sort: 'name', header: t('Organizations.DataTable.Name') },
      { key: 'type', sort: 'type', header: t('Organizations.Type') },
      // todo: wait for backend is always 0 now
      // {
      //   key: 'members',
      //   header: t('Organizations.Namespaces.Members'),
      // },
      { key: 'spend', sort: 'spend', header: t('Common.Spend') },
      {
        key: 'labels',
        header: t('Organizations.Labels'),
      },
      {
        key: 'createdAt',
        sort: 'created_at_ns',
        header: t('Common.Created'),
      },
      {
        key: 'updatedAt',
        sort: 'last_modified_at_ns',
        header: t('Common.Updated', 'Updated'),
      },
      {
        key: 'actions',
        header: t('Organizations.DataTable.Actions'),
      },
    ],
    [t],
  )
}
