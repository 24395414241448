import { SideNav } from '@carbon/react'
import styled from 'styled-components'

export const StyledSideNav = styled(SideNav)`
  transition: none;

  // Emulate the size in the rail mode.
  width: 3rem;

  &.cds--side-nav--expanded {
    width: 16rem;
  }
`

export const StyledSideNavContainer = styled.div`
  .cds--side-nav__navigation {
    background-color: var(--cds-background);
    font-family: var(--cn-font-family);
  }

  .cds--side-nav__link > .cds--side-nav__link-text {
    color: var(--cds-text-secondary);
  }

  .cds--side-nav__icon > svg {
    fill: var(--cds-icon-primary);
  }

  .cds--side-nav__link:hover {
    cursor: pointer;

    .cds--side-nav__icon > svg {
      fill: var(--cds-overlay);
    }
  }

  .cds--side-nav__link[aria-current='page'] {
    background-color: var(--cds-layer-02);
  }

  .cds--side-nav__link--current .cds--side-nav__link-text,
  .cds--side-nav__link[aria-current='page'] .cds--side-nav__link-text {
    color: var(--cds-text-secondary);
  }

  .cds--side-nav .cds--header__menu-item:hover,
  .cds--side-nav .cds--header__menu-title[aria-expanded='true']:hover {
    color: #161616;
  }

  .menu-container {
    position: relative;
    height: 100%;
  }

  .menu-container-footer {
    position: absolute;
    bottom: 60px;
    width: 100%;
  }

  button.cds--side-nav__link {
    transition: color 110ms, background-color 110ms, outline 110ms;
    background-color: transparent;
    border: none;
    width: 100%;
    display: flex;
    height: 2rem;
    align-items: center;
    padding: 0 1rem;

    font-size: var(--cds-productive-heading-01-font-size);
    font-weight: var(--cds-productive-heading-01-font-weight);
    line-height: var(--cds-productive-heading-01-line-height);
    letter-spacing: var(--cds-productive-heading-01-letter-spacing);

    &:focus {
      outline: 2px solid var(--cds-focus, #0f62fe);
      outline-offset: -2px;
    }
  }

  nav:not(.cds--side-nav--expanded) {
    .cds--side-nav__link {
      transition: none;
    }

    .cds--side-nav__link-text {
      position: relative;
    }

    .cds--side-nav__item {
      .nav-tooltip {
        opacity: 0;
        transition: opacity 0.15s ease-in-out;
      }

      &:hover {
        .cds--side-nav__link {
          background: transparent;

          &.active {
            background: var(--cds-layer-02);
          }
        }

        .cds--side-nav__icon > svg {
          fill: var(--cds-icon-primary);
        }

        .nav-tooltip {
          background: #e5e5e5;
          border-radius: 0.125rem;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          color: #161616;
          display: inline-block;
          font-weight: 500;
          left: 3rem;
          margin-left: 0.5rem;
          margin-top: -16px;
          opacity: 1;
          padding: 6px 1rem 6px 1rem;
          position: fixed;

          &:after {
            content: '';
            border: 0.4296875rem solid transparent;
            border-right-color: #e5e5e5;
            position: absolute;
            left: 0;
            top: 50%;
            height: 0;
            width: 0;
            transform: translate(-100%, -50%);
          }
        }
      }
    }
  }
`
