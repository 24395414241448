import React from 'react'
import { DefinitionTooltip, DefinitionTooltipProps } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { ReactComponent as DataEnrichment } from 'src/images/data-enrichment.svg'

const StyledDefinitionTooltip = styled(DefinitionTooltip)`
  display: flex;
  align-items: center;

  .cds--tooltip__trigger.cds--tooltip__trigger--definition {
    font-size: var(--cds-body-01-font-size);
    border-bottom: none;
  }

  svg {
    outline: none !important;
  }
`

export type FeatureInfoProps = DefinitionTooltipProps

export const FeatureInfo = (props: FeatureInfoProps) => {
  const { t } = useTranslation()

  return (
    <StyledDefinitionTooltip
      css={css`
        border-bottom: none !important;
      `}
      {...props}
    >
      {/*
      DataEnrichment is a new icon in carbon v11. It's also present in the
      v10 version but that icon has the plus sign in it from DataEnrichmentAdd.
      That is why we use the downloaded and modified svg here.
      */}
      <DataEnrichment
        style={{ height: '16px', color: 'var(--cds-icon-primary)' }}
      />{' '}
      {t('FeatureInfo.LearnMore', 'Learn more...')}
    </StyledDefinitionTooltip>
  )
}
