import React, { useMemo } from 'react'
import { Dropdown } from '@carbon/react'
import { useTranslation } from 'react-i18next'

import { GetTopSummariesResponse } from '@cloudnatix-types/dashboard'

import { Box } from 'src/next/components'
import { KubernetesTopSummariesChart } from 'src/next/components/dashboard/tabs/charts/KubernetesTopSummariesChart'
import {
  DropdownHeading,
  DropdownInlineLabel,
  DropdownSubheading,
} from 'src/next/components/Dropdown/DropdownHeading'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { useKubernetesTopSummaries } from 'src/next/components/KubernetesWorkloadCharts'
import { TopSummariesChartSettingMetric } from 'src/next/components/KubernetesWorkloadCharts/types'
import Loading from 'src/next/components/Loading'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import { unique } from 'src/next/utils/array'
import { getCarbonPaletteCssVariable } from 'src/next/utils/graph.utils'
import { TimePeriod, getTimePeriod } from 'src/next/utils/time'

import { useKubernetesWorkloadTopSummariesMetricDropdownItems } from './KubernetesWorkloadCharts.translations'
import ChartContainer from './ChartContainer'
import { TopSummariesChartSettingType } from './types'

const useKubernetesWorkloadTopSummariesTypeDropdownItems = (): (Record<
  'id',
  TopSummariesChartSettingType
> & { label: string })[] => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        id: 'WORKLOAD',
        label: t('Workloads.TopCharts.Rollup.FilterItems.Workloads'),
      },
      {
        id: 'NAMESPACE',
        label: t('Workloads.TopCharts.Rollup.FilterItems.Namespaces'),
      },
      {
        id: 'ORG',
        label: t('Workloads.TopCharts.Rollup.FilterItems.Organizations'),
      },
    ],
    [t],
  )
}

export const KubernetesWorkloadStackedBarChart = () => {
  const { t } = useTranslation()

  const summaryLimit = '5'

  const typeDropdownItems = useKubernetesWorkloadTopSummariesTypeDropdownItems()
  const metricDropdownItems =
    useKubernetesWorkloadTopSummariesMetricDropdownItems()

  const timeFilterItems = useMemo(
    () => [
      {
        id: 'daily',
        label: t('Workloads.TopCharts.Filters.DailyRollup'),
      },
      {
        id: 'weekly',
        label: t('Workloads.TopCharts.Filters.WeeklyRollup'),
      },
      {
        id: 'monthly',
        label: t('Workloads.TopCharts.Filters.MonthlyRollup'),
      },
    ],
    [t],
  )
  const [timeFilterRollup, setTimeFilterRollup] = useLocalStorage<TimePeriod>(
    'workloads-rollup-chart-time-filter',
    'daily',
  )

  const {
    dropdownProps: { selectedItem: type, ...typeDropdownProps },
  } = usePersistentDropdown('workloads-rollup-chart-type', typeDropdownItems)

  const {
    dropdownProps: { selectedItem: metric, ...metricDropdownProps },
  } = usePersistentDropdown(
    'workloads-rollup-chart-metric',
    metricDropdownItems,
  )

  // Not using string operation intentionally to make it easier to look up
  // translation IDs on the code search.
  const timePeriodTranslationMap = {
    daily: t('Common.TimePeriod.Daily'),
    weekly: t('Common.TimePeriod.Weekly'),
    monthly: t('Common.TimePeriod.Monthly'),
  }

  const { startTimeNs, endTimeNs } = getTimePeriod(timeFilterRollup)

  const { isLoading, isError, data } = useKubernetesTopSummaries(
    {
      summaryType: type.id as any,
      summaryInterval: timeFilterRollup.toUpperCase() as any,
      summaryMetrics: metric.id as any,
      limit: Number(summaryLimit),
      filter: {
        startTimeNs,
        endTimeNs,
      },
    },
    {
      useErrorBoundary: true,
    },
  )

  return (
    <ChartContainer
      selectedItem={timeFilterRollup}
      onChange={setTimeFilterRollup}
      menuItems={timeFilterItems}
      heading={
        <>
          <DropdownHeading>
            <DropdownInlineLabel>
              {t('Workloads.TopCharts.Rollup.FilterItems.Top')} {summaryLimit}{' '}
            </DropdownInlineLabel>
            <Dropdown
              type="inline"
              label={t('Workloads.TopCharts.Rollup.Heading.Type')}
              hideLabel
              selectedItem={type}
              {...typeDropdownProps}
              itemToString={item => (item ? item.label.toLowerCase() : '')}
              itemToElement={item => (item ? <>{item.label}</> : null)}
            />
            {/* by <metric> */}
            <DropdownInlineLabel>
              {t('Workloads.TopCharts.Rollup.Heading.By')}{' '}
            </DropdownInlineLabel>
            <Dropdown
              type="inline"
              label={t('Workloads.TopCharts.Rollup.Heading.Metric')}
              hideLabel
              selectedItem={metric}
              {...metricDropdownProps}
              itemToString={item => (item ? item.label.toLowerCase() : '')}
              itemToElement={item => (item ? <>{item.label}</> : null)}
            />
          </DropdownHeading>
          {/* ... Daily/Weekly/Monthly rollup */}
          <DropdownSubheading>
            {t('Common.Rollup', '{{timePeriod}} rollup', {
              timePeriod: timePeriodTranslationMap[timeFilterRollup],
            })}
          </DropdownSubheading>
        </>
      }
      data-testid="workloads-top-summaries-chart"
    >
      {isLoading ? (
        <Box height="300px" position="relative">
          <Loading size="small" centered withOverlay={false} />
        </Box>
      ) : isError || data === undefined ? (
        <InlineNotification
          kind="error"
          title={t(
            'Dashboard.Tabs.KubernetesTopSummaries.LoadingError',
            'Failed to load top summaries',
          )}
        />
      ) : (
        <Chart
          data={data}
          timePeriod={timeFilterRollup}
          summaryMetric={metric.id}
        />
      )}
    </ChartContainer>
  )
}

interface ChartProps {
  data: GetTopSummariesResponse
  timePeriod: TimePeriod
  summaryMetric: TopSummariesChartSettingMetric
}

const Chart = ({ data, timePeriod, summaryMetric }: ChartProps) => {
  const colorMap = useMemo(() => {
    const topSummariesMsgBySummaryTime = data.topSummaries!

    const flattenedSummaries = Object.values(topSummariesMsgBySummaryTime)
      .map(({ summaries }) => summaries!)
      .flat()

    const names = unique(
      flattenedSummaries.map(item => {
        return item.groupingName!
      }),
    )

    return names.sort().map((name, i) => {
      return {
        id: name,
        name: name,
        color: getCarbonPaletteCssVariable(i),
      }
    })
  }, [data])

  return (
    <KubernetesTopSummariesChart
      data={data}
      timePeriod={timePeriod}
      summaryMetric={summaryMetric}
      colorMap={colorMap}
      width={640}
      height={320}
    />
  )
}
