import { useEffect, useMemo, useState } from 'react'
import { Dropdown } from '@carbon/react'

import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'

import { useDashboardInstanceGroups } from 'src/api'
import { Box } from 'src/next/components'
import { CollapseComponent } from 'src/next/components/ui'

import { useTimeRangeOverflowMenu } from '../timerange'
import { GenericTabSummaryViewChart } from './charts'
import { TabDataTable } from './tables'
import { useFormatRows, useHeaders } from './tables/useSummaryTable'

interface GenericTabSummaryViewProps {
  id: string
  groupingKeyKey: `${ComputeInstanceGroupingKeyKey}`
  groupingKeyTagKey: string | undefined
}

export const GenericTabSummaryView = ({
  id,
  groupingKeyKey,
  groupingKeyTagKey,
}: GenericTabSummaryViewProps) => {
  const { TimeRangeMenu, items, selectedItem, startTimeNs, endTimeNs } =
    useTimeRangeOverflowMenu({
      id: `${id}-time-range`,
      show24HoursOption: true,
    })

  const { isLoading, data } = useDashboardInstanceGroups({
    groupingKey: {
      key: groupingKeyKey as any,
      tagKey: groupingKeyTagKey,
    },
    filter: {
      startTimeNs,
      endTimeNs,
    },
  })

  const groupingValues = useMemo(() => {
    return data?.map(({ name, uid }) => ({
      name: name!,
      uid: uid!,
    }))
  }, [data])

  const [selectedGroupingValue, setSelectedGroupingValue] = useState(
    groupingValues?.[0],
  )

  useEffect(() => {
    setSelectedGroupingValue(groupingValues?.[0])
  }, [groupingValues])

  const headers = useHeaders({
    overrideNameColumnHeader: groupingKeyTagKey,
  })

  const rows = useFormatRows(data)

  const numInstanceGroups = data?.length
  const hasData = numInstanceGroups !== undefined && numInstanceGroups > 0

  return (
    <>
      <CollapseComponent>
        <TabDataTable
          id={`${id}-table`}
          headers={headers}
          rows={rows}
          isLoading={isLoading}
          overflowMenu={
            <TimeRangeMenu label={selectedItem.label} items={items} />
          }
        />
      </CollapseComponent>
      <CollapseComponent>
        {groupingValues?.length && (
          <Box mr="auto" mt={5} mb={3} minWidth="250px" maxWidth="350px">
            <Dropdown
              id={`${id}-grouping-value`}
              data-testid={`${id}-grouping-value`}
              disabled={!hasData}
              label={groupingKeyTagKey || ''}
              titleText={groupingKeyTagKey}
              items={groupingValues || []}
              itemToString={item => item?.name || ''}
              selectedItem={selectedGroupingValue}
              onChange={({ selectedItem }) => {
                if (selectedItem === null) {
                  return
                }
                setSelectedGroupingValue(selectedItem)
              }}
            />
          </Box>
        )}
        {selectedGroupingValue && (
          <GenericTabSummaryViewChart
            id={`${id}-chart`}
            groupingKeyKey={groupingKeyKey}
            groupingKeyTagKey={groupingKeyTagKey}
            groupingValue={selectedGroupingValue.uid}
          />
        )}
      </CollapseComponent>
    </>
  )
}
