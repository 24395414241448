import { TFunction } from 'react-i18next'
import { UserRole } from '@cloudnatix-types/usercontroller'

export const getUserRoleNames = (
  t: TFunction<'translation'>,
): Record<keyof typeof UserRole, string> => ({
  USER_ROLE_VOID: t('UserRoles.Void', 'Void'),
  USER_ROLE_PATCH: t('UserRoles.Patch', 'Patch'),
  USER_ROLE_VIEWER: t('UserRoles.Viewer', 'Viewer'),
  USER_ROLE_USER: t('UserRoles.User', 'User'),
  USER_ROLE_ADMIN: t('UserRoles.Admin', 'Admin'),
})
