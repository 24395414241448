import { useMutation } from 'react-query'
import { ListWorkloadsRequest } from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'
import { useOrgSelection } from 'src/next/hooks'

export const useDownloadWorkloads = (request: ListWorkloadsRequest) => {
  const [orgUuid] = useOrgSelection()

  const params = useRequestParams({
    request: {
      ...request,
      filter: {
        ...request.filter,
        orgUuids: orgUuid,
        // reverse the filter values for Workload Status, since in the UI the labels are reversed: checked means enabled, while for the API this means hidden.
        hideCnatixManagedWorkload: !request.filter?.hideCnatixManagedWorkload,
        hideSystemWorkload: !request.filter?.hideSystemWorkload,
        hideDeletedWorkload: !request.filter?.hideDeletedWorkload,
      },
    },
  })

  return useMutation((filetype: string) => {
    return fetchData<ArrayBuffer>(
      '/v1/dashboard/workloads:download',
      {
        ...params,
        filetype,
      },
      { responseType: 'arraybuffer' },
    )
  })
}
