import React from 'react'
import { CodeSnippet } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components'
import Popover from 'src/next/components/ui/Popover'

const codeSnippetStyle = css`
  background-color: var(--cds-layer-selected-inverse);
  color: var(--cds-layer-02);

  &.cds--snippet--multi .cds--snippet-container {
    min-height: 16px !important;

    pre {
      padding-bottom: 0;
    }
  }

  button {
    background-color: var(--cds-layer-selected-inverse);
    border: 1px solid var(--cds-border-subtle-01);
    position: relative;

    &:hover,
    &:focus {
      background-color: inherit;
      border: 1px solid var(--cds-button-primary-active);
      outline: none;

      .cds--snippet__icon {
        fill: var(--cds-button-primary-active);
      }
    }

    .cds--snippet__icon {
      fill: var(--cds-layer-01);
      left: 6px;
      position: absolute;
      z-index: 1;
    }
  }
`

interface WorkloadLabelPopoverProps {
  id: string
  codeSnippet: string
  buttonContents: React.ReactNode
}

export const WorkloadCellPopover = ({
  id,
  buttonContents,
  codeSnippet,
}: WorkloadLabelPopoverProps) => {
  const { t } = useTranslation()

  return (
    <Popover
      data-testid={id}
      buttonContents={buttonContents}
      panelContents={
        <CodeSnippet
          css={codeSnippetStyle}
          feedback={t('Common.CopiedToClipboard')}
          type="multi"
          wrapText
        >
          {codeSnippet}
        </CodeSnippet>
      }
    />
  )
}
