import React from 'react'
import { createRoot } from 'react-dom/client'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import minMax from 'dayjs/plugin/minMax'
import { App } from './App/App'

import { unregister } from './registerServiceWorker'
import './App/i18n'
import '@ibm/plex/css/ibm-plex.min.css'

// Add Sentry
import 'src/next/utils/sentry'

import 'src/next/utils/consoleIgnore'
import 'dayjs/locale/ja'

// Day.js locale/plugins
// require('dayjs/locale/ja')
dayjs.extend(LocalizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(minMax)

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)

unregister()
