import React, { ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'

export const SidebarContainer = styled.div`
  display: flex;
  gap: var(--cds-spacing-05);
`

export interface SidebarProps extends ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode
}

export const Sidebar = ({ children, ...props }: SidebarProps) => {
  return <SidebarContainer {...props}>{children}</SidebarContainer>
}

const SidebarPanel = styled.div<{ $open?: boolean; $width?: string | number }>`
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  width: ${({ $width = 250 as string | number }) =>
    typeof $width === 'string' ? $width : `${$width}px`};
`
Sidebar.Panel = SidebarPanel

const SidebarMainContent = styled.div`
  flex: 1;
`
Sidebar.MainContent = SidebarMainContent
